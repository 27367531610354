import _reject from 'lodash/reject';
import { TOAST_CLOSE, TOAST_HIDE, TOAST_SHOW } from './actions';

const defaultState = {
	active: []
};

export default function toasts(state = defaultState, action) {
	switch (action.type) {
	case TOAST_SHOW:
		return {
			...state,
			active: [action.payload, ...state.active]
		};
	case TOAST_HIDE:
		return {
			...state,
			active: state.active.map(t => {
				if (t.uuid === action.uuid) {
					return { ...t, visible: false };
				}
				return t;
			})
		};
	case TOAST_CLOSE:
		return {
			...state,
			active: _reject(state.active,{ uuid: action.uuid })
		};
	}

	return state;
}
