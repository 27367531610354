import React, { PureComponent } from 'react';
import _debounce from 'lodash/debounce';
import { filterOptions, getOptionsPage, pageSize } from './dropdownOptionsPaginator';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const DEBOUNCE_IN_MILLIS = 400;

export default class PagedSelect extends PureComponent {
	constructor(props) {
		super(props);

		this.state = { inputValue: '', ...this.calculateInitialState(props) };
		this.applySearchingDebounced = _debounce(this.applySearching, DEBOUNCE_IN_MILLIS);
		this.inputRef = React.createRef();
	}

	calculateInitialState = (props) => {
		return {
			currentOptions: props.options,
			visibleOptions: getOptionsPage(props.options),
			isMore: props.options.length > pageSize,
			page: 0,
			isLoading: false
		};
	};

	shouldBePaged = () => {
		return this.props.options && this.props.options.length > pageSize * 2;
	};

	componentDidUpdate(prevProps) {
		if (this.props.autoFocus) {
			this.inputRef.focus();
		}
		if (prevProps.options !== this.props.options && this.shouldBePaged()) {
			this.onSearchTermChange(this.state.inputValue, true);
		}
	}

	componentDidMount() {
		if (this.props.autoFocus) {
			this.inputRef.focus();
		}
	}

	handleInputValueChange = (value) => {
		this.setState({ inputValue: value });
		this.onSearchTermChange(value);
	};

	onSearchTermChange = (searchTerm, immediate) => {
		if (!searchTerm) {
			this.applySearchingDebounced.cancel();
			this.setState(this.calculateInitialState(this.props));
		} else {
			// empty array to force scroll back to top
			this.setState({ visibleOptions: [], isLoading: true }, () => {
				immediate ? this.applySearching(searchTerm) : this.applySearchingDebounced(searchTerm);
			});
		}
	};

	applySearching = (searchTerm) => {
		const filteredOptions = filterOptions(this.props.options, searchTerm, this.props.filterOption);
		const visibleOptions = getOptionsPage(filteredOptions);
		this.setState({
			currentOptions: filteredOptions,
			visibleOptions,
			isMore: filteredOptions.length > pageSize,
			page: 0,
			isLoading: false
		});
	};

	onBottomReached = () => {
		if (this.state.isMore) {
			const nextPageNumber = this.state.page + 1;
			const nextPage = getOptionsPage(this.state.currentOptions, nextPageNumber);
			this.setState({
				visibleOptions: [...this.state.visibleOptions, ...nextPage],
				isMore: this.state.currentOptions.length > (nextPageNumber + 1) * pageSize,
				page: nextPageNumber
			});
		}
	};

	onCreateOption = (value) => {
		this.props.options.push({ value: value, label: value });
		this.props.onChange({ value: value, label: value });
	};

	handleIsValidNewOption = (inputValue, selectValue, selectOptions) => {
		const exactValueExists = selectOptions.find(option => option.value === inputValue);
		const valueIsNotEmpty = inputValue.trim().length;
		return !exactValueExists && valueIsNotEmpty;
	};

	renderSelect = () => {
		if (this.shouldBePaged() && this.props.creatable) {
			return <CreatableSelect
				{...this.props}
				ref={(input) => { this.inputRef = input; }}
				onCreateOption={this.onCreateOption}
				onChange={this.props.onChange}
				formatCreateLabel={inputValue => `${inputValue}`}
				createOptionPosition="first"
				isValidNewOption={this.handleIsValidNewOption}
				isLoading={this.props.isLoading || this.state.isLoading}
				options={this.state.visibleOptions}
				onInputChange={this.handleInputValueChange}
				inputValue={this.state.inputValue}
				filterOption={null}
				onMenuScrollToBottom={this.onBottomReached}
			/>;
		}
		if (this.shouldBePaged()) {
			return <Select
				{...this.props}
				ref={(input) => { this.inputRef = input; }}
				isLoading={this.props.isLoading || this.state.isLoading}
				options={this.state.visibleOptions}
				onInputChange={this.handleInputValueChange}
				inputValue={this.state.inputValue}
				filterOption={null}
				onMenuScrollToBottom={this.onBottomReached}
			/>;
		}
		if (this.props.creatable) {
			return <CreatableSelect
				{...this.props}
				ref={(input) => { this.inputRef = input; }}
				onCreateOption={this.onCreateOption}
				onChange={this.props.onChange}
				formatCreateLabel={inputValue => `${inputValue}`}
				createOptionPosition="first"
				isValidNewOption={this.handleIsValidNewOption}
			/>;
		}

		return <Select ref={(input) => { this.inputRef = input; }} {...this.props} />;
	};

	render() {
		return this.renderSelect();
	}
}
