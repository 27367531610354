/* eslint-disable no-magic-numbers */
import { thousandsGroupStyleTypes } from './constants';

export default function getThousandsGroupStyle(group) {
	switch (group) {
	case 4: {
		return thousandsGroupStyleTypes.wan;
	}
	case 3: {
		return thousandsGroupStyleTypes.thousand;
	}
	case 2: {
		return thousandsGroupStyleTypes.lakh;
	}
	case 0: {
		return false;
	}
	default: {
		return true;
	}
	}
}
