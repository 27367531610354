import { apiDelete, apiPost, apiVersionV3 } from '../../fetch/apiService';

const ENDPOINT = 'achievement-type-icons';

export const createAchievementTypeIcon = achievementTypeIcon => apiPost(
	ENDPOINT,
	achievementTypeIcon,
	{},
	{},
	apiVersionV3,
);

export const deleteAchievementTypeIcon = achievementTypeIconId => apiDelete(
	`${ENDPOINT}/${achievementTypeIconId}`,
	{},
	{},
	{},
	apiVersionV3,
);
