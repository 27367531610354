import { apiDelete, apiGet, apiPost, apiVersionV3 } from '../../fetch/apiService';

const ENDPOINT = 'posts';

export const getPosts = (parameters) => apiGet(
	ENDPOINT,
	parameters,
	{},
	apiVersionV3,
);

export const createPost = (payload) => apiPost(
	ENDPOINT,
	payload,
	{},
	{},
	apiVersionV3,
);

export const deletePost = (postId) => apiDelete(
	`${ENDPOINT}/${postId}`,
	{},
	{},
	{},
	apiVersionV3,
);

export const addPostReaction = (postId, request) => apiPost(
	`${ENDPOINT}/${postId}/add_reaction`,
	request,
	{},
	{},
	apiVersionV3,
);

export const removePostReaction = (postId, request) => apiPost(
	`${ENDPOINT}/${postId}/remove_reaction`,
	request,
	{},
	{},
	apiVersionV3,
);

