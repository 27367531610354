import { takeLatest, select, put } from '@redux-saga/core/effects';
import { getDevices } from './api';
import apiSagaGenerator from '../../saga/apiSagaGenerator';
import { FETCH, fetchDevicesSuccess, fetchDevicesError, FETCH_IF_NEEDED, fetchDevices } from './actions';
import { getDevicesStale } from './selectors';

const devicesSagas = [
	takeLatest(FETCH, 
		apiSagaGenerator()
			.forApi(getDevices)
			.withArguments(action => [action.deviceType])
			.forSuccess(fetchDevicesSuccess)
			.withActionArguments(action => [action.deviceType])
			.forError(fetchDevicesError)
			.withMessage('Error loading devices')
			.generate()
	),
	takeLatest(FETCH_IF_NEEDED, fetchIfNeeded),
];

function* fetchIfNeeded(action) {
	const { deviceType } = action;
	const isStale = yield select(getDevicesStale, deviceType);
	if (isStale) {
		yield put(fetchDevices(deviceType));
	}
}

export default devicesSagas;
