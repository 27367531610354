const numberFormat = {
	TextOutput: 'TextOutput',
	DecimalOutput: 'DecimalOutput',
	BooleanOutput: 'BooleanOutput',
	PercentOutput: 'PercentOutput',
	DurationOutput: 'DurationOutput',
	DateTimeOutput: 'DateTimeOutput',
	DateOutput: 'DateOutput',
	TimeSinceOutput: 'TimeSinceOutput',
};

export default numberFormat;
