import { useCallback, useEffect, useMemo, useState } from 'react';
import _difference from 'lodash/difference';
import { FilterItem } from './types';

type Props = {
	selectableItems: FilterItem[];
	initiallySelected: string[];
	onSelectionChange?: (ids: string[]) => unknown;
}

export function useFilterSelection({ selectableItems, initiallySelected = [], onSelectionChange }: Props) {
	const [selection, setSelection] = useState<string[]>(initiallySelected);
	const [selectionChanged, setChanged] = useState<boolean>(false);

	const uuids = selectableItems.map(item => item.id);

	const restore = useCallback(() => setSelection(initiallySelected), [initiallySelected]);

	const markSelectionDirtyAfterChange = useCallback((change: string[]) => {
		onSelectionChange?.(change);
		setChanged(true);
	}, [onSelectionChange]);

	const onSelect = useCallback((uuid: string) => {
		const change = [...selection, uuid];
		setSelection(change);
		markSelectionDirtyAfterChange(change);
	}, [markSelectionDirtyAfterChange, selection]);

	const onSelectMany = useCallback((uuids: string[]) => {
		setSelection(uuids);
		markSelectionDirtyAfterChange(uuids);
	} , [markSelectionDirtyAfterChange]);

	const onDeselectMany = useCallback((uuids: string[]) => {
		const change = selection.filter(item => !uuids.includes(item));
		setSelection(change);
		markSelectionDirtyAfterChange(change);
	}, [markSelectionDirtyAfterChange, selection]);

	const onDeselect = useCallback((uuid: string) => {
		const change = selection.filter((item) => item !== uuid);
		setSelection(change);
		markSelectionDirtyAfterChange(change);
	}, [markSelectionDirtyAfterChange, selection]);

	const onDeselectAll = useCallback(() => {
		setSelection([]);
		markSelectionDirtyAfterChange([]);
	}, [markSelectionDirtyAfterChange]);

	const onToggle = useCallback((uuid: string) =>
		selection.indexOf(uuid) > -1 ? onDeselect(uuid) : onSelect(uuid), [onDeselect, onSelect, selection]);

	const allSelected = _difference(uuids, selection).length === 0;

	const onToggleMany = useCallback(() => allSelected ? onDeselectMany(uuids) : onSelectMany(uuids),
		[allSelected, onDeselectMany, onSelectMany, uuids]);

	useEffect(restore, [restore]);

	return useMemo(() => ({
		onDeselectAll,
		onToggle,
		onToggleMany,
		allSelected,
		restore,
		selectionChanged,
		selection,
	}), [allSelected, onDeselectAll, onToggle, onToggleMany, restore, selection, selectionChanged]);
}
