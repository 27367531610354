import { apiDelete, apiGet, apiPost, apiPut } from '../../fetch/apiService';

export function getReportSchedule(reportId) {
	return apiGet(`reports/${reportId}/schedules`);
}

export function createReportSchedule(reportId, schedule) {
	return apiPost(`reports/${reportId}/schedules`, schedule);
}

export function updateReportSchedule(reportId, scheduleId, schedule) {
	return apiPut(`reports/${reportId}/schedules/${scheduleId}`, schedule);
}

export function deleteReportSchedule(reportId, scheduleId) {
	return apiDelete(`reports/${reportId}/schedules/${scheduleId}`);
}
