import { FETCH, FETCH_SUCCESS, FETCH_API_ERROR, CLEAR } from './actions';
import _omit from 'lodash/omit';

const initialState = {
};

const timePeriod = (state = initialState, action) => {
	switch (action.type) {
	case FETCH:
		return {
			...state,
			[action.operationId]: {
				data: {},
				...state[action.operationId],
				isFetching: true,
				callId: action.callId,
			}
		};
	case FETCH_SUCCESS:
		if (state[action.operationId] && state[action.operationId].callId === action.callId) {
			return {
				...state,
				[action.operationId]: {
					...state[action.operationId],
					data: action.result,
					isFetching: false,
					error: null,
				}
			};
		}
		break;
	case FETCH_API_ERROR:
		if (state[action.operationId] && state[action.operationId].callId === action.callId) {
			return {
				...state,
				[action.operationId]: {
					...state[action.operationId],
					isFetching: false,
					error: action.error,
				}
			};
		}
		break;
	case CLEAR: 
		return _omit(state, action.operationId);
	default:
		return state;
	}

	return state;
};

export default timePeriod;
