import React, { memo, useContext, useMemo } from 'react';
import Dropdown from '../../common/dropdown/Dropdown';
import styled from 'styled-components';
import TimePeriodSelectorContext from '../TimePeriodSelectorContext';
import { getGranularityModeName } from '../logic/translations';

const S = {};

S.GranularityDropdown = styled(Dropdown)`
	margin-left: 6px;
		flex: 2 1 auto;
		max-width: 8em;
		display: inline-block;
		border-bottom: 2px solid transparent;
		cursor: pointer;
		width: auto;
		font-weight: 400;

		.react-select__control {
			color: #416871;
			background-color: #d6e0e0;
			border-color: #d6e0e0;
			font-size: 12px;
		}

		.react-select__single-value,
		.react-select__dropdown-indicator {
			color: #416871;
		}
`;

function GranularitySelector() {
	const { granularityOptions, onChangeGranularity, granularity } = useContext(TimePeriodSelectorContext);

	const availableOptions = useMemo(() =>
		granularityOptions.map(value => ({ label: getGranularityModeName(value), value }))
	, [granularityOptions]);

	return <S.GranularityDropdown
		className='time-period-granularity-selector'
		onValueChange={onChangeGranularity}
		value={granularity}
		options={availableOptions}
	/>;
}

export default memo(GranularitySelector);
