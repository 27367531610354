import moment from 'moment-timezone';

const format = 'MMM D, YYYY';
const formatWithTime = 'MMM D, YYYY HH:mm';

const formatShort = 'L';

export function formatDate(date, timeZone) {
	let momentDate = moment(date);
	if (timeZone) {
		momentDate = momentDate.tz(timeZone);
	}
	return momentDate.format(format);
}

export function formatDateTime(date, timeZone) {
	let momentDate = moment(date);
	if (timeZone) {
		momentDate = momentDate.tz(timeZone);
	}
	return momentDate.format(formatWithTime);
}

export function formatDateShort(date) {
	return moment(date).format(formatShort);
}

export function getEndOfDay(date) {
	const endOfDay = new Date(date.getTime());
	const endOfDayHours = 23;
	const endOfDayMinutes = 59;
	const endOfDaySeconds = 59;
	const endOfDayMillis = 999;
	endOfDay.setHours(endOfDayHours,endOfDayMinutes,endOfDaySeconds,endOfDayMillis);
	return endOfDay;
}

export function getStartOfDay(date) {
	const startOfDay = new Date(date.getTime());
	startOfDay.setHours(0,0,0,0);
	return startOfDay;
}

export function getStartOfNextHour(date) {
	const startOfNextHour = new Date(date.getTime());
	startOfNextHour.setHours(date.getHours() + 1, 0, 0, 0);
	return startOfNextHour;
}

