import _isFunction from 'lodash/isFunction';

export default function updateStateById(state, id, updater) {
	const currentState = state[id] || {};
	const change = _isFunction(updater) ? updater(currentState) : updater;
	if (change) {
		return {
			...state,
			[id]: {
				...(state[id] || {}),
				...change
			}
		};
	}
	return state;
}
