import React, { memo } from 'react';
import Separator from './Separator';
import { getOptionPathValue } from '../optionsService';
import Item from './Item';
import CustomItem from './CustomItem';
import InfoItem from './InfoItem';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledMenu = styled.ul`
	max-height: 350px;
	overflow-y: auto;
	padding: 0;
	margin: 0;
	list-style: none;

	@media (max-width: 768px) {
		max-width: 100%;
		width: 100%;
	}
`;

function Menu({
	options, value, currentPath, onExpand, onSelect, onSelectIcon, fieldRelatioSelectionAllowed, nodeRef
}) {
	const { t } = useTranslation();
	return <StyledMenu className="multi-level-dropdown" ref={nodeRef}>
		{options && options.length ? options.map(option =>
			option.separator ? <Separator key={option.value} /> : option.component ?
				<CustomItem
					key={option.value}
					className={option.className}
					onExpand={onExpand}
					selected={getOptionPathValue(currentPath, option.value) === value}
					{...option}
				/> : <Item
					data={option.data}
					key={option.value}
					className={option.className}
					onExpand={onExpand}
					onSelect={onSelect}
					onSelectIcon={onSelectIcon}
					fieldRelatioSelectionAllowed={fieldRelatioSelectionAllowed}
					selected={getOptionPathValue(currentPath, option.value) === value}
					{...option}
				/>
		) :
			<InfoItem label={t('No options')}/>}
	</StyledMenu>;
}

export default memo(Menu);
