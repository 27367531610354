import React from 'react';
import DateRangeSelector from '../../common/dateRange/DateRangeSelector';

export default function CalendarTab({ customDate: { startDate, endDate }, actions, onPreviewChange }) {
	return (
		<>
			<div className="time-period-calendar">
				<DateRangeSelector
					timeFrom={startDate} 
					timeTo={endDate} 
					onChange={actions.changeCustomDateRange}
					onPreviewChange={onPreviewChange}/>
			</div>
		</>
	);
}
