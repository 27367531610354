import { takeEvery } from '@redux-saga/core/effects';
import * as timePeriodActions from './actions';
import * as api from './api';
import apiSagaGenerator from '../../saga/apiSagaGenerator';

const timePeriodSagas = [
	takeEvery(timePeriodActions.FETCH, apiSagaGenerator()
		.forApi(api.fetchPreview)
		.withArguments(action => [action.parameters])
		.forSuccess(timePeriodActions.fetchTimePeriodPreviewSuccess)
		.withActionArguments(action => [action.operationId])
		.forError(timePeriodActions.fetchTimePeriodPreviewError)
		.withMessage('Error fetching time period preview')
		.generate()),
	
];

export default timePeriodSagas;
