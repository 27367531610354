import { useEffect } from 'react';

let passiveSupported = undefined;

const whiteSpaceRegex = /[\s]+/;

export default function usePassiveEventListener(ref, eventsString, callback) {
	useEffect(() => {
		if (passiveSupported === undefined) {
			passiveSupported = false;

			try {
				const options = {
					get passive() { // This function will be called when the browser
						//   attempts to access the passive property.
						passiveSupported = true;
						return false;
					},
				};

				window.addEventListener('test', null, options);
				window.removeEventListener('test', null, options);
			} catch (err) {
				passiveSupported = false;
			}
		}

		const refElement = ref?.current ? ref.current : window;

		const eventCallback = () => callback(refElement);

		const events = eventsString.split(whiteSpaceRegex);
		for (const event of events) {
			refElement.addEventListener(event, eventCallback, passiveSupported ? { passive: true } : false);
		}
		return () => {
			for (const event of events) {
				refElement?.removeEventListener(event, eventCallback);
			}
		};
	}, [callback, eventsString, ref]);
}
