import { takeLatest } from '@redux-saga/core/effects';
import * as teamsActions from './actions';
import * as api from './api';
import apiSagaGenerator from '../../saga/apiSagaGenerator';

const myTeamsSagas = [takeLatest(teamsActions.FETCH,
	apiSagaGenerator()
		.forApi(api.fetchTeams)
		.withArguments((action) => [action.datasource])
		.forSuccess(teamsActions.fetchTeamsSuccess)
		.forError(teamsActions.fetchTeamsError)
		.includeErrorResult(result => result.detail)
		.withMessage('Error getting teams')
		.generate()
)];

export default myTeamsSagas;
