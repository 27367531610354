import React from 'react';
import {
	StyledDropdownItemTitle,
	StyledDropdownInfo,
} from './styledComponents';

export default function InfoItem({ label }) {
	return <StyledDropdownInfo className="multi-level-dropdown-item multi-level-dropdown-item-info">
		<StyledDropdownItemTitle className="multi-level-dropdown-item-title">{label}</StyledDropdownItemTitle>
	</StyledDropdownInfo>;
}
