import React, { memo, useMemo } from 'react';
import { periods } from '../logic/constants';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropdown from '../../common/dropdown/Dropdown';
import NumberInput from '../../common/numberInput/NumberInput';
import styled, { css } from 'styled-components';
import { getPeriodNamePlural } from '../logic/translations';

const numberWidth = '4em';
const dropdownWidth = '7em';
const periodWidth = '8.5em';
const controlsHeight = '30px';
const S = {};
S.ModeSelector = styled.div`
	flex-basis: auto;
	${props => props.label ? css`
		display: flex;
		margin-left: 20px;
	` : ''}
`;
S.Amount = styled(NumberInput)`
	flex: 1 2 ${numberWidth};
	padding: 4px 10px;
	height: ${controlsHeight};
`;
S.Mode = styled(Dropdown)`
	flex: 2 1 ${dropdownWidth};
	max-width: ${dropdownWidth};

	.react-select__control {
		min-width: initial;
	}
`;

S.Period = styled(Dropdown)`
	flex: 2 1 ${periodWidth};
	max-width: ${periodWidth};

	.react-select__control {
		min-width: initial;
	}
`;

S.Label = styled.span`
	color: #666666;
	flex: 0 1 auto;
`;

function RelativeModeSelector({
	className,
	mode,
	onModeChange,
	period,
	modesOptions,
	periodsOptions,
	fixedMode,
	onPeriodChange,
	amount,
	onAmountChange,
	minimumAmount,
	maximumAmount,
	fixedAmount,
	disabled,
	label
}) {
	const pluralizeOptions = amount > 1;
	const periodOptionsPluralized = useMemo(() => {
		if (pluralizeOptions) {
			return periodsOptions.map(op => ({
				...op,
				label: getPeriodNamePlural(op.value)
			}));
		} else {
			return periodsOptions;
		}
	}, [periodsOptions, pluralizeOptions]);
	
	return <S.ModeSelector className={classNames('mode-selector',className)} label={label}>
		{!!label && <S.Label className={classNames('mode-selector-label', { 'text-muted': disabled })}>
			{label}
		</S.Label>}
		{!fixedMode && <S.Mode
			className='mode-selector-mode'
			onValueChange={onModeChange}
			value={mode}
			options={modesOptions}
			disabled={disabled}
		/>}
		{!fixedAmount && <S.Amount 
			className='mode-selector-amount'
			value={amount}
			onValueChange={onAmountChange}
			min={minimumAmount || 1}
			max={maximumAmount}
			disabled={fixedAmount || disabled}
			dynamicWidth
		/>}
		<S.Period
			className='mode-selector-period'
			onValueChange={onPeriodChange}
			value={period}
			options={periodOptionsPluralized}
			disabled={disabled}
		/>	
	</S.ModeSelector>;
}

RelativeModeSelector.propTypes = {
	amount: PropTypes.number,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	fixedAmount: PropTypes.bool,
	label: PropTypes.string,
	maximumAmount: PropTypes.number,
	minimumAmount: PropTypes.number,
	mode: PropTypes.string.isRequired,
	modesOptions: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string
	})).isRequired,
	onAmountChange: PropTypes.func.isRequired,
	onModeChange: PropTypes.func.isRequired,
	onPeriodChange: PropTypes.func.isRequired,
	period: PropTypes.oneOf(Object.keys(periods)),
	periodsOptions: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string
	})).isRequired,
};

export default memo(RelativeModeSelector);
