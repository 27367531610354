import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export default function ConfirmDeleteModal({ objectType, objectName, relatedItems, onConfirmed, onClose }) {

	const { t } = useTranslation();

	const onConfirmedCallback = useCallback(() => {
		onClose();
		onConfirmed();
	},[onClose, onConfirmed]);

	return <div
		className="modal"
		role="dialog"
		aria-labelledby="form-modal"
		aria-hidden="false"
		style={{ display: 'block', opacity: 1,  marginTop: '0px' }}
	>
		<div className="modal-backdrop in" onClick={onClose}></div>
		<div className="modal-dialog">
			<div className="modal-content">
				<div data-form-modal-container="">
					<div className="pane-title-lg pane-warning">
						<div className="pane-title-left">
							<h1 className="pane-title-h1">
								<span className="icon icon-warning pane-title-icon-delete"></span>{t('Warning!')}
							</h1>
							<p>{t('Are you sure you want to delete these objects? This action cannot be reverted.')}</p>
						</div>
					</div>
					<div className="pane-content">
						<h4>{objectType}: <strong>{objectName}</strong></h4>
						{relatedItems && relatedItems.length ?
							<div className="table-responsive">
								<table className="table table-condensed nomargin">
									<thead>
										<tr><th>{t('The following related items will be deleted:')}</th></tr>
									</thead>
									<tbody>
										{relatedItems.map(item =>
											<tr key={item.id}><td>{`${item.type}: ${item.name}`}</td></tr>)}
									</tbody>
								</table>
							</div> : null}
						<div className="spacer"></div>
						<div className="clearfix">
							<div className="pull-right">
								<button
									type="button"
									onClick={onConfirmedCallback}
									className="btn btn-lg btn-danger"
									data-submit-animation="true"
								>
									{t('Confirm delete')}
								</button>
							</div>
							<div className="pull-left">
								<button
									type="button"
									onClick={onClose}
									className="btn btn-default nomargin"
									data-toggle="modal-close"
								>
									{t('Cancel')}
								</button>
							</div>
						</div>
					</div>

				</div></div></div></div>;
}
