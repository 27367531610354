import generateUuid from '../../utils/generateUuid';

export const LOAD = 'plecto/directory/LOAD';

export const loadDirectory = (directoryId, folderUuid, shouldReloadItemsOnly) => ({
	type: LOAD,
	directoryId,
	folderUuid,
	shouldReloadItemsOnly
});

export const LOAD_ITEMS = 'plecto/directory/LOAD_ITEMS';
export const LOAD_ITEMS_SUCCESS = 'plecto/directory/LOAD_ITEMS_SUCCESS';
export const LOAD_ITEMS_API_ERROR = 'plecto/directory/LOAD_ITEMS_API_ERROR';

export const loadItems = (directoryId, folderUuid, page, pageSize) => ({
	type: LOAD_ITEMS,
	directoryId,
	folderUuid,
	page,
	pageSize
});

export const loadItemsSuccess = (directoryId, folderUuid, page, pageSize, payload) => ({
	type: LOAD_ITEMS_SUCCESS,
	directoryId,
	folderUuid,
	page,
	pageSize,
	payload
});

export const loadItemsFailure = (directoryId, folderUuid, page, pageSize, error) => ({
	type: LOAD_ITEMS_API_ERROR,
	directoryId,
	folderUuid,
	page,
	pageSize,
	error
});

export const FETCH_ITEMS_BY_FOLDERS = 'plecto/directory/FETCH_ITEMS_BY_FOLDERS';
export const FETCH_ITEMS_BY_FOLDERS_SUCCESS = 'plecto/directory/FETCH_ITEMS_BY_FOLDERS_SUCCESS';
export const FETCH_ITEMS_BY_FOLDERS_API_ERROR = 'plecto/directory/FETCH_ITEMS_BY_FOLDERS_API_ERROR';

export const fetchItemsByFolders = (directoryId, folderIds) => ({
	type: FETCH_ITEMS_BY_FOLDERS,
	directoryId,
	folderIds
});

export const fetchItemsByFoldersSuccess = (directoryId, folderIds, payload) => ({
	type: FETCH_ITEMS_BY_FOLDERS_SUCCESS,
	directoryId,
	folderIds,
	payload,
});

export const fetchItemsByFoldersFailure = (directoryId, error) => ({
	type: FETCH_ITEMS_BY_FOLDERS_API_ERROR,
	directoryId,
	error
});

export const LOAD_FOLDERS = 'plecto/directory/LOAD_FOLDERS';
export const LOAD_FOLDERS_SUCCESS = 'plecto/directory/LOAD_FOLDERS_SUCCESS';
export const LOAD_FOLDERS_API_ERROR = 'plecto/directory/LOAD_FOLDERS_API_ERROR';

export const loadFolders = (directoryId, folderUuid) => ({
	type: LOAD_FOLDERS,
	directoryId,
	folderUuid,
});

export const loadFoldersSuccess = (directoryId, folderUuid, payload) => ({
	type: LOAD_FOLDERS_SUCCESS,
	directoryId,
	folderUuid,
	payload
});

export const loadFoldersFailure = (directoryId, folderUuid, error) => ({
	type: LOAD_FOLDERS_API_ERROR,
	directoryId,
	folderUuid,
	error
});

export const CREATE_FOLDER = 'plecto/directory/CREATE_FOLDER';
export const CREATE_FOLDER_SUCCESS = 'plecto/directory/CREATE_FOLDER_SUCCESS';
export const CREATE_FOLDER_API_ERROR = 'plecto/directory/CREATE_FOLDER_API_ERROR';

export const createFolder = (directoryId, folder, callback) => ({
	type: CREATE_FOLDER,
	directoryId,
	folder,
	callback,
	operationId: generateUuid()
});

export const createFolderSuccess = (directoryId, folder, callback, operationId, payload) => ({
	type: CREATE_FOLDER_SUCCESS,
	directoryId,
	folder,
	callback,
	operationId,
	payload,
});

export const createFolderFailure = (directoryId, folder, operationId, error) => ({
	type: CREATE_FOLDER_API_ERROR,
	directoryId,
	folder,
	operationId,
	error
});

export const RENAME_FOLDER = 'plecto/directory/RENAME_FOLDER';
export const RENAME_FOLDER_SUCCESS = 'plecto/directory/RENAME_FOLDER_SUCCESS';
export const RENAME_FOLDER_API_ERROR = 'plecto/directory/RENAME_FOLDER_API_ERROR';

export const renameFolder = (directoryId, folderUuid, name) => ({
	type: RENAME_FOLDER,
	directoryId,
	folderUuid,
	name
});

export const renameFolderSuccess = (directoryId, folderUuid, name, payload) => ({
	type: RENAME_FOLDER_SUCCESS,
	directoryId,
	folderUuid,
	payload,
	name
});

export const renameFolderFailure = (directoryId, folderUuid, name, error) => ({
	type: RENAME_FOLDER_API_ERROR,
	directoryId,
	folderUuid,
	name,
	error
});

export const ENQUEUE_MOVE_ITEMS = 'plecto/directory/ENQUEUE_MOVE_ITEMS';

export const enqueueMoveItems = (directoryId, folder, items = [], folders = []) => ({
	type: ENQUEUE_MOVE_ITEMS,
	directoryId,
	folder,
	items: items || [],
	folders: folders || []
});

export const MOVE_ITEMS_COMPLETED = 'plecto/directory/MOVE_ITEMS_COMPLETED';

export const moveItemsCompleted = (directoryId, folder, items, folders) => ({
	type: MOVE_ITEMS_COMPLETED,
	directoryId,
	folder,
	items,
	folders
});

export const MOVE_ITEMS = 'plecto/directory/MOVE_ITEMS';
export const MOVE_ITEMS_SUCCESS = 'plecto/directory/MOVE_ITEMS_SUCCESS';
export const MOVE_ITEMS_API_ERROR = 'plecto/directory/MOVE_ITEMS_API_ERROR';

export const moveItems = (directoryId, folder, items = [], folders = []) => ({
	type: MOVE_ITEMS,
	directoryId,
	folder,
	items: items || [],
	folders: folders || []
});

export const moveItemsSuccess = (directoryId, folder, items, folders, payload) => ({
	type: MOVE_ITEMS_SUCCESS,
	directoryId,
	folder,
	payload,
	items,
	folders
});

export const moveItemsFailure = (directoryId, folder, items, folders, error) => ({
	type: MOVE_ITEMS_API_ERROR,
	directoryId,
	folder,
	items,
	folders,
	error
});

export const DELETE_FOLDER = 'plecto/directory/DELETE_FOLDER';
export const DELETE_FOLDER_SUCCESS = 'plecto/directory/DELETE_FOLDER_SUCCESS';
export const DELETE_FOLDER_API_ERROR = 'plecto/directory/DELETE_FOLDER_API_ERROR';

export const deleteFolder = (directoryId, folderUuid) => ({
	type: DELETE_FOLDER,
	directoryId,
	folderUuid,
});

export const deleteFolderSuccess = (directoryId, folderUuid) => ({
	type: DELETE_FOLDER_SUCCESS,
	directoryId,
	folderUuid
});

export const deleteFolderFailure = (directoryId, folderUuid, error) => ({
	type: DELETE_FOLDER_API_ERROR,
	directoryId,
	folderUuid,
	error
});

export const BULK_DELETE_ITEMS = 'plecto/directory/BULK_DELETE_ITEMS';
export const BULK_DELETE_ITEMS_SUCCESS = 'plecto/directory/BULK_DELETE_ITEMS_SUCCESS';
export const BULK_DELETE_ITEMS_API_ERROR = 'plecto/directory/BULK_DELETE_ITEMS_API_ERROR';

export const bulkDeleteItems = (directoryId, { items, folders }, itemReplacements) => ({
	type: BULK_DELETE_ITEMS,
	directoryId,
	items,
	folders,
	itemReplacements
});

export const bulkDeleteItemsSuccess = (directoryId, items, folders) => ({
	type: BULK_DELETE_ITEMS_SUCCESS,
	directoryId,
	items,
	folders
});

export const bulkDeleteItemsFailure = (directoryId, error) => ({
	type: BULK_DELETE_ITEMS_API_ERROR,
	directoryId,
	error
});

export const SEARCH = 'plecto/directory/SEARCH';
export const SEARCH_SUCCESS = 'plecto/directory/SEARCH_SUCCESS';
export const SEARCH_API_ERROR = 'plecto/directory/SEARCH_API_ERROR';

export const search = (directoryId, query) => ({
	type: SEARCH,
	directoryId,
	query
});

export const searchSuccess = (directoryId, query, payload) => ({
	type: SEARCH_SUCCESS,
	directoryId,
	query,
	payload
});

export const searchFailure = (directoryId, query, error) => ({
	type: SEARCH_API_ERROR,
	directoryId,
	query,
	error
});

export const CANCEL_SEARCH = 'plecto/directory/CANCEL_SEARCH';

export const cancelSearch = (directoryId) => ({
	type: CANCEL_SEARCH,
	directoryId
});

export const PREPARE_MOVE_SIDE_STATE = 'plecto/directory/PREPARE_MOVE_SIDE_STATE';

export const prepareMoveSideState = (directoryId) => ({
	type: PREPARE_MOVE_SIDE_STATE,
	directoryId
});

export const RELOAD_FOLDER = 'plecto/directory/RELOAD_FOLDER';

export const reloadFolder = (directoryId, folderId, pagesToReload) => ({
	type: RELOAD_FOLDER,
	directoryId,
	folderId,
	pagesToReload
});
