import { apiGet, apiPost, apiPut, apiVersionV3 } from '../../fetch/apiService';

const ACHIEVEMENT_TYPES_ENDPOINT = 'achievement-types';

export function fetchAchievementTypes() {
	return apiGet(
		ACHIEVEMENT_TYPES_ENDPOINT,
		{},
		{},
		apiVersionV3,
	);
}

export function createAchievementType(achievementType) {
	return apiPost(
		ACHIEVEMENT_TYPES_ENDPOINT,
		achievementType,
		{},
		{},
		apiVersionV3,
	);
}

export function updateAchievementType(achievementTypeId, achievementType) {
	return apiPut(
		`${ACHIEVEMENT_TYPES_ENDPOINT}/${achievementTypeId}`,
		achievementType,
		{},
		{},
		apiVersionV3,
	);
}

export function giveAchievementType(achievementTypeId, payload) {
	return apiPost(
		`${ACHIEVEMENT_TYPES_ENDPOINT}/${achievementTypeId}/give/`,
		payload,
		{},
		{},
		apiVersionV3,
	);
}
