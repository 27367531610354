export default function convertToMomentLocale(organizationLocale) {
	switch (organizationLocale) {
	case 'da_DK':
		return 'da';
	case 'sv_SE':
		return 'sv';
	case 'nb_NO':
		return 'nb';
	case 'en_GB':
		return 'en-gb';

	case 'de_DE':
		return 'de';
	case 'pl_PL':
		return 'pl';
	case 'en_US':
	default:
		return 'en-us';
	}
}
