import uniqueMembers from './uniqueMembers';
import {
	FETCH_MEMBERS_V3,
	FETCH_MEMBERS_V3_SUCCESS,
	FETCH_MEMBERS_V3_ERROR,
	FETCH_MEMBERS_V3_BY_PERMISSION_SUCCESS,
	CLEAR_MEMBERS,
	FETCH_MEMBERS_V3_COMPACT,
	FETCH_MEMBERS_V3_COMPACT_SUCCESS
} from './actions';

function mapMember(member) {
	return {
		uuid: member.uuid,
		name: member.name,
		email: member.email,
		avatarUrl: member.avatar_url,
		teams: member.teams,
	};
}

const initPageNumber = 1;

const RESET_SELECTED_MEMBERS_ACTION = 'members/resetSelectedMembers';

// TODO: I think this whole reducer should be moved to plecto-frontend-employee-selector to the members slice,
//  especially now, that it reacts on an employee selector specific action
const membersV3 = (state = {
	isFetching: false,
	isFetchingCompact: false,
	members: [],
	selectedMembers: [],
	allMembers: [],
	isStale: true,
	pageNumber: initPageNumber
}, action) => {
	switch (action.type) {
	case FETCH_MEMBERS_V3: {
		return {
			...state,
			isFetching: true,
			isStale: false,
		};
	}
	case FETCH_MEMBERS_V3_SUCCESS: {
		return {
			...state,
			pageNumber: action.pageNumber ? action.pageNumber : initPageNumber,
			count: action.payload.count,
			next: action.payload.next,
			previous: action.payload.previous,
			members: uniqueMembers(state.members, action.payload.results.map(mapMember), action.pageNumber),
			isFetching: false,
		};
	}
	case FETCH_MEMBERS_V3_ERROR:
		return {
			...state,
			isFetching: false,
		};
	case FETCH_MEMBERS_V3_BY_PERMISSION_SUCCESS:
		return {
			...state,
			selectedMembers: action.payload.results.map(mapMember)
		};

	case FETCH_MEMBERS_V3_COMPACT: {
		return {
			...state,
			isFetchingCompact: true,
		};
	}
	case FETCH_MEMBERS_V3_COMPACT_SUCCESS: {
		return {
			...state,
			isFetchingCompact: false,
			allMembers: action.loadAll ? action.payload.results : state.allMembers,
		};
	}
	case CLEAR_MEMBERS:
		return {
			...state,
			members: []
		};
	case RESET_SELECTED_MEMBERS_ACTION:
		return {
			...state,
			isStale: true
		};
	default:
		return state;
	}
};

export { membersV3 };
