import { FETCH, FETCH_SUCCESS, FETCH_API_ERROR } from './actions';

const initialState = {
	data: [],
};

const widgets = (state = initialState, action) => {
	switch (action.type) {
	case FETCH:
		return {
			...state,
		};
	case FETCH_SUCCESS:
		return {
			...state,
			data: {
				...state.data,
				[action.dashboardUuid]: action.data.widgets,
			},
		};
	case FETCH_API_ERROR:
		return {
			...state,
		};
	default:
		return state;
	}
};

export default widgets;
