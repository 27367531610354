import { Tooltip } from '../tooltip/Tooltip';
import { SelectAll } from './SelectAll';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '@plecto/ui';
import { useTranslation } from 'react-i18next';

const StyledSearchBox = styled.div`
  padding: 8px 15px;
  display: flex;
  gap: 4px;
  align-items: center;
  border-bottom: 1px solid #E5E7EB;
`;

const StyledIcon = styled(Icon)`
  flex: 0;
`;

const StyledSearchIcon = styled(StyledIcon)`
  color: #CDD0D4;
`;

const StyledSearchClearIcon = styled(StyledIcon)`
  cursor: pointer;
  color: #6B7280;
`;

const StyledSearchInput = styled.input`
  border: none;
  background: transparent;
  flex: 1;
  outline: none;
  font-weight: 400;

  &::placeholder {
    color: #C9CACC;
  }

  &:hover, &:focus, &L:active {
    border: none;
    outline: none;
  }
`;

type FilterDropdownMenuHeaderProps = {
    searchInputValue: string;
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSearchForceChange: (value: string) => void;
    selectableCount: number;
    onToggle: () => void;
    allSelected: boolean;
    canSelectAll: boolean;
}

export function FilterDropdownMenuHeader({
	searchInputValue,
	onSearchChange,
	onSearchForceChange,
	selectableCount,
	onToggle,
	canSelectAll,
	allSelected,
}: FilterDropdownMenuHeaderProps) {
	const { t } = useTranslation();
	const onClearSearch = () => onSearchForceChange('');

	const showSelectAll = canSelectAll && selectableCount !== 0;

	return <>
		<StyledSearchBox>
			<StyledSearchInput
				value={searchInputValue}
				onChange={onSearchChange}
				placeholder={t('Search')}
				autoFocus />
			{searchInputValue ? <Tooltip message={'Clear search'} placement={'top'}>
				<StyledSearchClearIcon icon={'x'} onClick={onClearSearch} />
			</Tooltip> : <StyledSearchIcon icon={'magnifying-glass'} />}
		</StyledSearchBox>
		{ showSelectAll ? <SelectAll
			onToggle={onToggle}
			count={selectableCount}
			allSelected={allSelected}
		/> : null}
	</>;

}
