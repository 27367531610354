export const GET = 'plecto/announcement/GET';
export const GET_SUCCESS = 'plecto/announcement/GET_SUCCESS';
export const GET_API_ERROR = 'plecto/announcement/GET_API_ERROR';

export const loadAnnouncement = (id) => ({
	type: GET,
	id,
});

export const loadAnnouncementSuccess = (id, payload) => ({
	type: GET_SUCCESS,
	id,
	payload,
});

export const loadAnnouncementFailure = (id, error) => ({
	type: GET_API_ERROR,
	id,
	error,
});

export const SAVE = 'plecto/announcement/SAVE';
export const SAVE_SUCCESS = 'plecto/announcement/SAVE_SUCCESS';
export const SAVE_API_ERROR = 'plecto/announcement/SAVE_API_ERROR';

export const saveAnnouncement = (model, saveParameters) => ({
	type: SAVE,
	model,
	saveParameters,
});

export const saveAnnouncementSuccess = (model, saveParameters, payload) => ({
	type: SAVE_SUCCESS,
	model,
	saveParameters,
	payload
});

export const saveAnnouncementFailure = (model, saveParameters, error) => ({
	type: SAVE_API_ERROR,
	model,
	saveParameters,
	error,
});

export const SCHEDULE = 'plecto/announcement/SCHEDULE';
export const SCHEDULE_SUCCESS = 'plecto/announcement/SCHEDULE_SUCCESS';
export const SCHEDULE_API_ERROR = 'plecto/announcement/SCHEDULE_API_ERROR';

export const scheduleAnnouncement = (uuid) => ({
	type: SCHEDULE,
	uuid,
});

export const scheduleAnnouncementSuccess = (uuid, payload) => ({
	type: SCHEDULE_SUCCESS,
	uuid,
	payload
});

export const scheduleAnnouncementFailure = (uuid, error) => ({
	type: SCHEDULE_API_ERROR,
	uuid,
	error,
});

export const CANCEL = 'plecto/announcement/CANCEL';
export const CANCEL_SUCCESS = 'plecto/announcement/CANCEL_SUCCESS';
export const CANCEL_API_ERROR = 'plecto/announcement/CANCEL_API_ERROR';

export const cancelAnnouncement = (uuid) => ({
	type: CANCEL,
	uuid,
});

export const cancelAnnouncementSuccess = (uuid, payload) => ({
	type: CANCEL_SUCCESS,
	uuid,
	payload
});

export const cancelAnnouncementFailure = (uuid, error) => ({
	type: CANCEL_API_ERROR,
	uuid,
	error,
});

export const SEND_NOW = 'plecto/announcement/SEND_NOW';
export const SEND_NOW_SUCCESS = 'plecto/announcement/SEND_NOW_SUCCESS';
export const SEND_NOW_API_ERROR = 'plecto/announcement/SEND_NOW_API_ERROR';

export const sendAnnouncementNow = (uuid) => ({
	type: SEND_NOW,
	uuid,
});

export const sendAnnouncementNowSuccess = (uuid, payload) => ({
	type: SEND_NOW_SUCCESS,
	uuid,
	payload
});

export const sendAnnouncementNowFailure = (uuid, error) => ({
	type: SEND_NOW_API_ERROR,
	uuid,
	error,
});

export const END = 'plecto/announcement/END';
export const END_SUCCESS = 'plecto/announcement/END_SUCCESS';
export const END_API_ERROR = 'plecto/announcement/END_API_ERROR';

export const endAnnouncement = (uuid) => ({
	type: END,
	uuid,
});

export const endAnnouncementSuccess = (uuid, payload) => ({
	type: END_SUCCESS,
	uuid,
	payload
});

export const endAnnouncementFailure = (uuid, error) => ({
	type: END_API_ERROR,
	uuid,
	error,
});
