import { takeLatest } from 'redux-saga/effects';
import {
	FETCH, fetchScreensFailure, fetchScreensSuccess
} from './actions';
import { getScreens } from './api';
import apiSagaGenerator from '../../saga/apiSagaGenerator';

const screensSagas = [
	takeLatest(FETCH, 
		apiSagaGenerator()
			.forApi(getScreens)
			.withArguments(a => [a.organizationId])
			.forSuccess(fetchScreensSuccess)
			.forError(fetchScreensFailure)
			.withMessage('Error getting slideshows')
			.generate() 
	),
];

export default screensSagas;
