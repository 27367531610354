import React from 'react';
import styled from 'styled-components';
import OptionEnabler from '../blocks/OptionEnabler';
import RadiosFieldSet from '../../common/radioFieldSet/RadioFieldSet';
import RadiosFieldOption from '../../common/radioFieldSet/RadioFieldOption';
import { useTranslation } from 'react-i18next';

const S = {};

S.OptionEnabler = styled(OptionEnabler)`
	margin-top: 0;
`;

S.Alert = styled.div`
	padding: 10px;
	margin-bottom: 0;

	.icon {
		font-size: 15px;
		top: 0;
    	margin-top: 2px;
		width: auto;
	}

	.alert-text {
		margin-right: 30px;
		margin-left: 20px;
	}
`;

const allTimeOptions = {
	all_time: 'all_time',
	all_time_to_date: 'all_time_to_date',
};

export default function AllTimeTab({ date, actions }) {
	const selectedOption = date.enabled ? allTimeOptions.all_time_to_date : allTimeOptions.all_time;
	const onChange = (value) => {
		if (value !== selectedOption) {
			actions.toggleEndDate();
		}
	};
	const { t } = useTranslation();
	return (
		<>
			<RadiosFieldSet
				onSelect={onChange}
				selectedOption={selectedOption}
			>
				<RadiosFieldOption
					label={t('All time')}
					option={allTimeOptions.all_time}
					help={t('Show all data that has been synced from your data source.')}
				/>
				<RadiosFieldOption
					label={t('All time to date')}
					option={allTimeOptions.all_time_to_date}
					help={t('Show all data from the first registration until the end of the current day.')}
				/>
			</RadiosFieldSet>
		</>
	);
}
