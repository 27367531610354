import React from 'react';
import styled from 'styled-components';
import Timeline, { TimelineItem, TimelineItemBody, TimelineItemTitle } from '../../timeline/Timeline';
import { PrivacyMessage } from './PrivacyMessage';
import AchievementMessage from './AchievementMessage';
import PropTypes from 'prop-types';
import { FONT_SIZES, FONT_WEIGHT } from '../../../styles/typography';

const Container = styled.div`
  width: 300px;
  background-color: white;
`;

const HeaderWrapper = styled.div`
  padding: 16px 20px 16px 20px;
`;

const BodyWrapper = styled.div`
  padding: 16px 20px 12px 20px;
  max-height: 200px;
  overflow: auto;
`;

const Divider = styled.hr`
  display: block;
  border-top: 1px solid #E5E7EB;
  padding: 0;
  margin: 0;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: ${FONT_WEIGHT.bold};
  font-size: ${FONT_SIZES.big};
  line-height: 20px;
  align-items: center;
  color: #0F1E38;
  overflow-wrap: break-word;
`;

const Description = styled.div`
  font-style: normal;
  font-weight: ${FONT_WEIGHT.normal};
  font-size: ${FONT_SIZES.big};
  line-height: 20px;
  color: #6B7280;
  margin-top: 4px;
  overflow-wrap: break-word;
`;

const StyledPrivacyMessage = styled(PrivacyMessage)`
  margin-top: 8px;
  margin-bottom: 4px;
`;

const StyledMessage = styled(AchievementMessage)`
  padding-bottom: 4px
`;

function toTimelineItem({ id, active = true, localizedDate, message }) {
	return <TimelineItem key={id} active={active}>
		<TimelineItemTitle>{localizedDate}</TimelineItemTitle>
		{message ?
			<TimelineItemBody>
				<StyledPrivacyMessage/>
				<StyledMessage {...message}/>
			</TimelineItemBody> : null
		}
	</TimelineItem>;
}

export default function AchievementDescription({ achievements }) {
	const { name, description } = achievements[0];
	return <Container>
		<HeaderWrapper>
			<Title>{name}</Title>
			{description ? <Description>{description}</Description> : null}
		</HeaderWrapper>
		<Divider/>
		<BodyWrapper>
			<Timeline>{achievements.map(toTimelineItem)}</Timeline>
		</BodyWrapper>
	</Container>;
}

AchievementDescription.propTypes = {
	achievements: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			description: PropTypes.string,
			active: PropTypes.bool,
			message: PropTypes.shape({
				text: PropTypes.string.isRequired,
				authorAvatarUrl: PropTypes.string,
				authorName: PropTypes.string.isRequired,
				localizedDate: PropTypes.string.isRequired,
			}),
		}),
	),
};
