import { takeLatest } from 'redux-saga/effects';
import {
	FETCH, fetchSonosDevicesFailure, fetchSonosDevicesSuccess
} from './actions';
import { getGroups } from './api';
import apiSagaGenerator from '../../saga/apiSagaGenerator';

const sonosDevicesSagas = [
	takeLatest(FETCH, 
		apiSagaGenerator()
			.forApi(getGroups)
			.withArguments(a => [a.credentialsId])
			.forSuccess(fetchSonosDevicesSuccess)
			.forError(fetchSonosDevicesFailure)
			.withMessage('Error getting Sonos groups')
			.generate() 
	),
];

export default sonosDevicesSagas;
