import _keyBy from 'lodash/keyBy';
import _range from 'lodash/range';
import splitRenderAndSummary from '../summaryService';
import _reject from 'lodash/reject';
import _intersection from 'lodash/intersection';
import _difference from 'lodash/difference';
import generateUuid from '../../../utils/generateUuid';

export function initDrilldownRender({ render, pagination_data }) {
	const drilldownValues = render;
	if (drilldownValues.has_error) {
		return drilldownValues;
	}

	const renders = _keyBy(Object.entries(drilldownValues).map(([id, { name: label, data: renderWithSummary }]) => {
		const { render: initialRender, summary } = splitRenderAndSummary(renderWithSummary);
		let render = initialRender;
		if (pagination_data && pagination_data.index !== undefined) {
			const currentRenderTable = initRendersTable(pagination_data.items_count, render, pagination_data.index);
		
			render = insertActualRenders(currentRenderTable, render, pagination_data.index);
		}

		return { id, label, render, summary };
	}), 'id');

	return renders;
}

export function addPageToDrilldownRender({ render: drilldownValues, pagination_data }, stateRender) {
	if (drilldownValues?.has_error) {
		return drilldownValues;
	}
	
	const currentKeys = Object.keys(stateRender || {});
	const newKeys = Object.keys(drilldownValues || {});

	const commonKeys = _intersection(currentKeys, newKeys);

	const existingKeysWithEmptyNewData = _difference(currentKeys, newKeys);
	const newKeysNotExistentBefore = _difference(newKeys, currentKeys);

	const commonRenders = commonKeys.map((id) => {
		const currentRender = stateRender[id].render;
		return {
			...stateRender[id],
			render: addNewPageOfData(currentRender, drilldownValues[id].data, pagination_data)
		};
	});

	const newRenders = newKeysNotExistentBefore.map((id) => {
		const { name: label, data: renderWithSummary } = drilldownValues[id];
		const { render, summary } = splitRenderAndSummary(renderWithSummary);
		const currentRenderTable = initEmptyValuesTable(pagination_data.items_count);
		const nextRender = insertActualRenders(currentRenderTable, render, pagination_data.index);
		return { id, label, render: nextRender, summary };
	});

	const existingPaddedRenders = existingKeysWithEmptyNewData.map((id) => {
		const currentRender = stateRender[id].render;
		const emptyValues = _range(
			Math.min(pagination_data.page_size, pagination_data.items_count - pagination_data.index))
			.map(createEmptyPlaceholder);
		return {
			...stateRender[id],
			render: addNewPageOfData(currentRender, emptyValues, pagination_data)
		};
	});
	const renders = _keyBy([
		...commonRenders,
		...newRenders,
		...existingPaddedRenders
	], 'id');

	return renders;
}

function addNewPageOfData(currentRender, newData, pagination_data) {
	if (newData.has_error) {
		return newData;
	}

	if (currentRender.has_error) {
		return currentRender;
	}

	return insertActualRenders(currentRender, _reject(newData, 'is_summary'), pagination_data.index);
}

export function initRendersTable(itemsCount, actualRenders, startIndex) {
	return [
		..._range(0, startIndex).map(createPlaceholder),
		...actualRenders,
		..._range(startIndex + actualRenders.length, itemsCount).map(createPlaceholder),
	];
}

export function insertActualRenders(rendersTable, actualRenders, startIndex) {
	return [
		...rendersTable.slice(0, startIndex),
		...actualRenders,
		...rendersTable.slice(startIndex + actualRenders.length),
	];
}

export function initEmptyValuesTable(itemsCount) {
	return _range(0, itemsCount).map(createEmptyPlaceholder);
}

function createPlaceholder(index) {
	return { placeholder: true, placeholderId: `placeholder_${index}` };
}

function createEmptyPlaceholder() {
	return { placeholder: true, empty: true, emptyId: `empty_${generateUuid()}` };
}
