import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { animationDuration, smallScreenWidth, toastsZIndex } from './consts';
import styled from 'styled-components';
import { hide, putOnHold, release } from './actions';
import { useDispatch } from 'react-redux';
import { Icon } from '../common/icon/Icon';

const maxWidth = 500;

const StyledToast = styled.div`
	z-index: ${toastsZIndex};
	margin-top: 0;
	max-width: ${maxWidth}px;
	opacity: 0;
	transition: opacity 0.3s, margin-top 0.3s;
	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
	@media (min-width: ${smallScreenWidth}px) {
		width: ${maxWidth}px;
	}

	&.toast-appear {
		opacity: 0.01;
	}

	&.toast-enter-done,
	&.toast-appear-active {
		opacity: 1;
		margin-top: 10px;
	}

	&.alert {
		margin-bottom: 10px;
	}
`;

function Toast({ uuid, messageType, message, visible }) {
	const isError = messageType === 'error';
	const isWarning = messageType === 'warning';
	const isInfo = messageType === 'info';
	const isSuccess = messageType === 'success';

	const className = classNames('toast alert',{
		'alert-danger': isError,
		'alert-success': isSuccess,
		'alert-info': isInfo,
		'alert-warning': isWarning,
	});
	const iconClass =  classNames('alert-icon icon',{
		'icon-warning': isError || isWarning,
		'icon-checkmark': isSuccess,
		'icon-info': isInfo,
	});
	const dispatch = useDispatch();

	const onClose = useCallback(() => {
		dispatch(hide(uuid));
	},[dispatch, uuid]);

	const onMouseEnter = useCallback(() => {
		dispatch(putOnHold(uuid));
	},[dispatch, uuid]);

	const onMouseLeave = useCallback(() => {
		dispatch(release(uuid));
	},[dispatch, uuid]);

	return (<CSSTransition
		in={visible}
		classNames="toast"
		appear
		timeout={animationDuration}
	>
		<StyledToast className={className} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			<span className={iconClass}></span>
			<div className="alert-text">{message || uuid}</div>
			<button type="button" className="alert-close" onClick={onClose}><Icon name="x" /></button>
		</StyledToast>
	</CSSTransition>
	);
}

Toast.defaultProps = {
	visible: true
};

Toast.propTypes = {
	uuid: PropTypes.string.isRequired,
	messageType: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	visible: PropTypes.bool,
};

export default React.memo(Toast);
