import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilterDropdown } from './useFilterDropdown';
import { useFilterSearch } from './useFilterSearch';
import { useFilterSelection } from './useFilterSelection';
import { Icon } from '@plecto/ui';
import { Loader } from '../loaders/Loader';
import styled, { css } from 'styled-components';
import { StyledFilterCustomItem } from './StyledFilterItem';
import { FilterItem } from './types';
import { FilterStatus } from './FilterStatus';
import { FilterDropdownMenuHeader } from './FilterDropdownMenuHeader';
import { FilterDropdownMenuFooter } from './FilterDropdownMenuFooter';

const StyledDropdown = styled.div<{
    $open: boolean,
    $selected: boolean
}>`
  display: flex;
  border-radius: 3px;
  padding: 8px 12px;
  cursor: pointer;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  border-width: 1px;
  justify-content: space-between;
  border-style: solid;
  ${({ $selected, $open }) => $open ? css`
    background: #FFFFFF;
    color: #1F2937;
    border-color: #FFFFFF;
    outline: 2px solid #126BDD;
  ` : $selected ? css`
    background: #F2F7FE;
    color: #0E54AE;
    border-color: #F2F7FE;
  ` : css`
    background: #FFFFFF;
    color: #1F2937;
    border-color: #D1D5DB;

  `}
`;

const StyledChevron = styled(Icon)<{
    $selected: boolean;
    $open: boolean;
}>`
  flex: 0 auto;
  color: ${({ $selected, $open }) =>
		$selected ? ($open ? '#1F2937' : '#0E54AE') : '#6B7280'}
`;

const StyledLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledMenu = styled.div<{
    $fitContent: boolean;
    $maxWidth?: number;
}>`
  position: absolute;
  width: ${props =>
		props.$fitContent ? 'auto' : (props.$maxWidth ? `${props.$maxWidth}px` : '100%')};
  left: 0;
  top: calc(100% + 5px);
  border-radius: 4px;
  border: 1px solid #D1D5DB;
  background: #FFF;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: column;
  max-height: 310px;
  z-index: 45;
  font-size: 14px;
  color: #374151;
`;

const StyledDropdownContainer = styled.div`
  overflow: auto;
`;

const StyledNoElementsItem = styled(StyledFilterCustomItem)`
  color: #878F9C;
  cursor: default;
`;

type ChildrenProps = {
    items: FilterItem[];
	selection: string[];
    filteredItems: FilterItem[];
    onToggle: (id: string) => void;
    searchMode: boolean;
}

type FilterDropdownBaseProps = {
    className?: string;
    label: string;
    isSuccess?: boolean;
    isFetching?: boolean;
    items: FilterItem[];
    selection?: string[];
    onSelectionChange?: (selection: string[]) => unknown;
    onClose?: (selection: string[]) => void;
    onOpen?: () => void;
    children?: (props: ChildrenProps) => ReactNode | undefined;
    fitContent?: boolean;
    maxWidth?: number;
    compactLabel?: boolean;
    canSelectAll?: boolean;
}

export function FilterDropdownBase({
	canSelectAll = true,
	label = '',
	items = [],
	isFetching = false,
	selection: initiallySelected = [],
	onClose,
	onOpen,
	onSelectionChange,
	fitContent = false,
	maxWidth,
	compactLabel,
	children,
	className,
}: FilterDropdownBaseProps) {
	const { t } = useTranslation();
	const {
		searchInputValue,
		filteredItems,
		onSearchChange,
		forceChange: onSearchForceChange,
	} = useFilterSearch(items);

	const selectableItems = filteredItems.filter(item => !item.disabled);
	const selectableCount = selectableItems.length;
	const isEmpty = filteredItems.length === 0;

	const {
		onToggle: onToggleFilter,
		onToggleMany,
		allSelected,
		onDeselectAll,
		selectionChanged,
		selection,
	} = useFilterSelection({
		selectableItems,
		onSelectionChange,
		initiallySelected,
	});

	const {
		isOpen,
		onDropdownClose,
		onDropdownToggle,
		buttonRef,
		menuRef,
	} = useFilterDropdown({ onClose, onOpen, selection });

	const searchMode = Boolean(searchInputValue);
	const selectionActive = selection && selection.length > 0;

	const onCancel = () => {
		onDeselectAll();
		onDropdownClose([]);
	};

	const onSave = () => onDropdownClose(selection);

	return (
		<div className={className}>
			<StyledDropdown
				onClick={onDropdownToggle}
				ref={buttonRef}
				$selected={selectionActive}
				$open={isOpen}>
				<FilterStatus
					active={selectionActive}
					selection={selection}
					label={label}
					compactLabel={compactLabel}
					items={items}
				/>
				<StyledChevron
					$open={isOpen}
					$selected={selectionActive}
					icon={isOpen ? 'caret-up' : 'caret-down'}
				/>
			</StyledDropdown>
			{isOpen && <StyledMenu $fitContent={fitContent} $maxWidth={maxWidth} ref={menuRef}>
				<FilterDropdownMenuHeader
					onSearchForceChange={onSearchForceChange}
					onSearchChange={onSearchChange}
					searchInputValue={searchInputValue}
					selectableCount={selectableCount}
					onToggle={onToggleMany}
					allSelected={allSelected}
					canSelectAll={canSelectAll}
				/>
				<StyledDropdownContainer>
					{isFetching ? <StyledLoaderWrapper><Loader size={'sm'} /></StyledLoaderWrapper> :
						isEmpty && <StyledNoElementsItem>
							{searchInputValue ?
								t('No results found for "{{searchPhrase}}".', { searchPhrase: searchInputValue }) :
								t('Nothing to display yet.')}
						</StyledNoElementsItem>}
					{children?.({
						items,
						selection,
						filteredItems: selectableItems,
						onToggle: onToggleFilter,
						searchMode,
					})}
				</StyledDropdownContainer>
				<FilterDropdownMenuFooter
					onCancel={onCancel}
					disabled={!selectionChanged}
					onSave={onSave}
				/>
			</StyledMenu>}
		</div>
	);
}
