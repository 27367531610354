import {
	CREATE_ACHIEVEMENT_TYPE,
	CREATE_ACHIEVEMENT_TYPE_FAILURE,
	CREATE_ACHIEVEMENT_TYPE_SUCCESS,
	UPDATE_ACHIEVEMENT_TYPE,
	UPDATE_ACHIEVEMENT_TYPE_FAILURE,
	UPDATE_ACHIEVEMENT_TYPE_SUCCESS,
} from './actions';

const initialState = {
	isSaving: false,
	error: null,
	errors: {},
};

const achievementTypes = (state = initialState, action) => {
	switch (action.type) {
	case CREATE_ACHIEVEMENT_TYPE:
		return {
			...state,
			isSaving: true,
		};

	case CREATE_ACHIEVEMENT_TYPE_SUCCESS:
		return {
			...state,
			isSaving: false,
			error: null,
			errors: {},
		};

	case CREATE_ACHIEVEMENT_TYPE_FAILURE:
		return {
			...state,
			isSaving: false,
			error: action.error,
			errors: action.errors || {},
		};

	case UPDATE_ACHIEVEMENT_TYPE:
		return {
			...state,
			isSaving: true,
		};

	case UPDATE_ACHIEVEMENT_TYPE_SUCCESS:
		return {
			...state,
			isSaving: false,
			error: null,
			errors: {},
		};

	case UPDATE_ACHIEVEMENT_TYPE_FAILURE:
		return {
			...state,
			isSaving: false,
			error: action.error,
			errors: action.errors || {},
		};
	
	default:
		return state;
	}
};

export { achievementTypes };
