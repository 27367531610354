import { useMemo, useEffect } from 'react';
import _isFunction from 'lodash/isFunction';

export default function useDynamicCssRuleset(rulesetOrCreator) {
	const sheet = useMemo(createStyleSheet,[]);
  
	useEffect(() => {
		const ruleset = _isFunction(rulesetOrCreator) ? rulesetOrCreator() : rulesetOrCreator;
		clearRules(sheet);
		addRules(sheet, ruleset);
	}, [sheet, rulesetOrCreator]);

	useEffect(() => {
		return function sheetCleanup() {
			sheet.parentNode && sheet.parentNode.removeChild(sheet);
		};
	}, [sheet]);
}

function clearRules(sheet) {
	const rules = sheet.cssRules || sheet.rules;
	if (rules) {
		while (rules.length) {
			sheet.deleteRule(rules.length - 1);
		}
	}
}

function addRules(sheet, ruleset) {
	for (const { selector, rule } of ruleset) {
		addCSSRule(sheet, selector, rule);
	}
}

function addCSSRule(sheet, selector, rules, index) {
	if ('insertRule' in sheet) {
		sheet.insertRule(`${selector}{${rules}}`, index);
	}
	else if ('addRule' in sheet) {
		sheet.addRule(selector, rules, index);
	}
}

// copy from https://davidwalsh.name/add-rules-stylesheets, I left comment about hack
function createStyleSheet() {
	const style = document.createElement('style');

	// WebKit hack :(
	style.appendChild(document.createTextNode(''));

	document.head.appendChild(style);

	return style.sheet;
}
