import { modes, periods } from '../../logic/constants';
import getFormatterForLanguage from './formatterDispatcher';
import translatePreset from './translatePreset';

export function formatButtonLabel(state, language, preview, isFetching) {
	const {
		mode, 
		allTimeEndDate: {
			enabled: allTimeEndDateEnabled, 
		},
		timeRange: {
			enabled: timeRangeEnabled,
			fromHours,
			toHours,
			fromDay,
			toDay,
		},
		offset: {
			enabled: offsetEnabled,
		},
		relativeStartDate: {
			period: startPeriod, 
			mode: startMode,
		}, 
		preset
	} = state;

	const formatter = getFormatterForLanguage(language);

	if (preset) {
		const translated = translatePreset(preset, state.relativeStartDate, state.relativeEndDate);
		if (translated) {
			return { label: translated };
		}
	} else if (mode === modes.allTime) {
		if (allTimeEndDateEnabled) {
			return { label: formatter.formatAllTimeToDate() };
		} else {
			return { label: formatter.formatAllTime() };
		}
	} else if (mode === modes.custom) {
		return { label: formatter.formatCustomDates(state.customDate.startDate, state.customDate.endDate) };
	} else if (
		mode === modes.relative &&
		timeRangeEnabled &&
		!offsetEnabled &&
		(startPeriod === periods.day || startPeriod === periods.week)) {
		if (startPeriod === periods.day) {
			const label = formatter.formatDayRange(startMode, fromHours, toHours);
			if (label) {
				return { label };
			}
		} else if (startPeriod === periods.week) {
			const label = formatter.formatWeekRange(startMode, fromDay, toDay);
			if (label) {
				return { label };
			}
		}
	}
		
	return { label: preview, isFetching };
}

