import React from 'react';
import styled from 'styled-components';
import isDefaultAvatar from '../../../utils/isDefaultAvatar';
import initials from '../../../utils/initials';
import { FONT_WEIGHT } from '../../../styles/typography';

const SIZE = '20px';

const Container = styled.div`
  display: inline-block;
  background-color: #9A9F9F;
  border-radius: 50%;
  width: ${SIZE};
  height: ${SIZE};
`;

const Image = styled.img`
  vertical-align: middle;
  box-sizing: content-box;
  width: ${SIZE};
  height: ${SIZE};
  border-radius: 50%;
`;

const Initials = styled.div`
  color: white;
  display: inline-block;
  width: ${SIZE};
  height: ${SIZE};
  border-radius: 50%;
  text-align: center;
  font-style: normal;
  font-weight: ${FONT_WEIGHT.normal};
  font-size: 11px;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  box-sizing: content-box;
`;

const InitialsSpan = styled.span`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default function Avatar({ avatarUrl, name }) {
	return <Container title={name}>
		{isDefaultAvatar(avatarUrl) ?
			<Initials><InitialsSpan>{initials(name)}</InitialsSpan></Initials>
			: <Image src={avatarUrl}/>}
	</Container>;
}
