import _findIndex from 'lodash/findIndex';
import screenSizes from './screenSizes';

const sizesOrdered = [
	screenSizes.xs,
	screenSizes.vs,
	screenSizes.sm,
	screenSizes.md,
	screenSizes.lg,
	screenSizes.xl,
];

export default function getWindowSize(windowObject) {
	const width = windowObject.innerWidth;
	const height = windowObject.innerHeight;
	const firstGreater = _findIndex(sizesOrdered, size => size > width);
	let sizeBreakpoint;
	if (firstGreater < 0) {
		sizeBreakpoint = screenSizes.xl;
	} else if (firstGreater === 0) {
		sizeBreakpoint = screenSizes.xs;
	} else {
		sizeBreakpoint = sizesOrdered[firstGreater - 1];
	}

	return { width, height, sizeBreakpoint };
}

