import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@plecto/ui';

const StyledDropdownFooter = styled.div`
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
  border-top: 1px solid #E5E7EB;
`;

type FilterDropdownMenuFooterProps = {
	disabled: boolean;
	onSave: () => void;
	onCancel: () => void;
}

export function FilterDropdownMenuFooter({
	disabled,
	onSave,
	onCancel,
}: FilterDropdownMenuFooterProps) {
	const { t } = useTranslation();
	return <StyledDropdownFooter>
		<Button
			variant={'white'}
			size={'small'}
			disabled={disabled}
			label={t('Clear filters')}
			onClick={onCancel}
		/>
		<Button
			variant={'primary'}
			size={'small'}
			disabled={disabled}
			label={t('Apply')}
			onClick={onSave}
		/>
	</StyledDropdownFooter>;

}
