import { FETCH, FETCH_API_ERROR, FETCH_SUCCESS } from './actions';

const initialState = {
};

const devices = (state = initialState, action) => {
	switch (action.type) {
	case FETCH: {
		const { deviceType } = action;
		return updateDeviceState(state, deviceType, {
			isFetching: true
		});
	}
	case FETCH_SUCCESS: {
		const { deviceType, result } = action;
		return updateDeviceState(state, deviceType, {
			isFetching: false,
			data: result?.devices?.[deviceType]?.map(device => ({
				id: device.pk.toString(),
				name: device.name,
				isActive: device.is_available
			})) || []
		});
	}
	case FETCH_API_ERROR: {
		const { deviceType, error } = action;
		return updateDeviceState(state, deviceType, {
			isFetching: false,
			error: error,
			data: [],
		});
	}
	default:
		return state;
	}
};

function updateDeviceState(state, deviceType, change) {
	return {
		...state,
		[deviceType]: {
			...(state[deviceType] || {}),
			...change
		}
	};
}

export default devices;
