import React, { useCallback } from 'react';
import classNames from 'classnames';
import {
	StyledDropdownItem,
	StyledDropdownIcon,
	StyledDropdownItemTitle,
	StyledDropdownArrow,
} from './styledComponents';
import { ItemSubTitle, ItemTitle } from './StyledItem';

export default function CustomItem({ className, label, subLabel, value, selected, icon, onExpand }) {
	const onClick = useCallback(() => {
		onExpand(value);
	}, [value, onExpand]);

	return <StyledDropdownItem className={classNames('multi-level-dropdown-item', className, {
		'multi-level-dropdown-item-selected': selected,
		'multi-level-dropdown-item-selectable': !selected,
		'multi-level-dropdown-item-has-icon': icon,
	})} onClick={onClick} hasIcon={icon} selectable={!selected} selected={selected}>
		{icon && <StyledDropdownIcon className="multi-level-dropdown-item-icon" name={icon} />}
		<ItemTitle className="multi-level-dropdown-item-title-container">
			<StyledDropdownItemTitle className="multi-level-dropdown-item-title">
				{label}
			</StyledDropdownItemTitle>
			{subLabel && <ItemSubTitle className="multi-level-dropdown-item-sub-title">{subLabel}</ItemSubTitle>}
		</ItemTitle>
		<StyledDropdownArrow className="multi-level-dropdown-item-arrow" name="chevron-right" />
	</StyledDropdownItem>;
}
