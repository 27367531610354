import {
	CHANGE_START_MODE,
	CHANGE_START_PERIOD,
	CHANGE_START_AMOUNT,
	CHANGE_END_MODE,
	CHANGE_END_PERIOD,
	CHANGE_END_AMOUNT,
	TIME_PERIOD_REINIT,
	TOGGLE_END_DATE
} from './actions';
import { getAvailableEndDateOptions } from './endDateValidator';
import { getModesOptions, getPeriodsOptions, relativeModes, modes, getPeriods } from './constants';
import _find from 'lodash/find';
import _clamp from 'lodash/clamp';
import _uniq from 'lodash/uniq';
import _filter from 'lodash/filter';
import _flatten from 'lodash/flatten';
import _sortBy from 'lodash/sortBy';

function endDateApplier(endDate, action) {
	switch (action.type) {
	case TOGGLE_END_DATE: 
		return {
			...endDate,
			enabled: !endDate.enabled
		};
	case CHANGE_END_MODE:
		return {
			...endDate,
			mode: action.mode,
			fixedAmount: action.mode === relativeModes.current,
			amount: action.mode !== relativeModes.current ? endDate.amount : 1,
		};
	case CHANGE_END_PERIOD:
		return {
			...endDate,
			period: action.period,
		};
	case CHANGE_END_AMOUNT:
		return {
			...endDate,
			amount: action.amount,
		};
	}

	return endDate;
	
}

function optionsApplier(state, action) {
	switch (action.type) {
	case CHANGE_START_MODE:
	case CHANGE_START_PERIOD:
	case CHANGE_START_AMOUNT:
	case TIME_PERIOD_REINIT: {
		const currentOptions =
			getAvailableEndDateOptions(
				state.relativeStartDate.mode,
				state.relativeStartDate.period,
				state.relativeStartDate.amount,
				state.features
			);
		return applyOptionsToState(currentOptions, state);
	}
	case CHANGE_END_MODE:
	case CHANGE_END_PERIOD:
	case CHANGE_END_AMOUNT: {
		const currentOptions = state.relativeEndDate.options && state.relativeEndDate.options.availableChoices ?
			state.relativeEndDate.options.availableChoices :
			getAvailableEndDateOptions(
				state.relativeStartDate.mode,
				state.relativeStartDate.period,
				state.relativeStartDate.amount,
				state.features
			);
		return applyOptionsToState(currentOptions, state);
	}
	}
	
	return state;
}

export default function endDateReducer(state, action) {
	const currentMode = state.mode;

	switch (currentMode) {
	case modes.relative: {
		state = {
			...state,
			relativeEndDate: endDateApplier(state.relativeEndDate, action)
		};
	
		return optionsApplier(state, action);
	}
	case modes.allTime: {
		return {
			...state,
			allTimeEndDate: endDateApplier(state.allTimeEndDate, action)
		};
	}
	}
	return state;
}

function applyOptionsToState(availableEndOptions, state) {
	const { relativeEndDate: nextEnding, options: nextOptions } = getEndingOptions(
		availableEndOptions, 
		state.relativeEndDate.mode, 
		state.relativeEndDate.period, 
		state.relativeEndDate.amount);
	return {
		...state,
		relativeEndDate: {
			...state.relativeEndDate,
			...nextEnding,
			enabled: state.relativeEndDate.enabled &&
				(state.relativeStartDate.mode !== relativeModes.next || nextEnding.mode === relativeModes.current),
			fixedAmount: nextEnding.mode === relativeModes.current,
			amount: nextEnding.mode === relativeModes.current ? 1 : nextEnding.amount,
			options: {
				...state.relativeEndDate.options,
				availableChoices: availableEndOptions,
				...nextOptions
			}
		}
	};
}

function getEndingOptions(availableOptions, endMode, endPeriod, endAmount) {
	if (!availableOptions) {
		return { relativeEndDate: {}, options: {}};
	}

	const modesOptions = getModesOptions(_uniq(availableOptions.map(op => op.mode)));

	let bestMatch =
		_find(availableOptions, option => option.mode === endMode && option.periods.indexOf(endPeriod) >= 0);
	if (!bestMatch) {
		bestMatch = _find(availableOptions, { mode: endMode }) || availableOptions[0];
	}
	
	const isPeriodValid = bestMatch.periods.indexOf(endPeriod) >= 0;
	const minAmount = bestMatch.min || 1;
	const maxAmount = bestMatch.max || endAmount;
	
	if (endMode !== bestMatch.mode || !isPeriodValid) {
		endMode = bestMatch.mode;
		endAmount = minAmount;
		endPeriod = bestMatch.periods[0]; 
	} else {
		endAmount = _clamp(endAmount, minAmount, maxAmount);
	}

	const modeMatches = _filter(availableOptions, { mode: bestMatch.mode });
	const allPeriods = getPeriods();
	const allAvailablePeriods = _sortBy(_uniq(_flatten(modeMatches.map(m => m.periods))), [p => allPeriods.indexOf(p)]);
	const periodOptions = getPeriodsOptions(allAvailablePeriods);

	return { 
		relativeEndDate: { mode: endMode, period: endPeriod, amount: endAmount }, 
		options: { minAmount: bestMatch.min || 1, maxAmount: bestMatch.max, modesOptions, periodOptions }
	};
}
