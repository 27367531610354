import reportSagas from '../models/report/sagas';
import reportSchedulesSagas from '../models/reportSchedule/sagas';
import reportGroupFormulaSagas from '../models/reportGroupFormula/sagas';
import reportGroupSagas from '../models/reportGroup/sagas';
import teamsSagas from '../models/team/v2/sagas';
import formulasSagas from '../models/formula/sagas';
import membersSagas from '../models/member/v2/sagas';
import feedSagas from '../models/feed/sagas';
import myTeamsSagas from '../models/my-teams/sagas';
import dashboardsSagas from '../models/dashboards/sagas';
import datasoucresSagas from '../models/datasources/sagas';
import favoritesSagas from '../models/favorites/sagas';
import registrationsSagas from '../models/registrations/sagas';
import widgetsSagas from '../models/widgets/sagas';
import registrationPreviewsSagas from '../models/registrationPreviews/sagas';
import timePeriodSagas from '../models/timePeriod/sagas';
import dataSourceVariablesSagas from '../models/dataSourceVariables/sagas';
import devicesSagas from '../models/devices/sagas';
import announcementSagas from '../models/announcements/sagas';
import screensSagas from '../models/screens/sagas';
import membersV3Sagas from '../models/member/v3/sagas';
import teamsV3Sagas from '../models/team/v3/sagas';
import permissionProfilesSagas from '../models/permissionProfiles/sagas';
import timeZonesSagas from '../models/timeZones/sagas';
import sonosDevicesSagas from '../models/sonosDevices/sagas';
import hueDevicesSagas from '../models/hueDevices/sagas';
import writtenUpdateTemplatesSagas from '../models/writtenUpdateTemplates/sagas';
import writtenUpdateSchedulesSagas from '../models/writtenUpdateSchedules/sagas';
import writtenUpdatesSagas from '../models/writtenUpdates/sagas';
import directorySagas from '../models/directory/sagas';
import achievementTypeIconsSagas from '../models/achievementTypeIcons/sagas';
import achievementTypesSagas from '../models/achievementTypes/sagas';
import trainingQuizSagas from '../models/trainingQuizzes/sagas';
import exportJobSagas from '../models/exportJobs/sagas';
import slideshowsSagas from '../models/slideshows/sagas';
import oneOnOnesSagas from '../models/oneOnOnes/sagas';

const sagasByModule = {
	achievementTypeIcons: achievementTypeIconsSagas,
	achievementTypes: achievementTypesSagas,
	announcements: announcementSagas,
	dashboards: dashboardsSagas,
	dataSourceVariables: dataSourceVariablesSagas,
	datasources: datasoucresSagas,
	devices: devicesSagas,
	directory: directorySagas,
	exportJobs: exportJobSagas,
	favorites: favoritesSagas,
	feed: feedSagas,
	formulas: formulasSagas,
	members: membersSagas,
	membersV3: membersV3Sagas,
	myTeams: myTeamsSagas,
	oneOnOnes: oneOnOnesSagas,
	permissionProfiles: permissionProfilesSagas,
	registrationPreviews: registrationPreviewsSagas,
	registrations: registrationsSagas,
	report: reportSagas,
	reportGroupFormulasById: reportGroupFormulaSagas,
	reportGroupsById: reportGroupSagas,
	schedules: reportSchedulesSagas,
	screens: screensSagas,
	slideshows: slideshowsSagas,
	sonosDevices: sonosDevicesSagas,
	hueDevices: hueDevicesSagas,
	teams: teamsSagas,
	teamsV3: teamsV3Sagas,
	timePeriod: timePeriodSagas,
	timeZones: timeZonesSagas,
	trainingQuizzes: trainingQuizSagas,
	widgets: widgetsSagas,
	writtenUpdateSchedules: writtenUpdateSchedulesSagas,
	writtenUpdateTemplates: writtenUpdateTemplatesSagas,
	writtenUpdates: writtenUpdatesSagas,
};

export default sagasByModule;
