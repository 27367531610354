import React, { useCallback } from 'react';
import classNames from 'classnames';
import { StyledDropdownArrow, StyledDropdownIcon, StyledDropdownItemTitle } from './styledComponents';
import { ItemCheckmark, ItemMessage, ItemSelect, ItemSubTitle, ItemTitle, MenuItem } from './StyledItem';
import PropTypes from 'prop-types';

export default function Item({
	className,
	label,
	data,
	subLabel,
	value,
	options,
	selected,
	checked,
	icon,
	message,
	selectIcon,
	selectIconTitle,
	onSelect,
	onSelected,
	onSelectIcon,
	onSelectedIcon,
	fieldRelatioSelectionAllowed = false,
	onExpand
}) {
	const onClick = useCallback(() => {
		!fieldRelatioSelectionAllowed && options ? onExpand(value) : onSelect(value, onSelected);
	}, [fieldRelatioSelectionAllowed, onExpand, onSelect, onSelected, options, value]);

	const onChevronClick = useCallback((e) => {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();

		onExpand(value);
	}, [onExpand, value]);

	const onIconClick = useCallback((e) =>  {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();

		onSelectIcon(value, onSelectedIcon);
	}, [value, onSelectIcon, onSelectedIcon]);

	const dataAttribute = {
		[data.name]: data.value
	};

	return <MenuItem
		selected={selected}
		selectable={!selected}
		fieldRelatioSelectionAllowed={fieldRelatioSelectionAllowed}
		{...dataAttribute}
		className={classNames('multi-level-dropdown-item', className, {
			'multi-level-dropdown-item-selected': selected,
			'multi-level-dropdown-item-selectable': !selected,
			'multi-level-dropdown-item-has-icon': icon,
		})}
		onClick={onClick}
	>
		{icon && <StyledDropdownIcon className="multi-level-dropdown-item-icon" name={icon} />}
		<ItemTitle className="multi-level-dropdown-item-title-container">
			<StyledDropdownItemTitle className="multi-level-dropdown-item-title">
				{label}
			</StyledDropdownItemTitle>
			{subLabel && <ItemSubTitle className="multi-level-dropdown-item-sub-title">{subLabel}</ItemSubTitle>}
		</ItemTitle>
		{selectIcon && <ItemSelect
			iconName={selectIcon}
			onClick={onIconClick}
			useJsTooltip
			className={'multi-level-dropdown-item-select'}
			tooltipPlacement={'top'}
			title={selectIconTitle}
		/>}
		{checked && <ItemCheckmark className="multi-level-dropdown-item-arrow" name="checkmark" />}
		{message && <ItemMessage
			className="multi-level-dropdown-item-info"
			iconName={'info'}
			message={message}
		/>}
		{options && <StyledDropdownArrow
			onClick={onChevronClick}
			className="multi-level-dropdown-item-arrow"
			name="chevron-right"
		/>}
	</MenuItem>;
}

Item.defaultProps = {
	data: {}
};

Item.propTypes = {
	data: PropTypes.object,
};
