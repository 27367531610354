import React from 'react';
import styled from 'styled-components';
import screenSizes from '../../utils/size/screenSizes';
import { SPACING } from '../../styles/spacing';

const sidebarWidth = 200;

const StyledBottomBar = styled.div`
	z-index: 90;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: #FFFFFF;
	box-shadow: 0 0 6px #00000029;
	text-align: right;
	padding: ${SPACING.small} ${SPACING.big};
	transition: margin-left .2s;

	& > * {
		margin-left: ${SPACING.small};
	}

	& button {
		padding: ${SPACING.small} ${SPACING.big};
		text-transform: uppercase;
	}

	.main-content & {
		@media (min-width: ${screenSizes.sm}px) {
			margin-left: ${sidebarWidth}px;
			width: calc(100% - ${sidebarWidth}px);
		}
	}

	@media (max-width: ${screenSizes.sm - 1}px) {
		.body-sidebar-open .main-content &,
		.body-sidebar-opening .main-content &,
		.body-sidebar-closing .main-content & {
			width: 100%;
		}

		.body-sidebar-open .main-content &,
		.body-sidebar-opening .main-content & {
			margin-left: ${sidebarWidth}px;
		}
	}
`;

export default function BottomBar({ children }) {
	return <StyledBottomBar>
		{children}
	</StyledBottomBar>;
}
