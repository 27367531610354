import { getFutureModeName, getPeriodName, getRelativeModeName, getWeekdayName } from './translations';
import _padStart from 'lodash/padStart';
import _range from 'lodash/range';
import { t } from '../../../../setupTranslations';
import { DAYS_IN_A_MONTH, HOURS_IN_A_DAY, MONTHS_IN_A_YEAR } from '../constants';

export const modes = {
	relative: 'relative',
	allTime: 'allTime',
	custom: 'custom',
};

export const relativeModes = {
	previous: 'previous',
	current: 'current',
	next: 'next',
};

export const granularity = {
	hourly: 'hour',
	daily: 'day',
	weekly: 'week',
	monthly: 'month',
	quarterly: 'quarter',
	annually: 'year',
	allTime: 'all_time',
};

export const periods = {
	day: 'day',
	week: 'week',
	month: 'month',
	month_pairs: 'month_pairs',
	quarter: 'quarter',
	year: 'year',
};

export const allPeriods = Object.values(periods);

export function getPeriods() {
	return Object.values(periods);
}

export const getRelativeModesOptions = () => createOptionsFromObject(relativeModes, getRelativeModeName);

export const timeOptionsStart = generateTimeOptions(HOURS_IN_A_DAY - 1);
export const timeOptions = generateTimeOptions(HOURS_IN_A_DAY);

export const futureModes = {
	ago: 'ago',
	inTheFuture: 'inTheFuture',
};

export const weekDays = {
	monday: 'monday',
	tuesday: 'tuesday',
	wednesday: 'wednesday',
	thursday: 'thursday',
	friday: 'friday',
	saturday: 'saturday',
	sunday: 'sunday',
};
const weekDaysOrdered = [
	weekDays.monday,
	weekDays.tuesday,
	weekDays.wednesday,
	weekDays.thursday,
	weekDays.friday,
	weekDays.saturday,
	weekDays.sunday
];

export const getWeekDaysOrdered = (startingOnDay = 1) =>
{
	const options = [...weekDaysOrdered];

	const endOfTheWeek = options.splice(0, startingOnDay - 1);

	return options.concat(endOfTheWeek);
};

export const getWeekDaysOptions = (startingOnDay = 1) =>
{
	const options = createOptionsFromObject(weekDays, getWeekdayName);

	const endOfTheWeek = options.splice(0, startingOnDay - 1);

	return options.concat(endOfTheWeek);
};

export const getFutureModeOptions = () => createOptionsFromObject(futureModes, getFutureModeName);

export function getModesOptions(modesArray) {
	return modesArray.map(value => ({ label: getRelativeModeName(value), value }));
}

export function getPeriodsOptions(periodsArray) {
	if (!periodsArray) {
		periodsArray = getPeriods();
	}
	return periodsArray.map(value => ({ label: getPeriodName(value), value }));
}

function createOptionsFromObject(object, translationFunction) {
	return Object.values(object).map(value => ({ label: translationFunction(value), value }));
}

function generateTimeOptions(maxHours) {
	const timeOptions = [];
	for (let i = 0; i <= maxHours; i++) {
		timeOptions.push(createHourOption(i));
	}
	return timeOptions;
}

function createHourOption(hour) {
	const valueString = `${_padStart(hour, 2, '0')}:00`;
	return { label: valueString, value: valueString, meta: { hour }};
}

export function generateMonthNumbers() {
	/* eslint-disable no-magic-numbers */
	return _range(1, DAYS_IN_A_MONTH + 1).map(num => {

		const quotient = Math.floor(num / 10);
		const remainder = num % 10;

		const suffix = remainder === 1 & quotient !== 1 ? 'st'
			: (remainder === 2 & quotient !== 1 ? 'nd' 
				: (remainder === 3 & quotient !== 1 ? 'rd' 
					: 'th'));

		return {
			value: num,
			label: `${num}${suffix}`,
		};
	});
}

export function getMonthOptions() {
	return _range(0, MONTHS_IN_A_YEAR).map(num => {
		return {
			value: num + 1,
			label: translateMonth(num),
		};
	});
}

export const monthsToNumberOfDays = {
	1: 31,
	2: 29,
	3: 31,
	4: 30,
	5: 31,
	6: 30,
	7: 31,
	8: 31,
	9: 30,
	10: 31,
	11: 30,
	12: 31,
};

export function translateMonth(monthNumberZeroBased) {
	/* eslint-disable no-magic-numbers */
	switch (monthNumberZeroBased) {
	case 0: return t('January');
	case 1: return t('February');
	case 2: return t('March');
	case 3: return t('April');
	case 4: return t('May');
	case 5: return t('June');
	case 6: return t('July');
	case 7: return t('August');
	case 8: return t('September');
	case 9: return t('October');
	case 10: return t('November');
	case 11: return t('December');
	}
	return '';
}
