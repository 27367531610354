import { takeLatest } from 'redux-saga/effects';
import { FETCH, fetchWrittenUpdateTemplatesSuccess, fetchWrittenUpdateTemplatesFailure } from './actions';
import apiSagaGenerator from '../../saga/apiSagaGenerator';
import { getWrittenUpdateTemplates } from './api';

const writtenUpdateTemplatesSagas = [
	takeLatest(FETCH, 
		apiSagaGenerator()
			.forApi(getWrittenUpdateTemplates)
			.forSuccess(fetchWrittenUpdateTemplatesSuccess)
			.forError(fetchWrittenUpdateTemplatesFailure)
			.withMessage('Error getting written update templates')
			.generate() 
	),
];

export default writtenUpdateTemplatesSagas;
