import { takeLatest } from 'redux-saga/effects';
import apiSagaGenerator from '../../saga/apiSagaGenerator';
import { createAchievementType, fetchAchievementTypes, giveAchievementType, updateAchievementType } from './api';
import {
	CREATE_ACHIEVEMENT_TYPE,
	createAchievementTypeFailure,
	createAchievementTypeSuccess,
	FETCH_ACHIEVEMENT_TYPES,
	fetchAchievementTypeFailure,
	fetchAchievementTypeSuccess,
	GIVE_ACHIEVEMENT_TYPE,
	giveAchievementTypeFailure,
	giveAchievementTypeSuccess,
	UPDATE_ACHIEVEMENT_TYPE,
	updateAchievementTypeFailure,
	updateAchievementTypeSuccess,
} from './actions';

const achievementTypesSagas = [
	takeLatest(FETCH_ACHIEVEMENT_TYPES,
		apiSagaGenerator()
			.forApi(fetchAchievementTypes)
			.forSuccess(fetchAchievementTypeSuccess)
			.forError(fetchAchievementTypeFailure)
			.includeErrorResult()
			.withMessage('Error fetching achievements')
			.generate(),
	),
	takeLatest(CREATE_ACHIEVEMENT_TYPE,
		apiSagaGenerator()
			.forApi(createAchievementType)
			.withArguments((action) => [action.achievementType])
			.forSuccess(createAchievementTypeSuccess)
			.forError(createAchievementTypeFailure)
			.includeErrorResult()
			.withMessage('Error creating achievement')
			.generate(),
	),
	takeLatest(UPDATE_ACHIEVEMENT_TYPE,
		apiSagaGenerator()
			.forApi(updateAchievementType)
			.withArguments((action) => [action.achievementTypeId, action.achievementType])
			.forSuccess(updateAchievementTypeSuccess)
			.forError(updateAchievementTypeFailure)
			.includeErrorResult()
			.withMessage('Error updating achievement')
			.generate(),
	),
	takeLatest(GIVE_ACHIEVEMENT_TYPE,
		apiSagaGenerator()
			.forApi(giveAchievementType)
			.withArguments((action) => [action.achievementTypeId, action.payload])
			.forSuccess(giveAchievementTypeSuccess)
			.forError(giveAchievementTypeFailure)
			.includeErrorResult()
			.withMessage('Error giving achievement')
			.generate(),
	),
];

export default achievementTypesSagas;
