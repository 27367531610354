import React, { useCallback, useMemo } from 'react';
import { getModeOptions } from '../logic/presets';
import DropDownMenuItem from '../../common/dropdownMenu/DropDownMenuItem';
import DropDownMenu from '../../common/dropdownMenu/DropDownMenu';
import styled, { css } from 'styled-components';
import RelativeModeSelector from '../blocks/RelativeModeSelector';
import { getRelativeModesOptions, relativeModes } from '../logic/constants';
import OptionEnabler from '../blocks/OptionEnabler';
import Checkbox from '../../common/checkbox/Checkbox';
import { getPeriodName } from '../logic/translations';
import usePeriodOptions from '../hooks/usePeriodOptions';
import { useTranslation } from 'react-i18next';

const S = {};

S.PresetsMenuContainer = styled.div`
	display: block;
	padding-top: 5px;
	padding-bottom: 5px;
	${props => props.scrollable ? css`
		max-height: 255px;
		overflow-y: auto;
	` : ''}

	& > * {
		margin-right: 0 !important;
	}

	.mode-selector {
		flex: 2 1 40%;
    	display: flex;

		& > * {
			margin-right: 0.5em;
		}
	}

	.time-period-option-enabler {
		flex: 1 0 100%;
	}
`;

export default function PresetsTab({ selectedPreset, actions, startDate, endDate }) {
	const periodOptions = usePeriodOptions();
	const { t } = useTranslation();
	const relativeModesOptions = useMemo(() => getRelativeModesOptions(), []);

	const onChangeCallback = useCallback((value, config) => {
		actions.changePreset(value, config);
	},[actions]);
	const presets = getModeOptions(startDate.mode);
	return (
		<S.PresetsMenuContainer scrollable={startDate.mode === relativeModes.current}>
			<DropDownMenu value={selectedPreset} onValueChange={onChangeCallback}>
				{presets.map(preset => 
					<DropDownMenuItem 
						key={preset.value} 
						value={preset.value} 
						selectValue={preset.config} 
						label={preset.label}
						inline={preset.isCustom} >
						{preset.isCustom ? <>
							<RelativeModeSelector 
								className="time-period-start-mode-selector"
								onModeChange={actions.changeStartMode}
								onPeriodChange={actions.changeStartPeriod} 
								fixedMode
								mode={startDate.mode} 
								period={startDate.period} 
								amount={startDate.amount} 
								onAmountChange={actions.changeStartAmount}
								fixedAmount={startDate.fixedAmount}
								modesOptions={relativeModesOptions}
								periodsOptions={periodOptions}
							/>
							<OptionEnabler className="time-period-option-enabler">
								<Checkbox
									checked={endDate.enabled}
									onToggle={actions.toggleEndDate}
									label={`${t('Include current')} ${getPeriodName(startDate.period).toLowerCase()}`}
								/>
							</OptionEnabler>
						</> : null}
					</DropDownMenuItem>)}
			</DropDownMenu>
		</S.PresetsMenuContainer>
	);
}
