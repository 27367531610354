import numberFormat from './constants';

export default function isDecimalSupported(output) {
	switch (output) {
	case numberFormat.DecimalOutput:
	case numberFormat.PercentOutput: {
		return true;
	}
	default:
		return false;
	}
}
