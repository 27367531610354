import { css } from 'styled-components';
import { ButtonColorDefinition } from './ButtonVariant';
import { ButtonSizeDefinition } from './ButtonSize';

export const basicButtonStyling = css`
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	transition: background 0.16s, border 0.16s, color 0.16s;

	.icon:before {
		vertical-align: baseline;
	}
`;

export const sizeDependantStyling = (hasLabel: boolean, sizeDefinition: ButtonSizeDefinition) => css`
	line-height: ${sizeDefinition.lineHeight}px;
	padding: ${hasLabel
		? `${sizeDefinition.paddingVertical}px ${sizeDefinition.paddingHorizontal}px`
		: `${sizeDefinition.paddingNoLabel}px`};
	gap: ${sizeDefinition.gap}px;
	font-size: ${sizeDefinition.fontSize}px;
	${sizeDefinition.uppercase && css`
		text-transform: uppercase;
	`}
`;

export const variantStyling = (colorDefinition: ButtonColorDefinition, active?: boolean) => css`
	cursor: pointer;
	${active ?
		css`
				background: ${colorDefinition.background.active};
				color: ${colorDefinition.textColor.active};
				border: 1px solid ${colorDefinition.border.active};
			` :
		css`
				background: ${colorDefinition.background.default};
				color: ${colorDefinition.textColor.default};
				border: 1px solid ${colorDefinition.border.default};
			`}
	&:hover {
		background: ${colorDefinition.background.hover};
		color: ${colorDefinition.textColor.hover};
		border: 1px solid ${colorDefinition.border.hover};
	}

	&:active, &:focus {
		background: ${colorDefinition.background.active};
		color: ${colorDefinition.textColor.active};
		border: 1px solid ${colorDefinition.border.active};
	}

	&:disabled, &[disabled] {
		cursor: default;
		background: ${colorDefinition.background.disabled};
		color: ${colorDefinition.textColor.disabled};
		border: 1px solid ${colorDefinition.border.disabled};
		opacity: 0.6;
	}
`;
