import generateUuid from '../../utils/generateUuid';
import { exportTypes } from './exportTypes';

export const EXPORT = 'plecto/exportJobs/EXPORT';
export const EXPORT_SUCCESS = 'plecto/exportJobs/EXPORT_SUCCESS';
export const EXPORT_API_ERROR = 'plecto/exportJobs/EXPORT_API_ERROR';

export const exportRequest = (exportType, requestId, parameters) => ({
	type: EXPORT,
	exportType,
	requestId,
	parameters
});

export const exportSuccess = (exportType, requestId, parameters, payload) => ({
	type: EXPORT_SUCCESS,
	exportType,
	requestId,
	parameters,
	payload
});

export const exportFailure = (exportType, requestId, parameters, error) => ({
	type: EXPORT_API_ERROR,
	exportType,
	requestId,
	parameters,
	error
});

export const EXPORT_STATUS = 'plecto/exportJobs/EXPORT_STATUS';
export const EXPORT_STATUS_SUCCESS = 'plecto/exportJobs/EXPORT_STATUS_SUCCESS';
export const EXPORT_STATUS_API_ERROR = 'plecto/exportJobs/EXPORT_STATUS_API_ERROR';

export const exportStatusRequest = (exportType, requestId, exportId, parameters) => ({
	type: EXPORT_STATUS,
	exportType,
	requestId,
	exportId,
	parameters
});

export const exportStatusSuccess = (exportType, requestId, parameters, payload) => ({
	type: EXPORT_STATUS_SUCCESS,
	exportType,
	requestId,
	parameters,
	payload
});

export const exportStatusFailure = (exportType, requestId, parameters, error) => ({
	type: EXPORT_STATUS_API_ERROR,
	exportType,
	requestId,
	parameters,
	error
});

export const DISMISS_EXPORT = 'plecto/exportJobs/DISMISS_EXPORT';

export const dismissExport = (requestId) => ({
	type: DISMISS_EXPORT,
	requestId
});

export const CHECK_EXPORT_STATUS = 'plecto/exportJobs/CHECK_EXPORT_STATUS';

export const checkExportStatus = (exportType, requestId, exportId, parameters) => ({
	type: CHECK_EXPORT_STATUS,
	exportType,
	requestId,
	exportId,
	parameters
});

export const initiateReportExport = (parameters) => (dispatch) => 
	dispatch(exportRequest(exportTypes.report, generateUuid(), parameters));

export const initiateDataSourceExport = (parameters) => (dispatch) => 
	dispatch(exportRequest(exportTypes.datasource, generateUuid(), parameters));
