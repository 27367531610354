export const exportStatus = {
	failed: 'failed',
	completed: 'completed',
	pending: 'pending',
	running: 'running',
};

export function isJobOver(status) {
	return status === exportStatus.completed || status === exportStatus.failed;
}
