export const FETCH = 'plecto/devices/FETCH';
export const FETCH_IF_NEEDED = 'plecto/devices/FETCH_IF_NEEDED';
export const FETCH_SUCCESS = 'plecto/devices/FETCH_SUCCESS';
export const FETCH_API_ERROR = 'plecto/devices/FETCH_API_ERROR';

export const fetchDevices = (deviceType) => ({
	type: FETCH,
	deviceType
});

export const fetchDevicesIfNeeded = (deviceType) => ({
	type: FETCH_IF_NEEDED,
	deviceType
});

export const fetchDevicesSuccess = (deviceType, result) => ({
	type: FETCH_SUCCESS,
	deviceType,
	result,
});

export const fetchDevicesError = (deviceType, error) => ({
	type: FETCH_API_ERROR,
	deviceType,
	error,
});
