import React, { useCallback, useState, useEffect, memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _clamp from 'lodash/clamp';
import styled, { css } from 'styled-components';
import keyCodeValidator from './keyCodeValidator';
import { maxDynamicCharacterWidth, minDynamicCharacterWidth } from './constants';
import normalizeValue from './normalizeValue';

const CHARACTER_WIDTH = 6;

const S = {};

S.Input = styled.input`
	${props => props.$dynamicWidth ? css`
	min-width: ${props => props.$minCharacterWidth + CHARACTER_WIDTH}ch;
	max-width: ${props => props.$minCharacterWidth + CHARACTER_WIDTH}ch;
	width: ${props => props.$minCharacterWidth + CHARACTER_WIDTH}ch;
	` : ''}
`;

function NumberInput({
	className,
	value,
	placeholder,
	onValueChange,
	onKeyDown,
	step,
	min,
	max,
	disabled,
	dynamicWidth,
	autoFocus,
	allowDecimals
}) {

	const classes = classNames('form-control react-number-input', className, {
		disabled
	});
	const currentValueFromProps = value || value === 0 ? value : '';
	const [localValue, setLocalValue] = useState(currentValueFromProps);
	const onKeyDownCallback = useCallback((event) => {
		const keyCode = event.key;
		const validKeyCode = keyCodeValidator(keyCode);
		if (!validKeyCode) {
			event.preventDefault();
		} else {
			onKeyDown?.(event);
		}
	}, [onKeyDown]);

	const onChange = useCallback((event) => {
		const stringValue = event.target.value;

		const normalized = normalizeValue(stringValue, min, max, allowDecimals);
		if (normalized !== null) {
			onValueChange(normalized);
			setLocalValue(normalized);
		} else {
			setLocalValue('');
		}

	}, [allowDecimals, max, min, onValueChange]);

	const disableScroll = e => e.currentTarget.blur();

	useEffect(() => {
		setLocalValue(currentValueFromProps);
	}, [currentValueFromProps]);

	const getMinCharacterWidth =
		_clamp((localValue || '').toString().length, minDynamicCharacterWidth, maxDynamicCharacterWidth);

	return <S.Input
		onWheel={disableScroll}
		type="number"
		className={classes}
		min={min}
		max={max}
		step={step}
		placeholder={placeholder}
		autoFocus={autoFocus}
		onKeyDown={onKeyDownCallback}
		value={localValue}
		onChange={onChange}
		disabled={disabled}
		$dynamicWidth={dynamicWidth}
		$minCharacterWidth={getMinCharacterWidth}
	/>;
}

NumberInput.defaultProps = {
	step: 1,
	placeholder: '',
	allowDecimals: false,
	autoFocus: false,
};

NumberInput.propTypes = {
	className: PropTypes.string,
	value: PropTypes.number.isRequired,
	onValueChange: PropTypes.func.isRequired,
	step: PropTypes.number,
	min: PropTypes.number,
	max: PropTypes.number,
	disabled: PropTypes.bool,
	placeholder: PropTypes.string,
	autoFocus: PropTypes.bool,
	allowDecimals: PropTypes.bool,
};

export default memo(NumberInput);
