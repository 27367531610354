const zIndexSlideIn = 1070;
const zIndexPopoverInsideSlideIn = 1080;
const zIndexPopover = 1090;
const zIndexAboveAll = 2147483646;

export {
	zIndexSlideIn,
	zIndexPopoverInsideSlideIn,
	zIndexPopover,
	zIndexAboveAll
};
