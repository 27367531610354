/* eslint-disable no-magic-numbers */
import { modes, periods, granularity } from './constants';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import { getGranularityModeName } from './translations';
import { getEndOfDay, getStartOfDay } from './dateUtils';

const maxTimePeriodGroups = 130;

export function clampGranularityToNearestValid(granularity, timePeriodOptions, options = {}) {
	const optionsList = getGranularityOptions(timePeriodOptions, options);

	return optionsList.indexOf(granularity) >= 0 ? granularity : (
		granularityOrdered.indexOf(granularity) >= 0 &&
		granularityOrdered.indexOf(granularity) < granularityOrdered.indexOf(optionsList[0])
			? optionsList[0] 
			: optionsList[optionsList.length - 1]
	);
}

export function getGranularityOptions(timePeriodOptions, { disallowSinglePeriod = false } = {}) {
	const actualTimePeriod = extractTimePeriodToConsiderWithGranularity(timePeriodOptions);

	let timePeriodForGranularity = actualTimePeriod;
	if (disallowSinglePeriod 
		&& timePeriodOptions.mode === modes.relative 
		&& !timePeriodOptions.relativeEndDate.enabled
		&& timePeriodOptions.relativeStartDate.amount === 1) {
		timePeriodForGranularity = getLowerPeriod(actualTimePeriod);
	}

	const availableOptions = getValidGranularityOptionsPerPeriod(timePeriodForGranularity);

	const periodMilliseconds = _find(millisecondsPerPeriods, { period: actualTimePeriod }).threshold;

	return _filter(availableOptions, granularityOption => {
		const granularityMilliseconds = _find(millisecondsPerPeriods, { period: granularityOption }).threshold;
		return periodMilliseconds / granularityMilliseconds < maxTimePeriodGroups;
	});
}

function extractTimePeriodToConsiderWithGranularity(timePeriodOptions) {
	switch (timePeriodOptions.mode) {
	case modes.relative: {
		return timePeriodOptions.relativeEndDate.enabled ?
			getBiggerPeriod(timePeriodOptions.relativeStartDate.period,  timePeriodOptions.relativeEndDate.period) :
			timePeriodOptions.relativeStartDate.period;
	}
	case modes.allTime: {
		return periods.year;
	}
	case modes.custom: {
		return getCustomDatePeriod(timePeriodOptions.customDate.startDate, timePeriodOptions.customDate.endDate);
	}
	}
}

const availableLevelsDown = 2;
const availableLevelsUp = 2;

export function getGranularityDropdownOptions(period, inverted) {
	const availableOptions = getValidGranularityOptionsPerPeriod(period, inverted);
	return availableOptions.map(value => ({ label: getGranularityModeName(value), value }));
}

export function getValidGranularityOptionsPerPeriod(period, inverted) {
	const maxGranularity = granularityOrdered.indexOf(period) >= 0 ? period : granularity.monthly;
	const index = granularityOrdered.indexOf(maxGranularity);

	const granularityOptions = inverted ? 
		granularityOrdered.slice(index, (index + 1) + availableLevelsUp)
		: granularityOrdered.slice(Math.max(0, index - availableLevelsDown), index + 1);

	return granularityOptions;
}

const hourMilliseconds = 1000 * 60 * 60;
const dayMilliseconds = hourMilliseconds * 24;
const weekMilliseconds = dayMilliseconds * 7;
const smallestMonthMilliseconds = dayMilliseconds * 28;
const monthMilliseconds = dayMilliseconds * 30;
const quarterMilliseconds = monthMilliseconds * 3;
const yearMilliseconds = dayMilliseconds * 365;

export const allTimeGranularity = 'all_time';

const periodsOrdered = [periods.day, periods.week, periods.month, periods.quarter, periods.year];
export const granularityOrdered = [
	granularity.hourly,
	granularity.daily,
	granularity.weekly,
	granularity.monthly,
	granularity.quarterly,
	granularity.annually
];

const millisecondsPerPeriods = [
	{ threshold: yearMilliseconds, period: periods.year }, 
	{ threshold: quarterMilliseconds, period: periods.quarter },
	{ threshold: smallestMonthMilliseconds, period: periods.month },
	{ threshold: monthMilliseconds * 2, period: periods.month_pairs },
	{ threshold: weekMilliseconds, period: periods.week },
	{ threshold: dayMilliseconds, period: periods.day },
	{ threshold: hourMilliseconds, period: granularity.hourly },
];

function getCustomDatePeriod(start, end) {
	start = getStartOfDay(start);
	end = getEndOfDay(end);

	const millis = end - start;
	// minus one millisecond for end of day
	const firstBigger = _find(millisecondsPerPeriods, 
		({ threshold }) => millis >= threshold - 1);
	return firstBigger ? firstBigger.period : periods.day;
}

function getBiggerPeriod(first, second) {
	return periodsOrdered.indexOf(first) > periodsOrdered.indexOf(second) ? first : second;
}

function getLowerPeriod(period) {
	const currentPeriodIndex = granularityOrdered.indexOf(period) || 1;
	return granularityOrdered[currentPeriodIndex - 1];
}
