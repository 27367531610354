import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { futureModes, getFutureModeOptions, getPeriodsOptions, periods } from '../logic/constants';
import RelativeModeSelector from './RelativeModeSelector';
import Checkbox from '../../common/checkbox/Checkbox';
import styled from 'styled-components';
import OptionEnabler from './OptionEnabler';
import { useTranslation } from 'react-i18next';

const numberWidth = '4.2em';

const S = {};
S.ModeSelectorOffset = styled(RelativeModeSelector)`
	display: flex;
	margin-left: 20px;

	.mode-selector-mode {
		flex: 1 2 ${numberWidth};
		width: 0;
		max-width:${numberWidth};
	}
`;

function OffsetSelector({ offset, actions }) {
	const { t } = useTranslation();
	const periodsOptions = useMemo(() => getPeriodsOptions(), []);
	const futureModeOptions = useMemo(() => getFutureModeOptions(), []);
	return <React.Fragment>
		<OptionEnabler className="time-period-option-enabler">
			<Checkbox
				checked={offset.enabled}
				onToggle={actions.toggleOffset}
				label={t('Offset time period')}
			/>
		</OptionEnabler>
		{offset.enabled && <S.ModeSelectorOffset
			className="mode-selector-offset"
			onModeChange={actions.changeOffsetMode}
			onPeriodChange={actions.changeOffsetPeriod} 
			mode={offset.future ? futureModes.inTheFuture : futureModes.ago} 
			period={offset.period} 
			amount={offset.amount} 
			onAmountChange={actions.changeOffsetAmount}
			modesOptions={futureModeOptions}
			periodsOptions={periodsOptions}
			disabled={!offset.enabled}
			label={t('Offset by:')}
		/>}
	</React.Fragment>;
}

OffsetSelector.propTypes = {
	actions: PropTypes.shape({
		toggleOffset: PropTypes.func.isRequired,
		changeOffsetMode: PropTypes.func.isRequired,
		changeOffsetPeriod: PropTypes.func.isRequired,
		changeOffsetAmount: PropTypes.func.isRequired,
	}).isRequired,
	offset: PropTypes.shape({
		enabled: PropTypes.bool.isRequired,
		future: PropTypes.bool,
		amount: PropTypes.number,
		period: PropTypes.oneOf(Object.values(periods)),
	}).isRequired
};

export default memo(OffsetSelector);
