import { apiGet, apiVersionV3 } from '../../../fetch/apiService';

export function getTeams(
	parentUUID = 'null',
	searchValue = '',
	compact,
	withMembersOnly,
	dynamicDashboardId,
) {
	return apiGet(
		'teams',
		{
			parent: parentUUID,
			name__icontains: searchValue,
			...(compact && { compact: compact }),
			...(withMembersOnly && { has_members: withMembersOnly }),
			...(dynamicDashboardId && { for_dynamic_dashboard: dynamicDashboardId }),
		},
		{},
		apiVersionV3,
	);
}
