import React, { useRef, PropsWithChildren, ReactNode } from 'react';
import mergeRefs from '../../utils/mergeRefs';
import { useTooltip } from './useTooltip';

type Props = {
	message?: string,
	placement?: 'auto' | 'top' | 'bottom' | 'left' | 'right',
	forceShow?: boolean,
	forceShowDelay?: number,
	delay?: number,
}

export function Tooltip(
	{ 
		message,
		children,
		placement = 'right',
		forceShow = false,
		forceShowDelay = 250,
		delay = 250,
	}: PropsWithChildren<Props>
) {
	const elementRef = useRef<HTMLElement>();
	useTooltip(elementRef, { delay, forceShow, forceShowDelay, placement });

	const child: ReactNode = React.Children.only(children);
	// @ts-ignore
	return React.cloneElement(child, {
		// @ts-ignore
		ref: mergeRefs(elementRef, child?.ref),
		'data-toggle': 'tooltip',
		'data-placement': placement,
		'data-original-title': message }
	);

}

