import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import { withCssSelector } from '../../shared/withCssSelector';

const StyledHelpText = styled.p`
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6B7280;
	margin: 0;
	display: flex;
	align-items: center;
`;

export type HelpTextProps = ComponentPropsWithoutRef<'p'>

export const HelpText = withCssSelector(React.forwardRef<HTMLParagraphElement, HelpTextProps>(
	function HelpText(props, ref) {
		return <StyledHelpText ref={ref} {...props} />;
	}
), StyledHelpText.toString());
