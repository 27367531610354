import { darken } from 'polished';

const COLORS = {
	primary: '#12A34D',
	primaryLight: '#29AC68',
	secondary: '#374151',
	secondaryLight: '#4B5563',
	secondaryDark: '#1f2937',
	hover: '#F5F7FA',
	light: '#fff',
	dark: '#000',
	error: '#B91C1C',
};

const COLORS_BRAND = {
	primary: '#2bb673',
	secondary: '#12424d',
	secondaryLight: '#293E60'
};

const BOX_SHADOWS = {
	popover: '0px 0px 20px rgb(0 0 0 / 5%), 0px 2px 10px rgb(0 0 0 / 10%)',
	dropdownMenu: '0 6px 12px rgb(0 0 0 / 18%)',
};

const COLORS_GRAY = {
	100: '#F3F4F6',
	150: '#f5f6fa',
	200: '#f5f5f5',
	210: '#F5F7FE',
	250: '#e5e5e5',
	300: '#D3D8D8',
	350: '#E5E7EB',
	400: '#d6e0e0',
	500: '#cccccc',
	550: '#D1D5DB',
	600: '#9CA3AF',
	650: '#6B7280',
	700: '#555',
	800: '#666666',
};

const COLORS_WARNING = {
	light: '#FEE2E2',
	default: '#DC2626',
	dark: '#B91C1C',
};

const COLORS_INFO = {
	light: '#EDF4FE',
	default: '#2F95FF',
	dark: '#4599FF',
	darker: '#126BDD',
};

const COLORS_SUCCESS = {
	light: '#a2d0b3',
	default: '#12A34D',
	dark: darken('0.2', '#12A34D'),
};

const COLORS_BORDER = {
	brand: COLORS_SUCCESS.light,
	primary: COLORS_GRAY['300'],
	settings: '#E2E6E6',
	invalid: COLORS_WARNING.dark,
	dropdownMenu: 'rgba(0,0,0,0.15)',
};

const COLORS_DND_PLACEHOLDER = {
	border: COLORS_INFO.default,
	background: COLORS_INFO.light,
	color: COLORS_INFO.default,
};

const COLORS_CARD = {
	headerBackground: '#E4E9F2',
	headerBackgroundOver: '#E4E9F2',
	headerBackgroundHover: '#EAEEF5',
	groupHeaderBackground: COLORS_BRAND.secondaryLight,
	groupHeaderBackgroundHover: '#405173',
};

const COLORS_TEXT = {
	dark: COLORS_BRAND.secondary,
	subtitle: '#728E94',
	dropdownOption: '#273949',
	dropdownOptionHover: '#1e2c38',
	dropdownOptionDisabled: '#c6d0d5',
};

const COLORS_MODAL = {
	title: '#1F2937'
};

export {
	COLORS_MODAL,
	COLORS_CARD,
	COLORS_BRAND,
	COLORS_GRAY,
	COLORS_INFO,
	COLORS,
	COLORS_BORDER,
	BOX_SHADOWS,
	COLORS_SUCCESS,
	COLORS_WARNING,
	COLORS_TEXT,
	COLORS_DND_PLACEHOLDER
};
