import { takeLatest } from 'redux-saga/effects';
import {
	FETCH, fetchTeamsFailure, fetchTeamsSuccess
} from './actions';
import { getTeams } from './api';
import apiSagaGenerator from '../../../saga/apiSagaGenerator';

const teamsSagas = [
	takeLatest(FETCH, 
		apiSagaGenerator()
			.forApi(getTeams)
			.forSuccess(fetchTeamsSuccess)
			.forError(fetchTeamsFailure)
			.withMessage('Error getting teams')
			.generate() 
	),
];

export default teamsSagas;
