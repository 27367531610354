import _values from 'lodash/values';
import _groupBy from 'lodash/groupBy';
import React from 'react';
import AchievementBadgeStack from '../achievementBadgeStack/AchievementBadgeStack';
import styled from 'styled-components';
import { ACHIEVEMENT_BADGE_SIZES, ACHIEVEMENT_BADGE_SIZES_PX } from '../achievementBadgeSizes';
import PropTypes from 'prop-types';
import { ACHIEVEMENT_BADGE_THEMES } from '../achievementBadgeThemes';
import TooltipWrapper from '../../tooltip/TooltipWrapper';
import AchievementDescription from '../achievementDescription/AchievementDescription';
import isRequiredIf from 'react-proptype-conditional-require/isRequiredIf';

const TYPE_ID = 'typeId';

const TOOLTIP_CONFIG = {
	interactive: true,
	delayHide: 100,
	delayShow: 100,
	placement: 'top',
	// eslint-disable-next-line no-magic-numbers
	offset: [0, 8],
};

const TOOLTIP_FLIP_OPTIONS = {
	fallbackPlacements: ['bottom', 'left', 'right'],
};

const SingleSpacer = styled.div`
  display: inline-block;
`;

const StackSpacer = styled.div`
  display: inline-block;
`;

const Container = styled.div`
  display: block;
  height: ${props => ACHIEVEMENT_BADGE_SIZES_PX[props.size]};

  ${StackSpacer} + ${StackSpacer} {
	margin-left: 8px;
  }

  ${SingleSpacer} + ${SingleSpacer} {
	margin-left: 1px;
  }

  ${SingleSpacer} + ${StackSpacer} {
	margin-left: 4px;
  }

  ${StackSpacer} + ${SingleSpacer} {
	margin-left: 5px;
  }
`;

function badgeStackWithOptionalTooltip(achievements, size, theme, showTooltip) {
	const stack = <AchievementBadgeStack size={size} theme={theme} achievements={achievements}/>;
	if (showTooltip) {
		const description = <AchievementDescription achievements={achievements}/>;
		return <TooltipWrapper content={description} config={TOOLTIP_CONFIG} flipOptions={TOOLTIP_FLIP_OPTIONS}>
			{stack}
		</TooltipWrapper>;
	}
	return stack;
}

function badgeStack(achievements, size, theme, showTooltip) {
	const key = achievements[0][TYPE_ID];
	const stack = badgeStackWithOptionalTooltip(achievements, size, theme, showTooltip);
	if (achievements.length > 1) {
		return <StackSpacer key={key}>{stack}</StackSpacer>;
	}
	return <SingleSpacer key={key}>{stack}</SingleSpacer>;
}

export default function AchievementShowcase({
	size = ACHIEVEMENT_BADGE_SIZES.small,
	theme = ACHIEVEMENT_BADGE_THEMES.light,
	showTooltip = false,
	achievements,
	className,
}) {
	const stacks = _values(_groupBy(achievements, TYPE_ID))
		.map(stack => badgeStack(stack, size, theme, showTooltip));
	return <Container size={size} className={className}>{stacks}</Container>;
}

AchievementShowcase.propTypes = {
	size: PropTypes.oneOf(Object.values(ACHIEVEMENT_BADGE_SIZES)),
	theme: PropTypes.oneOf(Object.values(ACHIEVEMENT_BADGE_THEMES)),
	achievements: PropTypes.arrayOf(
		PropTypes.shape({
			id: isRequiredIf(PropTypes.string, props => props.showTooltip),
			typeId: PropTypes.string.isRequired,
			iconId: PropTypes.string.isRequired,
			name: isRequiredIf(PropTypes.string, props => props.showTooltip),
			localizedDate: isRequiredIf(PropTypes.string, props => props.showTooltip),
			description: isRequiredIf(PropTypes.string, props => props.showTooltip),
			active: PropTypes.bool,
			message: PropTypes.shape({
				text: PropTypes.string.isRequired,
				authorName: PropTypes.string.isRequired,
				localizedDate: PropTypes.string.isRequired,
				authorAvatarUrl: PropTypes.string,
			}),
		}),
	).isRequired,
	showTooltip: PropTypes.bool,
	className: PropTypes.string,
};
