import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { zIndexAboveAll } from '../../styles/zIndex';
import classNames from 'classnames';

const StyledModal = styled.div`
	display: none;
	overflow: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 10px;
	z-index: 1080;
	-webkit-overflow-scrolling: touch;
	outline: 0;
	@media (min-width: 768px) {
		& {
			padding: 30px;
		}
	}
`;

const StyledModalBackdrop = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #000;

	&.fade { 
		opacity: 0;
	}
	&.in { 
		opacity: 0.5; 
	}
`;

const StyledModalDialog = styled.div`
	position: relative;
	width: auto;
	margin: 0 auto;

	@media (min-width: 768px) {
		& {
			width: 600px;
		}
	}
`;

class ModalPortal extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		return ReactDOM.createPortal(
			this.props.children,
			document.body,
		);
	}
}

ModalPortal.propTypes = {
};

class Modal extends React.PureComponent {
	constructor(props) {
		super(props);

		this.el = null;
		this.handleBackdropClick = this.handleBackdropClick.bind(this);
	}

	componentDidMount() {
		if (this.el) {
			this.el.focus();
		}
	}

	handleBackdropClick() {
		if (this.props.closeOnBackdropClick) {
			this.props.onClose();
		}
	}

	render() {
		const { className: propsClassName, size, hidden, forceTop, children } = this.props;
		const className = classNames('modal', propsClassName, {
			[`modal-${this.props.size}`]: size
		});

		const style = { display: hidden ? 'none' : 'block' };

		if (forceTop) {
			style['zIndex'] = zIndexAboveAll + 1;
		}

		return <ModalPortal>
			<StyledModal
				className={className}
				tabIndex="-1"
				role="dialog"
				aria-labelledby="form-modal"
				aria-hidden="false"
				style={style}
				ref={el => this.el = el}
			>
				<StyledModalBackdrop className="modal-backdrop in" onClick={this.handleBackdropClick} />
				<StyledModalDialog className="modal-dialog">
					{children}
				</StyledModalDialog>
			</StyledModal>
		</ModalPortal>;
	}
}

Modal.propTypes = {
	hidden: PropTypes.bool,
	size: PropTypes.oneOf(['', 'sm', 'lg', 'xl', 'full']),
	onClose: PropTypes.func.isRequired,
	closeOnBackdropClick: PropTypes.bool,
};

Modal.defaultProps = {
	closeOnBackdropClick: true,
};

export default Modal;
