import { modes, periods, relativeModes, weekDays } from '../logic/constants';

export function fillWithDefaults({
	mode = modes.relative,
	offset = {
		enabled: false,
		future: true,
		period: periods.year,
		amount: 1
	},
	relativeStartDate = {
		mode: relativeModes.previous, 
		period: periods.month, 
		amount: 12, 
		startingOnDay: 1,
		startingOnMonth: 1,
	},
	relativeEndDate = {
		enabled: false,
		mode: relativeModes.current,
		amount: 1,
		period: periods.month,
	},
	allTimeEndDate = {
		enabled: false,
	},
	timeRange = {
		enabled: false, 
		fromHours: '09:00', 
		toHours: '17:00',
		fromDay: weekDays.monday,
		toDay: weekDays.sunday
	}, 
	customDate = {
		startDate: null,
		endDate: null,
	}
}) {
	return {
		mode, 
		offset, 
		relativeStartDate: {
			startingOnDay: 1,
			startingOnMonth: 1,
			...relativeStartDate
		}, 
		relativeEndDate, 
		allTimeEndDate, 
		timeRange, 
		customDate,
	};
}
