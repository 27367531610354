import React, { memo } from 'react';
import Flatpickr from 'react-flatpickr';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import 'flatpickr/dist/flatpickr.css';
import './flatpickrOverrides.css';
import { Icon } from '../icon/Icon';

const StyledFlatpickr = styled(Flatpickr)`
	& input[readonly] {
		background-color: white;
	}
	input::placeholder {
		color: #C9CACC;
	}
`;

const StyledInputContainer = styled.article`
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	input {
		border: none;
	}
`;

function DateTimePicker({ value, onChange, minDate, maxDate, placeholder }) {
	const options = {
		defaultHour: 0,
		defaultMinute: 0,
		minDate: minDate || null,
		maxDate: maxDate || null,
		time_24hr: true,
		wrap: true,
	};

	return (
		<StyledFlatpickr
			value={value}
			onChange={(selectedDates) => onChange(selectedDates[0])}
			options={options}
			placeholder={placeholder}
			data-enable-time
		>
			<StyledInputContainer className="flatpickr form-control" data-toggle>
				<input type="text" placeholder={placeholder} data-input />
				<Icon name="calendar" />
			</StyledInputContainer>
		</StyledFlatpickr>
	);
}

DateTimePicker.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.number]),
	onChange: PropTypes.func,
	minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.number]),
	maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.number]),
	placeholder: PropTypes.string,
};

export default memo(DateTimePicker);
