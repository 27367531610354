import React from 'react';
import styled from 'styled-components';
import { FilterTreeNode } from './types';
import { FilterTreeItem } from './FilterTreeItem';
import { useTreeDataContext } from './TreeDataContext';

const StyledList = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;
	padding: 0;
	margin: 0;
`;

type FilterTreeProps = {
    items: FilterTreeNode[];
    onToggle: (uuid: string) => void;
    isRoot?: boolean;
}

export const FilterTree = ({ items, onToggle, isRoot }: FilterTreeProps) => {
	const { initiallyExpanded, selection } = useTreeDataContext();
	return <StyledList>
		{items.map(item =>
			<FilterTreeItem
				filterItem={item}
				key={item.id}
				onToggle={onToggle}
				selected={selection.indexOf(item.id) > -1}
				expanded={initiallyExpanded.indexOf(item.id) > -1}
				isRoot={isRoot}
			/>,
		)}
	</StyledList>;
};
