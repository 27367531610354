import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

type Props = {
	className?: string,
	direction?: 'left' | 'right',
	color?: string,
	caret?: React.ReactNode,
	size?: 'xs' | 'sm' | 'lg',
};

export const DropdownMenu =
	({ className, children, direction = 'left', color = 'white', size, caret }: PropsWithChildren<Props>) =>
		<>
			<button
				type="button"
				className={classNames(
					'btn dropdown-toggle',
					className,
					{ [`btn-${size}`]: size, [`btn-${color}`]: color }
				)}
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				{caret || <span className="caret"></span>}
			</button>
			<ul className={`dropdown-menu pull-${direction}`}>
				{children}
			</ul>
		</>;
