import { combineReducers } from 'redux';
import _pick from 'lodash/pick';
import apiReducers from './apiReducers';

export default function mergeWithApiReducers(moduleReducers, apiReducersNames) {
	return apiReducersNames && apiReducersNames.length > 0 ?
		combineReducers({
			...moduleReducers,
			..._pick(apiReducers, apiReducersNames)
		})
		:
		combineReducers(moduleReducers);
}
