import styled, { css } from 'styled-components';
import React from 'react';
import { withCssSelector } from '../shared/withCssSelector';
import { ToggleSize, ToggleSizeDefinition, ToggleSizes } from './ToggleSize';
import { ToggleVariant, ToggleVariantDefinition, ToggleVariants } from './ToggleVariant';

const animationTime = .3;

const StyledToggleTrack = styled.div<{
	$active: boolean,
	$variantDefinition: ToggleVariantDefinition,
}>`
	background: ${(props) => props.$variantDefinition.trackColor};
	position: relative;
	transition: all ${animationTime}s ease;
	cursor: pointer;
	${(props) => props.$variantDefinition.border && css`
		border: solid #BDC3CB;
	`};
		
	${props => props.$active && css`
		background: ${props.$variantDefinition.trackActiveColor};
		border-color: ${props.$variantDefinition.trackActiveColor};
	`}
`;

const StyledToggleThumb = styled.div<{
	$active: boolean,
}>`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 50%;
	transition: ${animationTime}s ease;
	background: #fff;
`;

const StyledToggleTrackSized = styled(StyledToggleTrack)<{
	$sizeDefinition: ToggleSizeDefinition;
	$variantDefinition: ToggleVariantDefinition,
  }>`
	width: ${(props) => props.$sizeDefinition.width}px;
	height: ${(props) => props.$sizeDefinition.height}px;
	border-radius: ${(props) => props.$sizeDefinition.borderRadius}px;
	${(props) => props.$variantDefinition.border && css`
		border-width: ${props.$sizeDefinition.borderSize}px;
	`};
	${(props) => props.$variantDefinition.shadow && css`
		box-shadow: inset 0px ${props.$sizeDefinition.borderSize}px
		${props.$sizeDefinition.borderSize * 2}px rgba(6, 2, 2, 0.1);
	`};
`;

const StyledToggleThumbSized = styled(StyledToggleThumb)<{
	$sizeDefinition: ToggleSizeDefinition;
	$variantDefinition: ToggleVariantDefinition,
  }>`
	width: ${(props) => props.$sizeDefinition.innerRadius}px;
	height: ${(props) => props.$sizeDefinition.innerRadius}px;
	left: ${(props) => props.$sizeDefinition.padding}px;

	${(props) => props.$variantDefinition.shadow && css`
		box-shadow: 0px 0px ${props.$sizeDefinition.borderSize}px
			rgba(0, 0, 0, 0.05),
			0px ${props.$sizeDefinition.borderSize}px
			${props.$sizeDefinition.borderSize * 2}px rgba(0, 0, 0, 0.15);
	`};

	${props => props.$active && css`
		left: calc(100% - ${props.$sizeDefinition.innerRadius + props.$sizeDefinition.padding}px);
	`}
`;

export type ToggleProps = {
	className?: string,
	active: boolean,
	onToggle: (next: boolean) => unknown,
	size?: ToggleSize,
	variant?: ToggleVariant,
}

export const Toggle = withCssSelector(React.forwardRef<HTMLDivElement, ToggleProps>(
	function Toggle({
		className,
		active,
		onToggle,
		size = 'default',
		variant = 'default'
	}, ref) {
		const sizeDefinition = ToggleSizes[size];
		const variantDefinition = ToggleVariants[variant];

		return <StyledToggleTrackSized
			$active={active}
			$sizeDefinition={sizeDefinition} 
			$variantDefinition={variantDefinition} 
			ref={ref}
			className={className}
			onClick={() => onToggle(!active)}
		>
			<StyledToggleThumbSized
				$active={active}
				$sizeDefinition={sizeDefinition}
				$variantDefinition={variantDefinition}
			/>
		</StyledToggleTrackSized>;
	}
), StyledToggleTrack.toString());
