import { FETCH, FETCH_SUCCESS, FETCH_API_ERROR } from './actions';

const initialState = {
	data: [],
	isFetching: false,
	error: null,
};

const myTeams = (state = initialState, action) => {
	switch (action.type) {
	case FETCH:
		return {
			...state,
			isFetching: true,
		};
	case FETCH_SUCCESS:
		return {
			...state,
			data: action.result,
			isFetching: false,
			error: null,
		};
	case FETCH_API_ERROR:
		return {
			...state,
			isFetching: false,
			error: action.error,
		};
	default:
		return state;
	}
};

export default myTeams;
