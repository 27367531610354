import React from 'react';
import styled from 'styled-components';
import { Loader } from './Loader';

const StyledMessage = styled.h4`
	font-size: 24px;
	color: #fff;
`;

const LoaderBackdrop = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	left: 0;
	top: 0;
	z-index: 3;
`;

const StyledLoaderWrapper = styled.article`
	position: absolute;
	top: calc(50% - 40px);
	right: calc(50% - 40px);
	display: flex;
	flex-direction: column;
	align-items: center;

	div.loader {
		border-top-color: #fff;
		border-left-color: #fff;
	}
`;

const preventDefault = (event) => event.preventDefault();

export default function LoadingMessageOverlay({ show, message }) {
	if (!show) return null;

	return (
		<LoaderBackdrop onClick={preventDefault}>
			<StyledLoaderWrapper>
				<Loader size="xl"/>
				<StyledMessage>{message}</StyledMessage>
			</StyledLoaderWrapper>
		</LoaderBackdrop>
	);
}
