import React, { useState, useCallback, useReducer, useMemo } from 'react';
import multiDropdownReducer, { createInitialState, reset, searchBy } from './multiDropdownReducer';
import _debounce from 'lodash/debounce';
import Toggle from './components/Toggle';
import MultiLevelDropdownMenu from './MultiLevelDropdownMenu';
import styled from 'styled-components';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import MultiLevelDropdownWrapper from './MultiLevelDropdownWrapper';

const StyledDropdownMenu = styled(MultiLevelDropdownMenu)`
	color: #666;
	
	&.popover-tab-bottom-start {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
	&.popover-tab-top-start {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
`;

function MultiLevelDropdown({
	className,
	openOnStart,
	value,
	options,
	onValueChange,
	componentOnChange,
	componentValue,
	onBackLabel,
	componentProps,
	popupDisabled,
	padding,
	placeholder,
	fieldRelatioSelectionAllowed,
	fixedWidth
}, ref) {
	const [state, dispatch] = useReducer(multiDropdownReducer, { value, options, componentValue }, createInitialState);

	const { label, filteredOptions, currentOptions } = state;

	const [open, setOpen] = useState(!!openOnStart || !!popupDisabled);

	const search = useCallback((term) => dispatch(searchBy(term)), []);

	const searchDebounced = useMemo(() => _debounce((term) => search(term)), [search]);

	const onSearch = useCallback(async (value) => {
		if (!value) {
			searchDebounced.cancel();
			search(value);
		} else {
			searchDebounced(value);
		}
	}, [search, searchDebounced]);

	useDidUpdateEffect(() => {
		dispatch(reset(value, options, componentValue));
	},[value, options, componentValue]);

	const onOpen = useCallback((nextOpen) => {
		setOpen(nextOpen);

		if (!nextOpen) {
			dispatch(reset(value, options, componentValue));
		}
	}, [options, value, componentValue]);

	const onClose = () => setOpen(false);
	const optionsArray = filteredOptions || currentOptions;

	return <MultiLevelDropdownWrapper
		className={className}
		label={label}
		onOpen={onOpen}
		open={open}
		padding={padding}
		placement={'bottom-start'}
		popupDisabled={popupDisabled}
		toggle={<Toggle
			label={label}
			iconName={'search'}
			placeholder={placeholder}
			open={open}
			onSearch={onSearch}
		/>}
	>
		<StyledDropdownMenu
			ref={ref}
			onClose={onClose}
			value={value}
			options={optionsArray}
			onValueChange={onValueChange}
			componentOnChange={componentOnChange}
			componentValue={componentValue}
			onBackLabel={onBackLabel}
			componentProps={componentProps}
			popupDisabled={popupDisabled}
			fixedWidth={fixedWidth}
			fieldRelatioSelectionAllowed={fieldRelatioSelectionAllowed}
		/>
	</MultiLevelDropdownWrapper>;
}

export default React.forwardRef(MultiLevelDropdown);
