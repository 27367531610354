export const FETCH = 'plecto/timeZone/FETCH';
export const FETCH_SUCCESS = 'plecto/timeZone/FETCH_SUCCESS';
export const FETCH_API_ERROR = 'plecto/timeZone/FETCH_API_ERROR';

export const fetchTimeZones = () => ({
	type: FETCH,
});

export const fetchTimeZonesSuccess = (payload) => ({
	type: FETCH_SUCCESS,
	payload,
});

export const fetchTimeZonesFailure = (error) => ({
	type: FETCH_API_ERROR,
	error,
});

export function shouldFetchTimeZones(state,) {
	const timeZonesState = state.timeZones;
	return !timeZonesState.isFetching && timeZonesState.isStale;
}

export function fetchTimeZonesIfNeeded() {
	return (dispatch, getState) => {
		if (shouldFetchTimeZones(getState())) {
			return dispatch(fetchTimeZones());
		}
	};
}
