export const modalStateClasses = {
	warning: 'pane-warning',
	secondaryLight: 'pane-secondary-light',
	default: '',
} as const;

export type StateClasses = keyof typeof modalStateClasses;

export const modalStates = {
	warning: 'warning',
	default: 'default',
};
