export function scrollContainerTo(containerDOMNode, { top = -1, left = -1, behavior = 'smooth' }) {
	if (containerDOMNode) {
		top = top < 0 ? containerDOMNode.scrollTop : top;
		left = left < 0 ? containerDOMNode.scrollLeft : left;
		if (containerDOMNode.scrollTo) {
			containerDOMNode.scrollTo({
				top,
				left,
				behavior,
			});
		} else {
			containerDOMNode.scrollLeft = left;
			containerDOMNode.scrollTop = top;
		}
	}
	
}

export function scrollElementIntoView(selector) {
	const domNode = document.querySelector(selector);
	if (domNode) {
		scrollSmoothly(domNode);
	}
}

export function scrollSmoothly(domNode, options = {}) {
	requestAnimationFrame(() => {
		domNode.scrollIntoView({
			behavior: 'smooth',
			...options
		});
	});
}
