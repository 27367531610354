import { mapModelToApi } from './reducers';

export const FETCH = 'plecto/formula/FETCH';
export const FETCH_SUCCESS = 'plecto/formula/FETCH_SUCCESS';
export const FETCH_FORMULAS_API_ERROR = 'plecto/formula/FETCH_API_ERROR';

export const fetchFormulas = () => ({
	type: FETCH,
});

export const fetchFormulasSuccess = (payload) => ({
	type: FETCH_SUCCESS,
	payload,
});

export const fetchFormulasFailure = (error) => ({
	type: FETCH_FORMULAS_API_ERROR,
	error
});

export const RESOLVE = 'plecto/formula/RESOLVE';
export const RESOLVE_SUCCESS = 'plecto/formula/RESOLVE_SUCCESS';
export const RESOLVE_FORMULA_API_ERROR = 'plecto/formula/RESOLVE_FORMULA_API_ERROR';

export const resolveFormula = (formulaString, formulaId) => ({
	type: RESOLVE,
	formulaString,
	formulaId
});

export const resolveFormulaSuccess = (formulaString, formulaId, payload) => ({
	type: RESOLVE_SUCCESS,
	formulaString,
	formulaId,
	payload,
});

export const resolveFormulaFailure = (formulaString, formulaId, error) => ({
	type: RESOLVE_FORMULA_API_ERROR,
	formulaString,
	formulaId,
	error
});

export const PARSE_FORMULA = 'plecto/formula/PARSE_FORMULA';
export const PARSE_FORMULA_SUCCESS = 'plecto/formula/PARSE_FORMULA_SUCCESS';
export const PARSE_FORMULA_API_ERROR = 'plecto/formula/PARSE_FORMULA_API_ERROR';

export const parseFormula = (formulaString, formulaId, doNotShowToast) => ({
	type: PARSE_FORMULA,
	formulaString,
	formulaId,
	doNotShowToast,
});

export const parseFormulaSuccess = (formulaString, formulaId, doNotShowToast, payload) => ({
	type: PARSE_FORMULA_SUCCESS,
	formulaString,
	formulaId,
	doNotShowToast,
	payload,
});

export const parseFormulaFailure = (formulaString, formulaId, doNotShowToast, error) => ({
	type: PARSE_FORMULA_API_ERROR,
	formulaString,
	formulaId,
	doNotShowToast,
	error,
});

export function shouldFetchFormulas(state) {
	const formulas = state.formulas;
	return formulas.isStale && !formulas.isFetching;
}

export function fetchFormulasIfNeeded() {
	return (dispatch, getState) => {
		if (shouldFetchFormulas(getState())) {
			return dispatch(fetchFormulas());
		}
	};
}

export const FETCH_DIMENSIONS = 'plecto/formula/FETCH_DIMENSIONS';
export const FETCH_DIMENSIONS_SUCCESS = 'plecto/formula/FETCH_DIMENSIONS_SUCCESS';
export const FETCH_DIMENSIONS_API_ERROR = 'plecto/formula/FETCH_DIMENSIONS_API_ERROR';

export const fetchFormulaDimensions = (formulaId) => ({
	type: FETCH_DIMENSIONS,
	formulaId,
});

export const fetchFormulaDimensionsSuccess = (formulaId, payload) => ({
	type: FETCH_DIMENSIONS_SUCCESS,
	formulaId,
	payload,
});

export const fetchFormulaDimensionsFailure = (formulaId, error) => ({
	type: FETCH_DIMENSIONS_API_ERROR,
	formulaId,
	error
});

export function fetchFormulaDimensionsIfNeeded(formulaId) {
	return (dispatch, getState) => {
		const { isFetching, isLoaded } = getState().formulas?.dimensions?.[formulaId] || {};
		if (!isFetching && !isLoaded) {
			return dispatch(fetchFormulaDimensions(formulaId));
		}
	};
}

export const PRELOAD_FORMULA = 'plecto/formula/PRELOAD_FORMULA';
export const GET_FORMULA = 'plecto/formula/GET_FORMULA';
export const GET_FORMULA_SUCCESS = 'plecto/formula/GET_FORMULA_SUCCESS';
export const GET_FORMULA_API_ERROR = 'plecto/formula/GET_FORMULA_API_ERROR';

export const preloadFormula = (formulaModel) => ({
	type: PRELOAD_FORMULA,
	formulaModel,
});

export const getFormula = (formulaId) => ({
	type: GET_FORMULA,
	formulaId,
});

export const getFormulaSuccess = (formulaId, payload) => ({
	type: GET_FORMULA_SUCCESS,
	formulaId,
	payload,
});

export const getFormulaFailure = (formulaId, error) => ({
	type: GET_FORMULA_API_ERROR,
	formulaId,
	error
});

export const UPDATE_FORMULA = 'plecto/formula/UPDATE_FORMULA';

export const updateFormula = (formulaId, updates, isPreview) => ({
	type: UPDATE_FORMULA,
	formulaId,
	updates,
	isPreview
});

export const SAVE_FORMULA = 'plecto/formula/SAVE_FORMULA';
export const SAVE_FORMULA_SUCCESS = 'plecto/formula/SAVE_FORMULA_SUCCESS';
export const SAVE_FORMULA_API_ERROR = 'plecto/formula/SAVE_FORMULA_API_ERROR';

export function saveOrCreateFormula(formulaId, formulaString, onSaveCallback) {
	return (dispatch, getState) => {
		const state = getState();
		const formulaState = state.formulas.formula[formulaId];
		if (formulaState) {
			const model = mapModelToApi({
				...formulaState.data,
				format: formulaString,
			});
			return dispatch(saveFormula(formulaId, model, onSaveCallback));
		}
	};
}

export const saveFormula = (formulaId, model, onSaveCallback) => ({
	type: SAVE_FORMULA,
	formulaId,
	model,
	onSaveCallback
});

export const saveFormulaSuccess = (formulaId, model, onSaveCallback, payload) => ({
	type: SAVE_FORMULA_SUCCESS,
	formulaId,
	payload,
	onSaveCallback,
	model,
});

export const saveFormulaFailure = (formulaId, model, onSaveCallback, error) => ({
	type: SAVE_FORMULA_API_ERROR,
	formulaId,
	model,
	onSaveCallback,
	error,
});

export const PREVIEW_FORMULA = 'plecto/formula/PREVIEW';
export const PREVIEW_FORMULA_SUCCESS = 'plecto/formula/PREVIEW_FORMULA_SUCCESS';
export const PREVIEW_FORMULA_API_ERROR = 'plecto/formula/PREVIEW_FORMULA_API_ERROR';

// eslint-disable-next-line max-params
export const previewFormula = (formulaId, formulaString, period, period_amount, output, decimals) => ({
	type: PREVIEW_FORMULA,
	formulaId,
	formulaString,
	period,
	period_amount,
	output,
	decimals,
	doNotShowToast: true
});

// eslint-disable-next-line max-params
export const previewFormulaSuccess = (formulaId, formulaString, period, period_amount, output, decimals, payload) => ({
	type: PREVIEW_FORMULA_SUCCESS,
	formulaId,
	formulaString,
	period,
	period_amount,
	output,
	decimals,
	payload,
	doNotShowToast: true
});

// eslint-disable-next-line max-params
export const previewFormulaFailure = (formulaId, formulaString, period, period_amount, output, decimals, error) => ({
	type: PREVIEW_FORMULA_API_ERROR,
	formulaId,
	formulaString,
	period,
	period_amount,
	output,
	decimals,
	error,
	doNotShowToast: true
});

export const CLEAN_STATE = 'plecto/formula/CLEAN_STATE';

export const cleanFormulaState = (formulaId) => ({
	type: CLEAN_STATE,
	formulaId,
});

export const LOAD_EDITOR_PARAMETERS = 'plecto/formula/LOAD_EDITOR_PARAMETERS';
export const LOAD_EDITOR_PARAMETERS_SUCCESS = 'plecto/formula/LOAD_EDITOR_PARAMETERS_SUCCESS';
export const LOAD_EDITOR_PARAMETERS_API_ERROR = 'plecto/formula/LOAD_EDITOR_PARAMETERS_API_ERROR';

export const loadEditorParameters = () => ({
	type: LOAD_EDITOR_PARAMETERS,
});

export const loadEditorParametersSuccess = (payload) => ({
	type: LOAD_EDITOR_PARAMETERS_SUCCESS,
	payload,
});

export const loadEditorParametersFailure = (error) => ({
	type: LOAD_EDITOR_PARAMETERS_API_ERROR,
	error,
});
