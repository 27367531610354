import { FETCH, FETCH_API_ERROR, FETCH_SUCCESS } from './actions';

const sonosDevices = (state = { }, action) => {
	switch (action.type) {
	case FETCH:
		return {
			...state,
			[action.credentialsId]: {
				...state[action.credentialsId],
				isFetching: true,
				isStale: false,
			}
		};
	case FETCH_SUCCESS: {
		return {
			...state,
			[action.credentialsId]: {
				...state[action.credentialsId],
				players: action.payload.players.map(player => ({
					name: player.name,
					uuid: player.id
				})),
				isFetching: false,
			}
		};
	}
	case FETCH_API_ERROR: {
		return {
			...state,
			[action.credentialsId]: {
				...state[action.credentialsId],
				isFetching: false,
			}
		};
	}
	default:
		return state;
	}
};

export default sonosDevices;
