import { usePopperTooltip } from 'react-popper-tooltip';
import React from 'react';
import styled from 'styled-components';
import { zIndexAboveAll } from '../../styles/zIndex';
import ReactDOM from 'react-dom';
import { BORDER_RADIUS } from '../../styles/shape';

const Tooltip = styled.div`
  background-color: #FFF;
  box-sizing: border-box;
  padding: 5px 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  border-radius: ${BORDER_RADIUS.xxs};
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  z-index: ${zIndexAboveAll};
`;

const modifier = (name, options) => ({
	name: name,
	options: options,
});

export default function TooltipWrapper({
	children,
	content,
	config,
	flipOptions, // https://popper.js.org/docs/v2/modifiers/flip/#options
	className,
}) {
	const modifiers = [];

	if (flipOptions !== undefined) {
		modifiers.push(modifier('flip', flipOptions));
	}

	// when using both config, popperOptions the latter override some values from the config
	// need to set them explicitly again
	if (config?.offset !== undefined) {
		modifiers.push(modifier('offset', { offset: config.offset }));
	}

	const popperOptions = {
		placement: config?.placement || 'auto',
		modifiers: modifiers,
	};
	const {
		getTooltipProps,
		setTooltipRef,
		setTriggerRef,
		visible,
	} = usePopperTooltip(config, popperOptions);

	return <>
		<span ref={setTriggerRef} className={className}>{children}</span>
		{visible && content &&
			ReactDOM.createPortal(
				<Tooltip ref={setTooltipRef} {...getTooltipProps()}>{content}</Tooltip>,
				document.body,
			)
		}
	</>;
}
