import { apiPost } from '../../fetch/apiService';

// eslint-disable-next-line max-params
export const fetchRegistrationPreviews = (dataSource, filters, sorting, searchAfter, pageSize, signal) => {
	return apiPost('preview-registrations', {
		data_source: dataSource, 
		filters, 
		sorting,  
		search_after: searchAfter, 
		page_size: pageSize
	},null, { internalApi: true, signal });
};
