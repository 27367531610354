export const FETCH = 'plecto/sonosDevices/FETCH';
export const FETCH_SUCCESS = 'plecto/sonosDevices/FETCH_SUCCESS';
export const FETCH_API_ERROR = 'plecto/sonosDevices/FETCH_API_ERROR';

export const fetchSonosDevices = (credentialsId) => ({
	type: FETCH,
	credentialsId
});

export const fetchSonosDevicesSuccess = (credentialsId, payload) => ({
	type: FETCH_SUCCESS,
	payload,
	credentialsId,
});

export const fetchSonosDevicesFailure = (credentialsId, error) => ({
	type: FETCH_API_ERROR,
	error,
	credentialsId
});

export function shouldFetchSonosDevices(state, credentialsId) {
	const sonosDevicesState = state.sonosDevices[credentialsId] || { isStale: true };
	return !sonosDevicesState.isFetching && sonosDevicesState.isStale;
}

export function fetchSonosDevicesIfNeeded(credentialsId) {
	return (dispatch, getState) => {
		if (shouldFetchSonosDevices(getState(), credentialsId)) {
			return dispatch(fetchSonosDevices(credentialsId));
		}
	};
}
