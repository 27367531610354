import apiSagaGenerator from '../../saga/apiSagaGenerator';
import { createAchievementTypeIcon, deleteAchievementTypeIcon } from './api';
import {
	CREATE_ACHIEVEMENT_TYPE_ICON,
	createAchievementTypeIconFailure,
	createAchievementTypeIconSuccess,
	DELETE_ACHIEVEMENT_TYPE_ICON,
	deleteAchievementTypeIconFailure,
	deleteAchievementTypeIconSuccess,
} from './actions';
import { takeEvery, takeLatest } from '@redux-saga/core/effects';

const achievementTypeIconsSagas = [
	takeLatest(CREATE_ACHIEVEMENT_TYPE_ICON,
		apiSagaGenerator()
			.forApi(createAchievementTypeIcon)
			.withArguments((action) => [action.payload])
			.forSuccess(createAchievementTypeIconSuccess)
			.forError(createAchievementTypeIconFailure)
			.includeErrorResult()
			.withMessage('Error creating achievement')
			.generate(),
	),
	takeEvery(DELETE_ACHIEVEMENT_TYPE_ICON,
		apiSagaGenerator()
			.forApi(deleteAchievementTypeIcon)
			.withArguments((action) => [action.payload])
			.forSuccess(deleteAchievementTypeIconSuccess)
			.skipResult()
			.forError(deleteAchievementTypeIconFailure)
			.includeErrorResult()
			.withMessage('Error deleting achievement icon')
			.generate(),
	),
];

export default achievementTypeIconsSagas;
