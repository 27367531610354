const SPACING = {
	base: '15px',
	xxs: '2px',
	xs: '5px',
	tiny: '7px',
	smaller: '8px',
	small: '10px',
	big: '20px',
	large: '25px',
	xl: '30px',
	xxl: '35px'
};

export {
	SPACING
};
