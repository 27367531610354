import { actionPrefix } from '../constants';

export const CREATE_SESSION = `${actionPrefix}CREATE_SESSION`;
export const CREATE_SESSION_SUCCESS = `${actionPrefix}CREATE_SESSION_SUCCESS`;
export const CREATE_SESSION_FAILURE = `${actionPrefix}CREATE_SESSION_FAILURE`;

export const createSession = (quizId, session) => ({
	type: CREATE_SESSION,
	quizId,
	session
});

export const createSessionSuccess = () => ({
	type: CREATE_SESSION_SUCCESS,
});

export const createSessionFailure = (quizId, payload, error, errors) => ({
	type: CREATE_SESSION_FAILURE,
	error,
	errors
});
