import { Provider } from 'react-redux';
import React from 'react';
import ToastsBlock from './ToastsBlock';
import ReactDOM from 'react-dom';
import { toastsZIndex } from './consts';

export default class ToastsBlockContainer extends React.Component {
	constructor(props) {
		super(props);
		this.el = document.createElement('div');
		this.el.className = 'toast-block-container';
		this.el.style.zIndex = toastsZIndex;
	}

	componentDidMount() {
		document.body.appendChild(this.el);
	}

	componentWillUnmount() {
		document.body.removeChild(this.el);
	}

	render() {
		return ReactDOM.createPortal(
			<Provider store={this.props.store}>
				<ToastsBlock>
					{this.props.children}
				</ToastsBlock>
			</Provider>,
			this.el,
		);
	}
}
