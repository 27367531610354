import { FETCH_WRITTEN_UPDATE, FETCH_WRITTEN_UPDATE_SUCCESS } from './actions';

const writtenUpdates = (state = { isFetching: false, writtenUpdate: {}}, action) => {
	switch (action.type) {
	case FETCH_WRITTEN_UPDATE:
		return {
			...state,
			isFetching: true,
		};
	case FETCH_WRITTEN_UPDATE_SUCCESS: {
		return {
			...state,
			writtenUpdate: action.payload,
			isFetching: false,
		};
	}
	default:
		return state;
	}
};

export { writtenUpdates };
