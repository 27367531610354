import { fillWithDefaults } from '../blocks/defaultOptions';
import { CHANGE_PRESET, CHANGE_START_PERIOD, CHANGE_TO_PRESET_MODE, CLEAR_PRESET, TIME_PERIOD_REINIT } from './actions';
import { modes, periods, relativeModes } from './constants';
import { presetTypes, matchingPresets, getModeOptions } from './presets';
import { createInitialState } from './reducer';
import _find from 'lodash/find';
import _isMatchWith from 'lodash/isMatchWith';

export default function presetReducer(state, action) {
	switch (action.type) {
	case TIME_PERIOD_REINIT: {
		if (!action.skipPresetReducer) {
			const preset = findMatchingPresetForCurrentConfig(state);
			let newState = state;
			if (preset) {
				const { config, isCustom } = _find(getModeOptions(state.relativeStartDate.mode), { value: preset });
				newState = applyPreset(state, config, isCustom, true);
			}
	
			return {
				...newState,
				preset
			};
		}
		return state;
	}
	case CHANGE_TO_PRESET_MODE: {
		let presetToSet = findMatchingPresetForCurrentConfig(state, action.presetMode);
		if (!presetToSet) {
			presetToSet = state.preset ?
				decideWhichPresetToSet(state.relativeStartDate.mode, state.preset, action.presetMode) :
				getDefaultPreset(action.presetMode);
		}
		const { config, isCustom } = _find(getModeOptions(action.presetMode), { value: presetToSet });
		const newState = applyPreset(state, config, isCustom, false, !isCustom);
		return {
			...newState,
			preset: presetToSet,
		};
	}
	case CLEAR_PRESET: 
		return {
			...state,
			preset: null
		};
	case CHANGE_PRESET: {
		const previousPreset = _find(getModeOptions(state.relativeStartDate.mode), { value: state.preset });
		const { isCustom } = _find(getModeOptions(state.relativeStartDate.mode), { value: action.preset });

		const config = action.config;
		const newState = applyPreset(state, config, isCustom, false, !previousPreset?.isCustom);
		return {
			...newState,
			preset: action.preset
		};
	}
	case CHANGE_START_PERIOD: {
		if (state.mode === modes.relative && state.preset) {
			const { isCustom } = _find(getModeOptions(state.relativeStartDate.mode), { value: state.preset });
			if (isCustom) {
				return {
					...state,
					relativeEndDate: {
						...state.relativeEndDate,
						period: state.relativeStartDate.period
					}
				};
			}
		}
		return state;
	}
	default:
		return state;
	}
}

function applyPreset(state, config, isCustom, skipPresetReducer, applyCustomTimePeriod) {
	const newState = createInitialState(fillWithDefaults({
		...config,
		relativeStartDate: {
			...state.relativeStartDate,
			...config.relativeStartDate,
			...(isCustom && applyCustomTimePeriod ? { period: periods.day, amount: 7 } : {})

		},
		relativeEndDate: {
			...state.relativeEndDate,
			...config.relativeEndDate,
			...(isCustom ? { period: applyCustomTimePeriod ? periods.day : state.relativeStartDate.period } : {})
		},
		offset: {
			...state.offset,
			...config.offset,
		},
		timeRange: {
			...state.timeRange,
			...config.timeRange,
		},
		features: state.features,
	}), skipPresetReducer);
	return newState;
}

function decideWhichPresetToSet(mode, preset, nextMode) {
	const matchingEntry = _find(matchingPresets, pr => _find(pr, { mode, preset }));
	if (matchingEntry) {
		const presetForNextMode = _find(matchingEntry, { mode: nextMode });
		if (presetForNextMode) {
			return presetForNextMode.preset;
		}
	} 
		
	return getDefaultPreset(nextMode);
}

function getDefaultPreset(mode) {
	switch (mode) {
	case relativeModes.current:
		return presetTypes.today;
	case relativeModes.previous:
		return presetTypes.yesterday;
	case relativeModes.next:
		return presetTypes.tomorrow;
	default:
		return presetTypes.today;
	}
}

export function findMatchingPresetForCurrentConfig(config, mode) {
	const options = getModeOptions(mode || config.relativeStartDate.mode);
	const preset = _find(options, op => _isMatchWith(config, op.config, customizer));
	return preset?.value;
}

function customizer(configValue, presetValue, key, config) {
	if (key === 'relativeEndDate') {
		// if preset does not care if relativeEndDate is enabled and it's not, we should not care about it's contents
		if (!configValue.enabled && presetValue.enabled === undefined) {
			return true;
		}
		if (configValue.enabled &&
			presetValue.enabled === undefined &&
			config.relativeStartDate.period !== configValue.period) {
			return false;
		}
	}
	
}
