import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledFilterCustomItem, StyledInput } from './StyledFilterItem';

const StyledSelectAllBar = styled(StyledFilterCustomItem)`
	cursor: pointer;
	border-bottom: 1px solid #E5E7EB;
	&:hover {
		text-decoration: underline;
	}
`;

type SelectAllProps = {
	onToggle: () => void;
	count: number;
	allSelected: boolean;
	bottom?: boolean;
	disabled?: boolean;
}

export function SelectAll({ onToggle, count, allSelected, disabled = false }: SelectAllProps) {
	const { t } = useTranslation();

	const selectedAllLabel = allSelected ? t('{{count}} selected', { count }) : t('Select all ({{count}})', { count });

	return <StyledSelectAllBar onClick={onToggle}>
		<StyledInput type="checkbox" checked={allSelected} onChange={onToggle} disabled={disabled} />
		<span>{selectedAllLabel}</span>
	</StyledSelectAllBar>;
}
