import { apiGet, apiPost } from '../../fetch/apiService';
import { exportTypes } from './exportTypes';

export function exportReport({ reportId, exportType, additionalParameters }) {
	return apiPost(`reports/${reportId}/export`, additionalParameters || {}, { export_type: exportType });
}

export function exportReportStatus(exportId, { reportId }) {
	return apiGet(`reports/${reportId}/export_status`, { export_id: exportId });
}

export function exportDataSource({ dataSourceId, queryParameters }) {
	return apiPost(`datasources/${dataSourceId}/export/`, {}, queryParameters);
}

export function exportDataSourceStatus(exportId, { dataSourceId }) {
	return apiGet(`datasources/${dataSourceId}/export_status`, { export_id: exportId });
}

export function runExport(exportType, parameters) {
	switch (exportType) {
	case exportTypes.report:
		return exportReport(parameters);
	case exportTypes.datasource:
		return exportDataSource(parameters);
	default:
		throw new Error('Unsupported export type');

	}
}

export function getExportStatus(exportType, exportId, parameters) {
	switch (exportType) {
	case exportTypes.report:
		return exportReportStatus(exportId, parameters);
	case exportTypes.datasource:
		return exportDataSourceStatus(exportId, parameters);
	default:
		throw new Error('Unsupported export type');
	}
}
