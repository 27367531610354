import { takeLatest } from 'redux-saga/effects';
import {
	FETCH, fetchMembersFailure, fetchMembersSuccess,
} from './actions';
import { getMembers } from './api';
import apiSagaGenerator from '../../../saga/apiSagaGenerator';

const membersSagas = [
	takeLatest(FETCH, 
		apiSagaGenerator()
			.forApi(getMembers)
			.forSuccess(fetchMembersSuccess)
			.forError(fetchMembersFailure)
			.withMessage('Error getting members')
			.generate()		
	),
];

export default membersSagas;
