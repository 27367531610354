export const toggleVariants = [
	'default',
	'flat',
] as const;

export type ToggleVariant = typeof toggleVariants[number];

export type ToggleVariantDefinition = {
	shadow: boolean;
	border: boolean;
	trackColor: string;
	trackActiveColor: string;
};

export const ToggleVariants: {[key in ToggleVariant]: ToggleVariantDefinition} = {
	default: {
		shadow: true,
		border: true,
		trackColor: '#E5E7EB',
		trackActiveColor: '#12A34D',
	},
	flat: {
		shadow: false,
		border: false,
		trackColor: 'rgba(139, 158, 183, 0.25)',
		trackActiveColor: '#13A75E',
	},
};
