export const FETCH = 'plecto/screen/FETCH';
export const FETCH_SUCCESS = 'plecto/screen/FETCH_SUCCESS';
export const FETCH_API_ERROR = 'plecto/screen/FETCH_API_ERROR';

export const fetchScreens = (organizationId) => ({
	type: FETCH,
	organizationId
});

export const fetchScreensSuccess = (organizationId, payload) => ({
	type: FETCH_SUCCESS,
	payload,
	organizationId,
});

export const fetchScreensFailure = (organizationId, error) => ({
	type: FETCH_API_ERROR,
	error,
	organizationId
});

export function shouldFetchScreens(state, organizationId) {
	const screensState = organizationId ? state.screens[organizationId] || { isStale: true } : state.screens;
	return !screensState.isFetching && screensState.isStale;
}

export function fetchScreensIfNeeded(organizationId) {
	return (dispatch, getState) => {
		if (shouldFetchScreens(getState(), organizationId)) {
			return dispatch(fetchScreens(organizationId));
		}
	};
}
