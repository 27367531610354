import {
	BULK_DELETE_ITEMS,
	bulkDeleteItemsFailure,
	bulkDeleteItemsSuccess,
	CREATE_FOLDER,
	createFolderFailure,
	createFolderSuccess,
	DELETE_FOLDER,
	deleteFolderFailure,
	deleteFolderSuccess,
	ENQUEUE_MOVE_ITEMS,
	FETCH_ITEMS_BY_FOLDERS,
	fetchItemsByFoldersFailure,
	fetchItemsByFoldersSuccess,
	LOAD,
	LOAD_FOLDERS,
	LOAD_FOLDERS_API_ERROR,
	LOAD_FOLDERS_SUCCESS,
	LOAD_ITEMS,
	LOAD_ITEMS_API_ERROR,
	LOAD_ITEMS_SUCCESS,
	loadFolders,
	loadFoldersFailure,
	loadFoldersSuccess,
	loadItems,
	loadItemsFailure,
	loadItemsSuccess,
	MOVE_ITEMS,
	MOVE_ITEMS_SUCCESS,
	moveItems,
	moveItemsCompleted,
	moveItemsFailure,
	moveItemsSuccess,
	RENAME_FOLDER,
	renameFolderFailure,
	renameFolderSuccess,
	SEARCH,
	searchSuccess,
	searchFailure,
	search,
	DELETE_FOLDER_SUCCESS,
	BULK_DELETE_ITEMS_SUCCESS,
	RENAME_FOLDER_SUCCESS,
	CREATE_FOLDER_SUCCESS,
	RELOAD_FOLDER,
	MOVE_ITEMS_COMPLETED,
	reloadFolder,
	prepareMoveSideState,
} from './actions';
import {
	bulkDeleteItems,
	createFolder,
	getFolders,
	getItems,
	getItemsInFolders,
	getSearch,
	moveItemsToTarget,
	removeFolder,
	updateFolder,
} from './api';
import apiSagaGenerator from '../../saga/apiSagaGenerator';
import takeLatestBy from 'redux-saga-take-latest-by';
import { put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
import { PAGE_SIZE, ROOT_ID } from './constants';
import { moveSideState, searchSideState } from './sideState';
import _groupBy from 'lodash/groupBy';
import { directoryTypes } from './directoryTypes';

const directorySagas = [
	takeLatestBy(
		LOAD_FOLDERS,
		apiSagaGenerator()
			.forApi(getFolders)
			.withArguments((a) => [a.directoryId, a.folderUuid])
			.forSuccess(loadFoldersSuccess)
			.forError(loadFoldersFailure)
			.withMessage('Error loading folders')
			.generate(),
		(action) => `${action.directoryId.directoryType}/${action.folderUuid}`
	),
	takeLatestBy(
		LOAD_ITEMS,
		apiSagaGenerator()
			.forApi(getItems)
			.withArguments((a) => [a.directoryId, a.folderUuid, a.page, a.pageSize])
			.forSuccess(loadItemsSuccess)
			.forError(loadItemsFailure)
			.withMessage('Error loading items')
			.generate(),
		(action) =>
			`${action.directoryId.directoryType}/${action.folderUuid}/${action.page}`
	),
	takeLatestBy(
		FETCH_ITEMS_BY_FOLDERS,
		apiSagaGenerator()
			.forApi(getItemsInFolders)
			.withArguments((action) => [action.directoryId, action.folderIds])
			.forSuccess(fetchItemsByFoldersSuccess)
			.forError(fetchItemsByFoldersFailure)
			.withMessage('Error getting items in folders')
			.generate(),
		(action) =>
			`${action.directoryId.directoryType}/${_sortBy(
				action.folderIds || []
			).join('#')}`
	),
	takeEvery(
		CREATE_FOLDER,
		apiSagaGenerator()
			.forApi(createFolder)
			.withArguments((a) => [a.directoryId, a.folder, a.callback])
			.forSuccess(createFolderSuccess)
			.withActionArguments((a) => [a.directoryId, a.folder, a.callback, a.operationId])
			.forError(createFolderFailure)
			.withMessage('Error creating folder')
			.generate()
	),
	takeLatestBy(
		LOAD,
		fetchDirectory,
		(action) => `${action.directoryId.directoryType}/${action.uuid}`
	),
	takeLatestBy(
		RENAME_FOLDER,
		apiSagaGenerator()
			.forApi(updateFolder)
			.withArguments((a) => [a.directoryId, a.folderUuid, a.name])
			.forSuccess(renameFolderSuccess)
			.forError(renameFolderFailure)
			.withMessage('Error renaming folder')
			.generate(),
		(action) => `${action.directoryId.directoryType}/${action.folderUuid}`
	),
	takeLatestBy(
		MOVE_ITEMS,
		apiSagaGenerator()
			.forApi(moveItemsToTarget)
			.withArguments((a) => [a.directoryId, a.folder, a.items, a.folders])
			.forSuccess(moveItemsSuccess)
			.forError(moveItemsFailure)
			.withMessage('Error moving items to folder')
			.generate(),
		(action) =>
			`${action.directoryId.directoryType}/${action.folderUuid}/${_sortBy(
				action.items || []
			).join('#')}/${_sortBy(action.folders || []).join('#')}`
	),
	takeLatestBy(
		DELETE_FOLDER,
		apiSagaGenerator()
			.forApi(removeFolder)
			.withArguments((a) => [a.directoryId, a.folderUuid])
			.forSuccess(deleteFolderSuccess)
			.skipResult()
			.forError(deleteFolderFailure)
			.withMessage('Error deleting folder')
			.generate(),
		(action) => `${action.directoryId.directoryType}/${action.folderUuid}`
	),
	takeLatestBy(
		BULK_DELETE_ITEMS,
		apiSagaGenerator()
			.forApi(bulkDeleteItems)
			.withArguments((a) => [
				a.directoryId,
				a.items,
				a.folders,
				a.itemReplacements,
			])
			.forSuccess(bulkDeleteItemsSuccess)
			.skipResult()
			.forError(bulkDeleteItemsFailure)
			.withMessage('Error deleting items')
			.generate(),
		(action) =>
			`${action.directoryId.directoryType}/${action.folderUuid}/${_sortBy(
				action.items || []
			).join('#')}/${_sortBy(action.folders || []).join('#')}`
	),
	takeEvery(ENQUEUE_MOVE_ITEMS, enqueueItemMove),
	takeLatestBy(
		SEARCH,
		apiSagaGenerator()
			.forApi(getSearch)
			.withArguments((a) => [a.directoryId, a.query])
			.forSuccess(searchSuccess)
			.forError(searchFailure)
			.withMessage('Error searching')
			.generate(),
		(action) => `${action.directoryId.directoryType}/${action.query}`
	),
	takeLatest(
		[
			MOVE_ITEMS_SUCCESS,
			BULK_DELETE_ITEMS_SUCCESS,
			DELETE_FOLDER_SUCCESS,
			RENAME_FOLDER_SUCCESS,
			CREATE_FOLDER_SUCCESS,
		],
		resetSearch
	),
	takeLatest([
		CREATE_FOLDER_SUCCESS,
		LOAD_FOLDERS,
		LOAD_FOLDERS_SUCCESS
	], reloadMoveSideState),
	takeEvery(
		[MOVE_ITEMS_COMPLETED, BULK_DELETE_ITEMS_SUCCESS],
		initFolderReload
	),
	takeEvery(RELOAD_FOLDER, reloadFolderPages),
];

export const getFolderState = (
	state,
	currentDirectoryType,
	folderUuid = ROOT_ID
) => {
	return state.directory[currentDirectoryType].foldersById[folderUuid];
};

export const getSearchQuery = (state, stateId) => {
	return state.directory[stateId]?.search?.query;
};

export const getMoveSideState = (state, stateId) => {
	return state.directory[stateId];
};

function* reloadMoveSideState(action) {
	const { directoryId } = action;
	const { directoryType } = directoryId;

	const sideState = moveSideState(directoryType);
	const moveState = yield select(getMoveSideState, sideState);
	if (moveState) {
		yield put(prepareMoveSideState(directoryId));
	}
}

function* enqueueItemMove(action) {
	const { directoryId, folder, items, folders } = action;
	const { directoryType, stateId } = directoryId;
	const folderUuid = folder?.uuid || ROOT_ID;
	yield put(moveItems(directoryId, folder, items, folders));

	yield take(
		(act) =>
			act.type === MOVE_ITEMS_SUCCESS &&
			act.directoryId.directoryType === directoryType &&
			isSameFolder(act.folder?.uuid, folderUuid) &&
			_isEqual(
				act.items.map((i) => i.uuid),
				items.map((i) => i.uuid)
			) &&
			_isEqual(
				act.folders.map((i) => i.uuid),
				folders.map((i) => i.uuid)
			)
	);
	let folderState = yield select(getFolderState, stateId, folderUuid);

	if (folderState?.loadingItems && items?.length) {
		yield take(
			(act) =>
				(act.type === LOAD_ITEMS_SUCCESS ||
					act.type === LOAD_ITEMS_API_ERROR) &&
				act.directoryId.directoryType === directoryType &&
				isSameFolder(act.folder?.uuid, folderUuid)
		);
	}
	folderState = yield select(getFolderState, stateId, folderUuid);

	if (folderState?.loadingSubfolders && folders?.length) {
		yield take(
			(act) =>
				(act.type === LOAD_FOLDERS_SUCCESS ||
					act.type === LOAD_FOLDERS_API_ERROR) &&
				act.directoryId.directoryType === directoryType &&
				isSameFolder(act.folder?.uuid, folderUuid)
		);
	}

	yield put(moveItemsCompleted(directoryId, folder, items, folders));
}

function* fetchDirectory(action) {
	const { directoryId, folderUuid } = action;
	const { directoryType, stateId } = directoryId;

	const folder = yield select(getFolderState, stateId, folderUuid);

	if (
		directoryType !== directoryTypes.employee  &&
		(!folder ||
		((folder.subfolders.length || folder.loadFoldersOnInit) &&
			!folder.subfoldersLoaded &&
			!folder.loadingSubfolders))
	) {
		yield put(loadFolders(directoryId, folderUuid));
	}
	if (
		!folder || folder.shouldReloadItemsOnly ||
		(folder.itemsCount !== 0 && !folder.itemsLoaded && !folder.loadingItems)
	) {
		yield put(loadItems(directoryId, folderUuid, 1, PAGE_SIZE));
	}
}

function* resetSearch(action) {
	const { directoryId } = action;
	const { directoryType } = directoryId;

	const sideState = searchSideState(directoryType);

	const searchQuery = yield select(getSearchQuery, sideState);

	if (searchQuery) {
		yield put(search({ directoryType, stateId: sideState }, searchQuery));
	}
}

function* initFolderReload(action) {
	const { directoryId, items } = action;
	const { directoryType } = directoryId;
	const parents = _groupBy(items, 'parent');

	const parentsIds = Object.keys(parents);

	for (const parentId of parentsIds) {
		const folder = yield select(getFolderState, directoryType, parentId);
		if (folder?.itemsLoaded && folder.hasNextPage) {
			const pagesToReload = Math.ceil(
				parents[parentId].length / folder.pageSize
			);

			if (pagesToReload) {
				yield put(reloadFolder(directoryId, parentId, pagesToReload));
			}
		}
	}
}

function* reloadFolderPages(action) {
	const { directoryId, folderId, pagesToReload } = action;
	const { directoryType } = directoryId;

	const folder = yield select(getFolderState, directoryType, folderId);

	for (
		let page = folder.page - pagesToReload + 1;
		page <= folder.page;
		page++
	) {
		yield put(
			loadItems(
				directoryId,
				folderId === ROOT_ID ? '' : folderId,
				page,
				folder.pageSize
			)
		);
		yield take(
			(act) =>
				(act.type === LOAD_ITEMS_SUCCESS ||
					act.type === LOAD_ITEMS_API_ERROR) &&
				act.directoryId.directoryType === directoryType &&
				isSameFolder(act.folderUuid, folderId) &&
				act.page === page
		);

		const currentState = yield select(getFolderState, directoryType, folderId);
		if (!currentState.hasNextPage) {
			break;
		}
	}
}

function isSameFolder(id1, id2) {
	const id1Normalized = id1 || ROOT_ID;
	const id2Normalized = id2 || ROOT_ID;

	return id1Normalized === id2Normalized;
}

export default directorySagas;
