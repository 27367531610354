import { FETCH, FETCH_FILTERED, FETCH_FILTERED_SUCCESS, FETCH_SUCCESS } from './actions';

function mapTeam(team) {
	return {
		uuid: team.uuid,
		name: team.name,
		avatarUrl: team.picture,
	};
}

const teamsV3 = (
	state = {
		isFetching: false,
		teams: [],
		isStale: true,
		searchValue: '',
		teamsFiltered: [],
		allTeams: [],
	},
	action
) => {
	switch (action.type) {
	case FETCH:
		return {
			...state,
			isFetching: true,
			isStale: false,
		};
	case FETCH_SUCCESS: {
		return {
			...state,
			teams: action.payload.map(mapTeam),
			isFetching: false,
		};
	}
	case FETCH_FILTERED: {
		return {
			...state,
			searchValue: action.searchValue,
		};
	}
	case FETCH_FILTERED_SUCCESS: {
		return {
			...state,
			teamsFiltered: action.payload.map(mapTeam),
			allTeams:
					state.searchValue === ''
						? action.payload.map(mapTeam)
						: state.allTeams,
		};
	}
	default:
		return state;
	}
};

export { teamsV3 };
