import React, { forwardRef, memo, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import useOutsideClick from '../../hooks/useOutsideClick';
import { zIndexAboveAll, zIndexSlideIn } from '../styles/zIndex';
import mergeRefs from '../utils/mergeRefs';

// should match @transitions-duration prop in livestats slide-in.less
const ANIMATION_DURATION_IN_MILLIS = 500;
const SLIDE_IN_WIDTH_IN_PX = 400;
const ENTER_DONE_OPACITY = .5;

const animationTimeout = {
	exit: ANIMATION_DURATION_IN_MILLIS,
};

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	height: 0;
	width: 0;
	z-index: ${zIndexSlideIn};
	background-color: black;
	transition: opacity ${ANIMATION_DURATION_IN_MILLIS}ms;
	opacity: 0;

	&.enter-done, &.exit, &.exit-active {
		height: 100%;
		width: 100%;
	}

	&.enter-done {
		opacity: ${props => props.transparent ? 0 : ENTER_DONE_OPACITY};
	}`;

const Container = styled.div`
	min-height: 100vh;
	background-color: white;
	position: fixed;
	right: -${SLIDE_IN_WIDTH_IN_PX}px;
	z-index: ${zIndexAboveAll};
	transition: right ${ANIMATION_DURATION_IN_MILLIS}ms, box-shadow ${ANIMATION_DURATION_IN_MILLIS}ms;

	@media (max-width: ${SLIDE_IN_WIDTH_IN_PX}px) {
		right: -100vw;
	}

	&.enter-done {
		right: 0;

		-webkit-box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.2);
		-moz-box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.2);
		box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.2);
		@media (max-width: ${SLIDE_IN_WIDTH_IN_PX}px) {
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
		}
	}`;

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;

	width: ${SLIDE_IN_WIDTH_IN_PX}px;
	@media (max-width: ${SLIDE_IN_WIDTH_IN_PX}px) {
		width: 100vw;
	}
`;

function SlideIn({
	open,
	transparentOverlay,
	onEnterDone,
	children,
	onClick,
	onOutsideClick,
	onOverlayClick }, ref) {
	const overlayRef = useRef(null);
	const containerRef = useRef(null);

	useOutsideClick(containerRef, onOutsideClick, open);

	return <>
		<CSSTransition nodeRef={overlayRef} in={open} timeout={animationTimeout} onClick={onOverlayClick} appear>
			<Overlay ref={overlayRef} className="slide-in-overlay" transparent={transparentOverlay} />
		</CSSTransition>
		<CSSTransition nodeRef={containerRef} in={open} timeout={animationTimeout} appear onEntered={onEnterDone}>
			<Container ref={mergeRefs(containerRef, ref)} className="slide-in-container" onClick={onClick}>
				<ContentWrapper className="slide-in">
					{children}
				</ContentWrapper>
			</Container>
		</CSSTransition>

	</>;
}

export default memo(forwardRef(SlideIn));
