import { put, takeEvery } from 'redux-saga/effects';
import { showError } from './actions';

const apiErrorToastsSagas = [
	takeEvery(action => 
		/_API_ERROR$/.test(action.type) 
		&& (action.error || action.message || action.errorMessage) && !action.doNotShowToast, showErrorToast)];

function* showErrorToast(action) {
	yield put(showError(action.error || action.message || action.errorMessage));
}

export default apiErrorToastsSagas;
