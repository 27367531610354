import { useReducer, useMemo } from 'react';

const useReducerWithActionCreators = (reducer, initialArg, init, actions) => {
	const [state, dispatch] = useReducer(reducer, initialArg, init);
  
	const wrappedActions = useMemo(() => Object.keys(actions).reduce(
		(prev, actionKey) => ({
			...prev,
			[actionKey]: (...args) => dispatch(actions[actionKey](...args))
			
		}),
		{}
	),[actions]);
  
	return {
		state,
		actions: wrappedActions
	};
};

export default useReducerWithActionCreators;
