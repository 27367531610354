export const FETCH = 'plecto/dashboards/FETCH';
export const FETCH_SUCCESS = 'plecto/dashboards/FETCH_SUCCESS';
export const FETCH_API_ERROR = 'plecto/dashboards/FETCH_API_ERROR';

export const fetchDashboards = () => ({
	type: FETCH,
});

export const fetchDashboardsSuccess = result => ({
	type: FETCH_SUCCESS,
	result,
});

export const fetchDashboardsError = error => ({
	type: FETCH_API_ERROR,
	error,
});
