import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { 
	clampGranularityToNearestValid,
	getGranularityOptions 
} from './logic/granularityService';

export default function useTimePeriodWithGranularity(timePeriodOptions, granularity, onChange, disallowSinglePeriod) {
	const [periodOptionsState, setPeriodOptions] = useState(timePeriodOptions);
	const [granularityState, setGranularity] = useState(granularity);
	const savedOptions = useRef(timePeriodOptions);

	useEffect(() => {
		setPeriodOptions(timePeriodOptions);
	},[timePeriodOptions]);

	useEffect(() => {
		setGranularity(granularity);
	},[granularity]);

	const onChangePeriod = useCallback((nextOptions) => {
		setPeriodOptions(nextOptions);
		const validGranularity = clampGranularityToNearestValid(granularity, nextOptions, { disallowSinglePeriod });
		setGranularity(validGranularity);
		onChange(nextOptions, validGranularity);
	}, [onChange, granularity, disallowSinglePeriod]);

	const onSavePeriod = useCallback((nextOptions) => {
		savedOptions.current = nextOptions;
		onChangePeriod(nextOptions);
	}, [onChangePeriod]);

	const onCancelPeriod = useCallback(() => {
		onChangePeriod(savedOptions.current);
	}, [onChangePeriod]);

	const onChangeGranularity = useCallback((nextGranularity) => {
		setGranularity(nextGranularity);
		onChange(timePeriodOptions, nextGranularity);
	}, [onChange, timePeriodOptions]);

	const granularityOptions = useMemo(() => 
		getGranularityOptions(periodOptionsState, { disallowSinglePeriod }), 
	[periodOptionsState, disallowSinglePeriod]);

	return {
		granularityState,
		periodOptionsState,
		onChangePeriod,
		onSavePeriod,
		onCancelPeriod,
		onChangeGranularity,
		granularityOptions
	};
}
