import React from 'react';
import styled, { css } from 'styled-components';

import AchievementBadge from '../achievementBadge/AchievementBadge';
import { AchievementBadgeCounter } from './AchievementBadgeCounter';
import { ACHIEVEMENT_BADGE_SIZES as SIZES, ACHIEVEMENT_BADGE_SIZES_PX as SIZES_PX } from '../achievementBadgeSizes';
import PropTypes from 'prop-types';
import { ACHIEVEMENT_BADGE_THEMES as THEMES } from '../achievementBadgeThemes';
import { ACHIEVEMENT_BADGE_SHAPES as SHAPES } from '../achievementBadgeShapes';
import { ACHIEVEMENT_BADGE_COLORS as COLORS } from '../achievementBadgeColors';

const HORIZONTAL_OFFSET = {
	[SIZES.small]: '3',
	[SIZES.medium]: '4',
	[SIZES.large]: '5',
};

const VERTICAL_OFFSET = {
	[SIZES.small]: '2',
	[SIZES.medium]: '3',
	[SIZES.large]: '4',
};

const DROP_SHADOW_DEFAULT = css`filter: drop-shadow(2px 1px 0px rgba(0, 0, 0, 0.2))`;
const DROP_SHADOW_SQUARE = css`filter: drop-shadow(2px -1px 0px rgba(0, 0, 0, 0.2))`;

const StackedContainer = styled.div`
  display: block;
  position: relative;
  width: ${props => SIZES_PX[props.size]};
  height: ${props => SIZES_PX[props.size]};
`;

const StyledAchievementBadge = styled(AchievementBadge)`
  position: absolute;
  left: ${props => props.horizontalOffset}px;
  top: ${props => props.verticalOffset}px;
  ${({ dropShadow }) => dropShadow}
`;

export default function AchievementBadgeStack({ size, theme, achievements, className }) {
	const { iconId, color, shape } = achievements[0];
	const stacked = achievements.length > 1;

	if (!stacked) {
		return <AchievementBadge color={color} shape={shape} iconId={iconId} size={size} theme={theme}
			className={className}/>;
	}

	const horizontalOffset = HORIZONTAL_OFFSET[size];
	const isSquare = shape === SHAPES.square;
	const verticalOffset = isSquare ? VERTICAL_OFFSET[size] : 0;
	const dropShadow = isSquare ? DROP_SHADOW_SQUARE : DROP_SHADOW_DEFAULT;

	return <StackedContainer size={size} className={className}>
		<StyledAchievementBadge color={color} shape={shape} iconId={iconId} size={size} theme={theme}
			horizontalOffset={horizontalOffset}
			verticalOffset={-verticalOffset}
		/>
		<StyledAchievementBadge color={color} shape={shape} iconId={iconId} size={size} theme={theme}
			horizontalOffset={-horizontalOffset}
			verticalOffset={verticalOffset}
			dropShadow={dropShadow}/>
		<AchievementBadgeCounter size={size} color={color} theme={theme} count={achievements.length}/>
	</StackedContainer>;
}

AchievementBadgeStack.propTypes = {
	size: PropTypes.oneOf(Object.values(SIZES)),
	theme: PropTypes.oneOf(Object.values(THEMES)),
	achievements: PropTypes.arrayOf(
		PropTypes.shape({
			iconId: PropTypes.string.isRequired,
			color: PropTypes.oneOf(Object.values(COLORS)),
			shape: PropTypes.oneOf(Object.values(SHAPES)),
		}),
	).isRequired,
	className: PropTypes.string,
};
