import { apiVersionV3 } from '../../fetch/apiService';

const PREFIX = `plecto/${apiVersionV3}/achievementTypes/`;

export const FETCH_ACHIEVEMENT_TYPES = PREFIX + 'FETCH_ACHIEVEMENTS_TYPE';
export const FETCH_ACHIEVEMENT_TYPES_SUCCESS = PREFIX + 'FETCH_ACHIEVEMENT_TYPES_SUCCESS';
export const FETCH_ACHIEVEMENT_TYPES_FAILURE = PREFIX + 'FETCH_ACHIEVEMENT_TYPES_FAILURE';

export const CREATE_ACHIEVEMENT_TYPE = PREFIX + 'CREATE_ACHIEVEMENT_TYPE';
export const CREATE_ACHIEVEMENT_TYPE_SUCCESS = PREFIX + 'CREATE_ACHIEVEMENT_TYPE_SUCCESS';
export const CREATE_ACHIEVEMENT_TYPE_FAILURE = 'PREFIX + CREATE_ACHIEVEMENT_TYPE_FAILURE';

export const UPDATE_ACHIEVEMENT_TYPE = PREFIX + 'UPDATE_ACHIEVEMENT_TYPE';
export const UPDATE_ACHIEVEMENT_TYPE_SUCCESS = PREFIX + 'UPDATE_ACHIEVEMENT_TYPE_SUCCESS';
export const UPDATE_ACHIEVEMENT_TYPE_FAILURE = PREFIX + 'UPDATE_ACHIEVEMENT_TYPE_FAILURE';

export const GIVE_ACHIEVEMENT_TYPE = PREFIX + 'GIVE_ACHIEVEMENT_TYPE';
export const GIVE_ACHIEVEMENT_TYPE_SUCCESS = PREFIX + 'GIVE_ACHIEVEMENT_TYPE_SUCCESS';
export const GIVE_ACHIEVEMENT_TYPE_FAILURE = PREFIX + 'GIVE_ACHIEVEMENT_TYPE_FAILURE';

export const fetchAchievementTypes = () => ({ type: FETCH_ACHIEVEMENT_TYPES });

export const fetchAchievementTypeSuccess = (payload) => ({
	type: FETCH_ACHIEVEMENT_TYPES_SUCCESS,
	payload,
});

export const fetchAchievementTypeFailure = (payload, error, errors) => ({
	type: FETCH_ACHIEVEMENT_TYPES_FAILURE,
	error,
	errors,
});

export const createAchievementType = (achievementType) => ({
	type: CREATE_ACHIEVEMENT_TYPE,
	achievementType,
});

export const createAchievementTypeSuccess = (payload, responsePayload) => ({
	type: CREATE_ACHIEVEMENT_TYPE_SUCCESS,
	editUrl: responsePayload['edit_url'],
	uuid: responsePayload['uuid'],
});

export const createAchievementTypeFailure = (payload, error, errors) => ({
	type: CREATE_ACHIEVEMENT_TYPE_FAILURE,
	error,
	errors,
});

export const updateAchievementType = (achievementTypeId, achievementType) => ({
	type: UPDATE_ACHIEVEMENT_TYPE,
	achievementTypeId,
	achievementType,
});

export const updateAchievementTypeSuccess = () => ({ type: UPDATE_ACHIEVEMENT_TYPE_SUCCESS });

export const updateAchievementTypeFailure = (achievementUuid, payload, error, errors) => ({
	type: UPDATE_ACHIEVEMENT_TYPE_FAILURE,
	error,
	errors,
});

export const giveAchievementType = (achievementTypeId, payload) => ({
	type: GIVE_ACHIEVEMENT_TYPE,
	achievementTypeId,
	payload,
});

export const giveAchievementTypeSuccess = (achievementTypeId, requestPayload, responsePayload) => ({
	type: GIVE_ACHIEVEMENT_TYPE_SUCCESS,
	payload: responsePayload,
});

export const giveAchievementTypeFailure = (achievementUuid, payload, error, errors) => ({
	type: GIVE_ACHIEVEMENT_TYPE_FAILURE,
	error,
	errors,
});
