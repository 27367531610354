import { FETCH_WRITTEN_UPDATE_SCHEDULE, FETCH_WRITTEN_UPDATE_SCHEDULE_SUCCESS } from './actions';

const writtenUpdateSchedules = (state = { isFetching: false, writtenUpdateSchedule: { questions: [] }}, action) => {
	switch (action.type) {
	case FETCH_WRITTEN_UPDATE_SCHEDULE:
		return {
			...state,
			isFetching: true,
		};
	case FETCH_WRITTEN_UPDATE_SCHEDULE_SUCCESS: {
		return {
			...state,
			writtenUpdateSchedule: action.payload,
			isFetching: false,
		};
	}
	default:
		return state;
	}
};

export { writtenUpdateSchedules };
