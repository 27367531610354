import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { PopoverTabButtonToggle } from './PopoverTabButtonToggle';
import { COLORS, COLORS_GRAY } from '../../styles/colors';
import { SPACING } from '../../styles/spacing';
import { BORDER_RADIUS } from '../../styles/shape';

const minDropdownWidth = 270;

const S = {};
S.ButtonToggleContainer = styled.div.attrs(props => ({
	...(props.popupDisabled && {
		padding: `${SPACING.smaller} ${SPACING.big}`,
		border: `1px solid ${COLORS_GRAY['300']}`,
		borderRadius: `${BORDER_RADIUS.xs} ${BORDER_RADIUS.xs} 0 0`,
	}),
	height: props.size === 'sm' && '30px' || '38px',
}))`
	.multi-level-dropdown-toggle-container {
		background-color: ${COLORS.light};
		border-radius: ${props => props.borderRadius};
		padding: ${props => props.padding};
		border-bottom: ${props => props.border};
		font-size: 12px;
	}
	.popover-tab-button {
		min-height: ${props => props.height};
	}
`;

export default function MultiLevelDropdownWrapper({
	children,
	toggle,
	onOpen,
	className,
	label,
	open,
	placement,
	popupDisabled,
	size,
	minWidth = minDropdownWidth,
	padding
}) {
	return <S.ButtonToggleContainer
		size={size}
		popupDisabled={popupDisabled}
		className={'multi-level-dropdown-container'}
	>
		{popupDisabled ?
			<>
				{toggle}
				{children}
			</> : <PopoverTabButtonToggle
				className={classNames(className, 'multi-level-dropdown-toggle')}
				popupDisabled={popupDisabled}
				name={label}
				open={open}
				padding={padding}
				placement={placement}
				onOpen={onOpen}
				sameWidth
				minWidth={minWidth}
				buttonControl={toggle}
			>
				{children}
			</PopoverTabButtonToggle>}
	</S.ButtonToggleContainer>;
}
