import {
	ADD,
	ADD_SUCCESS,
	DELETE,
	DELETE_SUCCESS,
	FETCH,
	FETCH_SUCCESS,
	UPDATE,
	UPDATE_SUCCESS,
	UPDATE_API_ERROR,
	ADD_API_ERROR
} from './actions';

export const schedules = (state = { }, action) => {
	switch (action.type) {
	case FETCH:
	case UPDATE:
	case ADD:
	case DELETE:
		return {
			...state,
			[action.reportId]: {
				...state[action.reportId],
				isFetching: true
			}
		};
	case FETCH_SUCCESS: {
		return {
			...state,
			[action.reportId]: {
				...state[action.reportId],
				isFetching: false,
				schedule: mapSchedule(action.payload[0] || {}),
				isLive: !!action.payload.length
			}
		};
	}
	case UPDATE_API_ERROR: 
	case ADD_API_ERROR: 
		return {
			...state,
			[action.reportId]: {
				...state[action.reportId],
				isFetching: false,
				schedule: {
					...state[action.reportId].schedule, 
					errors: action.errors
				}
			}
		};
	case ADD_SUCCESS:
	case UPDATE_SUCCESS:
		return {
			...state,
			[action.reportId]: {
				...state[action.reportId],
				isFetching: false,
				schedule: mapSchedule(action.payload),
				isLive: true,
				errors: null
			}
		};
	case DELETE_SUCCESS: {
		return {
			...state,
			[action.reportId]: {
				...state[action.reportId],
				isFetching: false,
				schedule: null,
				isLive: false
			}
		};
	}
	default:
		return state;
	}
};

const mapSchedule = (schedule) => {
	return {
		uuid: schedule.uuid,
		recipients: schedule.recipients,
		interval: schedule.interval,
		amount: schedule.amount,
		repeatDays: schedule.repeat_days,
		time: schedule.time,
		endTime: schedule.end_time,
		startDate: schedule.start_date,
		endDate: schedule.end_date,
		lastRunDate: schedule.last_run_date,
		nextRunDate: schedule.next_run_date,
		pptxExportType: schedule.presentation_export_type
	};
};
