export const CREATE_WRITTEN_UPDATE_SCHEDULE =
	'plecto/v3/writtenUpdateSchedules/CREATE_WRITTEN_UPDATE_SCHEDULE';
export const CREATE_WRITTEN_UPDATE_SCHEDULE_SUCCESS =
	'plecto/v3/writtenUpdateSchedules/CREATE_WRITTEN_UPDATE_SCHEDULE_SUCCESS';
export const CREATE_WRITTEN_UPDATE_SCHEDULE_FAILURE =
	'plecto/v3/writtenUpdateSchedules/CREATE_WRITTEN_UPDATE_SCHEDULE_FAILURE';
export const FETCH_WRITTEN_UPDATE_SCHEDULE =
	'plecto/v3/writtenUpdateSchedules/FETCH_WRITTEN_UPDATE_SCHEDULE';
export const FETCH_WRITTEN_UPDATE_SCHEDULE_SUCCESS =
	'plecto/v3/writtenUpdateSchedules/FETCH_WRITTEN_UPDATE_SCHEDULE_SUCCESS';
export const FETCH_WRITTEN_UPDATE_SCHEDULE_FAILURE =
	'plecto/v3/writtenUpdateSchedules/FETCH_WRITTEN_UPDATE_SCHEDULE_FAILURE';
export const MODIFY_WRITTEN_UPDATE_SCHEDULE =
	'plecto/v3/writtenUpdateSchedules/MODIFY_WRITTEN_UPDATE_SCHEDULE';
export const MODIFY_WRITTEN_UPDATE_SCHEDULE_SUCCESS =
	'plecto/v3/writtenUpdateSchedules/MODIFY_WRITTEN_UPDATE_SCHEDULE_SUCCESS';
export const MODIFY_WRITTEN_UPDATE_SCHEDULE_FAILURE =
	'plecto/v3/writtenUpdateSchedules/MODIFY_WRITTEN_UPDATE_SCHEDULE_FAILURE';
export const DELETE_WRITTEN_UPDATE_SCHEDULE =
	'plecto/v3/writtenUpdateSchedules/DELETE_WRITTEN_UPDATE_SCHEDULE';
export const DELETE_WRITTEN_UPDATE_SCHEDULE_SUCCESS =
	'plecto/v3/writtenUpdateSchedules/DELETE_WRITTEN_UPDATE_SCHEDULE_SUCCESS';
export const DELETE_WRITTEN_UPDATE_SCHEDULE_FAILURE =
	'plecto/v3/writtenUpdateSchedules/DELETE_WRITTEN_UPDATE_SCHEDULE_FAILURE';

export const createWrittenUpdateSchedule = (writtenUpdateSchedule) => ({
	type: CREATE_WRITTEN_UPDATE_SCHEDULE,
	writtenUpdateSchedule,
});

export const createWrittenUpdateScheduleSuccess = () => ({
	type: CREATE_WRITTEN_UPDATE_SCHEDULE_SUCCESS,
});

export const createWrittenUpdateScheduleFailure = () => ({
	type: CREATE_WRITTEN_UPDATE_SCHEDULE_FAILURE,
});

export const fetchWrittenUpdateSchedule = (writtenUpdateScheduleId) => ({
	type: FETCH_WRITTEN_UPDATE_SCHEDULE,
	writtenUpdateScheduleId,
});

export const fetchWrittenUpdateScheduleSuccess = (id, payload) => ({
	type: FETCH_WRITTEN_UPDATE_SCHEDULE_SUCCESS,
	payload,
});

export const fetchWrittenUpdateScheduleFailure = () => ({
	type: FETCH_WRITTEN_UPDATE_SCHEDULE_FAILURE,
});

export const modifyWrittenUpdateSchedule = (
	writtenUpdateScheduleUuid,
	writtenUpdateSchedule
) => ({
	type: MODIFY_WRITTEN_UPDATE_SCHEDULE,
	writtenUpdateScheduleUuid,
	writtenUpdateSchedule,
});

export const modifyWrittenUpdateScheduleSuccess = () => ({
	type: MODIFY_WRITTEN_UPDATE_SCHEDULE_SUCCESS,
});

export const modifyWrittenUpdateScheduleFailure = () => ({
	type: MODIFY_WRITTEN_UPDATE_SCHEDULE_FAILURE,
});

export const deleteWrittenUpdateSchedule = (writtenUpdateScheduleId) => ({
	type: DELETE_WRITTEN_UPDATE_SCHEDULE,
	writtenUpdateScheduleId,
});

export const deleteWrittenUpdateScheduleSuccess = () => ({
	type: DELETE_WRITTEN_UPDATE_SCHEDULE_SUCCESS,
});

export const deleteWrittenUpdateScheduleFailure = () => ({
	type: DELETE_WRITTEN_UPDATE_SCHEDULE_FAILURE,
});
