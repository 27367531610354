import React from 'react';
import { Loader } from './Loader';
import styled from 'styled-components';
import { LoaderSizes, loaderSizes } from './loaderSizes';

const StyledLoaderOverlay = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(255,255,255,.5);
	left: 0;
	top: 0;

	.loader {
		position: absolute;
		top: calc(50% - 12px);
		right: calc(50% - 12px);
	}
`;

type Props = {
	size?: LoaderSizes
}

export function LoaderOverlay({ size = loaderSizes.default }: Props) {
	return <StyledLoaderOverlay>
		<Loader size={size} />
	</StyledLoaderOverlay>;
}
