import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _take from 'lodash/take';
import { parseMultiLevelDropdownValue, filterOptions } from './optionsService';

export function createInitialState({ value, options, componentValue }) {
	const valueParts = parseMultiLevelDropdownValue(value);

	if (valueParts.length <= 1) {
		const selectedOption = _find(options, { value: value });
		return {
			level: selectedOption?.component ? selectedOption : null,
			path: selectedOption?.component ? [{
				level: null,
				options: options
			}] : [],
			currentOptions: options,
			label: selectedOption?.component ? componentValue : selectedOption?.label || '',
		};
	}

	let previousOption = null;
	const path = [];
	let currentOptions = options;
	// last part of value is field
	for (let i = 0; i < valueParts.length - 1; ++i) {
		const part = valueParts[i];
		const option = _find(currentOptions, { value: part });
		if (option) {
			path.push({
				level: previousOption,
				options: currentOptions,
			});
			currentOptions = option.options;
			previousOption = option;
			if (!currentOptions || currentOptions.length === 0)
			{
				break;
			}
		}
	}
	const currentValue = _find(currentOptions, { value: valueParts[valueParts.length - 1] });

	return {
		level: previousOption,
		path,
		currentOptions,
		label: currentValue?.label,
	};

}

export default function multiDropdownReducer(state, action) {
	const nextState = actualReducer(state, action);

	if (state !== nextState) {
		return {
			...nextState,
			filteredOptions: filterOptions(nextState.currentOptions, nextState.searchTerm, nextState.level)
		};
	}

	return nextState;
}

function actualReducer(state, action) {
	switch (action.type) {
	case NEXT_LEVEL: {
		const option = _find(state.currentOptions, { value: action.option });
		if (option && option.options) {
			const nextPathItem = {
				level: state.level,
				options: state.currentOptions,
			};
			return {
				...state,
				path: [...state.path, nextPathItem],
				level: option,
				currentOptions: option.options,
			};
		} else if (option && option.component) {
			const nextPathItem = {
				level: state.level,
				options: state.currentOptions,
			};
			return {
				...state,
				path: [...state.path, nextPathItem],
				level: option,
				currentOptions: [],
			};
		}
		return state;
	}
	case BACK: {
		if (state.path.length > 0) {
			const previousLevel = state.path[state.path.length - 1];
			return {
				...state,
				level: previousLevel.level,
				currentOptions: previousLevel.options,
				path: _take(state.path, state.path.length - 1),
			};
		}
		return state;
	}
	case BACK_TO: {
		if (state.path.length > 0) {
			const indexOfLevel = action.level === baseLevel ?
				0 :
				_findIndex(state.path, { level: { value: action.level }});
			if (indexOfLevel >= 0) {
				const previousLevel = state.path[indexOfLevel];
				return {
					...state,
					level: previousLevel.level,
					currentOptions: previousLevel.options,
					path: _take(state.path, indexOfLevel),
				};
			}

		}
		return state;
	}
	case SEARCH: {
		return {
			...state,
			searchTerm: action.searchTerm,
		};
	}
	case RESET: {
		return createInitialState(action);
	}
	}

	return state;
}

const NEXT_LEVEL = 'NEXT_LEVEL';
const BACK = 'BACK';
const BACK_TO = 'BACK_TO';
const RESET = 'RESET';
const SEARCH = 'SEARCH';

export const goNextLevel = (option) => ({
	type: NEXT_LEVEL,
	option
});

export const goBack = () => ({
	type: BACK,
});

export const goBackTo = (level) => ({
	type: BACK_TO,
	level
});

export const reset = (value, options, componentValue) => ({
	type: RESET,
	value,
	options,
	componentValue
});

export const searchBy = (searchTerm) => ({
	type: SEARCH,
	searchTerm
});

export const baseLevel = Symbol('baseLevel');
