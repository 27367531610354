import { useMemo } from 'react';
import screenSizes from '../../utils/size/screenSizes';
import { useWindowSizeBelowBreakpoint } from '../../utils/size/sizeHooks';
import useDynamicCssRuleset from '../../utils/useDynamicCssRuleset';
import { DAYS_IN_A_WEEK } from '../constants';

// as ugly as it is, this is necessary if we want calendar cells to be circles or squares filling whole window
const CALENDAR_MARGIN = 32;
const CELL_HORIZONTAL_MARGINS = 8;

const dynamicStyleSheet = (windowSize) => {
	let size = ((windowSize - CALENDAR_MARGIN) / DAYS_IN_A_WEEK) - CELL_HORIZONTAL_MARGINS;
	size = size.toFixed(2);

	const dimensionsRule = `
		width: ${size}px;
		height: ${size}px;
	`;

	return [
		{
			selector: '.rdrDay',
			rule: dimensionsRule
		},
		{
			selector: '.rdrStartEdge:before,.rdrEndEdge:before, .rdrDayEndPreview:before,.rdrDayStartPreview',
			rule: dimensionsRule
		},
		{
			selector: '.rdrWeekDay',
			rule: dimensionsRule
		},
	];
};

export default function useDynamicCalendarSizing() {
	const windowSize = useWindowSizeBelowBreakpoint(screenSizes.vs);
	const ruleset = useMemo(() => windowSize ? dynamicStyleSheet(windowSize) : '', [windowSize]);

	useDynamicCssRuleset(ruleset);
}

