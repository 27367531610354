import generateUuid from '../../utils/generateUuid';

export function mapInitialPagination(paginationData) {
	return  {
		// this is the trigger for initial load, it will change every time we update report group
		paginationId: generateUuid(),
		count: paginationData.items_count,
		pageIndices: calculatePageNumbers(paginationData.index, paginationData.items_count, paginationData.page_size),
		initialPage: paginationData.current_page,
		renderedPages: [paginationData.current_page],
		pageSize: paginationData.page_size,
	};
}

export function addRenderedPage(currentPagination, page) {
	return {
		...currentPagination,
		renderedPages: [...currentPagination.renderedPages, page]
	};
}

export function calculatePageNumbers(index, itemsCount, pageSize) {
	const result = new Array(itemsCount);
	const firstPageSize =  index % pageSize;
	for (let i = 0; i < itemsCount; ++i) {
		result[i] =  Math.floor((i - firstPageSize) / pageSize) + (firstPageSize > 0 ? 1 : 0);
	}

	return result;
}
