import { apiDelete, apiGet, apiPost, apiPut, apiVersionV3 } from '../../fetch/apiService';
import { getFoldersEndpointByType, getItemsEndpointByType, getSearchEndpointByType } from './directoryEndpoint';
import getEndpointParameters from './endpointParameters';
import { ROOT_ID } from './constants';

export function getFolders(directoryId, folderUuid = '') {
	return apiGet(
		`folders/${getFoldersEndpointByType(directoryId)}/${folderUuid}`,
		{},
		{},
		apiVersionV3);
}

export function getItems(directoryId, folderUuid = '', pageNumber, pageSize) {
	return apiGet(
		`${getItemsEndpointByType(directoryId)}`,
		{
			...getEndpointParameters(directoryId),
			folder: folderUuid || 'null',
			page_size: pageSize,
			page: pageNumber
		},
		{},
		apiVersionV3);
}

export function getItemsInFolders(directoryId, folderIds = '') {
	return apiGet(`${getItemsEndpointByType(directoryId)}`,
		{
			...getEndpointParameters(directoryId),
			folder_children_in: folderIds.join(','),
		},
		{},
		apiVersionV3);
}

export function createFolder(directoryId, folder) {
	return apiPost(`folders/${getFoldersEndpointByType(directoryId)}`, {
		...folder,
		items: folder.items.map(i => i.uuid),
		subfolders: folder.subfolders.map(f => f.uuid),
	}, {}, {}, apiVersionV3);
}

export function updateFolder(directoryId, folderUuid, name) {
	return apiPut(`folders/${getFoldersEndpointByType(directoryId)}/${folderUuid}`, {
		uuid: folderUuid,
		name
	}, {}, {}, apiVersionV3);
}

export function removeFolder(directoryId, folderUuid) {
	return apiDelete(`folders/${getFoldersEndpointByType(directoryId)}/${folderUuid}`, {}, {}, {}, apiVersionV3);
}

export function moveItemsToTarget(directoryId, target, items = [], folders = []) {
	return apiPost(`folders/${getFoldersEndpointByType(directoryId)}/move`, {
		target: !target || !target.uuid || target.uuid === ROOT_ID ? null : target.uuid,
		items: items.map(i => i.uuid),
		folders: folders.map(f => f.uuid),
	}, {}, {}, apiVersionV3);
}

export function bulkDeleteItems(directoryId, items = [], folders = [], itemReplacements = {}) {
	return apiDelete(`folders/${getFoldersEndpointByType(directoryId)}/bulk_delete`, {
		items: items.map(i => i.uuid),
		folders: folders.map(f => f.uuid),
		item_replacements: Object.entries(itemReplacements).map(([key, value]) => ({ source: key, target: value }))
	}, {}, {}, apiVersionV3);
}

export function getSearch(directoryId, query) {
	return apiGet(
		getSearchEndpointByType(directoryId),
		{ q: query },
		{},
		apiVersionV3);
}
