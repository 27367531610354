export const buttonSizes = [
	'default',
	'small',
	'extra-small'
] as const;

export type ButtonSize = typeof buttonSizes[number];

export type ButtonSizeDefinition = {
	lineHeight: number;
	paddingVertical: number,
	paddingHorizontal: number,
	paddingNoLabel: number,
	fontSize: number,
	gap: number,
	uppercase: boolean,
};

export const ButtonSizes: {[key in ButtonSize]: ButtonSizeDefinition} = {
	default: {
		lineHeight: 20,
		paddingVertical: 8,
		paddingHorizontal: 18,
		paddingNoLabel: 8,
		fontSize: 14,
		gap: 7,
		uppercase: true,
	},
	small: {
		lineHeight: 18,
		paddingVertical: 5,
		paddingHorizontal: 12,
		paddingNoLabel: 6,
		fontSize: 12,
		gap: 4,
		uppercase: false
	},
	'extra-small': {
		lineHeight: 18,
		paddingVertical: 2,
		paddingHorizontal: 10,
		paddingNoLabel: 3,
		fontSize: 12,
		gap: 5,
		uppercase: false
	}
};
