import {
	CANCEL,
	cancelAnnouncementFailure,
	cancelAnnouncementSuccess,
	END,
	endAnnouncementFailure,
	endAnnouncementSuccess,
	GET,
	loadAnnouncementFailure,
	loadAnnouncementSuccess,
	SAVE,
	saveAnnouncementFailure,
	saveAnnouncementSuccess,
	SCHEDULE,
	scheduleAnnouncementFailure,
	scheduleAnnouncementSuccess,
	sendAnnouncementNowFailure,
	sendAnnouncementNowSuccess,
	SEND_NOW,
} from './actions';
import apiSagaGenerator from '../../saga/apiSagaGenerator';
import takeLatestBy from 'redux-saga-take-latest-by';
import {
	cancelAnnouncement,
	endAnnouncement,
	getAnnouncement,
	saveAnnouncement,
	scheduleAnnouncement,
	sendAnnouncementImmediately,
} from './api';
import { takeLatest } from '@redux-saga/core/effects';
import _isArray from 'lodash/isArray';

const announcementSagas = [
	takeLatestBy(
		GET,
		apiSagaGenerator()
			.forApi(getAnnouncement)
			.withArguments((action) => [action.id])
			.forSuccess(loadAnnouncementSuccess)
			.forError(loadAnnouncementFailure)
			.withMessage('Error loading announcement')
			.generate(),
		(a) => a.id
	),
	takeLatest(
		SAVE,
		apiSagaGenerator()
			.forApi(saveAnnouncement)
			.withArguments((action) => [action.model, action.saveParameters])
			.forSuccess(saveAnnouncementSuccess)
			.forError(saveAnnouncementFailure)
			.includeErrorResult((res) => {
				if (_isArray(res)) {
					return res.join(', ');
				}
				return undefined;
			})
			.withMessage('Error saving announcement')
			.generate()
	),
	takeLatest(
		SCHEDULE,
		apiSagaGenerator()
			.forApi(scheduleAnnouncement)
			.withArguments((action) => [action.uuid])
			.forSuccess(scheduleAnnouncementSuccess)
			.forError(scheduleAnnouncementFailure)
			.withMessage('Error sending announcement')
			.generate()
	),
	takeLatest(
		CANCEL,
		apiSagaGenerator()
			.forApi(cancelAnnouncement)
			.withArguments((action) => [action.uuid])
			.forSuccess(cancelAnnouncementSuccess)
			.forError(cancelAnnouncementFailure)
			.withMessage('Error canceling announcement')
			.generate()
	),
	takeLatest(
		END,
		apiSagaGenerator()
			.forApi(endAnnouncement)
			.withArguments((action) => [action.uuid])
			.forSuccess(endAnnouncementSuccess)
			.forError(endAnnouncementFailure)
			.withMessage('Error ending announcement')
			.generate()
	),
	takeLatest(
		SEND_NOW,
		apiSagaGenerator()
			.forApi(sendAnnouncementImmediately)
			.withArguments((action) => [action.uuid])
			.forSuccess(sendAnnouncementNowSuccess)
			.forError(sendAnnouncementNowFailure)
			.withMessage('Error sending announcement')
			.generate()
	),
];

export default announcementSagas;
