import { Icon } from '../../icon/Icon';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: table;
  width: 100%;
  padding: 3px 6px;
  background: #F6F6F7;
  border-radius: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #6B7280;
`;

const Message = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const IconWrapper = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding-right: 4px;
`;

export function PrivacyMessage({ className }) {
	const { t } = useTranslation();
	return <Container className={className}>
		<IconWrapper><Icon name="visible"/></IconWrapper>
		<Message>{t('Only you and the author will see this personal message')}</Message>
	</Container>;
}

PrivacyMessage.propTypes = {
	className: PropTypes.string,
};
