import _trim from 'lodash/trim';
import _compact from 'lodash/compact';

const maxDepth = 5;

export function parseMultiLevelDropdownValue(value) {
	return value ? value.split('"."').map(p => _trim(p, '"')) : [];
}

export function filterOptions(options, term, currentLevel) {
	return term && options ?
		_compact(options.map(o => optionMatch(o, term.toLocaleLowerCase(), 1, currentLevel))) :
		null;
}

export function stringifyPath(path) {
	return path.map(v => `"${v.value}"`).join('.');
}

export function getOptionPathValue(path, value) {
	return path ? `${path}."${value}"` : value;
}

function optionMatch(option, term, depth, level) {
	const label = option.label.toLocaleLowerCase();
	const optionMatches = label.indexOf(term) >= 0 || level?.label.toLocaleLowerCase().indexOf(term) >= 0;
	if (optionMatches) {
		return option;
	} else if (option.options) {
		if (depth > maxDepth) {
			return null;
		}
		const filteredOptions = _compact(option?.options?.map(o => optionMatch(o, term, depth + 1)));
		if (filteredOptions.length > 0) {
			return {
				...option,
				options: filteredOptions
			};
		}
	}
	return null;
}
