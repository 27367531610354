import PropTypes from 'prop-types';
import React, { memo, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { endOfDay, addDays } from 'date-fns';
import { Icon } from '../icon/Icon';
import RangeInput from './RangeInput';

function RangeInputs({ startDate, endDate, startChanged, endChanged, onChange }) {
	const [startError, setStartError] = useState(null);
	const [endError, setEndError] = useState(null);
	const [startDateLocal, setStartDateLocal] = useState(startDate);
	const [endDateLocal, setEndDateLocal] = useState(endDate);

	useEffect(() => {
		setStartDateLocal(startDate);
	}, [startDate]);

	useEffect(() => {
		setEndDateLocal(endDate);
	}, [endDate]);

	const onChangeStart = useCallback((start) => {
		setStartDateLocal(start);
		setEndError(null);
		if (start > endOfDay(endDateLocal)) {
			setStartError('Start date cannot be later than end date');
		} else {
			setStartError(null);
			onChange(start, endDateLocal);
		}
	},[endDateLocal, onChange]);

	const onChangeEnd = useCallback((end) => {
		setEndDateLocal(end);
		setStartError(null);
		if (end < endOfDay(addDays(startDateLocal, -1))) {
			setEndError('End date cannot be earlier than start date');
		} else {
			setEndError(null);
			onChange(startDateLocal, end);
		}
	},[startDateLocal, onChange]);

	return 	<div className='rdrRangeInputs form-group-sm '>
		<RangeInput
			date={startDate}
			onChange={onChangeStart}
			focused={startChanged}
			error={startError}
		/>
		<div className={classNames('rdrRangeInput-separator')}>
			<Icon name="arrow-right" />
		</div>
		<RangeInput
			date={endDate}
			onChange={onChangeEnd}
			focused={endChanged}
			error={endError}
		/>
	</div>;
}

RangeInputs.propTypes = {
	endChanged: PropTypes.bool,
	endDate: PropTypes.any,
	startChanged: PropTypes.bool,
	startDate: PropTypes.any
};

export default memo(RangeInputs);
