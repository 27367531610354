import React from 'react';
import styled from 'styled-components';
import { ACHIEVEMENT_BADGE_THEMES as THEMES } from '../achievementBadgeThemes';
import { ACHIEVEMENT_BADGE_SIZES as SIZES } from '../achievementBadgeSizes';
import { FONT_FAMILY } from '../../../styles/typography';
import { ACHIEVEMENT_BADGE_COLORS as COLORS } from '../achievementBadgeColors';
import PropTypes from 'prop-types';
import { getAchievementColorTheme } from '../achievementColorThemes';

const SIZE = {
	[SIZES.small]: '15px',
	[SIZES.medium]: '20px',
	[SIZES.large]: '26px',
};

const FONT_SIZE = {
	[SIZES.small]: '8px',
	[SIZES.medium]: '9px',
	[SIZES.large]: '11px',
};

const VERTICAL_OFFSET = {
	[SIZES.small]: '-2px',
	[SIZES.medium]: '-3px',
	[SIZES.large]: '-4px',
};

const HORIZONTAL_OFFSET = {
	[SIZES.small]: '-5px',
	[SIZES.medium]: '-3px',
	[SIZES.large]: '1px',
};

const Container = styled.div`
  font-family: ${FONT_FAMILY};
  position: absolute;
  top: ${props => VERTICAL_OFFSET[props.size]};
  right: ${props => HORIZONTAL_OFFSET[props.size]};
  background-color: ${props => props.background};
  color: ${props => props.color};
  border-radius: 50%;
  width: ${props => SIZE[props.size]};
  height: ${props => SIZE[props.size]};
  text-align: center;
  font-size: ${props => FONT_SIZE[props.size]};
  font-weight: 600;
  line-height: ${props => SIZE[props.size]};
`;

const Count = styled.span`
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
`;

const MAX_COUNTER_VALUE = 99;

export function AchievementBadgeCounter({ size, color, theme, count }) {
	const counterValue = count > MAX_COUNTER_VALUE ? '99+' : count;
	const { counter: { color: counterColor, background: counterBackground }}
		= getAchievementColorTheme({ color, theme });
	return <Container size={size} color={counterColor} background={counterBackground}>
		<Count>{counterValue}</Count>
	</Container>;
}

AchievementBadgeCounter.propTypes = {
	size: PropTypes.oneOf(Object.values(SIZES)),
	color: PropTypes.oneOf(Object.values(COLORS)),
	theme: PropTypes.oneOf(Object.values(THEMES)),
	count: PropTypes.number.isRequired,
};
