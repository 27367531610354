import { directoryTypes } from './directoryTypes';

export default function getEndpointParameters({ directoryType }) {
	switch (directoryType) {
	case directoryTypes.formula: {
		return {
			only_formulas: 'true',
			extended: 'true'
		};
	}
	case directoryTypes.employee:
	case directoryTypes.dataSource: {
		return {
			extended: 'true'
		};
	}

	default: 
		return {};
	}
}
