export const FETCH = 'plecto/dataSourceVariables/FETCH';
export const FETCH_IF_NEEDED = 'plecto/dataSourceVariables/FETCH_IF_NEEDED';
export const FETCH_SUCCESS = 'plecto/dataSourceVariables/FETCH_SUCCESS';
export const FETCH_API_ERROR = 'plecto/dataSourceVariables/FETCH_API_ERROR';

export const fetchDataSourceVariables = (dataSourceId) => ({
	type: FETCH,
	dataSourceId
});

export const fetchDataSourceVariablesIfNeeded = (dataSourceId) => ({
	type: FETCH_IF_NEEDED,
	dataSourceId
});

export const fetchDataSourceVariablesSuccess = (dataSourceId, result) => ({
	type: FETCH_SUCCESS,
	result,
	dataSourceId
});

export const fetchDataSourceVariablesError = (dataSourceId, error) => ({
	type: FETCH_API_ERROR,
	error,
	dataSourceId
});
