import { useRef } from 'react';
import useDidUpdateEffect from './useDidUpdateEffect';

export default function usePreviousValue(value) {
	const previousValue = useRef(value);
	const currentValue = useRef(value);

	useDidUpdateEffect(() => {
		previousValue.current = currentValue.current;
		currentValue.current = value;
	},[value]);

	return previousValue.current;
}

export function usePreviousImmediateValue(value) {
	const previousValue = useRef(value);
	const currentValue = useRef(value);

	previousValue.current = currentValue.current;
	currentValue.current = value;

	return previousValue.current;
}
