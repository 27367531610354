import { granularity, modes, periods, relativeModes } from '../timePeriod/logic/constants';

export default function calculateTimePeriods(timePeriodOptions, grouping) {
	switch (timePeriodOptions.mode) {
	case modes.relative:
		return calculateRelativePeriods(timePeriodOptions, grouping);
	}
}

const coefficients = {
	[periods.year]: {
		[granularity.annually]: 1,
		[granularity.quarterly]: 4,
		[granularity.monthly]: 12,
		[granularity.weekly]: 53,
		[granularity.daily]: 366,
		[granularity.hourly]: 8784,
	},
	[periods.quarter]: {
		[granularity.quarterly]: 1,
		[granularity.monthly]: 3,
		[granularity.daily]: 93,
		[granularity.weekly]: 15,
		[granularity.hourly]: 2232,
	},
	[periods.month]: {
		[granularity.monthly]: 1,
		[granularity.weekly]: 5,
		[granularity.daily]: 31,
		[granularity.hourly]: 744,
	},
	[periods.week]: {
		[granularity.weekly]: 1,
		[granularity.daily]: 7,
		[granularity.hourly]: 168,
	},
	[periods.day]: {
		[granularity.daily]: 1,
		[granularity.hourly]: 24,
	},
};

function calculateRelativePeriods({ 
	relativeStartDate: {
		mode: startMode, 
		period: startPeriod, 
		amount: startAmount, 
	},
	relativeEndDate: {
		enabled: endModeEnabled,
		mode: endMode,
		amount: endAmount,
		period: endPeriod,
	}
}, grouping) {
	if (endModeEnabled) {
		const startAmountFixed = startMode === relativeModes.current ? 1 : startAmount;
		const endAmountFixed = endMode === relativeModes.current ? 1 : endAmount;
		const startPeriods = getPeriodsNumber(startPeriod, grouping) * startAmountFixed;
		const endPeriods = getPeriodsNumber(endPeriod, grouping) * endAmountFixed;
		const daysToBeAdded = 6;

		if (startMode === relativeModes.previous) {
			if (endMode === relativeModes.previous) {
				return 2 * startPeriods - endPeriods;
			} else if (endMode === relativeModes.current) {
				let amount = startPeriods + getPeriodsNumber(startPeriod, grouping);
				if (endPeriod === periods.week && (grouping === granularity.daily || grouping === granularity.hourly)) {
					// if monday is the end of start period (quarter, month, year), we have to add six more days
					amount += getPeriodsNumber(periods.day, grouping) * daysToBeAdded;
				}
				return amount;
			}
		} else if (startMode === relativeModes.current) {
			if (endMode === relativeModes.current) {
				let amount = startPeriods;
				if (endPeriod === periods.week && (grouping === granularity.daily || grouping === granularity.hourly)) {
					// if monday is the end of start period (quarter, month, year), we have to add six more days
					amount += getPeriodsNumber(periods.day, grouping) * daysToBeAdded;
				}
				return amount;
			} else if (endMode === relativeModes.next) {
				let amount = startPeriods + endPeriods;
				if (endPeriod === periods.week && (grouping === granularity.daily || grouping === granularity.hourly)) {
					// if monday is the end of start period (quarter, month, year), we have to add six more days
					amount += getPeriodsNumber(periods.day, grouping) * daysToBeAdded;
				}
				return amount;
			}
		} 
	} else {
		return getPeriodsNumber(startPeriod, grouping) * startAmount;
	}

	return 0;
}

function getPeriodsNumber(period, grouping) {
	return coefficients[period]?.[grouping] || 1;
}
