import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export default function CancelButton({ onClick, className, extraClassName, text }) {
	const buttonClass = classNames(className || 'btn btn-default', extraClassName);
	const { t } = useTranslation();
	return <button type="button" className={buttonClass} onClick={onClick}>{text || t('Cancel')}</button>;
}

CancelButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	className: PropTypes.string,
	extraClassName: PropTypes.string,
	text: PropTypes.string,
};
