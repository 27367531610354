import { apiPost } from '../../fetch/apiService';

export function fetchPreview(parameters) {
	return apiPost('time-period', 
		parameters,
		null, 
		{ internalApi: true }
	);
}

