import { actionPrefix } from '../constants';

export const CREATE_COMMENT = `${actionPrefix}CREATE_COMMENT`;
export const CREATE_COMMENT_SUCCESS = `${actionPrefix}CREATE_COMMENT_SUCCESS`;
export const CREATE_COMMENT_FAILURE = `${actionPrefix}CREATE_COMMENT_FAILURE`;

export const GET_COMMENTS = `${actionPrefix}GET_COMMENTS`;
export const GET_COMMENTS_SUCCESS = `${actionPrefix}GET_COMMENTS_SUCCESS`;
export const GET_COMMENTS_FAILURE = `${actionPrefix}GET_COMMENTS_FAILURE`;

export const DELETE_COMMENT = `${actionPrefix}DELETE_COMMENT`;
export const DELETE_COMMENT_SUCCESS = `${actionPrefix}DELETE_COMMENT_SUCCESS`;
export const DELETE_COMMENT_FAILURE = `${actionPrefix}DELETE_COMMENT_FAILURE`;

export const getComments = (oneOnOneId) => ({
	type: GET_COMMENTS,
	oneOnOneId
});

export const getCommentsSuccess = (oneOnOneId, payload) => ({
	type: GET_COMMENTS_SUCCESS,
	oneOnOneId,
	payload
});

export const getCommentsFailure = (oneOnOneId, payload, error, errors) => ({
	type: GET_COMMENTS_FAILURE,
	error,
	errors
});

export const createComment = (oneOnOneId, comment) => ({
	type: CREATE_COMMENT,
	oneOnOneId,
	comment
});

export const createCommentSuccess = (oneOnOneId, comment) => ({
	type: CREATE_COMMENT_SUCCESS,
	commentId: comment.uuid,
});

export const createCommentFailure = (oneOnOneId, payload, error, errors) => ({
	type: CREATE_COMMENT_FAILURE,
	error,
	errors
});

export const deleteComment = (oneOnOneId, commentId) => ({
	type: DELETE_COMMENT,
	oneOnOneId,
	commentId
});

export const deleteCommentSuccess = (commentId) => ({
	type: DELETE_COMMENT_SUCCESS,
	commentId
});

export const deleteCommentFailure = (oneOnOneId, commentId, error, errors) => ({
	type: DELETE_COMMENT_FAILURE,
	error,
	errors
});

export function shouldFetchComments(state) {
	const oneOnOnes = state.oneOnOnes;
	return Object.values(oneOnOnes.comments).length === 0 && !oneOnOnes.isFetching;
}

export function fetchCommentsIfNeeded(oneOnOneId) {
	return (dispatch, getState) => {
		if (shouldFetchComments(getState(), oneOnOneId)) {
			return dispatch(getComments(oneOnOneId));
		}
	};
}
