import styled from 'styled-components';

export default styled.div`
	cursor: pointer;
	margin-top: 0.5em;

	& + & {
		margin-top: 1em;
	}
`;
