import React, { memo, useCallback, useMemo } from 'react';
import { components } from 'react-select';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _includes from 'lodash/includes';
import { Icon } from '@plecto/ui';
import PagedSelect from './PagedSelect';
import useValueArray from '../../../hooks/useValueArray';
import styled from 'styled-components';
import { lighten } from 'polished';
import ConditionalWrapper from '../conditionalWrapper/ConditionalWrapper';
import { Tooltip } from '../tooltip/Tooltip';
import Badge from '../badge/Badge';
import { badgeTypes } from '../badge/badgeTypes';

const LIGHTEN_COEFFICIENT = 0.5;

const StyledIcon = styled(Icon)`
	color: #9A9F9F;

	&:before {
		vertical-align: baseline;
	}
`;

const StyledDropdownControl = styled(PagedSelect).attrs(props => ({
	padding: props.size === 'sm' ? '2px 10px' : '5px 10px',
	height: props.size === 'sm' ? '30px' : '38px',
}))`
	.react-select__control {
		border-color: #d3d8d8;
		border-radius: 4px;
		background-color: #fff;
		min-height: ${props => props.height};

		&:hover {
			border-color: #2bb673;
		}

		&--is-disabled {
			opacity: 0.6;
		}
	}

	.react-select__control--is-focused {
		-webkit-box-shadow: 0 0 0 1px #2bb673; // iOS <4.3 & Android <4.1
		box-shadow: 0 0 0 1px #2bb673;
	}

	.react-select__value-container {
		padding: ${props => props.padding};
	}

	.react-select__menu {
		margin-top: 0;
		z-index: 1063;
	}

	.react-select__option {
		padding: 6px 12px;
		display: flex;
		justify-content: start;
		gap: 8px;
		align-items: center;
	}

	.react-select__option__annotation {
		flex: 0 1 auto;
		font-size: 10px;
		font-weight: lighter;
	}

	.react-select__option__annotation,
	.react-select__option__label {
		display: inline-block;
	}

	.react-select__option--is-focused,
	.react-select__option:active {
		background-color: ${lighten(LIGHTEN_COEFFICIENT, '#2bb673')};
	}

	.react-select__option--is-selected,
	.react-select__option--is-selected:active {
		background-color: #2bb673;

		.icon {
			color: #fff;
		}
	}

	/// multi
	.react-select__value-container--is-multi {
		padding: 4px 4px;

		.react-select__placeholder {
			margin-left: 0;
		}

		.react-select__input {
			margin-left: 4px;
		}
	}

	.react-select__multi-value {
		margin: 0 2px;
		color: #12424d;
		cursor: default;
		border-right: 1px solid #d3d8d8;
		border-bottom: 1px solid #d3d8d8;
		border-radius: 4px;
		background-color: #f3f6f6;
	}

	.react-select__multi-value__label {
		padding: 5px 3px 5px 8px;
	}

	.react-select__control--is-disabled {
		opacity: 0.5;
	}

	.react-select__dropdown-indicator {
		padding: ${props => props.padding};
	}

	.react-select__control {
		border-color: #d3d8d8;
		border-radius: 4px;
		background-color: #fff;
		cursor: pointer;

		&:hover {
			border-color: #2bb673;
		}

		&--is-disabled {
			opacity: 0.6;
		}
	}

	.react-select__control--is-focused {
		-webkit-box-shadow: 0 0 0 1px #2bb673; // iOS <4.3 & Android <4.1
		box-shadow: 0 0 0 1px #2bb673;
	}


	.react-select__single-value {
		color: #555;
		display: flex;
		justify-content: start;
		gap: 8px;
		align-items: center;
	}

	.react-select__menu {
		margin-top: 0;
		z-index: 1063;
	}

	.react-select__option {
		padding: 6px 12px;
		cursor: pointer;

		&--is-disabled {
			cursor: not-allowed;
		}
	}

	.react-select__option__label {
		flex: 0 1 auto;
	}

	.label {
		flex: 0 1 auto;
	}

	.react-select__option__annotation,
	.react-select__option__label {
		display: inline-block;
	}

	.react-select__option--is-focused,
	.react-select__option:active {
		background-color: #e7f9f0;
	}

	.react-select__option--is-selected,
	.react-select__option--is-selected:active {
		background-color: #2bb673;
	}

	.react-select__control--is-disabled {
		opacity: 0.5;
	}

	.has-error & {
		.react-select__control {
			border-color: #dc2626;
		}
	}
`;

const StyledInteractiveTooltip = styled.span`
	float: right;
`;

const getLabel = (opt) => opt.label;
const getValue = (opt) => opt.value;

const emptyComponent = () => null;

const { Option, SingleValue } = components;

const CustomOption = props => (
	<ConditionalWrapper
		condition={props.data.isDisabled}
		wrapper={option => <Tooltip message={props.data.disabledMessage} placement="top">
			<div>{option}</div>
		</Tooltip>}>
		<Option {...props} >
			<span className="react-select__option__label">
				{props.data.label}
			</span>
			{props.data.annotation ? <span className="text-xs react-select__option__annotation">
				{props.data.annotation}
			</span> : null}
			{props.data.badge ? <Badge type={props.data.badgeType || badgeTypes.info} label={props.data.badge}/> : null}
			{props.data.help ? <Tooltip
				placement={'top'}
				message={props.data.help}
			><StyledIcon icon={'info-circle'}/></Tooltip> : null}
			{props.data.interactiveTooltip &&
				<StyledInteractiveTooltip>{props.data.interactiveTooltip}</StyledInteractiveTooltip>
			}
		</Option>
	</ConditionalWrapper>
);

const CustomSingleValue = props => (
	<SingleValue {...props}>
		{props.data.label}
		{props.hasValue && props.data?.badge ?
			<Badge type={props.data.badgeType || badgeTypes.info} label={props.data.badge}/> : null}
	</SingleValue>
);

const filterOption = (groupedOptions) => ({ label, value }, string) => {
	// default search
	const searchString = (string || '').toLocaleLowerCase();
	if (_includes(label.toLocaleLowerCase(), searchString)
	|| _includes(value.toString().toLocaleLowerCase(), searchString)) {
		return true;
	}

	// check if a group as the filter string as label
	const groupOptions = groupedOptions.filter(group =>
		_includes(group.label.toLocaleLowerCase(), searchString)
	);

	if (groupOptions) {
		for (const groupOption of groupOptions) {
			if (groupOption.options) {
				const option = groupOption.options.find(opt => opt.value === value);
				if (option) {
					return true;
				}
			}
		}
	}
	return false;
};

const disabledFilterOption = () => true;

function Dropdown({
	className,
	value,
	onValueChange,
	options,
	disabled,
	disabledMessage,
	searchable,
	isLoading,
	showIndicator,
	multipleSelection,
	customStyles,
	placeholder,
	onMenuOpen,
	onMenuClose,
	caretIcon,
	onBottomReached,
	onInputChange,
	inputValue,
	disableFilterOption,
	size,
	creatable,
	menuPosition,
	autoFocus,
	onBlur,
	...props
}) {
	const classes = classNames('react-select-container', className);
	const valueArray = useValueArray(value, options, creatable);
	const onChange = useCallback((selectedOption) =>
		onValueChange(multipleSelection ?
			selectedOption?.map(o => o.value) || [] :
			selectedOption?.value, selectedOption), [onValueChange, multipleSelection]
	);

	const selectComponents = useMemo(() => {

		const dropdownComponents = {
			Option: CustomOption,
			SingleValue: CustomSingleValue
		};
		if (!showIndicator) {
			dropdownComponents.IndicatorSeparator = emptyComponent;
		}
		if (caretIcon) {
			dropdownComponents.DropdownIndicator = props => {
				return (
					<components.DropdownIndicator {...props}>
						<Icon icon={caretIcon}/>
					</components.DropdownIndicator>
				);
			};
			dropdownComponents.DropdownIndicator.displayName = 'DropdownIndicator';
		}
		return dropdownComponents;
	}, [showIndicator, caretIcon]);

	const styles = useMemo(() => ({
		placeholder: (baseStyles) => ({
			...baseStyles,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			left: '10px',
			right: 0,
			color: '#C9CACC',
		}),
		...customStyles,
	}), [customStyles]);

	return <StyledDropdownControl
		size={size}
		className={classes}
		classNamePrefix="react-select"
		menuPlacement="auto"
		isSearchable={searchable}
		onChange={onChange}
		options={options}
		isLoading={isLoading}
		value={valueArray}
		getOptionLabel={getLabel}
		getOptionValue={getValue}
		components={selectComponents}
		isDisabled={disabled}
		disabledMessage={disabledMessage}
		isMulti={multipleSelection}
		placeholder={placeholder}
		filterOption={disableFilterOption ? disabledFilterOption : filterOption(options)}
		onMenuOpen={onMenuOpen}
		onMenuScrollToBottom={onBottomReached}
		onInputChange={onInputChange}
		inputValue={inputValue}
		onMenuClose={onMenuClose}
		creatable={creatable}
		menuPosition={menuPosition}
		autoFocus={autoFocus}
		onBlur={onBlur}
		styles={styles}
		{...props}
	/>;
}

Dropdown.defaultProps = {
	searchable: true
};

const requireOptionsOrValue = (props) => {
	if (!props.options && (props.value === null || props.value === undefined)) {
		return new Error('One of "options" or "value" is required by dropdown options component.');
	}
};

Dropdown.propTypes = {
	className: PropTypes.string,
	value: PropTypes.oneOfType(
		[PropTypes.string, PropTypes.symbol, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]
	),
	onValueChange: PropTypes.func.isRequired,
	options: PropTypes
		.arrayOf(PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: requireOptionsOrValue,
			options: requireOptionsOrValue,
			badge: PropTypes.string,
			badgeType: PropTypes.string,
		}))
		.isRequired,
	disabled: PropTypes.bool,
	disabledMessage: PropTypes.string,
	searchable: PropTypes.bool,
	isLoading: PropTypes.bool,
	showIndicator: PropTypes.bool,
	multipleSelection: PropTypes.bool,
};

export default memo(Dropdown);
