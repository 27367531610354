import styled from 'styled-components';
import { COLORS_GRAY } from '../../../styles/colors';

const Separator = styled.div`
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
	background-color: ${COLORS_GRAY['250']};
	margin: 5px 0;
`;

export default Separator;
