import { useState, useRef, useCallback } from 'react';
import usePassiveEventListener from './usePassiveEventListener';

function useSticky({ topOffset = 0, containerRef = window, initSticky = false, eventsToListen = 'scroll' }) {
	const [isSticky, setIsSticky] = useState(initSticky);
	const ref = useRef(null);

	const toggleSticky = useCallback(
		({ top }) => {
			if (top <= topOffset) {
				if (!isSticky) {
					setIsSticky(true);
				}
			} else {
				if (isSticky) {
					setIsSticky(false);
				}
			}
		},
		[isSticky, topOffset]
	);

	const handleScroll = useCallback(() => {
		if (ref.current)
			toggleSticky(ref.current.getBoundingClientRect());
	}, [toggleSticky]);

	usePassiveEventListener(containerRef, eventsToListen, handleScroll);

	return {
		stickyRef: ref,
		isSticky
	};
}
export default useSticky;
