import { takeLatest, takeEvery } from 'redux-saga/effects';
import {
	CHANGE_GROUP_ORDER, changeReportGroupOrdersSuccess, changeReportGroupOrdersFailure,
	FETCH,
	fetchReportFailure,
	fetchReportSuccess,
	FETCH_ALL,
	fetchAllReportsSuccess,
	fetchAllReportsFailure,
	UPDATE, saveReportFailure,
	saveReportSuccess,
	ARCHIVE,
	archiveReportSuccess,
	archiveReportFailure,
	RESTORE,
	restoreReportSuccess,
	restoreReportFailure,
	deleteReportSuccess,
	deleteReportFailure,
	DELETE,
} from './actions';
import { getReport, reorderGroups, updateReport, archive, restore, deleteReport, getAllReports } from './api';
import apiSagaGenerator from '../../saga/apiSagaGenerator';

const reportSagas = [
	takeLatest(FETCH,
		apiSagaGenerator()
			.forApi(getReport)
			.withArguments((action) => [action.reportId])
			.forSuccess(fetchReportSuccess)
			.forError(fetchReportFailure)
			.withMessage('Error getting report')
			.generate()
	),
	takeLatest(FETCH_ALL,
		apiSagaGenerator()
			.forApi(getAllReports)
			.forSuccess(fetchAllReportsSuccess)
			.forError(fetchAllReportsFailure)
			.includeErrorFromResponse(error => error)
			.withMessage('Error getting all reports')
			.generate()
	),
	takeLatest(UPDATE,
		apiSagaGenerator()
			.forApi(updateReport)
			.withArguments((action) => [action.reportId, action.reportSettings])
			.forSuccess(saveReportSuccess)
			.withActionArguments((action) => [action.reportId])
			.forError(saveReportFailure)
			.withMessage('Error saving report')
			.generate()
	),
	takeEvery(CHANGE_GROUP_ORDER,
		apiSagaGenerator()
			.forApi(reorderGroups)
			.withArguments((action) => [action.reportId, action.orderedReportGroupIds])
			.forSuccess(changeReportGroupOrdersSuccess)
			.forError(changeReportGroupOrdersFailure)
			.withMessage('Error reordering report groups')
			.generate()
	),
	takeLatest(ARCHIVE,
		apiSagaGenerator()
			.forApi(archive)
			.withArguments((action) => [action.reportId])
			.forSuccess(archiveReportSuccess)
			.withActionArguments((action) => [action.reportId])
			.forError(archiveReportFailure)
			.withMessage('Error archiving report')
			.generate()
	),
	takeLatest(RESTORE,
		apiSagaGenerator()
			.forApi(restore)
			.withArguments((action) => [action.reportId])
			.forSuccess(restoreReportSuccess)
			.withActionArguments((action) => [action.reportId])
			.forError(restoreReportFailure)
			.withMessage('Error restoring report')
			.generate()
	),
	takeLatest(DELETE,
		apiSagaGenerator()
			.forApi(deleteReport)
			.withArguments((action) => [action.reportId])
			.forSuccess(deleteReportSuccess)
			.skipResult()
			.withActionArguments((action) => [action.reportId])
			.forError(deleteReportFailure)
			.withMessage('Error deleting report')
			.generate()
	),
];

export default reportSagas;
