import { FETCH, FETCH_SUCCESS } from './actions';

const timeZones = (state = { isFetching: false, choices: [], isStale: true, }, action) => {
	switch (action.type) {
	case FETCH:
		return {
			...state,
			isFetching: true,
			isStale: false,
		};
	case FETCH_SUCCESS: {
		return {
			...state,
			choices: action.payload,
			isFetching: false,
		};
	}
	default:
		return state;
	}
};

export default timeZones;
