import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '../icon/Icon';
import { badgeTypes } from './badgeTypes';
import { badgeSizes } from './badgeSizes';

export default function Badge({ label, size, icon, type }) {
	return <span className={classNames(`label label-${type}`, {
		[`label-${size}`]: size,
	})}>
		{icon && <Icon name={icon} />}
		{label}
	</span>;
}

Badge.defaultProps = {
	type: badgeTypes.primary,
};

Badge.propTypes = {
	type: PropTypes.oneOf(Object.values(badgeTypes)),
	size: PropTypes.oneOf(Object.values(badgeSizes)),
	label: PropTypes.string.isRequired,
	icon: PropTypes.string,
};
