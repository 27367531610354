import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import { formOpacities } from '../formOpacities';
import { withCssSelector } from '../../shared/withCssSelector';

const StyledFieldLabel = styled.label<{
	$disabled?: boolean | undefined;
	$hasError?: boolean | undefined;
}>`
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	align-items: center;
	color: ${props => props.$hasError ? '#b21d1d' : '#374151'};
	opacity: ${props => props.$disabled ? formOpacities.disabled : formOpacities.full};
	margin: 0;

	&[for] {
		cursor: pointer;
	}
`;

export type FieldLabelProps = {
	disabled?: boolean | undefined
	hasError?: boolean | undefined
} & ComponentPropsWithoutRef<'label'>

export const FieldLabel = withCssSelector(React.forwardRef<HTMLLabelElement, FieldLabelProps>(
	function FieldLabel({ disabled, hasError, htmlFor, ...props }, ref) {
		return <StyledFieldLabel
			ref={ref}
			htmlFor={disabled ? undefined : htmlFor}
			$disabled={disabled}
			$hasError={hasError}
			{...props}
		/>;
	}
), StyledFieldLabel.toString());
