import { apiVersionV3 } from '../../fetch/apiService';

const PREFIX = `plecto/${apiVersionV3}/posts/`;

export const GET_POSTS = PREFIX + 'GET_POSTS';
export const GET_POSTS_SUCCESS = PREFIX + 'GET_POSTS_SUCCESS';
export const GET_POSTS_FAILURE = PREFIX + 'GET_POSTS_FAILURE';

export const GET_NEW_POSTS = PREFIX + 'GET_NEW_POSTS';
export const GET_NEW_POSTS_SUCCESS = PREFIX + 'GET_NEW_POSTS_SUCCESS';
export const GET_NEW_POSTS_FAILURE = PREFIX + 'GET_NEW_POSTS_FAILURE';

export const CREATE_POST = PREFIX + 'CREATE_POST';
export const CREATE_POST_SUCCESS = PREFIX + 'CREATE_POST_SUCCESS';
export const CREATE_POST_FAILURE = PREFIX + 'CREATE_POST_FAILURE';

export const DELETE_POST = PREFIX + 'DELETE_POST';
export const DELETE_POST_SUCCESS = PREFIX + 'DELETE_POST_SUCCESS';
export const DELETE_POST_FAILURE = PREFIX + 'DELETE_POST_FAILURE';

export const ADD_POST_REACTION = PREFIX + 'ADD_POST_REACTION';
export const ADD_POST_REACTION_SUCCESS = PREFIX + 'ADD_POST_REACTION_SUCCESS';
export const ADD_POST_REACTION_FAILURE = PREFIX + 'ADD_POST_REACTION_FAILURE';

export const REMOVE_POST_REACTION = PREFIX + 'REMOVE_POST_REACTION';
export const REMOVE_POST_REACTION_SUCCESS = PREFIX + 'REMOVE_POST_REACTION_SUCCESS';
export const REMOVE_POST_REACTION_FAILURE = PREFIX + 'REMOVE_POST_REACTION_FAILURE';

export const getPosts = (parameters = {}) => ({
	type: GET_POSTS,
	parameters,
});

export const getPostsSuccess = (parameters, response) => ({
	type: GET_POSTS_SUCCESS,
	payload: response,
});

export const getPostsFailure = (parameters, request, error, errors) => ({
	type: GET_POSTS_FAILURE,
	error,
	errors,
});

export const getNewPosts = (parameters = {}) => ({
	type: GET_NEW_POSTS,
	parameters,
});

export const getNewPostsSuccess = (parameters, response) => ({
	type: GET_NEW_POSTS_SUCCESS,
	parameters,
	response,
});

export const getNewPostsFailure = (parameters, request, error, errors) => ({
	type: GET_NEW_POSTS_FAILURE,
	parameters,
	error,
	errors,
});

export const createPost = (request) => ({
	type: CREATE_POST,
	payload: request,
});

export const createPostSuccess = (request, response) => ({
	type: CREATE_POST_SUCCESS,
	payload: response,
});

export const createPostFailure = (request, error, errors) => ({
	type: CREATE_POST_FAILURE,
	error,
	errors,
});

export const deletePost = (postId) => ({
	type: DELETE_POST,
	postId,
});

export const deletePostSuccess = (postId) => ({
	type: DELETE_POST_SUCCESS,
	postId,
});

export const deletePostFailure = (postId, error, errors) => ({
	type: DELETE_POST_FAILURE,
	postId,
	error,
	errors,
});

export const addPostReaction = (postId, request) => ({
	type: ADD_POST_REACTION,
	request,
	postId,
});

export const addPostReactionSuccess = (postId, request) => ({
	type: ADD_POST_REACTION_SUCCESS,
	request,
	postId,
});

export const addPostReactionFailure = (postId, request, error, errors) => ({
	type: ADD_POST_REACTION_FAILURE,
	postId,
	request,
	error,
	errors,
});

export const removePostReaction = (postId, request) => ({
	type: REMOVE_POST_REACTION,
	request,
	postId,
});

export const removePostReactionSuccess = (postId, request) => ({
	type: REMOVE_POST_REACTION_SUCCESS,
	request,
	postId,
});

export const removePostReactionFailure = (postId, request, error, errors) => ({
	type: REMOVE_POST_REACTION_FAILURE,
	postId,
	request,
	error,
	errors,
});
