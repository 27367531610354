import styled, { css } from 'styled-components';
import {
	StyledDropdownItem, StyledDropdownIcon
} from './styledComponents';
import { IconButton } from '../../buttons/IconButton';
import IconWithTooltip from '../../iconWithTooltip/IconWithTooltip';
import { COLORS, COLORS_GRAY, COLORS_TEXT } from '../../../styles/colors';

export const MenuItem = styled(StyledDropdownItem)`
	align-items: stretch;
	${props => props.selected ? css`
		span,
		.icon {
			color: ${COLORS.light};
		}
	` : ''}
	&:hover {
		.multi-level-dropdown-item-select {
			opacity: 1;
		}
	}
`;

export const ItemTitle = styled.span`
	flex: 1 0;
	align-items: start;
	padding: 6px 10px;
`;

export const ItemSubTitle = styled.span`
	color: ${COLORS_TEXT.subtitle};
	font-size: 12px;
`;

export const ItemCheckmark = styled(StyledDropdownIcon)`
	font-size: 12px;
	color: #2bb673;
`;

export const ItemSelect = styled(IconButton)`
	transition: opacity 300ms;
	opacity: 0;
	color: ${COLORS_TEXT.dark};
	background-color: transparent;
	border: none;
`;

export const ItemMessage = styled(IconWithTooltip)`
	align-items: center;
	display: flex;
	color: ${COLORS_GRAY['300']};
	padding: 6px 10px;
`;
