import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

function Button({
	className,
	onClick,
	forwardedRef,
	label,
	disabled,
	title,
	useJsTooltip,
	tooltipPlacement,
}) {
	const buttonRef = useRef();

	useEffect(() => {
		const buttonEl = buttonRef.current;
		if (useJsTooltip) {
			$(buttonEl).tooltip({
				container: 'body'
			});
		}

		return () => {if (useJsTooltip) $(buttonEl).tooltip('destroy');};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <span
		ref={buttonRef}
		className="d-inline-block"
		data-original-title={title}
		title={title}
		data-toggle={useJsTooltip ? 'tooltip' : null}
		data-placement={tooltipPlacement || 'bottom'}
	>
		<button
			ref={forwardedRef}
			type="button"
			className={className || 'btn btn-sm btn-default'}
			style={useJsTooltip && disabled ? { pointerEvents: 'none' } : null}
			onClick={onClick}
			disabled={disabled}
		>
			{label ? <span>{label}</span> : null}
		</button>
	</span>;

}

Button.propTypes = {
	label: PropTypes.string,
	title: PropTypes.string,
	badge: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	count: PropTypes.number,
};

// eslint-disable-next-line react/display-name
export default React.forwardRef((props, ref) => (
	<Button {...props} forwardedRef={ref}/>
));
