import {
	SAVE_SLIDESHOW,
	SAVE_SLIDESHOW_SUCCESS,
	SAVE_SLIDESHOW_API_ERROR,
} from './actions';

const slideshows = (
	state = {
		isStale: true,
		isFetching: false,
		slideshowsById: {},
		resolved: {},
		dimensions: {},
		slideshow: {},
	},
	action
) => {
	switch (action.type) {
	case SAVE_SLIDESHOW: {
		return {
			...state,
			isSaving: true,
			errorResult: null,
		};
	}
	case SAVE_SLIDESHOW_SUCCESS: {
		return {
			...state,
			isSaving: false,
			data: action.payload,
		};
	}
	case SAVE_SLIDESHOW_API_ERROR: {
		return {
			...state,
			isSaving: false,
			error: action.error,
		};
	}
	default:
		return state;
	}
};

export { slideshows };
