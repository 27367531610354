import {
	FETCH_QUIZ,
	FETCH_QUIZ_FAILURE,
	FETCH_QUIZ_SUCCESS,
	GET_QUESTION,
	GET_QUESTION_FAILURE,
	GET_QUESTION_SUCCESS,
	GET_QUESTIONS,
	GET_QUESTIONS_FAILURE,
	GET_QUESTIONS_SUCCESS,
	GET_RESULTS,
	GET_RESULTS_FAILURE,
	GET_RESULTS_SUCCESS,
	GET_SESSION_RESULTS,
	GET_SESSION_RESULTS_FAILURE,
	GET_SESSION_RESULTS_QUESTION_DETAILS,
	GET_SESSION_RESULTS_QUESTION_DETAILS_FAILURE,
	GET_SESSION_RESULTS_QUESTION_DETAILS_SUCCESS,
} from './actions';
import _keyBy from 'lodash/keyBy';

const trainingQuizzes = (state = {
	isFetching: false,
	quiz: {
		questions: {},
		answers: {},
	}
}, action) => {
	switch (action.type) {
	case FETCH_QUIZ:
		return {
			...state,
			isFetching: true,
		};
	case FETCH_QUIZ_SUCCESS: {
		return {
			...state,
			quiz: action.payload,
			isFetching: false,
		};
	}
	case FETCH_QUIZ_FAILURE:
		return {
			...state,
			isFetching: false
		};
	case GET_QUESTION:
		return {
			...state,
			quiz: {
				...state.quiz,
				questions: {
					...state.quiz.questions,
					[action.questionId]: {
						...state.quiz.questions[action.questionId],
						isFetching: true
					}
				}
			}
		};
	case GET_QUESTION_SUCCESS: {
		return {
			...state,
			quiz: {
				...state.quiz,
				questions: {
					...state.quiz.questions,
					[action.questionId]: {
						...state.quiz.questions[action.questionId],
						...action.payload,
						isFetching: false
					}
				}
			}
		};
	}
	case GET_QUESTION_FAILURE:
		return {
			...state,
			quiz: {
				...state.quiz,
				questions: {
					...state.quiz.questions,
					[action.questionId]: {
						...state.quiz.questions[action.questionId],
						isFetching: false
					}
				}
			}
		};
	case GET_QUESTIONS:
		return {
			...state,
			isFetching: true,
		};
	case GET_QUESTIONS_SUCCESS: {
		return {
			...state,
			isFetching: false,
			quiz: {
				...state.quiz,
				questions: _keyBy(action.payload, 'uuid')
			}
		};
	}
	case GET_QUESTIONS_FAILURE:
		return {
			...state,
			isFetching: false,
		};
	case GET_RESULTS:
		return {
			...state,
			isFetching: true,
		};
	case GET_RESULTS_SUCCESS: {
		return {
			...state,
			isFetching: false,
			quiz: {
				...state.quiz,
				answers: action.payload
			}
		};
	}
	case GET_RESULTS_FAILURE:
		return {
			...state,
			isFetching: false,
		};
	case GET_SESSION_RESULTS:
		return {
			...state,
			isFetching: true,
		};
	case GET_SESSION_RESULTS_FAILURE:
		return {
			...state,
			isFetching: false,
		};
	case GET_SESSION_RESULTS_QUESTION_DETAILS:
		return {
			...state,
			isFetching: true,
		};
	case GET_SESSION_RESULTS_QUESTION_DETAILS_SUCCESS:
	case GET_SESSION_RESULTS_QUESTION_DETAILS_FAILURE:
		return {
			...state,
			isFetching: false,
		};
	default:
		return state;
	}
};

export { trainingQuizzes };
