export const buttonVariants = [
	'white',
	'default',
	'dark',
	'primary',
	'info',
	'warning',
	'danger',
	'default-danger',
	'ghost'
] as const;

export type ButtonVariant = typeof buttonVariants[number];

type StateColorsDefinition = {
	default: string
	hover: string
	active: string
	disabled: string
}

export type ButtonColorDefinition = {
	background: StateColorsDefinition
	textColor: StateColorsDefinition
	border: StateColorsDefinition
}

const sameColorEveryState = (color: string) : StateColorsDefinition => ({
	default: color,
	hover: color,
	active: color,
	disabled: color
});

export const ButtonColors: {[key in ButtonVariant]: ButtonColorDefinition} = {
	default: {
		background: {
			default: '#E5E7EB',
			hover: '#D7DAE0',
			active: '#CED2D9',
			disabled: '#E5E7EB'
		},
		textColor: sameColorEveryState('#0F1E38'),
		border: {
			default: '#E5E7EB',
			hover: '#D7DAE0',
			active: '#CED2D9',
			disabled: '#E5E7EB'
		},
	},
	white: {
		background: {
			default: '#FFFFFF',
			hover: '#FFFFFF',
			active: '#F2F2F2',
			disabled: '#FFFFFF'
		},
		textColor: sameColorEveryState('#0F1E38'),
		border: {
			default: '#D1D5DB',
			hover: '#BDC3CB',
			active: '#BDC3CB',
			disabled: '#D1D5DB'
		},
	},
	primary: {
		background: {
			default: '#12A34D',
			hover: '#14B154',
			active: '#0F8C42',
			disabled: '#12A34D'
		},
		textColor: sameColorEveryState('#FFFFFF'),
		border: {
			default: '#12A34D',
			hover: '#14B154',
			active: '#0F8C42',
			disabled: '#12A34D'
		},
	},
	dark: {
		background: {
			default: '#0F1E38',
			hover: '#2D415A',
			active: '#010D17',
			disabled: '#656e7e'
		},
		textColor: sameColorEveryState('#FFFFFF'),
		border: {
			default: '#0F1E38',
			hover: '#2D415A',
			active: '#010D17',
			disabled: '#656e7e'
		},
	},
	info: {
		background: {
			default: '#126BDD',
			hover: '#1372EB',
			active: '#1060C5',
			disabled: '#126BDD'
		},
		textColor: sameColorEveryState('#FFFFFF'),
		border: {
			default: '#126BDD',
			hover: '#1372EB',
			active: '#1060C5',
			disabled: '#126BDD'
		},
	},
	warning: {
		background: {
			default: '#F79116',
			hover: '#F89825',
			active: '#EB8408',
			disabled: '#F79116'
		},
		textColor: sameColorEveryState('#FFFFFF'),
		border: {
			default: '#F79116',
			hover: '#F89825',
			active: '#EB8408',
			disabled: '#F79116'
		},
	},
	danger: {
		background: {
			default: '#DC2626',
			hover: '#DE3333',
			active: '#C82020',
			disabled: '#DC2626'
		},
		textColor: sameColorEveryState('#FFFFFF'),
		border: {
			default: '#DC2626',
			hover: '#DE3333',
			active: '#C82020',
			disabled: '#DC2626'
		},
	},
	'default-danger': {
		background: {
			default: '#FBE4E4',
			hover: '#FADFDF',
			active: '#F6CACA',
			disabled: '#FBE4E4'
		},
		textColor: {
			default: '#DF6061',
			hover: '#DC2626',
			active: '#DC2626',
			disabled: '#DF6061'
		},
		border: {
			default: '#FBE4E4',
			hover: '#FADFDF',
			active: '#F6CACA',
			disabled: '#FBE4E4'
		},
	},
	ghost: {
		background: {
			default: 'transparent',
			hover: 'transparent',
			active: 'transparent',
			disabled: 'transparent'
		},
		textColor: {
			default: '#0F1E38',
			hover: '#12A34D',
			active: '#0F1E38',
			disabled: '#0F1E38'
		},
		border: {
			default: 'transparent',
			hover: 'transparent',
			active: 'transparent',
			disabled: 'transparent'
		},
	}

};
