import { takeLatest } from '@redux-saga/core/effects';
import * as dashboardActions from './actions';
import * as api from './api';
import apiSagaGenerator from '../../saga/apiSagaGenerator';

const dashboardsSagas = [takeLatest(dashboardActions.FETCH, 
	apiSagaGenerator()
		.forApi(api.fetchDashboards)
		.forSuccess(dashboardActions.fetchDashboardsSuccess)
		.forError(dashboardActions.fetchDashboardsError)
		.includeErrorResult(result => result.detail)
		.withMessage('Error getting dashboards')
		.generate()	
)];

export default dashboardsSagas;
