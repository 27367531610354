import React, { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import ModeTabsChooser from '../blocks/ModeTabsChooser';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Preview from '../blocks/Preview';
const controlsHeight = '28px';

const StyledTimePeriodPopover = styled.div`
    font-size: 14px;
	line-height: 1.42857143;
	background-color: #fff;
	border: 1px solid #d3d8d8;
	border-radius: 4px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	max-width: 400px;
	min-width: 400px;
	width: auto;
`;

const StyledPopoverPart = styled.div`
	padding: ${props => props.padded ? '10px' : '0'};
	height: auto;
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	color: #121212;
	border-bottom: 1px solid #e2e6e6;

	.help-inline {
		max-height: none;
		margin-bottom: 0;
		min-height: 0;

		.icon {
			font-size: 16px;
			margin-left: 0;
		}
	}

	.react-number-input {
		box-sizing: border-box;
	}

	.react-number-input,
	.react-select-container .react-select__control {
		line-height: 1.42857143;
		height: ${controlsHeight};
		min-height: ${controlsHeight};
	}

	.react-select__value-container {
		padding: 0 10px;
		align-self: stretch;
		position: initial;
		height: 100%;
	}

	.react-select__indicators {
		height: 100%;
	}
`;

const StyledFooter = styled(StyledPopoverPart)`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

const StyledContent = styled(StyledPopoverPart)`
	> *:not(:first-child) {
		margin-top: 1em;
	}

	> * {
		flex: 1 0 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		&:not(.time-period-calendar) > * {
			margin-right: 0.5em;

			&:last-child {
				margin-right: 0;
			}
		}
	}
`;

const StyledActions = styled.div`
	flex: 0 1 auto;

	.btn {
		min-width: 86px;
		font-size: 14px;
	}

	.btn + .btn {
		margin-left: 0.5em;
	}
`;
const TimePeriodPopoverTab = forwardRef(({
	children,
	state,
	onSave,
	onCancel,
	padded,
	previewOperationId,
	preview,
	changeMode,
	changeToPresetMode,
	clearPreset
}, ref) => {
	const { t } = useTranslation();
	return <StyledTimePeriodPopover ref={ref} className="time-period-tab">
		<ModeTabsChooser
			currentMode={state.mode}
			preset={state.preset}
			changeMode={changeMode}
			changeToPresetMode={changeToPresetMode}
			startMode={state.relativeStartDate.mode}
			clearPreset={clearPreset} />
		<StyledContent className="time-period-tab-content" padded={padded}>
			{children}
		</StyledContent>
		<StyledFooter className="time-period-tab-footer" padded>
			<Preview operationId={previewOperationId} preview={preview} />
			<StyledActions className='time-period-tab-footer-actions'>
				<button
					type="button"
					className="btn btn-sm btn-default cancel-button"
					onClick={onCancel}>
					{t('Cancel')}
				</button>
				<button
					type="button"
					className="btn btn-sm btn-primary save-button"
					onClick={onSave}>
					{t('Apply')}
				</button>
			</StyledActions>
		</StyledFooter>
	</StyledTimePeriodPopover>;
});

TimePeriodPopoverTab.propTypes = {
	title: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	state: PropTypes.object.isRequired,
};

TimePeriodPopoverTab.displayName = 'TimePeriodPopoverTab';

export default memo(TimePeriodPopoverTab);
