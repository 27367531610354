import _filter from 'lodash/filter';
import { relativeModes, periods, getWeekDaysOrdered, getPeriods } from './constants';
import { getWeekdayName } from './translations';
import _padStart from 'lodash/padStart';
import timeExpressionValidator from './timeExpressionValidator';

export function calculateOptionsToShow(fromHours, timeOptions) {
	const { hours } = parseHours(fromHours);

	return _filter(timeOptions, opt => opt.meta.hour > hours);
}

export function calculateWeekdaysOptionsToShow(fromDay, weekStart = 1) {
	const weekDaysOrdered = getWeekDaysOrdered(weekStart);
	const fromIndex = weekDaysOrdered.indexOf(fromDay);
	const validToOptions = weekDaysOrdered.slice(fromIndex);

	return validToOptions.map(value => ({ label: getWeekdayName(value), value }));
}

export function changeIfInvalid(fromHours, toHours) {
	if (!timeExpressionValidator(fromHours) && !timeExpressionValidator(toHours)) {
		if (fromHours >= toHours) {
			const { hours } = parseHours(fromHours);
			return `${_padStart(hours + 1, 2, '0')}:00`;
		}

		return fromHours >= toHours ? fromHours : toHours;
	}
	
	return toHours;
}

export function changeWeekDayIfInvalid(fromDay, toDay, weekStart = 1) {	
	const fromIndex = getWeekDaysOrdered(weekStart).indexOf(fromDay);
	const toIndex = getWeekDaysOrdered(weekStart).indexOf(toDay);

	if (fromIndex > toIndex) {
		return fromDay;
	}
	return toDay;
}

export function parseHours(hoursString) {
	const parts = hoursString.split(':');
	const hours = parseInt(parts[0]);
	const minutes = parseInt(parts[1]);
	return { hours, minutes };
}

export function getAvailableEndDateOptions(mode, period, amount, features) {
	const allPeriods = getPeriods(features);

	const options = [];
	const currentOptions = { mode: relativeModes.current, periods: allPeriods };
	const allNextOptions = { mode: relativeModes.next, periods: allPeriods, min: 1 };
	const allPreviousOptions = { mode: relativeModes.previous, periods: allPeriods, min: 1 };
	if (mode === relativeModes.previous) {
		if (amount > 1) {
			options.push({ mode: relativeModes.previous, periods: [period], max: amount, min: 1 });
		}
		options.push(...getCoefficients(period).map(coefficient => (
			{ mode: relativeModes.previous, periods: [coefficient.period], max: amount * coefficient.amount, min: 1 }
		)));
		options.push(currentOptions);
		options.push(allNextOptions);

	} else if (mode === relativeModes.current) {
		options.push(...getCoefficients(period).map(coefficient => (
			{ mode: relativeModes.previous, periods: [coefficient.period], max: coefficient.amount, min: 1 }
		)));
		options.push(currentOptions);
		options.push(allNextOptions);
	} else if (mode === relativeModes.next) {
		options.push(allPreviousOptions);
		options.push(currentOptions);
		options.push({ mode: relativeModes.next, periods: [period], min: 1, max: amount });
	}

	return options;
}

function getCoefficients(startPeriod) {
	switch (startPeriod) {
	case periods.week:
		return [
			{ period: periods.day, amount: 7 }
		];
	case periods.month:
		return [
			{ period: periods.week, amount: 5 },
			{ period: periods.day, amount: 31 },
		];
	case periods.quarter:
		return [
			{ period: periods.month, amount: 3 },
			{ period: periods.week, amount: 15 },
			{ period: periods.day, amount: 93 },
		];
	case periods.year:
		return [
			{ period: periods.quarter, amount: 4 },
			{ period: periods.month, amount: 12 },
			{ period: periods.week, amount: 53 },
			{ period: periods.day, amount: 366 },
		];
	}
	return [];
}
