import styled from 'styled-components';
import { Input } from '@plecto/ui';

const DISABLED_OPACITY = 0.5;
const DEFAULT_OPACITY = 1;

export const StyledInput = styled(Input)`
	&[type='checkbox'] {
		margin: 0;
	}
`;

export const StyledFilterItem = styled.div<{
	$disabled?: boolean;
}>`
	cursor: ${props => props.$disabled ? 'not-allowed' : 'pointer'};
	opacity: ${props => props.$disabled ? DISABLED_OPACITY : DEFAULT_OPACITY};
	align-items: stretch;
	display: flex;
	${StyledInput} {
		cursor: ${props => props.$disabled ? 'not-allowed' : 'pointer'};
	}

	&:hover {
		background: #F6F6F7;
	}
`;

export const StyledFilterCustomItem = styled.div`
	align-items: center;
	justify-content: start;
	display: flex;
	padding: 8px 12px;
	gap: 8px;
	cursor: pointer;

	&:hover {
		background: #F6F6F7;
	}
`;
