import React from 'react';
import PropTypes from 'prop-types';

export default function TableRow({ rows, keys }) {
	const RowCells = ({ row, keys }) => {
		return keys.map((key) => <td key={row[key]}>{row[key]}</td>);
	};

	return <tbody>
		{rows.map((row, index) =>
			<tr key={index}>
				<RowCells key={index} row={row} keys={keys}/>
			</tr>
		)}
	</tbody>;
}

TableRow.propTypes = {
	rows: PropTypes.arrayOf(PropTypes.object).isRequired,
	keys: PropTypes.arrayOf(PropTypes.string).isRequired,
};
