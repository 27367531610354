import { takeEvery } from '@redux-saga/core/effects';
import * as widgetsActions from './actions';
import apiSagaGenerator from '../../saga/apiSagaGenerator';
import { fetchWidgets } from './api';

const widgetsSagas = [takeEvery(widgetsActions.FETCH, 
	apiSagaGenerator()
		.forApi(fetchWidgets)
		.withArguments((action) => [action.dashboardUuid])
		.forSuccess(widgetsActions.fetchWidgetsSuccess)
		.forError(widgetsActions.fetchWidgetsError)
		.includeErrorResult(result => result.detail)
		.generate() 
)];

export default widgetsSagas;
