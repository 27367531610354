import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { zIndexPopover } from '../../styles/zIndex';

const S = {};

S.Host = styled.div`
	z-index: ${zIndexPopover};
`;
export class PopoverHost extends React.Component {
	constructor(props) {
		super(props);
		this.el = document.createElement('div');
		this.el.className = 'popover-tab-host-wrapper';
		this.el.style.position = 'relative';
	}

	componentDidMount() {
		document.body.appendChild(this.el);
	}

	componentWillUnmount() {
		document.body.removeChild(this.el);
	}

	render() {
		return ReactDOM.createPortal(
			<S.Host className='popover-tab-host' ref={this.props.forwardedRef} >
				{this.props.children}
			</S.Host>,
			this.el,
		);
	}
}

export default React.forwardRef((props, ref) => (
	<PopoverHost {...props} forwardedRef={ref}/>
));
