import { all, spawn } from 'redux-saga/effects';
import sagasByModule from './sagasByModule';
import _pick from 'lodash/pick';
import _flatten from 'lodash/flatten';

export default function mergeWithApiSaga(moduleSagas, apiReducersNames, options) {
	const selectedSagas = _pick(sagasByModule, apiReducersNames);
	const allSagas = _flatten(Object.values(selectedSagas));

	return function* merged() {
		yield all([
			...allSagas,
			...moduleSagas
		].map(saga =>
			spawn(function* () {
				try {
					yield saga;
				} catch (e) {
					if (options && options.onError) {
						options.onError(e);
					} else {
						console.error(e, e.stack);
					}
				}
			})
		));
	};
}
