import {
	PREVIEW_FORMULA,
	PREVIEW_FORMULA_API_ERROR,
	PREVIEW_FORMULA_SUCCESS,
	FETCH,
	FETCH_SUCCESS,
	RESOLVE,
	RESOLVE_SUCCESS,
	RESOLVE_FORMULA_API_ERROR,
	FETCH_DIMENSIONS,
	FETCH_DIMENSIONS_SUCCESS,
	FETCH_DIMENSIONS_API_ERROR,
	GET_FORMULA,
	UPDATE_FORMULA,
	GET_FORMULA_SUCCESS,
	PRELOAD_FORMULA,
	SAVE_FORMULA,
	SAVE_FORMULA_SUCCESS,
	SAVE_FORMULA_API_ERROR,
	PARSE_FORMULA,
	PARSE_FORMULA_SUCCESS,
	PARSE_FORMULA_API_ERROR,
	CLEAN_STATE,
	LOAD_EDITOR_PARAMETERS,
	LOAD_EDITOR_PARAMETERS_SUCCESS,
	LOAD_EDITOR_PARAMETERS_API_ERROR,
} from './actions';
import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import createDimensionKey from './createDimensionKey';

function mapFormula(formula) {
	return {
		id: formula.id,
		isDataSource: formula.is_datasource,
		isEditable: formula.is_editable,
		isNew: formula.isNew || !formula.uuid,
		uuid: formula.uuid,
		title: formula.title || '',
		format: formula.format,
		unresolved_format: formula.unresolved_format,
		output: formula.output || 'DecimalOutput',
		decimals: formula.decimals || 0,
		prefix: formula.prefix || '',
		suffix: formula.suffix || '',
		lowerValueIsBetter: formula.lower_value_is_better || false,
		isAvailableInNewEditor: formula.is_available_in_new_editor,
		conditionalColors: (formula.conditional_colors || []).map((c) => ({
			...c,
			id: c.uuid,
			color: c.color,
			value: c.value,
		})),
		preview: mapPreviewFormula(formula),
		forTour: formula.for_tour,
		...(formula.object_permissions &&
			{ permissions: formula.object_permissions.map(permission => permission.code) }),
	};
}

function mapPreviewFormula(formula) {
	return {
		result: mapPreviewResult(formula),
	};
}

function mapPreviewResult(formula) {
	return {
		calculation: formula.calculation,
		endDate: formula.end_date,
		startDate: formula.start_date,
		value: formula.value || '',
	};
}

export function mapModelToApi(model) {
	return {
		decimals: model.decimals,
		format: model.format,
		is_datasource: model.isDataSource,
		is_editable: model.isEditable,
		isNew: model.isNew,
		output: model.output,
		prefix: model.prefix,
		suffix: model.suffix,
		title: model.title,
		...(model.isNew && model.forTour && { for_tour: model.forTour }),
		uuid: model.isNew ? undefined : model.uuid,
		lower_value_is_better: model.lowerValueIsBetter,
		is_available_in_new_editor: model.isAvailableInNewEditor,
		conditional_colors: model.conditionalColors.map((c) => ({
			uuid: c.uuid,
			color: c.color,
			value: c.value,
		})),
	};
}

const formulas = (
	state = {
		isStale: true,
		isFetching: false,
		formulasById: {},
		resolved: {},
		dimensions: {},
		formula: {},
	},
	action
) => {
	switch (action.type) {
	case FETCH:
		return {
			...state,
			isStale: false,
			isFetching: true,
		};
	case FETCH_SUCCESS: {
		const newFormulas = _keyBy(action.payload.map(mapFormula), 'uuid');
		return {
			...state,
			isFetching: false,
			formulasById: newFormulas,
		};
	}
	case RESOLVE: {
		const key = action.formulaId || action.formulaString;

		return {
			...state,
			resolved: {
				...state.resolved,
				[key]: {
					isResolving: true,
				},
			},
		};
	}
	case RESOLVE_SUCCESS: {
		const key = action.formulaId || action.formulaString;
		return {
			...state,
			resolved: {
				...state.resolved,
				[key]: {
					isResolving: false,
					data: action.payload,
				},
			},
		};
	}
	case RESOLVE_FORMULA_API_ERROR: {
		const key = action.formulaId || action.formulaString;

		return {
			...state,
			resolved: {
				...state.resolved,
				[key]: {
					isResolving: false,
					error: action.error,
				},
			},
		};
	}
	case FETCH_DIMENSIONS:
		return {
			...state,
			dimensions: {
				...state.dimensions,
				[action.formulaId]: {
					...(state.dimensions[action.formulaId] || {}),
					isFetching: true,
				},
			},
		};
	case FETCH_DIMENSIONS_SUCCESS: {
		return {
			...state,
			dimensions: {
				...state.dimensions,
				[action.formulaId]: {
					...(state.dimensions[action.formulaId] || {}),
					isFetching: false,
					isLoaded: true,
					data: action.payload.map((d) => ({
						...d,
						key: createDimensionKey(d),
					})),
				},
			},
		};
	}
	case FETCH_DIMENSIONS_API_ERROR: {
		return {
			...state,
			dimensions: {
				...state.dimensions,
				[action.formulaId]: {
					...(state.dimensions[action.formulaId] || {}),
					isFetching: false,
					error: action.error,
				},
			},
		};
	}
	case PARSE_FORMULA: {
		return {
			...state,
			formula: {
				...state.formula,
				[action.formulaId]: {
					...state.formula[action.formulaId],
					isResolving: true,
				},
			},
		};
	}
	case PARSE_FORMULA_SUCCESS: {
		return {
			...state,
			formula: {
				...state.formula,
				[action.formulaId]: {
					...state.formula[action.formulaId],
					isResolving: false,
					parseResult: action.payload,
				},
			},
		};
	}
	case PARSE_FORMULA_API_ERROR: {
		return {
			...state,
			formula: {
				...state.formula,
				[action.formulaId]: {
					...state.formula[action.formulaId],
					isResolving: false,
					error: action.error,
				},
			},
		};
	}
	case GET_FORMULA:
		return {
			...state,
			formula: {
				[action.formulaId]: {
					isStale: false,
					isFetching: true,
				},
			},
		};
	case PRELOAD_FORMULA: {
		return {
			...state,
			formula: {
				...state.formula,
				[action.formulaModel.uuid]: {
					...state.formula[action.formulaModel.uuid],
					isStale: false,
					isFetching: false,
					data: mapFormula(action.formulaModel),
				},
			},
		};
	}
	case GET_FORMULA_SUCCESS: {
		return {
			...state,
			formula: {
				...state.formula,
				[action.formulaId]: {
					isStale: false,
					isFetching: false,
					data: mapFormula(action.payload),
				},
			},
		};
	}
	case CLEAN_STATE: {
		return {
			...state,
			formula: _omit(state.formula, action.formulaId),
		};
	}
	case UPDATE_FORMULA: {
		return {
			...state,
			formula: {
				[action.formulaId]: {
					...state.formula[action.formulaId],
					isStale: false,
					isFetching: false,
					data: {
						...(state.formula[action.formulaId]?.data || {}),
						...(action.isPreview
							? {
								preview: {
									...state.formula[action.formulaId].data.preview,
									...action.updates,
								},
							}
							: action.updates),
					},
				},
			},
		};
	}

	case SAVE_FORMULA: {
		return {
			...state,
			formula: {
				[action.formulaId]: {
					...state.formula[action.formulaId],
					isSaving: true,
					errorResult: null,
				},
			},
		};
	}
	case SAVE_FORMULA_SUCCESS: {
		return {
			...state,
			formula: {
				[action.formulaId]: {
					...state.formula[action.formulaId],
					isSaving: false,
					data: mapFormula(action.payload),
				},
			},
		};
	}
	case SAVE_FORMULA_API_ERROR: {
		return {
			...state,
			formula: {
				[action.formulaId]: {
					...state.formula[action.formulaId],
					isSaving: false,
					error: action.error,
				},
			},
		};
	}
	case PREVIEW_FORMULA: {
		return {
			...state,
			formula: {
				[action.formulaId]: {
					...state.formula[action.formulaId],
					data: {
						...state.formula[action.formulaId]?.data,
						preview: {
							...state.formula[action.formulaId]?.data?.preview,
							isResolving: true,
						},
					},
				},
			},
		};
	}
	case PREVIEW_FORMULA_SUCCESS: {
		return {
			...state,
			formula: {
				[action.formulaId]: {
					...state.formula[action.formulaId],
					data: {
						...state.formula[action.formulaId]?.data,
						preview: {
							...state.formula[action.formulaId]?.data?.preview,
							isResolving: false,
							result: mapPreviewResult(action.payload),
							error: null,
						},
					},
				},
			},
		};
	}
	case PREVIEW_FORMULA_API_ERROR: {
		return {
			...state,
			formula: {
				...state.formula,
				[action.formulaId]: {
					...state.formula[action.formulaId],
					data: {
						...state.formula[action.formulaId].data,
						preview: {
							...state.formula[action.formulaId].data.preview,
							isResolving: false,
							error: action.error,
						},
					},
				},
			},
		};
	}
	case LOAD_EDITOR_PARAMETERS: {
		return {
			...state,
			loadingEditorParameters: true,
		};
	}
	case LOAD_EDITOR_PARAMETERS_SUCCESS: {
		return {
			...state,
			loadingEditorParameters: false,
			editorParameters: action.payload,
		};
	}
	case LOAD_EDITOR_PARAMETERS_API_ERROR: {
		return {
			...state,
			loadingEditorParameters: false,
			editorError: action.error,
		};
	}
	default:
		return state;
	}
};

export { formulas };
