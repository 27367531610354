import { mapGroupToApiModel } from './reducers';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import { fetchFormulaDrilldownRenderRequest } from '../reportGroupFormula/actions';

export const FETCH_RENDER_ALL = 'plecto/reportGroup/FETCH_RENDER_ALL';
export const FETCH_RENDER_ALL_SUCCESS = 'plecto/reportGroup/FETCH_RENDER_ALL_SUCCESS';
export const FETCH_RENDER_ALL_API_ERROR = 'plecto/reportGroup/FETCH_RENDER_ALL_API_ERROR';
export const FETCH_RENDER = 'plecto/reportGroup/FETCH_RENDER';
export const FETCH_RENDER_SUCCESS = 'plecto/reportGroup/FETCH_RENDER_SUCCESS';
export const FETCH_RENDER_API_ERROR = 'plecto/reportGroup/FETCH_RENDER_API_ERROR';

export const FETCH_RENDER_PAGE = 'plecto/reportGroup/FETCH_RENDER_PAGE';
export const FETCH_RENDER_PAGE_SUCCESS = 'plecto/reportGroup/FETCH_RENDER_PAGE_SUCCESS';
export const FETCH_RENDER_PAGE_API_ERROR = 'plecto/reportGroup/FETCH_RENDER_PAGE_API_ERROR';

export const fetchReportGroupRenders = (reportGroupId) => {
	return (dispatch, getState) => {
		dispatch(fetchReportGroupRenderRequest(reportGroupId));
		const formulasWithDrilldowns = getFormulasWithDrilldowns(reportGroupId, getState());
		for (const formulaId of formulasWithDrilldowns) {
			dispatch(fetchFormulaDrilldownRenderRequest(formulaId));
		}
	};
};

function getFormulasWithDrilldowns(reportGroupId, state) {
	const { reportGroupsById, reportGroupFormulasById } = state;
	const formulas = reportGroupsById[reportGroupId].reportGroupFormulaIds;
	const hasDrilldowns = _filter(formulas, formulaId => _get(reportGroupFormulasById[formulaId], 'appliedDimension'));
	return hasDrilldowns;
}

export const fetchAllReportGroupRenders = (reportGroupId) => ({
	type: FETCH_RENDER_ALL,
	reportGroupId
});

export const fetchReportGroupRenderRequest = (reportGroupId) => ({
	type: FETCH_RENDER,
	reportGroupId
});

export const fetchReportGroupRenderSuccess = (reportGroupId, payload) => ({
	type: FETCH_RENDER_SUCCESS,
	payload,
	reportGroupId
});

export const fetchReportGroupRenderFailure = (reportGroupId, errorMessage) => ({
	type: FETCH_RENDER_API_ERROR,
	reportGroupId,
	errorMessage
});

export const fetchReportGroupRenderAllSuccess = (reportGroupId, payload) => ({
	type: FETCH_RENDER_ALL_SUCCESS,
	payload,
	reportGroupId
});

export const fetchReportGroupRenderAllFailure = (reportGroupId, errorMessage) => ({
	type: FETCH_RENDER_ALL_API_ERROR,
	reportGroupId,
	errorMessage
});

export const fetchReportGroupRendersPage = (reportGroupId, page) => {
	return (dispatch, getState) => {
		const state = getState();
		const { pagination } = state.reportGroupsById[reportGroupId] || {};
		if (pagination && pagination.renderedPages && pagination.renderedPages.indexOf(page) < 0) {
			dispatch(fetchReportGroupRenderPageRequest(reportGroupId, page));
		}

		const formulasWithDrilldowns = getFormulasWithDrilldowns(reportGroupId, state);
		for (const formulaId of formulasWithDrilldowns) {
			const formulaState = state.reportGroupFormulasById[formulaId];
			const renderedDrilldownPages = formulaState ? formulaState.renderedDrilldownPages : [];
			if (!renderedDrilldownPages || renderedDrilldownPages.indexOf(page) < 0) {
				dispatch(fetchFormulaDrilldownRenderRequest(formulaId, page));
			}
		}
	};
};

export const fetchReportGroupRenderPageRequest = (reportGroupId, page) => ({
	type: FETCH_RENDER_PAGE,
	reportGroupId,
	page
});

export const fetchReportGroupRenderPageSuccess = (reportGroupId, page, payload) => ({
	type: FETCH_RENDER_PAGE_SUCCESS,
	page,
	payload,
	reportGroupId
});

export const fetchReportGroupRenderPageFailure = (reportGroupId, page, errorMessage) => ({
	type: FETCH_RENDER_PAGE_API_ERROR,
	reportGroupId,
	page,
	errorMessage
});

export const ADD = 'plecto/reportGroup/ADD';
export const ADD_SUCCESS = 'plecto/reportGroup/ADD_SUCCESS';
export const ADD_API_ERROR = 'plecto/reportGroup/ADD_API_ERROR';

export const createReportGroup = (reportId, group) => ({
	type: ADD,
	reportId,
	group
});

export const createReportGroupSuccess = (reportId, payload) => ({
	type: ADD_SUCCESS,
	payload,
	reportId
});

export const createReportGroupFailure = (reportId, error) => ({
	type: ADD_API_ERROR,
	reportId,
	error
});

export const UPDATE = 'plecto/reportGroup/UPDATE';
export const UPDATE_SUCCESS = 'plecto/reportGroup/UPDATE_SUCCESS';
export const UPDATE_API_ERROR = 'plecto/reportGroup/UPDATE_API_ERROR';

export const saveReportGroup = (reportGroupId, group, prefetchRenders) => ({
	type: UPDATE,
	reportGroupId,
	group,
	prefetchRenders
});

export const saveReportGroupSuccess = (reportGroupId, prefetchRenders, payload) => ({
	type: UPDATE_SUCCESS,
	payload,
	prefetchRenders,
	reportGroupId
});

export const saveReportGroupFailure = (reportGroupId, prefetchRenders, error) => ({
	type: UPDATE_API_ERROR,
	reportGroupId,
	prefetchRenders,
	error
});

export const updateReportGroupWithProps = (reportGroupId, changes, prefetchRenders) => (dispatch, getState) => {
	const group = getState().reportGroupsById[reportGroupId];
	const apiGroup = mapGroupToApiModel({ ...group, ...changes, timePeriodOptions: {
		...group.timePeriodOptions,
		...(changes.timePeriodOptions || {})
	}});
	dispatch(saveReportGroup(reportGroupId, apiGroup, prefetchRenders));
};

export const DELETE = 'plecto/reportGroup/DELETE';
export const DELETE_SUCCESS = 'plecto/reportGroup/DELETE_SUCCESS';
export const DELETE_API_ERROR = 'plecto/reportGroup/DELETE_API_ERROR';

export const deleteReportGroup = (reportGroupId) => ({
	type: DELETE,
	reportGroupId,
});

export const deleteReportGroupSuccess = (reportGroupId) => ({
	type: DELETE_SUCCESS,
	reportGroupId
});

export const deleteReportGroupFailure = (reportGroupId, error) => ({
	type: DELETE_API_ERROR,
	reportGroupId,
	error
});

export const CHANGE_FORMULA_ORDER = 'plecto/reportGroup/CHANGE_FORMULA_ORDER';
export const CHANGE_FORMULA_ORDER_SUCCESS = 'plecto/reportGroup/CHANGE_FORMULA_ORDER_SUCCESS';
export const CHANGE_FORMULA_ORDER_API_ERROR = 'plecto/reportGroup/CHANGE_FORMULA_ORDER_API_ERROR';

export const changeReportGroupFormulaOrders = (reportGroupId, orderedReportGroupFormulaIds) => ({
	type: CHANGE_FORMULA_ORDER,
	reportGroupId,
	orderedReportGroupFormulaIds
});

export const changeReportGroupFormulaOrdersSuccess = (reportGroupId, orderedReportGroupFormulaIds, payload) => ({
	type: CHANGE_FORMULA_ORDER_SUCCESS,
	reportGroupId,
	orderedReportGroupFormulaIds,
	payload
});

export const changeReportGroupFormulaOrdersFailure = (reportGroupId, orderedReportGroupFormulaIds, error) => ({
	type: CHANGE_FORMULA_ORDER_API_ERROR,
	reportGroupId,
	orderedReportGroupFormulaIds,
	error
});

export const COPY = 'plecto/reportGroup/COPY';
export const COPY_SUCCESS = 'plecto/reportGroup/COPY_SUCCESS';
export const COPY_API_ERROR = 'plecto/reportGroup/COPY_API_ERROR';

export const copyReportGroup = (reportGroupId) => ({
	type: COPY,
	reportGroupId
});

export const copyReportGroupSuccess = (reportGroupId, payload) => ({
	type: COPY_SUCCESS,
	payload,
	reportGroupId
});

export const copyReportGroupFailure = (reportGroupId, errorMessage) => ({
	type: COPY_API_ERROR,
	reportGroupId,
	errorMessage
});

export const PREVIEW = 'plecto/reportGroup/PREVIEW';
export const PREVIEW_WITHOUT_DATA = 'plecto/reportGroup/PREVIEW_WITHOUT_DATA';
// eslint-disable-next-line max-params
export const initPreview = (reportGroupId, previewId, previewSettings, previewRawSettings, allPages, downloadData) => 
	(dispatch, getState) => {
		const group = getState().reportGroupsById[reportGroupId];
		if (downloadData) {
			return dispatch({
				type: PREVIEW,
				reportGroupId,
				previewId,
				previewRawSettings,
				previewSettings: mapGroupToApiModel({ ...group, ...previewSettings, timePeriodOptions: {
					...group.timePeriodOptions,
					...previewSettings.timePeriodOptions || {},
				}}),
				allPages,
			});
		} else {
			return dispatch({
				type: PREVIEW_WITHOUT_DATA,
				reportGroupId,
				previewSettings: mapGroupToApiModel({ ...group, ...previewSettings, timePeriodOptions: {
					...group.timePeriodOptions,
					...previewSettings.timePeriodOptions || {},
				}}),
			});
		}
	};

export const PREVIEW_SUCCESS = 'plecto/reportGroup/PREVIEW_SUCCESS';
export const PREVIEW_API_ERROR = 'plecto/reportGroup/PREVIEW_API_ERROR';

export const previewSuccess = (reportGroupId, previewId, allPages, payload) => ({
	type: PREVIEW_SUCCESS,
	previewId,
	payload,
	reportGroupId,
	allPages
});

export const previewFailure = (reportGroupId, previewId, allPages, errorMessage) => ({
	type: PREVIEW_API_ERROR,
	previewId,
	reportGroupId,
	errorMessage,
	allPages
});

export const DISMISS_PREVIEW = 'plecto/reportGroup/DISMISS_PREVIEW';
export const dismissPreview = (reportGroupId) => ({
	type: DISMISS_PREVIEW,
	reportGroupId
});
