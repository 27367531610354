import { FETCH, FETCH_SUCCESS } from './actions';

const writtenUpdateTemplates = (state = { isFetching: false, writtenUpdateTemplates: [] }, action) => {
	switch (action.type) {
	case FETCH:
		return {
			...state,
			isFetching: true,
		};
	case FETCH_SUCCESS: {
		return {
			...state,
			writtenUpdateTemplates: action.payload,
			isFetching: false,
		};
	}
	default:
		return state;
	}
};

export { writtenUpdateTemplates };
