import React from 'react';
import classNames from 'classnames';
import DateInput from '../dateInput/DateInput';
import { Tooltip } from '../tooltip/Tooltip';

const rangeCssClass = 'rdrRangeInput';

export default function RangeInput({ date, focused, onChange, error }) {
	return <Tooltip message={error} placement="bottom" forceShow={error} forceShowDelay={0}>
		<div className={`${rangeCssClass}-container`}>
			<DateInput
				className={classNames(rangeCssClass,
					'form-control',
					{
						[`${rangeCssClass}--focused`]: focused && !error,
						[`${rangeCssClass}--has-error`]: error,
					})
				}
				value={date}
				onChange={onChange}
			/>
		</div>
	</Tooltip>;
}
