const numberRegex = /^([+-]?[1-9]\d*|0)$/;

export default function parseValidInteger(value) {
	if (Number.isInteger(value) || (value && value.match && value.match(numberRegex))) {
		const parsed = parseInt(value);
		if (Number.isInteger(parsed)) {
			return parsed;
		}
	}

	return null;
}
