import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Icon } from '../common/icon/Icon';
import { COLORS_BRAND } from '../styles/colors';

const TitlePane = styled.div`
	border-radius: 0;
	flex: 0 0 auto;
	background-color: ${COLORS_BRAND.secondaryLight};
	border-color: transparent;
	color: #d4e0e3;
	padding: 9px 20px 17px 20px;

	&:before,
	&:after {
		content: " "; // 1
		display: table; // 2
	}
	&:after {
		clear: both;
	}
`;

const CloseButtonArea = styled.div`
	float: right;
	line-height: 18px;
	margin-left: 0;
	margin-top: 8px;

	> .btn {
		margin: 0 6px 6px 0;
		&:last-child {
			margin-right: 0;
		}
	}
`;

const CloseButton = styled.button`
	color: #ddd;
	border: none;
	background: none;
	&:hover, &:focus, &:active {
		color: #fff;
	}
`;

const Label = styled.div`
	display: flex;
	align-items: center;
	color: #fff;
	font-size: 16px;
	margin-top: 8px;
	& > .icon {
		color: #fff;
	}
`;

const LabelIcon = styled(Icon)`
	margin-right: 5px;
	font-size: 10px;
`;

const UrlButton = styled.button`
	color: #fff;
	border: none;
	background: none;
	font-size: 10px;
	margin-left: 5px;
`;

export default function Title({ title, icon, url, onClose }) {

	const onOpenUrl = useCallback(() => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
		if (newWindow) newWindow.opener = null;
	}, [url]);

	return <TitlePane className="slide-in-title">
		<CloseButtonArea>
			<CloseButton onClick={onClose}>
				<Icon name='x' />
			</CloseButton>
		</CloseButtonArea>
		<Label>
			{icon && <LabelIcon name={icon} />}
			{title}
			{url && <UrlButton onClick={onOpenUrl}>
				<Icon name='link' />
			</UrlButton>}
		</Label>

	</TitlePane>;
}
