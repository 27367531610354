import { FETCH, FETCH_SUCCESS } from './actions';
import _keyBy from 'lodash/keyBy';
import _pick from 'lodash/pick';

const teams = (state = { isFetching: false, teamsById: {}, isStale: true, }, action) => {
	switch (action.type) {
	case FETCH:
		return {
			...state,
			isFetching: true,
			isStale: false,
		};
	case FETCH_SUCCESS: {
		return {
			...state,
			teamsById: _keyBy(action.payload.map(t => _pick(t, ['uuid', 'name', 'members', 'home_screens'])), 'uuid'),
			isFetching: false,
		};
	}
	default:
		return state;
	}
};

export { teams };
