import React, { useRef, useEffect, ForwardedRef } from 'react';
import Badge from '../badge/Badge';
import classNames from 'classnames';
import { Icon } from '../icon/Icon';

type Props = {
	className?: string,
	onClick?: (args: never) => void,
	iconName: string,
	label?: string,
	badge?: string,
	count?: number,
	disabled?: boolean,
	title?: string,
	useJsTooltip?: boolean,
	tooltipPlacement?: 'auto' | 'top' | 'bottom' | 'left' | 'right',
	isDropdownToggle?: boolean,
	forwardedRef: ForwardedRef<HTMLButtonElement>,
};

function _IconButton({
	className,
	onClick,
	iconName,
	forwardedRef,
	label,
	badge,
	count,
	disabled,
	title,
	useJsTooltip,
	tooltipPlacement,
	isDropdownToggle = false
}: Props) {
	const buttonRef = useRef(null);

	useEffect(() => {
		const buttonEl = buttonRef.current;
		if (useJsTooltip) {
			// @ts-ignore
			$(buttonEl).tooltip({
				container: 'body'
			});
		}
		// @ts-ignore
		return () => {if (useJsTooltip) $(buttonEl).tooltip('destroy');};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <span
		ref={buttonRef}
		className="d-inline-block"
		data-original-title={title}
		title={title}
		data-toggle={useJsTooltip ? 'tooltip' : isDropdownToggle ? 'dropdown' : null}
		data-placement={tooltipPlacement || 'bottom'}
	>
		<button
			ref={forwardedRef}
			type="button"
			className={className || 'btn btn-sm btn-default'}
			style={useJsTooltip && disabled ? { pointerEvents: 'none' } : {}}
			onClick={onClick}
			disabled={disabled}
		>
			<Icon name={iconName} className={classNames({ 'icon-nospace': !label })} />
			{!!count && <span className="btn-tag btn-tag-primary">{count}</span>}
			{label ? <span>{label}</span> : null}
			{badge && <Badge label={badge} />}
		</button>
	</span>;
}

export const IconButton =
	React.forwardRef<HTMLButtonElement, Omit<Props, 'forwardedRef'>>((props: Omit<Props, 'forwardedRef'>, ref) => (
		<_IconButton {...props} forwardedRef={ref} />
	));

IconButton.displayName = 'IconButton';
