import { apiGet, apiDelete, apiPost } from '../../fetch/apiService';

export function fetchFavorites() {
	return apiGet('favorites', { all_organizations: 1 });
}

export function addFavorite(body, memberUuid) {
	return apiPost('favorites', body, { active_member: memberUuid });
}

export function deleteFavorite(memberUuid, uuid) {
	return apiDelete(`favorites/${uuid}`, {}, { active_member: memberUuid });
}
