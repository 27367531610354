import createDimensionKey from '../formula/createDimensionKey';
import _isFinite from 'lodash/isFinite';
export const ADD = 'plecto/reportGroupFormula/ADD';
export const ADD_SUCCESS = 'plecto/reportGroupFormula/ADD_SUCCESS';
export const ADD_API_ERROR = 'plecto/reportGroupFormula/ADD_API_ERROR';

export const createReportGroupFormulaRequest = (
	reportGroupId,
	formula,
	after
) => ({
	type: ADD,
	formula,
	reportGroupId,
	after,
});

export const createReportGroupFormulaSuccess = (
	reportGroupId,
	after,
	payload
) => ({
	type: ADD_SUCCESS,
	reportGroupId,
	payload,
	after,
});

export const createReportGroupFormulaFailure = (
	reportGroupId,
	after,
	error,
	errors
) => ({
	type: ADD_API_ERROR,
	reportGroupId,
	errors,
	error,
});

export const DELETE = 'plecto/reportGroupFormula/DELETE';
export const DELETE_SUCCESS = 'plecto/reportGroupFormula/DELETE_SUCCESS';
export const DELETE_API_ERROR = 'plecto/reportGroupFormula/DELETE_API_ERROR';

export const deleteReportGroupFormulaRequest = (
	reportGroupId,
	reportGroupFormulaId
) => ({
	type: DELETE,
	reportGroupId,
	reportGroupFormulaId,
});

export const deleteReportGroupFormulaSuccess = (
	reportGroupId,
	reportGroupFormulaId
) => ({
	type: DELETE_SUCCESS,
	reportGroupId,
	reportGroupFormulaId,
});

export const deleteReportGroupFormulaFailure = (
	reportGroupId,
	reportGroupFormulaId,
	error
) => ({
	type: DELETE_API_ERROR,
	reportGroupId,
	reportGroupFormulaId,
	error,
});

export const FETCH_COMMENTS = 'plecto/reportGroupFormula/FETCH_COMMENTS';
export const FETCH_COMMENTS_SUCCESS =
	'plecto/reportGroupFormula/FETCH_COMMENTS_SUCCESS';
export const FETCH_COMMENTS_API_ERROR =
	'plecto/reportGroupFormula/FETCH_COMMENTS_API_ERROR';

export const fetchReportGroupFormulaCommentsRequest = (
	reportGroupFormulaId
) => ({
	type: FETCH_COMMENTS,
	reportGroupFormulaId,
});

export const fetchReportGroupFormulaCommentsSuccess = (
	reportGroupFormulaId,
	payload
) => ({
	type: FETCH_COMMENTS_SUCCESS,
	payload,
	reportGroupFormulaId,
});

export const fetchReportGroupFormulaCommentsFailure = (
	reportGroupFormulaId,
	error
) => ({
	type: FETCH_COMMENTS_API_ERROR,
	reportGroupFormulaId,
	error,
});

export function shouldFetchReportGroupFormulaComments(
	state,
	reportGroupFormulaId
) {
	const reportGroupFormula =
		state.reportGroupFormulasById[reportGroupFormulaId];
	if (
		!reportGroupFormula.isFetchingComments &&
		!reportGroupFormula.isCreatingComment
	) {
		return true;
	} else {
		return false;
	}
}

export function fetchReportGroupFormulaCommentsIfNeeded(reportGroupFormulaId) {
	return (dispatch, getState) => {
		if (
			shouldFetchReportGroupFormulaComments(getState(), reportGroupFormulaId)
		) {
			return dispatch(
				fetchReportGroupFormulaCommentsRequest(reportGroupFormulaId)
			);
		}
	};
}

export const ADD_COMMENT = 'plecto/reportGroupFormula/ADD_COMMENT';
export const ADD_COMMENT_SUCCESS =
	'plecto/reportGroupFormula/ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_API_ERROR =
	'plecto/reportGroupFormula/ADD_COMMENT_API_ERROR';

export const createReportGroupFormulaCommentRequest = (
	reportGroupFormulaId,
	data
) => ({
	type: ADD_COMMENT,
	reportGroupFormulaId,
	data,
});

export const createReportGroupFormulaCommentSuccess = (
	reportGroupFormulaId,
	payload
) => ({
	type: ADD_COMMENT_SUCCESS,
	payload,
	reportGroupFormulaId,
});

export const createReportGroupFormulaCommentFailure = (
	reportGroupFormulaId,
	error
) => ({
	type: ADD_COMMENT_API_ERROR,
	reportGroupFormulaId,
	error,
});

export const CLEAR_DRILLDOWNS_RENDER =
	'plecto/reportGroupFormula/CLEAR_DRILLDOWNS_RENDER';

export const FETCH_DRILLDOWNS_RENDER =
	'plecto/reportGroupFormula/FETCH_DRILLDOWNS_RENDER';
export const FETCH_DRILLDOWNS_RENDER_SUCCESS =
	'plecto/reportGroupFormula/FETCH_DRILLDOWNS_RENDER_SUCCESS';
export const FETCH_DRILLDOWNS_RENDER_API_ERROR =
	'plecto/reportGroupFormula/FETCH_DRILLDOWNS_RENDER_API_ERROR';

export const initReportGroupDrilldownsRerender = (reportGroupFormulaId) => ({
	type: CLEAR_DRILLDOWNS_RENDER,
	reportGroupFormulaId,
});

export const fetchFormulaDrilldownRenderRequest = (
	reportGroupFormulaId,
	page
) => ({
	type: FETCH_DRILLDOWNS_RENDER,
	reportGroupFormulaId,
	page,
	isPaginated: _isFinite(page),
});

export const fetchFormulaDrilldownRenderSuccess = (
	reportGroupFormulaId,
	page,
	appliedDimension,
	payload
) => ({
	type: FETCH_DRILLDOWNS_RENDER_SUCCESS,
	payload,
	appliedDimension,
	reportGroupFormulaId,
	page,
	isPaginated: _isFinite(page),
});

export const fetchFormulaDrilldownRenderFailure = (
	reportGroupFormulaId,
	page,
	appliedDimension,
	errorMessage
) => ({
	type: FETCH_DRILLDOWNS_RENDER_API_ERROR,
	reportGroupFormulaId,
	appliedDimension,
	errorMessage,
	page,
	isPaginated: _isFinite(page),
});

export const DRILL_DOWN_BY = 'plecto/reportGroupFormula/DRILL_DOWN_BY';

export const drillDownFormula = (reportGroupFormulaId, dimension) => ({
	type: DRILL_DOWN_BY,
	reportGroupFormulaId,
	dimension: {
		key: createDimensionKey(dimension),
		...dimension,
	},
});

export const PIN_DRILL_DOWN = 'plecto/reportGroupFormula/PIN_DRILL_DOWN';

export const pinDrillDown = (reportGroupFormulaId) => ({
	type: PIN_DRILL_DOWN,
	reportGroupFormulaId,
});

export const UNPIN_DRILL_DOWN = 'plecto/reportGroupFormula/UNPIN_DRILL_DOWN';

export const unpinDrillDown = (reportGroupFormulaId) => ({
	type: UNPIN_DRILL_DOWN,
	reportGroupFormulaId,
});

export const CLEAR_DRILL_DOWN = 'plecto/reportGroupFormula/CLEAR_DRILL_DOWN';

export const clearDrillDown = (reportGroupFormulaId) => ({
	type: CLEAR_DRILL_DOWN,
	reportGroupFormulaId,
});

export const SAVE_DRILLDOWN = 'plecto/reportGroupFormula/SAVE_DRILLDOWN';
export const SAVE_DRILLDOWN_SUCCESS =
	'plecto/reportGroupFormula/SAVE_DRILLDOWN_SUCCESS';
export const SAVE_DRILLDOWN_API_ERROR =
	'plecto/reportGroupFormula/SAVE_DRILLDOWN_API_ERROR';

export const saveDrillDown = (reportGroupFormulaId, dimension) => ({
	type: SAVE_DRILLDOWN,
	reportGroupFormulaId,
	dimension,
});

export const saveDrilldownSuccess = (reportGroupFormulaId, dimension) => ({
	type: SAVE_DRILLDOWN_SUCCESS,
	dimension,
	reportGroupFormulaId,
});

export const saveDrilldownFailure = (
	reportGroupFormulaId,
	dimension,
	error
) => ({
	type: SAVE_DRILLDOWN_API_ERROR,
	reportGroupFormulaId,
	dimension,
	error,
});

export const FETCH_AVAILABLE_DIMENSIONS =
	'plecto/reportGroupFormula/FETCH_AVAILABLE_DIMENSIONS';
export const FETCH_AVAILABLE_DIMENSIONS_SUCCESS =
	'plecto/reportGroupFormula/FETCH_AVAILABLE_DIMENSIONS_SUCCESS';
export const FETCH_AVAILABLE_DIMENSIONS_API_ERROR =
	'plecto/reportGroupFormula/FETCH_AVAILABLE_DIMENSIONS_API_ERROR';

export const fetchAvailableFormulaDimensionsRequest = (
	reportGroupFormulaId
) => ({
	type: FETCH_AVAILABLE_DIMENSIONS,
	reportGroupFormulaId,
});

export const fetchAvailableFormulaDimensionsSuccess = (
	reportGroupFormulaId,
	payload
) => ({
	type: FETCH_AVAILABLE_DIMENSIONS_SUCCESS,
	payload,
	reportGroupFormulaId,
});

export const fetchAvailableFormulaDimensionsFailure = (
	reportGroupFormulaId,
	error
) => ({
	type: FETCH_AVAILABLE_DIMENSIONS_API_ERROR,
	reportGroupFormulaId,
	error,
});

export const REQUEST_BACKGROUND_DRILLDOWN_RENDER =
	'plecto/reportGroupFormula/REQUEST_BACKGROUND_DRILLDOWN_RENDER';
export const REQUEST_BACKGROUND_DRILLDOWN_RENDER_SUCCESS =
	'plecto/reportGroupFormula/REQUEST_BACKGROUND_DRILLDOWN_RENDER_SUCCESS';
export const REQUEST_BACKGROUND_DRILLDOWN_RENDER_API_ERROR =
	'plecto/reportGroupFormula/REQUEST_BACKGROUND_DRILLDOWN_RENDER_API_ERROR';

export const requestDrilldownBackgroundRender = (
	reportGroupFormulaId,
	appliedDimension,
	operationId,
	pageNumber
) => ({
	type: REQUEST_BACKGROUND_DRILLDOWN_RENDER,
	reportGroupFormulaId,
	appliedDimension,
	pageNumber,
	operationId,
});

export const drilldownBackgroundRenderSuccess = (
	reportGroupFormulaId,
	appliedDimension,
	operationId,
	pageNumber,
	payload
) => ({
	type: REQUEST_BACKGROUND_DRILLDOWN_RENDER_SUCCESS,
	reportGroupFormulaId,
	pageNumber,
	appliedDimension,
	operationId,
	payload,
});

export const drilldownBackgroundRenderFailure = (
	reportGroupFormulaId,
	appliedDimension,
	operationId,
	pageNumber,
	errorMessage
) => ({
	type: REQUEST_BACKGROUND_DRILLDOWN_RENDER_API_ERROR,
	reportGroupFormulaId,
	appliedDimension,
	pageNumber,
	operationId,
	errorMessage,
});

export const BACKGROUND_RENDER_STATUS =
	'plecto/reportGroupFormula/BACKGROUND_RENDER_STATUS';
export const BACKGROUND_RENDER_STATUS_SUCCESS =
	'plecto/reportGroupFormula/BACKGROUND_RENDER_STATUS_SUCCESS';
export const BACKGROUND_RENDER_STATUS_API_ERROR =
	'plecto/reportGroupFormula/BACKGROUND_RENDER_STATUS_API_ERROR';

export const backgroundRenderStatus = (
	reportGroupFormulaId,
	operationId,
	taskId
) => ({
	type: BACKGROUND_RENDER_STATUS,
	reportGroupFormulaId,
	operationId,
	taskId,
});

export const backgroundRenderStatusSuccess = (
	reportGroupFormulaId,
	operationId,
	taskId,
	payload
) => ({
	type: BACKGROUND_RENDER_STATUS_SUCCESS,
	reportGroupFormulaId,
	operationId,
	taskId,
	payload,
});

export const backgroundRenderStatusFailure = (
	reportGroupFormulaId,
	operationId,
	taskId,
	errorMessage
) => ({
	type: BACKGROUND_RENDER_STATUS_API_ERROR,
	reportGroupFormulaId,
	operationId,
	taskId,
	errorMessage,
});

export const CHECK_RENDER_STATUS =
	'plecto/reportGroupFormula/CHECK_RENDER_STATUS';

export const checkRenderStatus = (
	reportGroupFormulaId,
	operationId,
	pageNumber,
	taskId,
	attemptNo = 0
) => ({
	type: CHECK_RENDER_STATUS,
	reportGroupFormulaId,
	operationId,
	pageNumber,
	taskId,
	attemptNo,
});

export const UPDATE_SORTING = 'plecto/reportGroupFormula/UPDATE_SORTING';
export const UPDATE_SORTING_SUCCESS =
	'plecto/reportGroupFormula/UPDATE_SORTING_SUCCESS';
export const UPDATE_SORTING_API_ERROR =
	'plecto/reportGroupFormula/UPDATE_SORTING_API_ERROR';

export const updateDrilldownSorting = (reportGroupFormulaId, sorting) => ({
	type: UPDATE_SORTING,
	reportGroupFormulaId,
	sorting,
});

export const updateDrilldownSortingSuccess = (
	reportGroupFormulaId,
	sorting
) => ({
	type: UPDATE_SORTING_SUCCESS,
	sorting,
	reportGroupFormulaId,
});

export const updateDrilldownSortingFailure = (
	reportGroupFormulaId,
	sorting,
	error
) => ({
	type: UPDATE_SORTING_API_ERROR,
	reportGroupFormulaId,
	sorting,
	error,
});
