import { useCallback, useState } from 'react';
import Storage from 'store';

export default function useLocalStorageState(key, defaultValue) {
	const [value, setValue] = useState(Storage.get(key, defaultValue));
	const update = useCallback((newValue) => {
		Storage.set(key, newValue);
		setValue(newValue);
	},[key]);

	return [value, update];
}
