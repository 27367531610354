import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

const LABEL_OPACITY = 0.7;

const StyledCheckbox = styled.label`
	display: block;
	cursor: pointer;
	margin-bottom: 0;

	input[type="checkbox"] {
		margin-right: 4px;
	}
`;

const StyledLabel = styled.span`
	font-size: 14px;
	margin-left: 2px;
	color: #666666;
	opacity: ${props => props.disabled ? 1 : LABEL_OPACITY};
`;

export const CheckboxLabel = StyledLabel;

function Checkbox({ className, checked, onToggle, label, image, disabled }) {
	return <StyledCheckbox className="react-form-checkbox">
		<input type="checkbox"
			className={classNames('react-form-checkbox-input', className)}
			checked={checked}
			onChange={onToggle}
			disabled={disabled} />
		{image && <img src={image} className={'react-form-checkbox-image'} />}
		<StyledLabel className={classNames('react-form-checkbox-label', {
			'react-form-checkbox-label--disabled': disabled
		})} disabled>{label}</StyledLabel>
	</StyledCheckbox>;
}

Checkbox.propTypes = {
	checked: PropTypes.bool,
	label: PropTypes.string.isRequired,
	className: PropTypes.string,
	onToggle: PropTypes.func.isRequired,
};

export default memo(Checkbox);
