import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Icon } from '../../icon/Icon';
import styled, { css } from 'styled-components';

const S = {};

S.PathButton = styled.span`
	color: #666;

	${props => props.$enabled ? css`
		cursor: pointer;

		&:hover {
			text-decoration: underline;
			color: #262626;
		}
	` : ''}
`;

S.Arrow = styled(Icon)`
	margin: 0 5px;
	color: hsl(0, 0%, 60%);
`;

export default function Breadcrumb({ value, label, goToLevel, isLast }) {
	const onClick = useCallback(() => goToLevel(value), [goToLevel, value]);
	return <>
		<S.PathButton className={classNames('multi-level-dropdown-breadcrumbs-path-btn', {
			'multi-level-dropdown-breadcrumbs-path-btn-enabled': !isLast
		})} onClick={onClick} $enabled={!isLast}>{label}</S.PathButton>
		{!isLast && <S.Arrow className="multi-level-dropdown-breadcrumbs-path-arrow" name="chevron-right" />}
	</>;
}
