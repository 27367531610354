import { apiGet, apiPut, apiDelete, apiPost } from '../../fetch/apiService';

export const fetchRegistrations = dataSource => {
	return apiGet('registrations', { data_source: dataSource });
};

export const addRegistration = body => {
	return apiPost('registrations', body);
};

export const updateRegistration = (path, body) => {
	return apiPut(`registrations/${path}`, body);
};

export const deleteRegistration = path => {
	return apiDelete(`registrations/${path}`);
};
