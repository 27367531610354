import React, { useCallback } from 'react';
import styled from 'styled-components';
import HelpInline from '../helpInline/HelpInline';

const S = {};
S.Container = styled.div`
	display: block;
	&:not(:first-child) {
		margin-top: 0.5em;
	}
`;

S.RadioOption = styled.div`
	display: inline-block;
	cursor: pointer;
	margin-bottom: 0;
	
	input[type="radio"] {
		margin-right: 6px;
		cursor: pointer;

		// safari
		@media screen and (-webkit-min-device-pixel-ratio: 0) {
			height: 13px;
		}
	}
`;

S.Label = styled.span`
	font-size: 14px;
	color: #666666;
`;

S.Help = styled(HelpInline)`
	margin-left: 0.5em;
	vertical-align: baseline;
`;

export function RadiosFieldOptionControl({ label, optionSelected, option, onSelect, help }) {
	const onChangeCallback = useCallback(() => {
		onSelect(option);
	}, [onSelect, option]);

	return <S.Container>
		<S.RadioOption onClick={onChangeCallback}>
			<S.Label>
				<input type="radio" checked={optionSelected} readOnly/>
				{label}
			</S.Label>
		</S.RadioOption>
		{help ? <S.Help text={help} /> : null}
	</S.Container>;
}

export default function RadiosFieldOption({ children }) {
	if (!React.isValidElement(children)) return null;
	return children;
}
