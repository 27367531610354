export const FETCH = 'plecto/report/FETCH';
export const FETCH_SUCCESS = 'plecto/report/FETCH_SUCCESS';
export const FETCH_API_ERROR = 'plecto/report/FETCH_API_ERROR';

export const fetchReport = (reportId) => ({
	type: FETCH,
	reportId
});

export const fetchReportSuccess = (reportId, payload) => ({
	type: FETCH_SUCCESS,
	reportId,
	payload
});

export const fetchReportFailure = (reportId, error) => ({
	type: FETCH_API_ERROR,
	reportId,
	error
});

export const FETCH_ALL = 'plecto/report/FETCH_ALL';
export const FETCH_ALL_SUCCESS = 'plecto/report/FETCH_ALL_SUCCESS';
export const FETCH_ALL_API_ERROR = 'plecto/report/FETCH_ALL_API_ERROR';

export const fetchAllReports = () => ({
	type: FETCH_ALL,
});

export const fetchAllReportsSuccess = (payload) => ({
	type: FETCH_ALL_SUCCESS,
	payload,
});

export const fetchAllReportsFailure = (error) => ({
	type: FETCH_ALL_API_ERROR,
	error,
});

export function shouldFetchReport(state) {
	const report = state.report;
	if (!report) {
		return true;
	} else if (report.isFetching) {
		return false;
	} else {
		return report.isStale;
	}
}

export function fetchReportIfNeeded(reportId) {
	return (dispatch, getState) => {
		if (shouldFetchReport(getState(), reportId)) {
			return dispatch(fetchReport(reportId));
		}
	};
}

export const UPDATE = 'plecto/report/UPDATE';
export const UPDATE_SUCCESS = 'plecto/report/UPDATE_SUCCESS';
export const UPDATE_API_ERROR = 'plecto/report/UPDATE_API_ERROR';

export const saveReportRequest = (reportId, reportSettings) => ({
	type: UPDATE,
	reportId,
	reportSettings
});

export const saveReportSuccess = (reportId, payload) => ({
	type: UPDATE_SUCCESS,
	reportId,
	payload
});

export const saveReportFailure = (reportId, error) => ({
	type: UPDATE_API_ERROR,
	reportId,
	error
});

export const CHANGE_GROUP_ORDER = 'plecto/report/CHANGE_GROUP_ORDER';
export const CHANGE_GROUP_ORDER_SUCCESS = 'plecto/report/CHANGE_GROUP_ORDER_SUCCESS';
export const CHANGE_GROUP_ORDER_API_ERROR = 'plecto/report/CHANGE_GROUP_ORDER_API_ERROR';

export const changeReportGroupOrders = (reportId, orderedReportGroupIds) => ({
	type: CHANGE_GROUP_ORDER,
	reportId,
	orderedReportGroupIds
});

export const changeReportGroupOrdersSuccess = (reportId, orderedReportGroupIds, payload) => ({
	type: CHANGE_GROUP_ORDER_SUCCESS,
	reportId,
	orderedReportGroupIds,
	payload
});

export const changeReportGroupOrdersFailure = (reportId, orderedReportGroupIds, error) => ({
	type: CHANGE_GROUP_ORDER_API_ERROR,
	reportId,
	orderedReportGroupIds,
	error
});

export const ARCHIVE = 'plecto/report/ARCHIVE';
export const ARCHIVE_SUCCESS = 'plecto/report/ARCHIVE_SUCCESS';
export const ARCHIVE_API_ERROR = 'plecto/report/ARCHIVE_API_ERROR';

export const archiveReportRequest = (reportId) => ({
	type: ARCHIVE,
	reportId,
});

export const archiveReportSuccess = (reportId) => ({
	type: ARCHIVE_SUCCESS,
	reportId,
});

export const archiveReportFailure = (reportId, error) => ({
	type: ARCHIVE_API_ERROR,
	reportId,
	error
});

export const RESTORE = 'plecto/report/RESTORE';
export const RESTORE_SUCCESS = 'plecto/report/RESTORE_SUCCESS';
export const RESTORE_API_ERROR = 'plecto/report/RESTORE_API_ERROR';

export const restoreReportRequest = (reportId) => ({
	type: RESTORE,
	reportId,
});

export const restoreReportSuccess = (reportId) => ({
	type: RESTORE_SUCCESS,
	reportId,
});

export const restoreReportFailure = (reportId, error) => ({
	type: RESTORE_API_ERROR,
	reportId,
	error
});

export const DELETE = 'plecto/report/DELETE';
export const DELETE_SUCCESS = 'plecto/report/DELETE_SUCCESS';
export const DELETE_API_ERROR = 'plecto/report/DELETE_API_ERROR';

export const deleteReportRequest = (reportId) => ({
	type: DELETE,
	reportId,
});

export const deleteReportSuccess = (reportId) => ({
	type: DELETE_SUCCESS,
	reportId,
});

export const deleteReportFailure = (reportId, error) => ({
	type: DELETE_API_ERROR,
	reportId,
	error
});
