import classNames from 'classnames';
import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '../../styles/colors';
import SelectedItem from './SelectedItem';
import {
	placeholderFontSize,
	getMaxHeight,
	multiSelectInputSizes,
	itemGap,
	selectorBorder,
	singleItemSize,
	getMinHeight,
	placeholderPadding,
	InputSizes
} from './sizing';
import { ItemInput } from './ItemInput';
import useEditMode from './useEditMode';

const StyledSelectInput = styled.div<{$size: InputSizes, $active?: boolean}>`
	display: flex;
	flex-direction: row;
	white-space: nowrap;
	min-height: ${props => getMinHeight(props.$size)}px;
	padding: ${itemGap}px;
	height: auto;
	max-height: ${props => getMaxHeight(props.$size)}px;
	position: relative;
	cursor: pointer;

	&:hover {
		border: ${selectorBorder}px solid ${COLORS.primary};
	}
	${props => props.$active ? css`
      border: ${selectorBorder}px solid ${COLORS.primary};
      box-shadow: 0 0 0 1px ${COLORS.primary};
    ` : ''}
`;

const StyledSelectionItems = styled.div`
	display: flex;
	overflow: auto;
	position: relative;
	flex: 1 auto;
	flex-wrap: wrap;
	gap: ${itemGap}px;
`;

const StyledPlaceholder = styled.span<{$size: InputSizes}>`
	color: #C9CACC;
	text-overflow: ellipsis;
	overflow: hidden;
	padding: ${props => placeholderPadding[props.$size]}px 6px;
	height: ${props => singleItemSize[props.$size]}px;
	align-items: center;
	white-space: nowrap;
	font-size: ${props => placeholderFontSize[props.$size]}px;
`;

type Props = {
	className?: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	selectedItems: Array<any>,
	onRemove: (id: string) => void,
	onClick?: (e: React.MouseEvent<HTMLDivElement>) => void,
	placeholder?: string,
	active?: boolean,
	onAdd: (value: string) => void,
	onChange: (id: string, value: string) => void,
	size?: InputSizes,
	editable?: boolean,
};

export function MultiSelectInput({
	className,
	selectedItems,
	onRemove,
	onClick,
	placeholder,
	active,
	onAdd,
	onChange,
	size = multiSelectInputSizes.default,
	editable
}: Props) {
	const selection = selectedItems.length > 0;

	const { editMode, startEdit, endEdit } = useEditMode();

	const onContainerClick = (e: React.MouseEvent<HTMLInputElement>) => {
		if (editable) {
			startEdit();
		} else {
			onClick?.(e);
		}
	};

	return <StyledSelectInput
		className={classNames(className, 'form-control')}
		onClick={onContainerClick}
		$active={active}
		$size={size}
		onBlur={endEdit}
	>
		{!selection && !editMode ?
			(placeholder ?
				<StyledPlaceholder
					className={'form-control-placeholder'}
					$size={size}>
					{placeholder}
				</StyledPlaceholder>
				: null) :
			<StyledSelectionItems className={'form-control-preview'}>
				{selectedItems.map(item => <SelectedItem
					key={item.id}
					onRemove={onRemove}
					item={item}
					size={size}
					editable={editable}
					onChange={onChange}
					onEdit={endEdit}
				/>)}
				{editMode ? <ItemInput size={size} onEntered={onAdd} /> : null}
			</StyledSelectionItems>}
	</StyledSelectInput>;
}
