import { apiGet, apiPost, apiPut } from '../../fetch/apiService';
import isDecimalSupported from './numberFormat/isDecimalSupported';

export function getFormulas() {
	return apiGet('formulas');
}

export function getFormula(formulaId, signal) {
	return apiGet(`formulas/${formulaId}`, null, { signal });
}

export function saveFormula(formulaId, model, signal) {
	if (model.isNew) {
		return apiPost('formulas', model, null, { signal });
	}
	return apiPut(`formulas/${formulaId}`, model, null, { signal });
}

export function createFormula(formulaId, model) {
	return apiPost(`formulas/${formulaId}`, model);
}

export function resolveFormula(formulaString) {
	return apiPost('resolve-formula',
		{ formula: formulaString },
		null,
		{ internalApi: true }
	);
}

export function parseFormula(formulaString, signal) {
	return apiPost('parse-formula',
		{ format: formulaString, output: 'DecimalOutput' },
		null,
		{ internalApi: true, signal }
	);
}

export function previewFormula(formulaString, period, period_amount, output, decimals) {
	return apiPost('preview-formula',
		{
			format: formulaString,
			period: period,
			period_amount: period_amount,
			output: output,
			decimals: isDecimalSupported(output) ? decimals : 0
		},
		null,
		{ internalApi: true }
	);
}

export function getAvailableDimensions(formulaId) {
	return apiGet(`formulas/${formulaId}/available_dimensions`);
}

export function getEditorParameters() {
	return apiGet('formula-editor-parameters',
		null,
		{ internalApi: true }
	);
}
