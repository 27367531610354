import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import Modal from './Modal';
import { PaneTitle } from './PaneTitle';
import { StateClasses, modalStateClasses, modalStates } from './modalStates';
import { COLORS, COLORS_GRAY, COLORS_MODAL } from '../../styles/colors';
import { FONT_SIZES } from '../../styles/typography';
import { darken } from 'polished';
import ModalContext from './ModalContext';

const DARKEN_COEFFICIENT = 0.2;

const StyledModalContent = styled.div`
	position: relative;
	background-color: ${COLORS.light};
	border: 1px solid ${COLORS_GRAY['700']};
	border: 1px solid rgba(0,0,0,0.2);
	border-radius: 6px;
	-webkit-box-shadow: 0 5px 30px rgb(0 0 0 / 50%);
	box-shadow: 0 5px 30px rgb(0 0 0 / 50%);
	background-clip: padding-box;
	outline: 0;
	padding: 0;
	font-size: ${FONT_SIZES.big};

	@media (min-width: 768px) {
		& {
			box-shadow: 0 10px 100px rgb(0 0 0 / 50%);
		}
	}
`;

const StyledPaneTitle = styled(PaneTitle)<{ $state?: StateClasses }>`
	${props => props.blankMode || props.$state === modalStates.default ? css`
	background-color: ${COLORS.light};
	& .modal-close-btn {
		color: ${COLORS_MODAL.title};
		&:focus,
		&:hover,
		&:active {
			color: ${darken(DARKEN_COEFFICIENT, COLORS_MODAL.title)};
		}
	}
	` : ''}
	${props => props.blankMode ? css`
	padding-bottom: 0;
	border: none;
	` : ''}
	${props => props.$state === modalStates.default ? css`
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	` : ''}
`;

type Props = {
	className?: string,
	badge?: string,
	size?: string,
	onClose: () => void,
	title?: JSX.Element | string,
	icon?: string,
	hidden?: boolean,
	blankMode?: boolean,
	forceTop?: boolean,
	closeOnBackdropClick?: boolean,
	state?: StateClasses,
};

export function PaneModal({
	className,
	badge,
	size,
	onClose,
	title,
	icon,
	hidden: initHidden = false,
	blankMode,
	forceTop,
	children,
	closeOnBackdropClick,
	state
}: PropsWithChildren<Props>) {

	const [hidden, setHidden] = useState(initHidden);

	const onHide = useCallback(() => {
		setHidden(true);
	}, []);

	const onShow = useCallback(() => {
		setHidden(false);
	}, []);

	const context = useMemo(() => ({
		hide: onHide,
		show: onShow
	}), [onHide, onShow]);

	return <ModalContext.Provider value={context}>
		<Modal
			className={className}
			size={size}
			hidden={hidden}
			onClose={onClose}
			closeOnBackdropClick={closeOnBackdropClick}
			forceTop={forceTop}
		>
			<StyledModalContent className="modal-content">
				<StyledPaneTitle
					$state={state}
					blankMode={blankMode}
					className={state ? modalStateClasses[state] : modalStateClasses.secondaryLight}
					onClose={onClose}
					badge={badge}
					title={title}
					icon={icon}
				/>
				{children}
			</StyledModalContent>
		</Modal>
	</ModalContext.Provider>;
}
