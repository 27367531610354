// https://stackoverflow.com/a/37285344/15563490
export default function ensureInView(container, element) {
	if (container && element) {
		//Determine container top and bottom
		const containerTop = container.scrollTop;
		const containerBottom = containerTop + container.clientHeight;

		//Determine element top and bottom
		const elementTop = element.offsetTop;
		const elementBottom = elementTop + element.clientHeight;

		//Check if out of view
		if (elementTop < containerTop) {
			container.scrollTop -= (containerTop - elementTop);
		}
		else if (elementBottom > containerBottom) {
			container.scrollTop += (elementBottom - containerBottom);
		}
	}
}
