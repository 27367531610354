import React from 'react';
import styled from 'styled-components';

const COLOR = '#CDD0D4';
const ACTIVE_COLOR = '#000000';
const DOT_SIZE = 10;
const WIDTH = 2;
const LINE_OFFSET = (DOT_SIZE - WIDTH) / 2;

const Container = styled.div`
  position: relative;
  margin: 0 auto;
`;

const StyledTimeline = styled.div`
  &::after {
	content: '';
	position: absolute;
	width: ${WIDTH}px;
	background-color: ${COLOR};
	left: ${LINE_OFFSET}px;
	right: auto;
	top: ${DOT_SIZE}px;
	bottom: ${DOT_SIZE}px;
  }
`;

export const TimelineItem = styled.div`
  padding-left: 22px;
  position: relative;

  &::after {
	content: '';
	position: absolute;
	background-color: ${props => props.active ? ACTIVE_COLOR : COLOR};
	border-radius: 50%;
	width: ${DOT_SIZE}px;
	height: ${DOT_SIZE}px;
	left: 0;
	top: 5px;
	z-index: 1;
  }

  & + & {
	margin-top: 8px;
  }
`;

export const TimelineItemTitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
`;

export const TimelineItemBody = styled.div`
  margin-top: 8px;

  &::after {
	content: '';
	position: absolute;
	width: ${WIDTH}px;
	background-color: ${COLOR};
	left: ${LINE_OFFSET}px;
	right: auto;
	top: ${DOT_SIZE}px;
	bottom: 0;
  }
`;

export default function Timeline({ children, className }) {
	return <Container className={className}>
		<StyledTimeline>{children}</StyledTimeline>
	</Container>;
}
