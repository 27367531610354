import { relativeModes, modes, periods, granularity, weekDays, getWeekDaysOrdered } from './constants';
import moment from 'moment-timezone';
import { fillWithDefaults } from '../blocks/defaultOptions';
import _padStart from 'lodash/padStart';
import { parseHours } from './endDateValidator';
import { getEndOfDay, getStartOfDay } from './dateUtils';
import { DAYS_IN_A_WEEK, HOURS_IN_A_DAY, MINUTES_IN_AN_HOUR } from '../constants';

const END_HOUR = 24;

export function mapToTimePeriodApiModel(timePeriodOptions) {
	switch (timePeriodOptions.mode) {
	case modes.allTime: 
		return mapAllTimeMode(timePeriodOptions);
	case modes.custom: 
		return mapCustomDates(timePeriodOptions);
	case modes.relative:
		return mapToTimePeriodRelativeModel(timePeriodOptions);
	}
}

export function mapToTimePeriodRelativeModel({ relativeStartDate, relativeEndDate, timeRange, offset }) {
	let start = relativeStartDate;

	const { startingOnDay, startingOnMonth } = start;

	let end = relativeEndDate;
	if (!relativeEndDate.enabled ||
		(relativeStartDate.mode === relativeModes.next && relativeEndDate.mode !== relativeModes.current)) {
		end = { ...relativeStartDate, amount: 1 };
	}
	if (relativeStartDate.mode === relativeModes.next) {
		start = end;
		end = relativeStartDate;
	}
	const offsetAmount = offset.enabled ?
		getAmount(offset.future ? relativeModes.next : relativeModes.previous, offset.amount) :
		0;
	const timeRangeOffsets = { start_shift_amount: 0, end_shift_amount: 0 };
	if (timeRange && timeRange.enabled && start.amount === 1) {
		if (start.period === periods.day && timeRange.fromHours && timeRange.toHours) {
			timeRangeOffsets.start_shift_amount = getHoursAmount(timeRange.fromHours);
			timeRangeOffsets.start_shift_period = granularity.hourly;
			timeRangeOffsets.end_shift_amount =
				getHoursAmount(timeRange.toHours) - END_HOUR; //when choosing time, we want to exclude the end hour
			timeRangeOffsets.end_shift_period = granularity.hourly;
		}

		if (start.period === periods.week && timeRange.fromDay && timeRange.toDay) {
			const startShift = getWeekAmount(timeRange.fromDay, startingOnDay);
			const endShift =
				getWeekAmount(timeRange.toDay, startingOnDay)
				- (DAYS_IN_A_WEEK - 1); //when choosing days, we want to include the boundaries days
			if (startShift !== 0 || endShift !== 0) {
				timeRangeOffsets.start_shift_amount = startShift;
				timeRangeOffsets.start_shift_period = periods.day;
				timeRangeOffsets.end_shift_amount = endShift;
				timeRangeOffsets.end_shift_period = periods.day;
			}
		}
	}

	return {
		start_amount: getAmount(start.mode, start.amount),
		start_period: start.period,
		end_period: end.period,
		end_amount: getAmount(end.mode, end.amount),
		total_shift_amount: offsetAmount,
		total_shift_period: offset.period,
		starting_on_day: startingOnDay,
		starting_on_month: startingOnMonth,
		concrete_start_date: null,
		concrete_end_date: null,
		...timeRangeOffsets
	};
}

function getHoursAmount(hoursString) {
	const { hours, minutes } = parseHours(hoursString);
	return hours + (minutes / MINUTES_IN_AN_HOUR);
}

function getWeekAmount(weekDay, startingOnDay) {
	return getWeekDaysOrdered(startingOnDay).indexOf(weekDay);
}

const allTimePeriod = 'all_time';
export function mapAllTimeMode({ allTimeEndDate }) {
	return {
		start_amount: 0,
		start_period: allTimePeriod,
		start_shift_amount: 0,
		end_period: allTimeEndDate.enabled ? periods.day : allTimePeriod,
		end_amount: 0,
		end_shift_amount: 0,
		concrete_start_date: null,
		concrete_end_date: null,
	};
}

function mapCustomDates({ customDate }) {
	return {
		concrete_start_date: convertConcreteDate(getStartOfDay(customDate.startDate)),
		concrete_end_date: convertConcreteDate(getEndOfDay(customDate.endDate)),
	};
}

function convertConcreteDate(date) {
	return moment(date).format('YYYY-MM-DDTHH:mm:ss');
}

function getAmount(mode, amount) {
	return mode === relativeModes.previous ? -amount : (mode === relativeModes.current ? 0 : amount);
}

function convertFromConcreteDate(dateString) {
	const date = new Date(dateString + 'Z'); // Z to treat it as UTC
	date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); // make sure we are on 0 in browser time
	return date;
}

function convertAmountBack(amount) {
	amount = amount || 0;
	const mode = amount > 0 ? relativeModes.next : (amount < 0 ? relativeModes.previous : relativeModes.current);
	amount = mode === relativeModes.current ? 1 : (mode === relativeModes.previous ? -amount : amount); 
	return {
		amount,
		mode 
	};
}

export function mapFromTimePeriodApiModel(model) {
	let options = {};
	if (model.concrete_start_date && model.concrete_end_date) {
		options = {
			mode: modes.custom,
			customDate: {
				startDate: convertFromConcreteDate(model.concrete_start_date),
				endDate: convertFromConcreteDate(model.concrete_end_date)
			}
		};
	} else if (model.start_period === allTimePeriod) {
		const isEndAllTime = model.end_period === allTimePeriod;
		options = {
			mode: modes.allTime,
			allTimeEndDate: {
				enabled: !isEndAllTime,
			}
		};
	} else if (model.start_period && model.end_period) {
		let start = { period: model.start_period, ...convertAmountBack(model.start_amount) };
		let end = { period: model.end_period, ...convertAmountBack(model.end_amount) };
		let endEnabled = true;
		if (start.mode === end.mode) {
			if (start.mode === relativeModes.next) {
				endEnabled = false; 
			} else if (start.period === end.period) {
				if (end.mode === relativeModes.previous && end.amount === 1) {
					endEnabled = false;
				} else if (start.mode === relativeModes.current && start.amount === end.amount) {
					endEnabled = false;
				}
			}
		}

		if (start.mode === relativeModes.next ||
			(end.mode === relativeModes.next && start.mode === relativeModes.current && start.period === end.period)) {
			const temp = start;
			start = end;
			end = temp;
		}

		const timeRange = {
			enabled: false,
			fromDay: weekDays.monday,
			toDay: weekDays.sunday,
			fromHours: '09:00',
			toHours: '17:00'
		};

		const { starting_on_day, starting_on_month } = model;

		if (start.amount === 1 && !endEnabled && (model.start_shift_amount !== 0 || model.end_shift_amount !== 0)) {
			if (start.period === periods.day &&
				model.start_shift_period === granularity.hourly &&
				model.end_shift_period === granularity.hourly) {
				timeRange.enabled = true;
				timeRange.fromHours = getHoursFromAmount(model.start_shift_amount);
				timeRange.toHours = getHoursFromAmount(model.end_shift_amount + HOURS_IN_A_DAY);
			}

			if (start.period === periods.week &&
				model.start_shift_period === periods.day &&
				model.end_shift_period === periods.day) {
				timeRange.enabled = true;
				const orderedDays = getWeekDaysOrdered(starting_on_day);
				timeRange.fromDay = orderedDays[model.start_shift_amount];
				timeRange.toDay = orderedDays[model.end_shift_amount + (DAYS_IN_A_WEEK - 1)];
			}
		}

		const offset = {
			enabled: false,
			future: true,
			period: periods.year,
			amount: 1
		};

		if (model.total_shift_amount !== 0 && model.total_shift_period) {
			offset.enabled = true;
			offset.amount = Math.abs(model.total_shift_amount);
			offset.future = model.total_shift_amount > 0;
			offset.period = model.total_shift_period;
		}

		start.startingOnDay = starting_on_day;
		start.startingOnMonth = starting_on_month;

		options = {
			mode: modes.relative,
			relativeStartDate: start,
			relativeEndDate: {
				enabled: endEnabled,
				...end
			},
			timeRange,
			offset
		};
	}

	return fillWithDefaults(options);
}

function getHoursFromAmount(amount) {
	return `${_padStart(parseInt(amount), 2, '0')}:00`;
}
