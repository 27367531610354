import styled from 'styled-components';
import { InputLabel } from './input-label';
import { Input } from './input';
import { TextArea } from './text-area';
import { HelpText } from './help-text';
import { FieldLabel } from './field-label';
import React from 'react';
import { ErrorBlock } from './error-block';
import { withCssSelector } from '../shared/withCssSelector';

const StyledFormField = styled.div`
	display: flex;
	flex-direction: column;

	& + & {
		margin-top: 20px;
	}

	& > ${FieldLabel.cssSelector} + ${InputLabel.cssSelector},
	& > ${FieldLabel.cssSelector} + ${Input.cssSelector},
	& > ${FieldLabel.cssSelector} + ${TextArea.cssSelector},
	& > ${Input.cssSelector} + ${HelpText.cssSelector},
	& > ${InputLabel.cssSelector} + ${InputLabel.cssSelector},
	& > ${InputLabel.cssSelector} + ${HelpText.cssSelector},
	& > ${TextArea.cssSelector} + ${HelpText.cssSelector},
	& > ${HelpText.cssSelector} + ${InputLabel.cssSelector},
	& > ${HelpText.cssSelector} + ${Input.cssSelector},
	& > ${HelpText.cssSelector} + ${TextArea.cssSelector},
	& > ${ErrorBlock.cssSelector} + ${TextArea.cssSelector},
	& > ${ErrorBlock.cssSelector} + ${Input.cssSelector} {
		margin-top: 5px;
	}

`;

export type FormFieldProps = React.ComponentPropsWithoutRef<'div'>

export const FormField = withCssSelector(React.forwardRef<HTMLDivElement, FormFieldProps>(
	function FormField(props, ref) {
		return <StyledFormField ref={ref}{...props} />;
	}
), StyledFormField.toString());
