import { apiGet, apiPost, apiPut } from '../../fetch/apiService';

export function getAnnouncement(id) {
	return apiGet(`announcements/${id}`);
}

export function saveAnnouncement(model) {
	if (model.uuid) {
		return apiPut(`announcements/${model.uuid}`, model);
	} else {
		return apiPost('announcements', model);

	}
}

export function sendAnnouncementImmediately(id) {
	return apiPut(`announcements/${id}/send_now`);
}

export function scheduleAnnouncement(id) {
	return apiPut(`announcements/${id}/schedule`);
}

export function cancelAnnouncement(id) {
	return apiPut(`announcements/${id}/cancel`);
}

export function endAnnouncement(id) {
	return apiPut(`announcements/${id}/end`);
}

