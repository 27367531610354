import { takeLatest } from '@redux-saga/core/effects';
import * as datasourcesActions from './actions';
import * as api from './api';
import apiSagaGenerator from '../../saga/apiSagaGenerator';
import { takeEvery } from 'redux-saga/effects';

const datasoucresSagas = [
	takeLatest(datasourcesActions.FETCH, 
		apiSagaGenerator()
			.forApi(api.fetchDatasources)
			.forSuccess(datasourcesActions.fetchDatasourcesSuccess)
			.forError(datasourcesActions.fetchDatasourcesError)
			.includeErrorResult(result => result.detail)
			.withMessage('Error getting data sources')
			.generate()	
	),
	takeLatest(datasourcesActions.FETCH_ALL,
		apiSagaGenerator()
			.forApi(api.fetchAllDatasources)
			.forSuccess(datasourcesActions.fetchAllDatasourcesSuccess)
			.forError(datasourcesActions.fetchAllDatasourcesError)
			.includeErrorResult(result => result.detail)
			.withMessage('Error getting data sources')
			.generate()
	),
	takeEvery(datasourcesActions.FETCH_DATASOURCE,
		apiSagaGenerator()
			.forApi(api.fetchDatasource)
			.withArguments(action => [action.dataSourceId])
			.forSuccess(datasourcesActions.fetchDataSourceSuccess)
			.forError(datasourcesActions.fetchDataSourceError)
			.includeErrorResult(result => result.detail)
			.withMessage('Error getting data source')
			.generate()
	),
];

export default datasoucresSagas;
