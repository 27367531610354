import {
	FETCH,
	FETCH_SUCCESS,
	FETCH_ALL,
	FETCH_ALL_SUCCESS,
	FETCH_ALL_API_ERROR,
	UPDATE_SUCCESS, CHANGE_GROUP_ORDER,
	CHANGE_GROUP_ORDER_SUCCESS,
	UPDATE,
	UPDATE_API_ERROR,
	ARCHIVE_SUCCESS,
	RESTORE_SUCCESS,
} from './actions';

import * as reportGroupActions from '../reportGroup/actions';
import _reject from 'lodash/reject';

const report = (state = { isFetching: false, isStale: true, title: '', reportGroupIds: [] }, action) => {
	switch (action.type) {
	case FETCH:
		return {
			...state,
			isFetching: true,
			isStale: false,
		};
	case FETCH_ALL:
		return {
			...state,
			isFetching: true,
		};
	case FETCH_ALL_SUCCESS:
		return {
			...state,
			isFetching: false,
			error: null,
			data: action.payload,
		};
	case FETCH_ALL_API_ERROR:
		return {
			...state,
			isFetching: false,
			error: action.error,
		};
	case UPDATE: {
		return {
			...state,
			isFetching: true,
		};
	}
	case UPDATE_API_ERROR: {
		return {
			...state,
			isFetching: false,
		};
	}
	case FETCH_SUCCESS:
	case UPDATE_SUCCESS:
		return {
			...state,
			isFetching: false,
			isStale: false,
			title: action.payload.title,
			teams: action.payload.teams,
			members: action.payload.members,
			availableFor: action.payload.available_for,
			availableForTeams: action.payload.available_for_teams,
			recipients: action.payload.recipients ? action.payload.recipients : [],
			reportGroupIds: action.payload.report_groups.map(group => group.uuid),
			archived: action.payload.is_disabled
		};
	case ARCHIVE_SUCCESS: {
		return {
			...state,
			archived: true
		};
	}
	case RESTORE_SUCCESS: {
		return {
			...state,
			archived: false
		};
	}
	case CHANGE_GROUP_ORDER:
		return {
			...state,
			reportGroupIds: action.orderedReportGroupIds,
		};
	case CHANGE_GROUP_ORDER_SUCCESS:
		return {
			...state,
			reportGroupIds: action.payload.report_groups.map(group => group.uuid),
		};
	case reportGroupActions.ADD_SUCCESS: {
		return {
			...state,
			reportGroupIds: state.reportGroupIds.concat([action.payload.uuid]),
		};
	}
	case reportGroupActions.COPY_SUCCESS: {
		return {
			...state,
			reportGroupIds: [
				...state.reportGroupIds.slice(0, action.payload.order - 1), 
				action.payload.uuid, 
				...state.reportGroupIds.slice(action.payload.order - 1)],
		};
	}
	case reportGroupActions.DELETE_SUCCESS: {
		return {
			...state,
			reportGroupIds: _reject(state.reportGroupIds, id => id === action.reportGroupId),
		};
	}
	default:
		return state;
	}
};

export { report };
