import { actionPrefix } from '../constants';

export const CREATE_RESULTS = `${actionPrefix}CREATE_RESULTS`;
export const CREATE_RESULTS_SUCCESS = `${actionPrefix}CREATE_RESULTS_SUCCESS`;
export const CREATE_RESULTS_FAILURE = `${actionPrefix}CREATE_RESULTS_FAILURE`;

export const GET_RESULTS = `${actionPrefix}GET_RESULT_ANSWERS`;
export const GET_RESULTS_SUCCESS = `${actionPrefix}GET_RESULT_ANSWERS_SUCCESS`;
export const GET_RESULTS_FAILURE = `${actionPrefix}GET_RESULT_ANSWERS_FAILURE`;

export const GET_SESSION_RESULTS = `${actionPrefix}GET_SESSION_RESULTS`;
export const GET_SESSION_RESULTS_SUCCESS = `${actionPrefix}GET_SESSION_RESULTS_SUCCESS`;
export const GET_SESSION_RESULTS_FAILURE = `${actionPrefix}GET_SESSION_RESULTS_FAILURE`;

export const GET_SESSION_RESULTS_QUESTION_DETAILS = `${actionPrefix}GET_SESSION_RESULTS_QUESTION_DETAILS`;
export const GET_SESSION_RESULTS_QUESTION_DETAILS_SUCCESS =
	`${actionPrefix}GET_SESSION_RESULTS_QUESTION_DETAILS_SUCCESS`;
export const GET_SESSION_RESULTS_QUESTION_DETAILS_FAILURE =
	`${actionPrefix}GET_SESSION_RESULTS_QUESTION_DETAILS_FAILURE`;

export const CREATE_REVIEW = `${actionPrefix}CREATE_REVIEW`;
export const CREATE_REVIEW_SUCCESS = `${actionPrefix}CREATE_REVIEW_SUCCESS`;
export const CREATE_REVIEW_FAILURE = `${actionPrefix}CREATE_REVIEW_FAILURE`;

export const createReview = (id, score, reviewComment, sessionId, questionId) => ({
	type: CREATE_REVIEW,
	id,
	score,
	reviewComment,
	sessionId,
	questionId,
});

export const createReviewSuccess = (id, score, reviewComment, sessionId, questionId) => ({
	type: CREATE_REVIEW_SUCCESS,
	id,
	score,
	reviewComment,
	sessionId,
	questionId,
});

// eslint-disable-next-line max-params
export const createReviewFailure = (id, score, reviewComment, sessionId, questionId, error) => ({
	type: CREATE_REVIEW_FAILURE,
	error,
});

export const createResults = (quizResultId, timeLeft, timedOut) => ({
	type: CREATE_RESULTS,
	quizResultId,
	timeLeft,
	timedOut
});

export const createResultsSuccess = (quizResultId, timeLeft, timedOut, payload) => ({
	type: CREATE_RESULTS_SUCCESS,
	quizResultId,
	payload
});

// eslint-disable-next-line max-params
export const createResultsFailure = (quizResultId, timeLeft, timedOut, payload, error, errors) => ({
	type: CREATE_RESULTS_FAILURE,
	error,
	errors
});

export const getResults = (sessionResultId) => ({
	type: GET_RESULTS,
	sessionResultId
});

export const getResultsSuccess = (sessionResultId, payload) => ({
	type: GET_RESULTS_SUCCESS,
	sessionResultId,
	payload
});

export const getResultsFailure = (sessionResultId, payload, error, errors) => ({
	type: GET_RESULTS_FAILURE,
	error,
	errors
});

export const getSessionResults = (quizId, sessionId) => ({
	type: GET_SESSION_RESULTS,
	quizId,
	sessionId
});

export const getSessionResultsSuccess = (quizId, sessionId, payload) => ({
	type: GET_SESSION_RESULTS_SUCCESS,
	quizId,
	sessionId,
	payload
});

export const getSessionResultsFailure = (quizId, sessionId, payload, error, errors) => ({
	type: GET_SESSION_RESULTS_FAILURE,
	error,
	errors
});

export const getSessionResultsQuestionDetails = (quizId, sessionId, questionId) => ({
	type: GET_SESSION_RESULTS_QUESTION_DETAILS,
	quizId,
	sessionId,
	questionId
});

export const getSessionResultsQuestionDetailsSuccess = (sessionId, questionId, payload) => ({
	type: GET_SESSION_RESULTS_QUESTION_DETAILS_SUCCESS,
	sessionId,
	questionId,
	payload
});

// eslint-disable-next-line max-params
export const getSessionResultsQuestionDetailsFailure = (quizId, sessionId, questionId, payload, error, errors) => ({
	type: GET_SESSION_RESULTS_QUESTION_DETAILS_FAILURE,
	error,
	errors
});

export function fetchAnswersIfNeeded(sessionResultId) {
	return (dispatch, getState) => {
		if (shouldFetchAnswers(getState(), sessionResultId)) {
			return dispatch(getResults(sessionResultId));
		}
	};
}

function shouldFetchAnswers(state) {
	const trainingQuizzes = state.trainingQuizzes;
	return Object.values(trainingQuizzes.quiz.answers).length === 0 && !trainingQuizzes.isFetching;
}
