import { modes, relativeModes, periods, futureModes, granularity, weekDays } from './constants';
import { t } from '../../../../setupTranslations';

export function getRelativeModeName(mode) {
	switch (mode) {
	case relativeModes.current:
		return t('Current');
	case relativeModes.next:
		return t('Next');
	case relativeModes.previous:
		return t('Previous');
	default:
		return mode;
	}
}

export function getModeName(mode) {
	switch (mode) {
	case modes.relative:
		return t('Advanced');
	case modes.allTime:
		return t('All time');
	case modes.custom:
		return t('Fixed');
	default:
		return mode;
	}
}

export function getPeriodName(period) {
	switch (period) {
	case periods.day:
		return t('Day');
	case periods.month:
		return t('Month');
	case periods.month_pairs:
		return t('Month pair');
	case periods.quarter:
		return t('Quarter');
	case periods.week:
		return t('Week');
	case periods.year:
		return t('Year');
	
	default:
		return period;
	}
}

export function getPeriodNamePlural(period) {
	switch (period) {
	case periods.day:
		return t('Days');
	case periods.month:
		return t('Months');
	case periods.month_pairs:
		return t('Month pairs');
	case periods.quarter:
		return t('Quarters');
	case periods.week:
		return t('Weeks');
	case periods.year:
		return t('Years');
	
	default:
		return period;
	}
}

export function getGranularityModeName(mode) {
	switch (mode) {
	case granularity.hourly:
		return t('Hourly');
	case granularity.daily:
		return t('Daily');
	case granularity.monthly:
		return t('Monthly');
	case granularity.quarterly:
		return t('Quarterly');
	case granularity.weekly:
		return t('Weekly');
	case granularity.annually:
		return t('Annually');
	default:
		return mode;
	}
}

export function getFutureModeName(mode) {
	switch (mode) {
	case futureModes.ago:
		return '-';
	case futureModes.inTheFuture:
		return '+';
	default:
		return mode;
	}
}

export function getWeekdayName(weekDay) {
	switch (weekDay) {
	case weekDays.monday:
		return t('Monday');
	case weekDays.tuesday:
		return t('Tuesday');
	case weekDays.wednesday:
		return t('Wednesday');
	case weekDays.thursday:
		return t('Thursday');
	case weekDays.friday:
		return t('Friday');
	case weekDays.saturday:
		return t('Saturday');
	case weekDays.sunday:
		return t('Sunday');
	default:
		return weekDay;
	}
}
