import React from 'react';
import styled from 'styled-components';
import Avatar from './Avatar';
import PropTypes from 'prop-types';
import { FONT_SIZES, FONT_WEIGHT } from '../../../styles/typography';

const Text = styled.span`
  font-style: normal;
  font-size: ${FONT_SIZES.base};
  font-weight: ${FONT_WEIGHT.normal};
  line-height: 17px;
  color: #374151;

  &::before {
	content: open-quote;
  }

  &::after {
	content: close-quote;
  }
`;

const Details = styled.div`
  margin-top: 6px;
`;

const Author = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
  font-size: ${FONT_SIZES.base};
  font-weight: ${FONT_WEIGHT.normal};
`;

const Date = styled.span`
  display: inline-block;
  vertical-align: middle;
  color: #9CA3AF;
  font-size: ${FONT_SIZES.base};
  margin-left: 6px;
`;

export default function AchievementMessage({ text, authorAvatarUrl, authorName, localizedDate, className }) {
	return <div className={className}>
		<Text>{text}</Text>
		<Details>
			<Avatar avatarUrl={authorAvatarUrl} name={authorName}/>
			<Author>{authorName}</Author>
			<Date>{localizedDate}</Date>
		</Details>
	</div>;
}

AchievementMessage.propTypes = {
	text: PropTypes.string.isRequired,
	authorName: PropTypes.string.isRequired,
	localizedDate: PropTypes.string.isRequired,
	authorAvatarUrl: PropTypes.string,
	className: PropTypes.string,
};
