import generateUuid from '../utils/generateUuid';
import { animationDuration, defaultToastShowTime, defaultReleaseToastTime } from './consts';
import _get from 'lodash/get';
import _find from 'lodash/find';

export const TOAST_SHOW = 'TOAST_SHOW';
export const TOAST_HIDE = 'TOAST_HIDE';
export const TOAST_CLOSE = 'TOAST_CLOSE';
export const TOAST_PUT_ON_HOLD = 'TOAST_PUT_ON_HOLD';
export const TOAST_RELEASE = 'TOAST_RELEASE';

const hideTimeouts = {};

export function showError(message, time = defaultToastShowTime) {
	return show('error', message, time);
}

export function showSuccess(message, time = defaultToastShowTime) {
	return show('success', message, time);
}

export function showInfo(message, time = defaultToastShowTime) {
	return show('info', message, time);
}

export function showWarning(message, time = defaultToastShowTime) {
	return show('warning', message, time);
}

export function show(messageType, message, time) {
	return (dispatch) => {
		const uuid = generateUuid();
		dispatch({
			type: TOAST_SHOW,
			payload: {
				uuid,
				messageType,
				message,
				visible: true,
				time,
			}
		});

		hideTimeouts[uuid] = setTimeout(() => {
			dispatch(hide(uuid));
		}, time || defaultToastShowTime);
	};
}

export function putOnHold(uuid) {
	const timeout = hideTimeouts[uuid];
	if (timeout) {
		clearTimeout(timeout);
	}

	return { type: TOAST_PUT_ON_HOLD, uuid };
}

export function release(uuid) {
	return (dispatch, getState) => {
		const toast = _find(_get(getState(),'app.toasts.active', []), { uuid });
		if (toast) {
			const timeout = hideTimeouts[uuid];
			if (timeout) {
				clearTimeout(timeout);
			}
			hideTimeouts[uuid] = setTimeout(() => {
				dispatch(hide(uuid));
			}, toast.time || defaultReleaseToastTime);
			dispatch({
				type: TOAST_RELEASE,
				uuid
			});
		}
	};
}

export function hide(uuid) {
	return (dispatch) => {
		dispatch({
			type: TOAST_HIDE,
			uuid
		});

		delete hideTimeouts[uuid];

		setTimeout(() => {
			dispatch(close(uuid));
		}, animationDuration);
	};
}

export function close(uuid) {
	return { type: TOAST_CLOSE, uuid };
}

export const toastShowActions = {
	show,
	showError,
	showSuccess,
	showInfo,
	showWarning
};
