import React, { useMemo } from 'react';
import styled from 'styled-components';
import _find from 'lodash/find';
import { useTranslation } from 'react-i18next';
import { FilterItem } from './types';

const StyledLabel = styled.div`
  overflow: hidden;
  min-width: 1px;
  font-size: 14px;
  display: flex;
  gap: 6px;
  align-items: center;
`;

const StyledDefaultText = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 auto;
`;

const StyledSelectionText = styled.span`
  white-space: nowrap;
  flex: 0 auto;
`;

type SelectionStatusProps = {
    active: boolean;
    label: string;
    compactLabel?: boolean;
    selection: string[];
    items: FilterItem[];
    hideExtraLabel?: boolean;
}

export function FilterStatus({ active, selection = [], items, label, compactLabel = false }: SelectionStatusProps) {
	const { t } = useTranslation();

	const firstSelected = useMemo(() => {
		return active ? _find(items, (item) => item.id === selection?.[0]) : null;
	}, [active, items, selection]);

	const selectionCount = selection.length - 1;

	const selectionLabel = compactLabel ? t('+ {{count}}', {
		count: selectionCount
	}) : t('+ {{count}} more', {
		count: selectionCount
	});

	return <StyledLabel>
		{active ? <>
			<StyledDefaultText>{firstSelected?.label}</StyledDefaultText>
			{selection && selection.length > 1 ? <StyledSelectionText>{selectionLabel}</StyledSelectionText> : null}
		</> : <StyledDefaultText>{label}</StyledDefaultText>}
	</StyledLabel>;
}
