import styled from 'styled-components';
import PopoverTabButton from '../popoverTabs/PopoverTabButton';
import { SPACING } from '../../styles/spacing';
import { COLORS_BRAND } from '../../styles/colors';

export const PopoverTabButtonToggle = styled(PopoverTabButton)`
	width: 100%;
	display: inline-block;
	.popover-tab-button {
		padding: 4px 10px 4px 12px;
		border: 1px solid #d3d8d8;
		border-radius: 4px;
		background-color: #fff;
		width: 100%;
		text-align: left;

		&:hover {
			border-color: ${COLORS_BRAND.primary};
		}
		.multi-level-dropdown-toggle-icon {
			float: right;
			margin-right: ${SPACING.xs};
		}
	}

	button.popover-tab-button-open {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	button.popover-tab-top-start {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
`;
