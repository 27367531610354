import { apiVersionV3 } from '../../fetch/apiService';

const PREFIX = `plecto/${apiVersionV3}/achievementTypeIcons/`;

export const CREATE_ACHIEVEMENT_TYPE_ICON = PREFIX + 'CREATE_ACHIEVEMENT_TYPE_ICON';
export const CREATE_ACHIEVEMENT_TYPE_ICON_SUCCESS = PREFIX + 'CREATE_ACHIEVEMENT_TYPE_ICON_SUCCESS';
export const CREATE_ACHIEVEMENT_TYPE_ICON_FAILURE = PREFIX + 'CREATE_ACHIEVEMENT_TYPE_ICON_FAILURE';

export const DELETE_ACHIEVEMENT_TYPE_ICON = PREFIX + 'DELETE_ACHIEVEMENT_TYPE_ICON';
export const DELETE_ACHIEVEMENT_TYPE_ICON_SUCCESS = PREFIX + 'DELETE_ACHIEVEMENT_TYPE_ICON_SUCCESS';
export const DELETE_ACHIEVEMENT_TYPE_ICON_FAILURE = PREFIX + 'DELETE_ACHIEVEMENT_TYPE_ICON_FAILURE';

export const createAchievementTypeIcon = (request) => ({
	type: CREATE_ACHIEVEMENT_TYPE_ICON,
	payload: request,
});

export const createAchievementTypeIconSuccess = (request, response) => ({
	type: CREATE_ACHIEVEMENT_TYPE_ICON_SUCCESS,
	payload: response,
});

export const createAchievementTypeIconFailure = (request, error, errors) => ({
	type: CREATE_ACHIEVEMENT_TYPE_ICON_FAILURE,
	error,
	errors,
});

export const deleteAchievementTypeIcon = (achievementTypeIconId) => ({
	type: DELETE_ACHIEVEMENT_TYPE_ICON,
	payload: achievementTypeIconId,
});

export const deleteAchievementTypeIconSuccess = (achievementTypeIconId) => ({
	type: DELETE_ACHIEVEMENT_TYPE_ICON_SUCCESS,
	payload: achievementTypeIconId,
});

export const deleteAchievementTypeIconFailure = (achievementTypeIconId, error, errors) => ({
	type: DELETE_ACHIEVEMENT_TYPE_ICON_FAILURE,
	payload: achievementTypeIconId,
	error,
	errors,
});
