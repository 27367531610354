import React from 'react';
import { generateMonthNumbers } from '../logic/constants';
import Dropdown from '../../common/dropdown/Dropdown';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const S = {};

S.Container = styled.div`
	display: flex;
`;

S.Label = styled.span`
	color: #666666;
	flex: 0 1 auto;
`;

S.Dropdown = styled(Dropdown)`
	flex: 0 1 5em;
`;

const dayOfMonthOptions = generateMonthNumbers();
export default function MonthStartPoint({ startingOnDay = 1, actions }) {
	const { t } = useTranslation();
	return (
		<S.Container>
			<S.Label className={'mode-selector-label'}>
				{t('Month starts on')}
			</S.Label>
			<S.Dropdown
				onValueChange={actions.changeMonthStartPoint}
				value={startingOnDay}
				options={dayOfMonthOptions}
			/>
		</S.Container>
	);
}
