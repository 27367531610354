import React, { useMemo } from 'react';
import EndDateSelector from '../blocks/EndDateSelector';
import OffsetSelector from '../blocks/OffsetSelector';
import RelativeModeSelector from '../blocks/RelativeModeSelector';
import TimeRangeSelector from '../blocks/TimeRangeSelector';
import { getRelativeModesOptions } from '../logic/constants';
import styled from 'styled-components';
import StartPointOptions from '../blocks/StartPointOptions';
import usePeriodOptions from '../hooks/usePeriodOptions';

const S = {};

S.MainSelector = styled(RelativeModeSelector)`
	border-bottom: 1px solid #D8E0E0;
	padding-bottom: 10px;
`;

export default function AdvancedTab({ startDate, endDate, timeRange, offset, visibility, actions }) {
	const periodOptions = usePeriodOptions();
	const relativeModes = useMemo(() => getRelativeModesOptions(), []);
	return (
		<>
			<S.MainSelector 
				className="time-period-start-mode-selector"
				onModeChange={actions.changeStartMode}
				onPeriodChange={actions.changeStartPeriod} 
				mode={startDate.mode} 
				period={startDate.period} 
				amount={startDate.amount} 
				onAmountChange={actions.changeStartAmount}
				fixedAmount={startDate.fixedAmount}
				modesOptions={relativeModes}
				periodsOptions={periodOptions}
			/>
			<StartPointOptions startDate={startDate} actions={actions} />
			<EndDateSelector relativeEndDate={endDate} actions={actions} disabled={!visibility.customEndDateVisible} />
			<TimeRangeSelector
				period={startDate.period} 
				timeRange={timeRange}
				actions={actions}
				visibility={visibility}
				startingOnDay={startDate.startingOnDay}
				disabled={!visibility.timeRangeVisible}/>
			<OffsetSelector offset={offset} actions={actions} />	
		</>
	);
}
