import { useCallback, useEffect } from 'react';
import _isArray from 'lodash/isArray';

export default function useKeyPressed(keys, action) {
	const callback = useCallback(({ key }) => {
		const keysToCheck = _isArray(keys) ? keys : [keys];
		if (keysToCheck.indexOf(key) >= 0) {
			action();
		}
	}, [keys, action]);
	useEffect(() => {
		window.addEventListener('keydown', callback);
		return () => window.removeEventListener('keydown', callback);
	}, [callback]);
}
