export const FETCH = 'plecto/hueDevices/FETCH';
export const FETCH_SUCCESS = 'plecto/hueDevices/FETCH_SUCCESS';
export const FETCH_API_ERROR = 'plecto/hueDevices/FETCH_API_ERROR';

export const fetchHueDevices = (credentialsId) => ({
	type: FETCH,
	credentialsId
});

export const fetchHueDevicesSuccess = (credentialsId, payload) => ({
	type: FETCH_SUCCESS,
	payload,
	credentialsId,
});

export const fetchHueDevicesFailure = (credentialsId, error) => ({
	type: FETCH_API_ERROR,
	error,
	credentialsId
});

export function shouldFetchHueDevices(state, credentialsId) {
	const hueDevicesState = state.hueDevices[credentialsId] || { isStale: true };
	return !hueDevicesState.isFetching && hueDevicesState.isStale;
}

export function fetchHueDevicesIfNeeded(credentialsId) {
	return (dispatch, getState) => {
		if (shouldFetchHueDevices(getState(), credentialsId)) {
			return dispatch(fetchHueDevices(credentialsId));
		}
	};
}
