import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { modes, relativeModes } from '../logic/constants';
import { getModeName } from '../logic/translations';
import TabButton from '../tabs/TabButton';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const S = {};
S.Modes = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	color: #121212;
	padding: 0;
	border-bottom: 1px solid #e2e6e6;
`;

S.ModeButton = styled(TabButton)`
	padding-top: 1em;
	font-size: 12px;
	> span {
		display: inline-block;
		padding: 0 5px 8px;
	}
`;
function ModeTabsChooser({ currentMode, changeMode, changeToPresetMode, clearPreset, startMode, preset }) {
	const changeToStandardMode =  useCallback((mode) => {
		clearPreset();
		changeMode(mode);
	},
	[changeMode, clearPreset]);
	const onPresetMode = useCallback((relativeMode) => {
		changeMode(modes.relative);
		changeToPresetMode(relativeMode);
	}, [changeMode, changeToPresetMode]);

	const onPresetCurrentMode = useCallback(() => {
		onPresetMode(relativeModes.current);
	}, [onPresetMode]);
	const onPresetPreviousMode = useCallback(() => {
		onPresetMode(relativeModes.previous);
	}, [onPresetMode]);
	const onPresetNextMode = useCallback(() => {
		onPresetMode(relativeModes.next);
	}, [onPresetMode]);
	const onRelativeMode = useCallback(() => changeToStandardMode(modes.relative), [changeToStandardMode]);
	const onCustomMode = useCallback(() => changeToStandardMode(modes.custom), [changeToStandardMode]);
	const onAllTimeMode = useCallback(() => changeToStandardMode(modes.allTime), [changeToStandardMode]);
	const { t } = useTranslation();
	return <S.Modes className="time-period-modes">
		<S.ModeButton
			label={t('Current')}
			selected={currentMode === modes.relative && startMode === relativeModes.current && Boolean(preset)}
			onSelect={onPresetCurrentMode}
		/>
		<S.ModeButton
			label={t('Previous')}
			selected={currentMode === modes.relative && startMode === relativeModes.previous && Boolean(preset)}
			onSelect={onPresetPreviousMode}
		/>
		<S.ModeButton
			label={t('Next')}
			selected={currentMode === modes.relative && startMode === relativeModes.next && Boolean(preset)}
			onSelect={onPresetNextMode}
		/>
		<S.ModeButton
			label={getModeName(modes.allTime)}
			selected={currentMode === modes.allTime}
			onSelect={onAllTimeMode}
		/>
		<S.ModeButton
			label={getModeName(modes.relative)}
			selected={currentMode === modes.relative && !preset}
			onSelect={onRelativeMode}
		/>
		<S.ModeButton
			label={getModeName(modes.custom)}
			selected={currentMode === modes.custom}
			onSelect={onCustomMode}
		/>
	</S.Modes>;
}

ModeTabsChooser.propTypes = {
	currentMode: PropTypes.oneOf(Object.values(modes)).isRequired,
	changeMode: PropTypes.func.isRequired,
};

export default memo(ModeTabsChooser);
