import React from 'react';
import { FilterDropdownItem } from './FilterDropdownItem';
import { FilterItem } from './types';
import { FilterDropdownBase } from './FilterDropdownBase';

type FilterDropdownProps = {
    label: string,
    items: FilterItem[];
    selection?: string[];
    isFetching?: boolean;
    fitContent?: boolean;
    canSelectAll?: boolean;
    maxWidth?: number;
    compactLabel?: boolean;
    onSelectionChange?: (selection: string[]) => unknown;
    onClose?: (selection: string[]) => void;
    onOpen?: () => void;
};

export function FilterDropdown({
	label,
	items,
	selection = [],
	isFetching,
	onSelectionChange,
	fitContent,
	canSelectAll,
	maxWidth,
	compactLabel,
	onClose,
	onOpen,
}: FilterDropdownProps) {

	return (
		<FilterDropdownBase
			fitContent={fitContent}
			isFetching={isFetching}
			items={items}
			label={label}
			maxWidth={maxWidth}
			onClose={onClose}
			onOpen={onOpen}
			compactLabel={compactLabel}
			canSelectAll={canSelectAll}
			onSelectionChange={onSelectionChange}
			selection={selection}
		>
			{
				({ filteredItems: items, selection, onToggle }) => items.map((item) => <FilterDropdownItem
					key={item.id}
					filterItem={item}
					onToggle={onToggle}
					selected={selection.indexOf(item.id) > -1}
				/>)
			}
		</FilterDropdownBase>
	);
}
