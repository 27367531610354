import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

const classNamePrefix = 'table';

export default function Table({ className, data, bordered, borderless, striped, responsive, hover, props }) {
	const keys = Object.keys(data[0]);

	return <table {...props} className={classNames('table', {
		className,
		[`${classNamePrefix}-bordered`]: bordered,
		[`${classNamePrefix}-borderless`]: borderless,
		[`${classNamePrefix}-striped`]: striped,
		[`${classNamePrefix}-responsive`]: responsive,
		[`${classNamePrefix}-hover`]: hover,
	})}>
		<TableHeader columns={keys}></TableHeader>
		<TableRow rows={data} keys={keys}></TableRow>
	</table>;
}

Table.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	className: PropTypes.string,
	borderless: PropTypes.bool,
	bordered: PropTypes.bool,
	striped: PropTypes.bool,
	hover: PropTypes.bool,
	responsive: PropTypes.bool,
};
