import classNames from 'classnames';
import styled from 'styled-components';
import { PropsWithClassName } from './PropsWithClassName';
import { useCallback } from 'react';

const StyledTabButton = styled.div`
	display: inline-block;
	cursor: pointer;
	width: auto;
	text-align: center;
	font-weight: 400;
	white-space: nowrap;
	flex: 1;
	max-width: 50%;
	color: #9CA3AF;
	> span {
		display: inline-block;
		padding: 0 5px 10px;
	}

	&:after {
		content: '';
		display: block;
		width: 0;
		height: 4px;
		background: #12A34D;
		transition: width .2s;
		animation-timing-function: ease;
		margin: auto;
	}

	&:hover {
		color: #374151;
	}

	&.tab-button--selected {
		color: #374151;

		&:after {
			width: 100%;
		}
	}
`;

type Props = {
	id: string,
	label: string,
	selected?: boolean,
	onSelect: (id: string) => void
};

export const TabButton = ({ id, className, label, selected, onSelect }: PropsWithClassName<Props>) => {
	const handleClick = useCallback(() => onSelect(id), [id, onSelect]);

	return (<StyledTabButton
		className={classNames('tab-button', className, { 'tab-button--selected': selected })}
		onClick={handleClick}
	>
		<span>{label}</span>
	</StyledTabButton>);
};
