import React, { useState } from 'react';
import { Icon } from '@plecto/ui';
import styled, { css } from 'styled-components';
import { FilterTree } from './FilterTree';
import { FilterTreeNode } from './types';
import { FilterDropdownItem } from './FilterDropdownItem';

const StyledIcon = styled(Icon)`
	padding: 8px 12px;
	&:hover {
		background-color: rgb(217, 217, 217);
	}
`;

const StyledTreeContainer = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;
	padding: 0;
	margin: 0;
`;

const StyledTreeNode = styled.li<{
	$isRoot: boolean;
}>`
	${({ $isRoot }) => !$isRoot && css`
		padding-left: 8px;
	`}
`;

type TeamTreeNodeProps = {
	filterItem: FilterTreeNode;
	selected: boolean;
	onToggle: (uuid: string) => void;
	expanded?: boolean;
	isRoot?: boolean;
}

export const FilterTreeItem = ({
	filterItem,
	selected,
	onToggle,
	expanded = false,
	isRoot = true,
}: TeamTreeNodeProps) => {
	const [isExpanded, setIsExpanded] = useState(expanded);
	const hasChildren = Boolean(filterItem.children?.length);

	const onExpand = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		setIsExpanded((wasExpanded: boolean) => !wasExpanded);
	};

	return (
		<StyledTreeNode $isRoot={isRoot}>
			<FilterDropdownItem
				selected={selected}
				filterItem={filterItem}
				onToggle={onToggle}
			>
				{hasChildren && <StyledIcon icon={`chevron-${isExpanded ? 'up' : 'down'}`} onClick={onExpand} /> }
			</FilterDropdownItem>
			{hasChildren && isExpanded && (
				<StyledTreeContainer>
					<FilterTree
						items={filterItem.children}
						onToggle={onToggle}
						isRoot={false}
					/>
				</StyledTreeContainer>
			)}
		</StyledTreeNode>
	);
};
