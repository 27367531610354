import { apiGet, apiPut, apiVersionV3 } from '../../fetch/apiService';

export function fetchWrittenUpdate(writtenUpdateId) {
	return apiGet(
		`written-updates/${writtenUpdateId}`,
		{},
		{},
		apiVersionV3
	);
}

export function submitWrittenUpdate(writtenUpdateId, writtenUpdate) {
	return apiPut(
		`written-updates/${writtenUpdateId}`,
		writtenUpdate,
		{},
		{},
		apiVersionV3
	);
}
