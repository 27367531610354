import { takeLatest, select, put } from '@redux-saga/core/effects';
import { getDataSourceVariables } from './api';
import apiSagaGenerator from '../../saga/apiSagaGenerator';
import {
	FETCH,
	fetchDataSourceVariablesSuccess,
	fetchDataSourceVariablesError,
	FETCH_IF_NEEDED,
	fetchDataSourceVariables,
} from './actions';
import { getVariablesForDataSource } from './selectors';

const dataSourceVariablesSagas = [
	takeLatest(FETCH, 
		apiSagaGenerator()
			.forApi(getDataSourceVariables)
			.withArguments((action) => [action.dataSourceId])
			.forSuccess(fetchDataSourceVariablesSuccess)
			.withActionArguments((action) => [action.dataSourceId])
			.forError(fetchDataSourceVariablesError)
			.withMessage('Error loading data source variables')
			.generate()),
	takeLatest(FETCH_IF_NEEDED, fetchIfNeeded),
];

function* fetchIfNeeded(action) {
	if (action.dataSourceId) {
		const currentVariablesObject = yield select(getVariablesForDataSource, action.dataSourceId);
		if (!currentVariablesObject) {
			yield put(fetchDataSourceVariables(action.dataSourceId));
		}
	}
}

export default dataSourceVariablesSagas;
