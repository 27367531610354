export const FETCH_ALL = 'plecto/registrationPreviews/FETCH_ALL';
export const FETCH_ALL_SUCCESS = 'plecto/registrationPreviews/FETCH_ALL_SUCCESS';
export const FETCH_ALL_API_ERROR = 'plecto/registrationPreviews/FETCH_ALL_API_ERROR';

export const FETCH_NEXT = 'plecto/registrationPreviews/FETCH_NEXT';
export const FETCH_NEXT_SUCCESS = 'plecto/registrationPreviews/FETCH_NEXT_SUCCESS';
export const FETCH_NEXT_API_ERROR = 'plecto/registrationPreviews/FETCH_NEXT_API_ERROR';

export const CLEAR = 'plecto/registrationPreviews/CLEAR';

export const PRELOAD = 'plecto/registrationPreviews/PRELOAD';

export const preloadRegistrationsPreviews = (dataSource, result, uuid) => ({
	type: PRELOAD,
	result,
	dataSource,
	uuid
});

export const fetchAllRegistrationsPreviews = (dataSource, filters, sorting, uuid, pageSize) => ({
	type: FETCH_ALL,
	dataSource,
	filters,
	sorting,
	pageSize,
	uuid
});

export const fetchAllRegistrationsPreviewsSuccess = (dataSource, uuid, result) => ({
	type: FETCH_ALL_SUCCESS,
	result,
	dataSource,
	uuid
});

export const fetchAllRegistrationsPreviewsError = (dataSource, error, uuid) => ({
	type: FETCH_ALL_API_ERROR,
	error,
	dataSource,
	uuid
});

export function fetchAllRegistrationsPreviewsIfNeeded(dataSource, filters, sorting, uuid, pageSize) {
	return (dispatch, getState) => {
		if (shouldFetchRegistrationsPreviews(getState().registrationPreviews[uuid]?.[dataSource])) {
			return dispatch(fetchAllRegistrationsPreviews(dataSource, filters, sorting, uuid, pageSize));
		}
	};
}

export function shouldFetchRegistrationsPreviews(registrationPreviews) {
	return registrationPreviews && registrationPreviews.isStale && !registrationPreviews.isFetching;
}

// eslint-disable-next-line max-params
export const fetchNextRegistrationsPreviews = (dataSource, filters, sorting, uuid, pageSize, searchAfter) => ({
	type: FETCH_NEXT,
	dataSource,
	filters,
	sorting,
	pageSize,
	searchAfter,
	uuid
});

export const fetchNextRegistrationsPreviewsSuccess = (dataSource, uuid, pageSize, result)  => ({
	type: FETCH_NEXT_SUCCESS,
	result,
	dataSource,
	pageSize,
	uuid
});

export const fetchNextRegistrationsPreviewsError = (dataSource, uuid, pageSize, error) => ({
	type: FETCH_NEXT_API_ERROR,
	uuid,
	error
});

export const clear = () => ({
	type: CLEAR
});
