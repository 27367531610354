import React from 'react';
import DatePicker from '../../common/datePicker/DatePicker';

const componentTypes = {
	calendar: 'calendar'
};

export default function componentMapper(type, onChange, value, componentProps) {
	switch (type) {
	case componentTypes.calendar: {
		const date = value ? new Date(value) : new Date();
		const { minDate, maxDate } = componentProps || {};
		return <DatePicker
			onDateChange={onChange}
			date={date}
			minDate={minDate}
			maxDate={maxDate}
		/>;
	}
	}
}
