import { t } from '../../../../../setupTranslations';
import { periods } from '../../logic/constants';
import { presetTypes } from '../../logic/presets';
import { getPeriodName, getPeriodNamePlural } from '../../logic/translations';

export default function translatePreset(preset, startDate, endDate) {
	switch (preset) {
	case presetTypes.today:
		return t('Today');
	case presetTypes.week:
		return t('Current Week');
	case presetTypes.month: 
		return t('Current Month');
	case presetTypes.quarter:
		return t('Current Quarter');
	case presetTypes.year:
		return t('Current Year');
	case presetTypes.weekToDate:
		return t('Week to date');
	case presetTypes.monthToDate:
		return t('Month to date');
	case presetTypes.quarterToDate:
		return t('Quarter to date');
	case presetTypes.yearToDate:
		return t('Year to date');
	case presetTypes.yesterday:
		return t('Yesterday');
	case presetTypes.last_week:
		return t('Previous Week');
	case presetTypes.last_month:
		return t('Previous Month');
	case presetTypes.last_quarter:
		return t('Previous Quarter');
	case presetTypes.last_year: 
		return t('Previous Year');
	case presetTypes.tomorrow:
		return t('Tomorrow');
	case presetTypes.next_week:
		return t('Next Week');
	case presetTypes.next_month: 
		return t('Next Month');
	case presetTypes.next_quarter: 
		return t('Next Quarter');
	case presetTypes.next_year: 
		return t('Next Year');
	case presetTypes.last_custom: {

		switch (startDate.period) {
		case periods.day:
			if (startDate.amount === 1) {
				if (endDate.enabled) {
					return t('Yesterday and today');
				} else {
					return t('Yesterday');
				}
			} else {
				if (endDate.enabled) {
					return t('Previous {{amount}} days and today', { amount: startDate.amount });
				} else {
					return t('Previous {{amount}} days', { amount: startDate.amount });
				}
			}
		default:
			if (startDate.amount === 1) {
				if (endDate.enabled) {
					return t('Previous and current {{period}}',
						{ period: getPeriodName(startDate.period).toLowerCase() });
				} else {
					return  t('Previous {{period}}',
						{ period: getPeriodName(startDate.period).toLowerCase() });
				}
			} else {
				if (endDate.enabled) {
					return t('Previous {{amount}} {{periods}} and current',
						{ amount: startDate.amount, periods: getPeriodNamePlural(startDate.period).toLowerCase() });
				} else {
					return t('Previous {{amount}} {{periods}}',
						{ amount: startDate.amount, periods: getPeriodNamePlural(startDate.period).toLowerCase() });
				}
			}
		}
	}

	case presetTypes.next_custom: {

		switch (startDate.period) {
		case periods.day:
			if (startDate.amount === 1) {
				if (endDate.enabled) {
					return t('Tomorrow and today');
				} else {
					return t('Tomorrow');
				}
			} else {
				if (endDate.enabled) {
					return t('Today and next {{amount}} days', { amount: startDate.amount });
				} else {
					return t('Next {{amount}} days', { amount: startDate.amount });
				}
			}
		default:
			if (startDate.amount === 1) {
				if (endDate.enabled) {
					return t('Current and next {{period}}',
						{ period: getPeriodName(startDate.period).toLowerCase() });
				} else {
					return  t('Next {{period}}',
						{ period: getPeriodName(startDate.period).toLowerCase() });
				}
			} else {
				if (endDate.enabled) {
					return t('Current and next {{amount}} {{periods}}',
						{ amount: startDate.amount, periods: getPeriodNamePlural(startDate.period).toLowerCase() });
				} else {
					return t('Next {{amount}} {{periods}}',
						{ amount: startDate.amount, periods: getPeriodNamePlural(startDate.period).toLowerCase() });
				}
			}
		}
	}

	default:
		return '';
	}
}
