import { apiGet, apiVersionV3 } from '../../fetch/apiService';

export function getWrittenUpdateTemplates() {
	return apiGet(
		'written-update-templates',
		{},
		{},
		apiVersionV3
	);
}
