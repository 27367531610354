export const FETCH = 'plecto/registrations/FETCH';
export const FETCH_SUCCESS = 'plecto/registrations/FETCH_SUCCESS';
export const FETCH_API_ERROR = 'plecto/registrations/FETCH_API_ERROR';
export const ADD = 'plecto/registrations/ADD';
export const ADD_SUCCESS = 'plecto/registrations/ADD_SUCCESS';
export const ADD_API_ERROR = 'plecto/registrations/ADD_API_ERROR';
export const UPDATE = 'plecto/registrations/UPDATE';
export const UPDATE_SUCCESS = 'plecto/registrations/UPDATE_SUCCESS';
export const UPDATE_API_ERROR = 'plecto/registrations/UPDATE_API_ERROR';
export const CLEAR = 'plecto/registrations/CLEAR';
export const DELETE = 'plecto/registrations/DELETE';
export const DELETE_REQUEST = 'plecto/registrations/DELETE_REQUEST';
export const DELETE_UNDO = 'plecto/registrations/DELETE_UNDO';
export const DELETE_SUCCESS = 'plecto/registrations/DELETE_SUCCESS';
export const DELETE_API_ERROR = 'plecto/registrations/DELETE_API_ERROR';

export const fetchRegistrations = dataSource => ({
	type: FETCH,
	dataSource,
});

export const fetchRegistrationsSuccess = result => ({
	type: FETCH_SUCCESS,
	result,
});

export const fetchRegistrationsError = error => ({
	type: FETCH_API_ERROR,
	error,
});

export const addRegistration = (
	dataSource,
	body,
	retry,
	index,
) => ({
	type: ADD,
	dataSource,
	body,
	retry,
	index,
});

export const addRegistrationSuccess = (
	addedRegistration,
	retry = false,
	index = 0,
) => ({
	type: ADD_SUCCESS,
	addedRegistration,
	retry,
	index,
});

export const addRegistrationError = (error, addedRegistration) => ({
	type: ADD_SUCCESS,
	error,
	addedRegistration,
});

export const updateRegistration = (
	itemUuid,
	dataSource,
	body,
) => ({
	type: UPDATE,
	itemUuid,
	dataSource,
	body,
});

export const updateRegistrationSuccess = editedRegistration => ({
	type: UPDATE_SUCCESS,
	editedRegistration,
});

export const updateRegistrationError = error => ({
	type: UPDATE_API_ERROR,
	error,
});

export const deleteRegistrationRequest = uuid => ({
	type: DELETE_REQUEST,
	uuid,
});

export const deleteRegistrationUndo = previousState => ({
	type: DELETE_UNDO,
	previousState,
});

export const deleteRegistration = (
	uuid,
	datasourceUuid,
) => ({
	type: DELETE,
	uuid,
	datasourceUuid,
});

export const deleteRegistrationSuccess = () => ({
	type: DELETE_SUCCESS,
});

export const deleteRegistrationError = error => ({
	type: DELETE_API_ERROR,
	error,
});

export const clearRegistrations = () => ({
	type: CLEAR,
});
