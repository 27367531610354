import { useEffect, useState } from 'react';
import getWindowSize from './windowSize';
import _debounce from 'lodash/debounce';

const DEBOUNCE_TIME = 500;

export default function useWindowSize() {
	const [size, setSize] =
		useState(() => getWindowSize(window));
  
	useEffect(() => {
		function handleResize() {
			setSize(getWindowSize(window));
		}

		const debounced = _debounce(handleResize, DEBOUNCE_TIME, { leading: true, trailing: true });

		window.addEventListener('resize', debounced);
		return () => {
			debounced.cancel();
			window.removeEventListener('resize', debounced);
		};
	}, []);

	return size;
}
