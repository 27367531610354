import React from 'react';
import { periods } from '../logic/constants';
import MonthStartPoint from './MonthStartPoint';
import WeekStartPoint from './WeekStartPoint';
import YearStartPoint from './YearStartPoint';

export default function StartPointOptions({ startDate, actions }) {
	const { period, startingOnDay, startingOnMonth } = startDate;
	return (
		<>
			
			{period === periods.week ? 
				<WeekStartPoint actions={actions} startingOnDay={startingOnDay} /> : null}
			{period === periods.month ? 
				<MonthStartPoint actions={actions} startingOnDay={startingOnDay} /> : null}

			{period === periods.year ? 
				<YearStartPoint actions={actions} startingOnDay={startingOnDay} startingOnMonth={startingOnMonth} /> :
				null
			}
		</>
	);
}
