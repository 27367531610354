import apiSagaGenerator from '../../saga/apiSagaGenerator';
import { addPostReaction, createPost, deletePost, getPosts, removePostReaction } from './api';
import {
	ADD_POST_REACTION,
	addPostReactionFailure,
	addPostReactionSuccess,
	CREATE_POST,
	createPostFailure,
	createPostSuccess,
	DELETE_POST,
	deletePostFailure,
	deletePostSuccess,
	GET_NEW_POSTS,
	GET_POSTS,
	getNewPostsFailure,
	getNewPostsSuccess,
	getPostsFailure,
	getPostsSuccess,
	REMOVE_POST_REACTION,
	removePostReactionFailure,
	removePostReactionSuccess,
} from './actions';
import { takeEvery, takeLatest } from '@redux-saga/core/effects';

const feedSagas = [
	takeLatest(GET_POSTS,
		apiSagaGenerator()
			.forApi(getPosts)
			.withArguments(action => [action.parameters])
			.forSuccess(getPostsSuccess)
			.forError(getPostsFailure)
			.includeErrorResult()
			.withMessage('Error getting posts')
			.generate(),
	),
	takeLatest(GET_NEW_POSTS,
		apiSagaGenerator()
			.forApi(getPosts)
			.withArguments(action => [action.parameters])
			.forSuccess(getNewPostsSuccess)
			.forError(getNewPostsFailure)
			.includeErrorResult()
			.withMessage('Error getting posts')
			.generate(),
	),
	takeLatest(CREATE_POST,
		apiSagaGenerator()
			.forApi(createPost)
			.withArguments((action) => [action.payload])
			.forSuccess(createPostSuccess)
			.forError(createPostFailure)
			.includeErrorResult()
			.withMessage('Error creating post')
			.generate(),
	),
	takeLatest(DELETE_POST,
		apiSagaGenerator()
			.forApi(deletePost)
			.withArguments((action) => [action.postId])
			.forSuccess(deletePostSuccess)
			.withActionArguments((action) => [action.postId])
			.skipResult()
			.forError(deletePostFailure)
			.includeErrorResult()
			.withMessage('Error deleting post')
			.generate(),
	),
	takeEvery(ADD_POST_REACTION,
		apiSagaGenerator()
			.forApi(addPostReaction)
			.withArguments((action) => [action.postId, action.request])
			.forSuccess(addPostReactionSuccess)
			.skipResult()
			.forError(addPostReactionFailure)
			.includeErrorResult()
			.withMessage('Error adding post reaction')
			.generate(),
	),
	takeEvery(REMOVE_POST_REACTION,
		apiSagaGenerator()
			.forApi(removePostReaction)
			.withArguments((action) => [action.postId, action.request])
			.forSuccess(removePostReactionSuccess)
			.skipResult()
			.forError(removePostReactionFailure)
			.withMessage('Error removing post reaction')
			.generate(),
	),
];

export default feedSagas;
