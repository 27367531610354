import React from 'react';
import ReactDOM from 'react-dom';
import SlideIn from './SlideIn';
import styled from 'styled-components';

const Host = styled.div`
	min-height: 100vh;
	background-color: grey;
	position: absolute;
	right: 0;
	top: 0;
`;

class SlideInHost extends React.Component {
	constructor(props) {
		super(props);
		this.el = document.createElement('div');
	}

	componentDidMount() {
		document.body.appendChild(this.el);
	}

	componentWillUnmount() {
		document.body.removeChild(this.el);
	}

	render() {
		return ReactDOM.createPortal(
			<Host className="slide-in-host">
				<SlideIn {...this.props} ref={this.props.innerRef}>
					{this.props.children}
				</SlideIn>
			</Host>,
			this.el,
		);
	}
}

export default React.forwardRef((props, ref) => <SlideInHost 
	innerRef={ref} {...props}
/>);
