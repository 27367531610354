import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ACHIEVEMENT_BADGE_SIZES as SIZES, ACHIEVEMENT_BADGE_SIZES_PX as SIZES_PX } from '../achievementBadgeSizes';
import { ACHIEVEMENT_BADGE_THEMES as THEMES } from '../achievementBadgeThemes';
import { ACHIEVEMENT_BADGE_COLORS as COLORS } from '../achievementBadgeColors';
import { ACHIEVEMENT_BADGE_SHAPES as SHAPES } from '../achievementBadgeShapes';
import { getAchievementColorTheme } from '../achievementColorThemes';
import AchievementBadgeBackground from './AchievementBadgeBackground';
import BaseUrlContext from '../../../../contexts/BaseUrlContext';

const ICON_SIZE = {
	[SIZES.small]: '12px',
	[SIZES.medium]: '20px',
	[SIZES.large]: '26px',
};

const Container = styled.div`
  display: block;
  position: relative;
  width: ${props => SIZES_PX[props.size]};
  height: ${props => SIZES_PX[props.size]};
  min-width: ${props => SIZES_PX[props.size]};
`;

const Icon = styled.img`
  position: absolute;
  display: block;
  width: ${props => ICON_SIZE[props.size]};
  height: ${props => ICON_SIZE[props.size]};
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
`;

function badgeIconSvg({ baseUrl, iconId, iconColor }) {
	const urlColor = iconColor.replace('#', '');
	return `${baseUrl}/svg/achievement-icon/${iconId}/${urlColor}/icon.svg`;
}

export default function AchievementBadge({
	size = SIZES.large,
	theme,
	color,
	shape,
	iconId,
	className,
}) {
	const { icon: { color: iconColor }, badge: { fill, stroke }} = getAchievementColorTheme({ color, theme });
	const baseUrl = useContext(BaseUrlContext);

	return <Container size={size} className={className}>
		<AchievementBadgeBackground shape={shape} fill={fill} stroke={stroke}/>
		<Icon src={badgeIconSvg({ baseUrl, iconId, iconColor })} size={size}/>
	</Container>;
}

AchievementBadge.propTypes = {
	size: PropTypes.oneOf(Object.values(SIZES)),
	theme: PropTypes.oneOf(Object.values(THEMES)),
	color: PropTypes.oneOf(Object.values(COLORS)),
	shape: PropTypes.oneOf(Object.values(SHAPES)),
	iconId: PropTypes.string.isRequired,
	className: PropTypes.string,
};
