import {
	FETCH,
	FETCH_SUCCESS,
	FETCH_API_ERROR,
	ADD,
	ADD_SUCCESS,
	ADD_API_ERROR,
	UPDATE_SUCCESS,
	UPDATE_API_ERROR,
	CLEAR,
	DELETE_REQUEST,
	DELETE_UNDO,
	DELETE_API_ERROR,
	DELETE_SUCCESS,
} from './actions';

const initialState = {
	data: {
		results: [],
	},
	error: null,
	isFetching: false,
	isSaving: false,
};

const registrations = (state = initialState, action) => {
	switch (action.type) {
	case FETCH:
		return {
			...state,
			isFetching: true,
		};
	case FETCH_SUCCESS:
		return {
			...state,
			data: action.result,
			isFetching: false,
		};
	case FETCH_API_ERROR:
		return {
			...state,
			isFetching: false,
		};
	case ADD:
		return {
			...state,
			isSaving: true,
		};
	case ADD_SUCCESS:
		return {
			...state,
			data: {
				...state.data,
				results: action.retry
					? state.data.results.map((item, index) => {
						if (index === action.index) {
							return action.addedRegistration;
						}
						return item;
					})
					: [action.addedRegistration, ...state.data.results],
			},
			isSaving: false,
		};
	case ADD_API_ERROR:
		return {
			...state,
			error: action.error,
			data: {
				...state.data,
				results: [action.addedRegistration, ...state.data.results],
			},
			isSaving: false,
		};

	case UPDATE_SUCCESS:
		return {
			...state,
			data: {
				...state.data,
				results: state.data.results.map(item => {
					return item.uuid === action.editedRegistration.uuid
						? { ...item, ...action.editedRegistration }
						: item;
				}),
			},
			isSaving: false,
		};
	case UPDATE_API_ERROR:
		return {
			...state,
			error: action.error,
			isSaving: false,
		};

	case DELETE_REQUEST:
		return {
			...state,
			data: {
				...state.data,
				results: state.data.results.filter(item => item.uuid !== action.uuid),
			},
		};
	case DELETE_UNDO:
		return {
			...state,
			data: {
				results: action.previousState,
			},
		};
	case DELETE_SUCCESS:
		return {
			...state,
		};
	case DELETE_API_ERROR:
		return {
			...state,
			error: action.error,
		};
	case CLEAR:
		return {
			...initialState,
		};
	default:
		return state;
	}
};

export default registrations;
