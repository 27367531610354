export const FETCH = 'plecto/timePeriod/FETCH';
export const FETCH_SUCCESS = 'plecto/timePeriod/FETCH_SUCCESS';
export const FETCH_API_ERROR = 'plecto/timePeriod/FETCH_API_ERROR';
export const CLEAR = 'plecto/timePeriod/CLEAR';

export const fetchTimePeriodPreview = (operationId, parameters) => ({
	type: FETCH,
	operationId,
	parameters,
});

export const fetchTimePeriodPreviewSuccess = (operationId, result) => ({
	type: FETCH_SUCCESS,
	operationId,
	result,
});

export const fetchTimePeriodPreviewError = (operationId, error) => ({
	type: FETCH_API_ERROR,
	operationId,
	error,
});

export const clearTimePeriodPreview = (operationId) => ({
	type: CLEAR,
	operationId
});
