import { useLayoutEffect } from 'react';

export function useTooltip(
	elementRef: React.RefObject<HTMLElement | undefined>,
	{
		disabled = false,
		delay = 250,
		forceShow = false,
		forceShowDelay = 250,
		title,
		placement = 'right'
	}: {
		disabled?: boolean,
		delay?: number,
		forceShow?: boolean,
		forceShowDelay?: number,
		title?: string,
		placement?: 'auto' | 'top' | 'bottom' | 'left' | 'right'
	}
) {
	useLayoutEffect(() => {
		if (!disabled) {
			// @ts-ignore
			const el = $(elementRef.current);
			// @ts-ignore
			el.tooltip({
				container: 'body',
				title,
				placement,
				delay: {
					show: delay
				},
			});

			// @ts-ignore
			return () => {	el.tooltip('destroy'); };
		}
		return undefined;
	}, [delay, disabled, elementRef, placement, title]);

	useLayoutEffect(() => {
		let wasShownForcefully = false;
		const node = elementRef.current;
		let showTimeout: NodeJS.Timeout;
		if (forceShow && !disabled) {
			showTimeout = setTimeout(() => {
				wasShownForcefully = true;
				// @ts-ignore
				$(node).tooltip('show');

			}, forceShowDelay);

		}
		return () => {
			clearTimeout(showTimeout);
			if (wasShownForcefully && node) {
				// @ts-ignore
				$(node).tooltip('hide');
			}
		};
	}, [disabled, elementRef, forceShow, forceShowDelay]);
}
