import { useDebouncedInputState } from '../../utils/useDebouncedInputState';
import { FilterItem } from './types';
import { useMemo } from 'react';

export function useFilterSearch(items: FilterItem[]) {
	const [
		searchInputValue,
		searchPhrase,
		onSearchChange,
		forceChange,
	] = useDebouncedInputState();

	const phraseInLoweCase = searchPhrase.toLowerCase();

	return useMemo(() => ({
		searchInputValue,
		filteredItems: phraseInLoweCase ?
			items.filter(items => items.label.toLowerCase().includes(phraseInLoweCase)) : items,
		onSearchChange,
		forceChange,
	}), [forceChange, items, onSearchChange, phraseInLoweCase, searchInputValue]);
}
