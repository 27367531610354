import { modes } from './constants';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { formatDate, formatDateTime, getStartOfNextHour } from './dateUtils';
import { mapToTimePeriodRelativeModel, mapAllTimeMode } from './optionsModelMapper';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTimePeriodPreview, clearTimePeriodPreview } from '@plecto/models/actions/timePeriod';
import generateUuid from '../../utils/generateUuid';
import TimeZoneContext from '../../../contexts/TimeZoneContext';
import { t } from '../../../../setupTranslations';

export default function usePreview(state) {
	const operationId = useMemo(generateUuid, []);
	const timeZone = useContext(TimeZoneContext);

	const dispatch = useDispatch();
	const apiPreviewAction = useCallback((...args) => dispatch(fetchTimePeriodPreview(...args)), [dispatch]);
	const apiCleanupFunction = useCallback((...args) => dispatch(clearTimePeriodPreview(...args)), [dispatch]);
	const { data: { start_date, end_date, invalid_period }} =
		useSelector(state => state.timePeriod[operationId] || { data: {}});

	const {
		mode,
		relativeStartDate,
		allTimeEndDate,
		relativeEndDate,
		offset,
		timeRange,
		customDate,
		visibility
	} = state;
	useEffect(() => {
		if (isServerPreviewMode(mode, allTimeEndDate)) {
			initPreview({
				mode,
				relativeStartDate,
				relativeEndDate: mode === modes.allTime ? allTimeEndDate : relativeEndDate,
				timeRange,
				offset,
				operationId,
				timeZone,
				apiPreviewAction
			});
		}
	}, [mode, relativeStartDate, allTimeEndDate, 
		relativeEndDate, timeRange, offset, operationId, timeZone, apiPreviewAction]);

	const [preview, setPreview] = useState(t('Loading preview'));
	useEffect(() => {
		setPreview(
			formatPreview({
				mode,
				allTimeEndDate,
				timeRange,
				visibility,
				start_date,
				end_date,
				invalid_period,
				customDate,
				timeZone
			})
		);
	}, [mode, allTimeEndDate, timeRange, customDate, start_date, end_date, invalid_period, visibility, timeZone]);

	useEffect(() => {
		return function previewCleanup() {
			apiCleanupFunction(operationId);
		};
	}, [operationId, apiCleanupFunction]);

	const isFetching = useSelector(state => state.timePeriod[operationId]?.isFetching);

	return { preview, isFetching, operationId };
}

function initPreview({ 
	mode, relativeStartDate, relativeEndDate, timeRange, offset, operationId, timeZone, apiPreviewAction }) {
	if (mode === modes.relative) {
		const apiModel = mapToTimePeriodRelativeModel({ relativeEndDate, relativeStartDate, timeRange, offset });
		const model = {
			start: {
				amount: apiModel.start_amount,
				period: apiModel.start_period,
				shift_amount: apiModel.start_shift_amount,
				shift_period: apiModel.start_shift_period,
				total_shift_amount: apiModel.total_shift_amount,
				total_shift_period: apiModel.total_shift_period,
				starting_on_day: apiModel.starting_on_day,
				starting_on_month: apiModel.starting_on_month
			},
			end: {
				amount: apiModel.end_amount,
				period: apiModel.end_period,
				shift_amount: apiModel.end_shift_amount,
				shift_period: apiModel.end_shift_period,
				total_shift_amount: apiModel.total_shift_amount,
				total_shift_period: apiModel.total_shift_period,
				starting_on_day: apiModel.starting_on_day,
				starting_on_month: apiModel.starting_on_month
			},
			time_zone: timeZone 
		};

		apiPreviewAction(operationId, model);
	} else if (mode === modes.allTime && relativeEndDate.enabled) {
		const apiModel = mapAllTimeMode({ allTimeEndDate: relativeEndDate });
		const model = {
			start: {
				amount: apiModel.start_amount,
				period: apiModel.start_period,
				shift_amount: apiModel.start_shift_amount,
				shift_period: apiModel.start_shift_period
			},
			end: {
				amount: apiModel.end_amount,
				period: apiModel.end_period,
				shift_amount: apiModel.end_shift_amount,
				shift_period: apiModel.end_shift_period
			}
		};

		apiPreviewAction(operationId, model);
	}
}

function formatPreview({
	mode,
	allTimeEndDate,
	timeRange,
	visibility,
	start_date,
	end_date,
	invalid_period,
	customDate,
	timeZone
}) {
	const hasPreviewFromServer = start_date && end_date;
	if (isServerPreviewMode(mode, allTimeEndDate)) {
		if (!hasPreviewFromServer) {
			return 'Loading preview';
		}

		if (invalid_period) {
			return 'End date cannot be before the start date';
		}
	}

	if (mode === modes.relative) {
		if (timeRange.enabled && visibility.timeRangeDay) {
			return `${formatDateTime(start_date, timeZone)} - ${
				formatDateTime(
					getStartOfNextHour(new Date(end_date)), timeZone)}`;
		} else {
			return `${formatDate(start_date, timeZone)} - ${formatDate(end_date, timeZone)}`;
		}
	} else if (mode === modes.allTime) {
		if (allTimeEndDate.enabled) {
			return `${t('First registration')} - ${formatDate(end_date, timeZone)}`;
		} else {
			return t('All time');
		}
	} else if (mode === modes.custom) {
		return `${formatDate(customDate.startDate, timeZone)} - ${formatDate(customDate.endDate, timeZone)}`;
	}

}

function isServerPreviewMode(mode, allTimeEndDate) {
	return mode === modes.relative || (mode === modes.allTime && allTimeEndDate.enabled);
}
