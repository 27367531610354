import {
	CANCEL,
	CANCEL_API_ERROR,
	CANCEL_SUCCESS,
	END,
	END_API_ERROR,
	END_SUCCESS,
	GET,
	GET_API_ERROR,
	GET_SUCCESS,
	SAVE,
	SAVE_API_ERROR,
	SAVE_SUCCESS,
	SCHEDULE,
	SCHEDULE_API_ERROR,
	SCHEDULE_SUCCESS,
	SEND_NOW,
	SEND_NOW_API_ERROR,
	SEND_NOW_SUCCESS,
} from './actions';

export default function announcementsReducer(state = {}, action) {
	switch (action.type) {
	case GET:
		return updateAnnouncement(state, action.id, () => ({
			fetching: true,
		}));
	case GET_SUCCESS:
		return updateAnnouncement(state, action.id, () => ({
			fetching: false,
			...action.payload,
		}));
	case GET_API_ERROR:
		return updateAnnouncement(state, action.id, () => ({
			fetching: false,
			error: action.error,
		}));
	case SAVE:
		return updateAnnouncement(
			state,
			action.model.uuid || 'new_announcement',
			() => ({
				saving: true,
			})
		);
	case SEND_NOW:
		return updateAnnouncement(state, action.uuid, () => ({
			sending: true,
		}));
	case SCHEDULE:
		return updateAnnouncement(state, action.uuid, () => ({
			scheduling: true,
		}));
	case END:
		return updateAnnouncement(state, action.uuid, () => ({
			ending: true,
		}));
	case CANCEL:
		return updateAnnouncement(state, action.uuid, () => ({
			cancelling: true,
		}));
	case SAVE_SUCCESS:
		return updateAnnouncement(state, action.payload.uuid, () => ({
			saving: false,
			...action.payload,
		}));
	case SEND_NOW_SUCCESS:
	case SEND_NOW_API_ERROR:
		return updateAnnouncement(state, action.uuid, () => ({
			sending: false,
		}));
	case SCHEDULE_SUCCESS:
	case SCHEDULE_API_ERROR:
		return updateAnnouncement(state, action.uuid, () => ({
			scheduling: false,
		}));
	case END_SUCCESS:
	case END_API_ERROR:
		return updateAnnouncement(state, action.uuid, () => ({
			ending: false,
		}));
	case CANCEL_SUCCESS:
	case CANCEL_API_ERROR:
		return updateAnnouncement(state, action.uuid, () => ({
			cancelling: false,
		}));
	case SAVE_API_ERROR:
		return updateAnnouncement(
			state,
			action.model.uuid || 'new_announcement',
			() => ({
				saving: false,
				error: action.error,
			})
		);
	default:
		return state;
	}
}

function updateAnnouncement(state, id, change) {
	const currentState = state[id];

	return {
		...state,
		[id]: {
			...(currentState || {}),
			...change(currentState),
		},
	};
}
