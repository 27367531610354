import { useEffect } from 'react';
import _isArray from 'lodash/isArray';
import _every from 'lodash/every';

const useOutsideClick = (refs, callback, condition = true) => {
	if (!_isArray(refs)) {
		refs = [refs];
	}

	const handleClick = e => {
		if (
			_every(refs, ref => isRefOutsideNode(e.target, ref)) &&
			typeof callback === 'function'
		) {
			callback();
		}
	};

	useEffect(() => {
		condition && document.addEventListener('mousedown', handleClick);

		return () => {
			condition && document.removeEventListener('mousedown', handleClick);
		};
	});
};

function isRefOutsideNode(node, ref) {
	return !ref || !ref.current || !ref.current.contains(node);
}

export default useOutsideClick;
