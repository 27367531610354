import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _flatMap from 'lodash/flatMap';
import ConfirmCloseUnsavedChangesModal from '../components/common/modals/ConfirmCloseUnsavedChangesModal';

export default function useModalOnNavigatingAway(isDirty, onCancelCallback, linkSelectors, message) {
	const callbackMemo = useMemo(() => onCancelCallback, [onCancelCallback]);
	const [closeUrl, setCloseUrl] = useState(null);
	const [closeCallback, setCloseCallback] = useState(null);

	const cleanCloseProps = useCallback(() => {
		setCloseUrl(null);
		setCloseCallback(null);
	}, []);

	const setUrlOnClose = useCallback((url) => {
		setCloseUrl(url);
		setCloseCallback(null);
	}, []);

	const setCallbackOnClose = useCallback((callback) => {
		setCloseUrl(null);
		setCloseCallback(() => callback);
	}, []);

	const onUnload = useCallback((e) => {
		e.preventDefault();
		e.returnValue = '';
	}, []);

	useEffect(() => {
		if (isDirty) {
			window.addEventListener('beforeunload', onUnload);
		}

		return () => {
			window.removeEventListener('beforeunload', onUnload);
		};
	}, [isDirty, onUnload]);

	const onLeave = useCallback((e) => {
		if (e.shiftKey || e.ctrlKey || e.metaKey) {
			return;
		}
		const anchor = e.target.closest('a');
		if (isDirty && anchor) {
			e.preventDefault();
			setUrlOnClose(anchor.href);
		}
	}, [isDirty, setUrlOnClose]);

	useEffect(() => {
		let navLinks = [];
		if (isDirty) {
			navLinks = _flatMap(linkSelectors, selector => Array.from(document.querySelectorAll(selector)));
			for (const link of navLinks) {
				link.addEventListener('click', onLeave);
			}
		}

		return () => {
			for (const link of navLinks) {
				link.removeEventListener('click', onLeave);
			}
		};
	}, [isDirty, linkSelectors, onLeave]);

	const onCloseCanceled = useCallback(() => {
		cleanCloseProps();
	}, [cleanCloseProps]);

	const onCancel = useCallback(() => {
		if (isDirty) {
			setCallbackOnClose(callbackMemo);
		} else if (onCancelCallback) {
			onCancelCallback();
		}
	}, [callbackMemo, isDirty, onCancelCallback, setCallbackOnClose]);

	const onCloseConfirmed = useCallback(() => {
		window.removeEventListener('beforeunload', onUnload);
		if (closeUrl) {
			window.location = closeUrl;
		} else if (closeCallback) {
			closeCallback();
		}
	}, [closeCallback, closeUrl, onUnload]);
	const modal = closeUrl || closeCallback ?
		<ConfirmCloseUnsavedChangesModal
			onCancel={onCloseCanceled}
			onDiscard={onCloseConfirmed}
			message={message}/>
		: null;

	return { modal, onCancel };
}
