import React from 'react';
import DeleteButton from '../buttons/DeleteButton';
import CancelButton from '../buttons/CancelButton';
import { PaneModal } from './PaneModal';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { modalStates } from './modalStates';

const S = {};

S.PaneButtons = styled.div`
	margin-top: 20px;
`;

S.PaneButtonsContainer = styled.div`
	.btn {
		text-transform: uppercase;
		font-size: 14px;
	}

	.btn + .btn {
		margin-left: 5px;
	}
`;

export default function ConfirmCloseUnsavedChangesModal({ message, onDiscard, onCancel }) {
	const { t } = useTranslation();
	return <PaneModal
		title={t('Discard unsaved changes?')}
		onClose={onCancel}
		state={modalStates.warning}
		icon={'warning'}
		forceTop
	>
		<div className="pane-content">
			<span>
				{message}
			</span>
			<S.PaneButtons>
				<S.PaneButtonsContainer className="pull-right pane-buttons-container">
					<DeleteButton text={t('Discard changes')} onClick={onDiscard}/>
				</S.PaneButtonsContainer>
				<S.PaneButtonsContainer className="pull-left pane-buttons-container">
					<CancelButton onClick={onCancel} />
				</S.PaneButtonsContainer>
			</S.PaneButtons>
		</div>
	</PaneModal>;
}
