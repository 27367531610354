import { actionPrefix } from '../constants';

export const CREATE_QUESTION = `${actionPrefix}CREATE_QUESTION`;
export const CREATE_QUESTION_SUCCESS = `${actionPrefix}CREATE_QUESTION_SUCCESS`;
export const CREATE_QUESTION_FAILURE = `${actionPrefix}CREATE_QUESTION_FAILURE`;

export const GET_QUESTION = `${actionPrefix}GET_QUESTION`;
export const GET_QUESTION_SUCCESS = `${actionPrefix}GET_QUESTION_SUCCESS`;
export const GET_QUESTION_FAILURE = `${actionPrefix}GET_QUESTION_FAILURE`;

export const GET_QUESTIONS = `${actionPrefix}GET_QUESTIONS`;
export const GET_QUESTIONS_SUCCESS = `${actionPrefix}GET_QUESTIONS_SUCCESS`;
export const GET_QUESTIONS_FAILURE = `${actionPrefix}GET_QUESTIONS_FAILURE`;

export const UPDATE_QUESTIONS_ORDER = `${actionPrefix}UPDATE_QUESTIONS_ORDER`;
export const UPDATE_QUESTIONS_ORDER_SUCCESS = `${actionPrefix}UPDATE_QUESTIONS_ORDER_SUCCESS`;
export const UPDATE_QUESTIONS_ORDER_FAILURE = `${actionPrefix}UPDATE_QUESTIONS_ORDER_FAILURE`;

export const UPDATE_QUESTION = `${actionPrefix}UPDATE_QUESTION`;
export const UPDATE_QUESTION_SUCCESS = `${actionPrefix}UPDATE_QUESTION_SUCCESS`;
export const UPDATE_QUESTION_FAILURE = `${actionPrefix}UPDATE_QUESTION_FAILURE`;

export const DELETE_QUESTION = `${actionPrefix}DELETE_QUESTION`;
export const DELETE_QUESTION_SUCCESS = `${actionPrefix}DELETE_QUESTION_SUCCESS`;
export const DELETE_QUESTION_FAILURE = `${actionPrefix}DELETE_QUESTION_FAILURE`;

export const UPLOAD_QUESTION_IMAGE = `${actionPrefix}UPLOAD_QUESTION_IMAGE`;
export const UPLOAD_QUESTION_IMAGE_SUCCESS = `${actionPrefix}UPLOAD_QUESTION_IMAGE_SUCCESS`;
export const UPLOAD_QUESTION_IMAGE_FAILURE = `${actionPrefix}UPLOAD_QUESTION_IMAGE_FAILURE`;

export const createQuestion = (quizId, question) => ({
	type: CREATE_QUESTION,
	quizId,
	question
});

export const createQuestionSuccess = (quizId, question) => ({
	type: CREATE_QUESTION_SUCCESS,
	questionId: question.uuid,
});

export const createQuestionFailure = (quizId, payload, error, errors) => ({
	type: CREATE_QUESTION_FAILURE,
	error,
	errors
});

export const getQuestions = (quizId) => ({
	type: GET_QUESTIONS,
	quizId
});

export const getQuestionsSuccess = (quizId, payload) => ({
	type: GET_QUESTIONS_SUCCESS,
	quizId,
	payload
});

export const getQuestionsFailure = (quizId, payload, error, errors) => ({
	type: GET_QUESTIONS_FAILURE,
	error,
	errors
});

export const updateQuestionsOrder = (quizId, questions) => ({
	type: UPDATE_QUESTIONS_ORDER,
	quizId,
	questions
});

export const updateQuestionsOrderSuccess = (quizId, questions) => ({
	type: UPDATE_QUESTIONS_ORDER_SUCCESS,
	quizId,
	questions,
});

export const updateQuestionsOrderFailure = (quizId, questions, error, errors) => ({
	type: UPDATE_QUESTIONS_ORDER_FAILURE,
	error,
	errors
});

export const getQuestion = (quizId, questionId, showCorrectSolution) => ({
	type: GET_QUESTION,
	quizId,
	questionId,
	showCorrectSolution
});

export const getQuestionSuccess = (quizId, questionId, showCorrectSolution, payload) => ({
	type: GET_QUESTION_SUCCESS,
	quizId,
	questionId,
	showCorrectSolution,
	payload
});

// eslint-disable-next-line max-params
export const getQuestionFailure = (quizId, questionId, showCorrectSolution, payload, error, errors) => ({
	type: GET_QUESTION_FAILURE,
	quizId,
	questionId,
	showCorrectSolution,
	error,
	errors
});

export const updateQuestion = (quizId, question) => ({
	type: UPDATE_QUESTION,
	quizId,
	question
});

export const updateQuestionSuccess = (quizId, payload, response) => ({
	type: UPDATE_QUESTION_SUCCESS,
	payload,
	response,
});

export const updateQuestionFailure = (quizId, payload, error, errors) => ({
	type: UPDATE_QUESTION_FAILURE,
	error,
	errors
});

export const deleteQuestion = (quizId, questionId) => ({
	type: DELETE_QUESTION,
	quizId,
	questionId
});

export const deleteQuestionSuccess = (questionId) => ({
	type: DELETE_QUESTION_SUCCESS,
	questionId
});

export const deleteQuestionFailure = (quizId, questionId, error, errors) => ({
	type: DELETE_QUESTION_FAILURE,
	error,
	errors
});

export const uploadQuestionImage = (quizId, image) => ({
	type: UPLOAD_QUESTION_IMAGE,
	quizId,
	image
});

export const uploadQuestionImageSuccess = (quizId, image, response) => ({
	type: UPLOAD_QUESTION_IMAGE_SUCCESS,
	quizId,
	payload: response
});

export const uploadQuestionImageFailure = (quizId, image, error, errors) => ({
	type: UPLOAD_QUESTION_IMAGE_FAILURE,
	error,
	errors
});

export function shouldFetchQuestions(state) {
	const trainingQuizzes = state.trainingQuizzes;
	return Object.values(trainingQuizzes.quiz.questions).length === 0 && !trainingQuizzes.isFetching;
}

export function fetchQuestionsIfNeeded(quizId) {
	return (dispatch, getState) => {
		if (shouldFetchQuestions(getState(), quizId)) {
			return dispatch(getQuestions(quizId));
		}
	};
}
