import React, { memo, useEffect, useCallback, useMemo, useRef } from 'react';
import { modes } from './logic/constants';
import TimePeriodPopoverTab from './tabs/TimePeriodPopoverTab';
import useTimePeriodSelectorState from './logic/useTimePeriodSelectorState';
import { formatButtonLabel } from './tabs/languageFormatters/buttonLabelFormatter';
import { createInitialState } from './logic/reducer';
import useDynamicCalendarSizing from './logic/useDynamicCalendarSizing';
import styled from 'styled-components';
import CalendarTab from './tabs/CalendarTab';
import AllTimeTab from './tabs/AllTimeTab';
import AdvancedTab from './tabs/AdvancedTab';
import PresetsTab from './tabs/PresetsTab';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import deepEqual from 'fast-deep-equal';
import { mapToTimePeriodApiModel } from './logic/optionsModelMapper';
import usePreview from './logic/usePreview';
import PopupInitiator from '../common/popupInitiator/PopupInitiator';
import { Input, Icon } from '@plecto/ui';
import { Loader } from '../common/loaders/Loader';

const StyledInputWrapper = styled.div`
	position: relative;
`;

const StyledInput = styled(Input)`
	box-sizing: border-box;
	padding-right: 20px;
	cursor: pointer;
`;

const StyledIcon = styled(Icon)`
	position: absolute;
	right: 12px;
	top: 8px;
	color: #CDD0D4;
`;

function TimePeriodSelectorControl({
	openOnStart, 
	onChange, 
	onSave,
	onCancel,
	timePeriodOptions,
	onSelectorOpen
}) {
	const { state, actions } = useTimePeriodSelectorState(timePeriodOptions);
	const lastApiOptions = useRef(mapToTimePeriodApiModel(state));
	
	useDidUpdateEffect(() => {
		if (onChange) {
			const mapped = mapToTimePeriodApiModel(state);
			if (!deepEqual(mapped, lastApiOptions.current)) {
				lastApiOptions.current = mapped;
				onChange(state);
			}
		}
	}, [state, onChange]);

	const setEditMode = useCallback((next) => {
		onSelectorOpen?.(next);
	}, [onSelectorOpen]);

	useEffect(() => {
		actions.reInit(timePeriodOptions);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [actions, timePeriodOptions]);

	const onTabCancel = useCallback(() => {
		actions.reInit(timePeriodOptions);
		setEditMode(false);
		onCancel?.();
	}, [actions, onCancel, timePeriodOptions, setEditMode]);

	const onTabSave = useCallback(() => {
		onSave(state);
		setEditMode(false);
	}, [onSave, state, setEditMode]);

	const { preview, isFetching, operationId: previewOperationId } = usePreview(state);
	
	const { label, isFetching: isFetchingLabel } =
		useMemo(() =>
			formatButtonLabel(createInitialState(timePeriodOptions), null, preview, isFetching),
		[timePeriodOptions, preview, isFetching]);

	useDynamicCalendarSizing();

	return <PopupInitiator
		placement='bottom-start'
		closeOnOutsideClick
		initiallyOpen={!!openOnStart}
		popup={({ popupRef, closePopup }) => {
			const onCancel = () => {
				onTabCancel();
				closePopup();
			};
			const onSave = () => {
				onTabSave();
				closePopup();
			};
			return <TimePeriodPopoverTab
				ref={popupRef}
				onCancel={onCancel}
				onSave={onSave}
				state={state}
				changeMode={actions.changeMode}
				changeToPresetMode={actions.changeToPresetMode}
				clearPreset={actions.clearPreset}
				padded={state.mode !== modes.relative || !state.preset}
				preview={preview}
				previewOperationId={previewOperationId}>
				{state.mode === modes.relative ?
					(state.preset ?
						<PresetsTab
							selectedPreset={state.preset}
							actions={actions}
							startDate={state.relativeStartDate}
							endDate={state.relativeEndDate}/> :
						<AdvancedTab
							startDate={state.relativeStartDate}
							endDate={state.relativeEndDate}
							actions={actions}
							visibility={state.visibility}
							offset={state.offset}
							timeRange={state.timeRange}
						/>) : null}
				{state.mode === modes.allTime && <AllTimeTab date={state.allTimeEndDate} actions={actions} />}
				{state.mode === modes.custom && <CalendarTab customDate={state.customDate} actions={actions} />}
			</TimePeriodPopoverTab>;
		}
		}
	>
		{({ initiatorRef, togglePopup, isOpen }) => {
			const onClick = () => {
				if (!isOpen)
					setEditMode(true);
				togglePopup();
			};
			return <StyledInputWrapper ref={initiatorRef}>
				<StyledInput
					readOnly
					disabled={isFetchingLabel}
					value={label}
					onClick={onClick}
				/>
				{isFetchingLabel ? <Loader size={'sm'} /> : <StyledIcon icon={'calendar'} />}
			</StyledInputWrapper>;
		}
		}
	</PopupInitiator>;
}

export default memo(TimePeriodSelectorControl);

