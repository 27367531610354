import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

const StyledIconWrapper = styled.div`
	display: inline-block;
`;

type Props = {
	name: string,
	className?: string
}

export const Icon = ({ className, name, ...props }: Props) => {
	return <StyledIconWrapper>
		<span className={classNames(`icon icon-${name}`, className)} {...props} />
	</StyledIconWrapper>;
};

Icon.propTypes = {
	name: PropTypes.string.isRequired,
	className: PropTypes.string
};
