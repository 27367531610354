import React, { memo } from 'react';
import PropTypes from 'prop-types';
import RelativeModeSelector from './RelativeModeSelector';
import { relativeModes, periods, getRelativeModesOptions } from '../logic/constants';
import Checkbox from '../../common/checkbox/Checkbox';
import HelpInline from '../../common/helpInline/HelpInline';
import OptionEnabler from './OptionEnabler';
import { useTranslation } from 'react-i18next';
import usePeriodOptions from '../hooks/usePeriodOptions';

function EndDateSelector({ relativeEndDate, actions, disabled }) {
	const periodOptions = usePeriodOptions();
	const options = relativeEndDate.options || {
		minAmount: 1,
		modesOptions: getRelativeModesOptions(),
		periodOptions: periodOptions,
	};
	const { t } = useTranslation();
	return <React.Fragment>
		<OptionEnabler className="time-period-option-enabler">
			<Checkbox
				checked={relativeEndDate.enabled && !disabled}
				onToggle={actions.toggleEndDate}
				label={t('Use custom end date')}
				disabled={disabled}
			/>
			{disabled && <HelpInline text={t('Custom end date is only available in Current and Previous mode')} />}
		</OptionEnabler>
		{relativeEndDate.enabled && !disabled && <RelativeModeSelector
			onModeChange={actions.changeEndMode}
			onPeriodChange={actions.changeEndPeriod} 
			mode={relativeEndDate.mode} 
			period={relativeEndDate.period} 
			amount={relativeEndDate.amount} 
			onAmountChange={actions.changeEndAmount}
			fixedAmount={relativeEndDate.fixedAmount}
			minimumAmount={options.minAmount}
			maximumAmount={options.maxAmount}
			modesOptions={options.modesOptions}
			periodsOptions={options.periodOptions}
			disabled={!relativeEndDate.enabled}
			label={t('To end of:')}
		/>}
	</React.Fragment>;
}

EndDateSelector.propTypes = {
	actions: PropTypes.shape({
		toggleEndDate: PropTypes.func.isRequired,
		changeEndMode: PropTypes.func.isRequired,
		changeEndPeriod: PropTypes.func.isRequired,
		changeEndAmount: PropTypes.func.isRequired,
	}).isRequired,
	relativeEndDate: PropTypes.shape({
		enabled: PropTypes.bool.isRequired,
		mode: PropTypes.oneOf(Object.values(relativeModes)),
		amount: PropTypes.number,
		period: PropTypes.oneOf(Object.values(periods)),
		fixedAmount: PropTypes.bool
	}).isRequired
};

export default memo(EndDateSelector);
