import { takeLatest } from 'redux-saga/effects';
import {
	FETCH, fetchTimeZonesFailure, fetchTimeZonesSuccess
} from './actions';
import { getTimeZones } from './api';
import apiSagaGenerator from '../../saga/apiSagaGenerator';

const timeZonesSagas = [
	takeLatest(FETCH, 
		apiSagaGenerator()
			.forApi(getTimeZones)
			.forSuccess(fetchTimeZonesSuccess)
			.forError(fetchTimeZonesFailure)
			.withMessage('Error getting time zones')
			.generate() 
	),
];

export default timeZonesSagas;
