import {
	FETCH,
	FETCH_SUCCESS,
	FETCH_API_ERROR,
	FETCH_ALL,
	FETCH_ALL_SUCCESS,
	FETCH_ALL_API_ERROR,
	FETCH_DATASOURCE_SUCCESS
} from './actions';

const initialState = {
	data: [],
	isFetching: false,
	error: null,
	dataSourcesById: {}
};

const datasources = (state = initialState, action) => {
	switch (action.type) {
	case FETCH:
	case FETCH_ALL:
		return {
			...state,
			isFetching: true,
		};
	case FETCH_SUCCESS:
	case FETCH_ALL_SUCCESS:
		return {
			...state,
			data: action.result,
			isFetching: false,
			error: null,
		};
	case FETCH_API_ERROR:
	case FETCH_ALL_API_ERROR:
		return {
			...state,
			error: action.error,
			isFetching: false,
		};
	case FETCH_DATASOURCE_SUCCESS:
		return {
			...state,
			dataSourcesById: {
				...state.dataSourcesById,
				[action.payload.uuid]: action.payload
			}
		};
	default:
		return state;
	}
};

export default datasources;
