import { apiGet, apiVersionV3 } from '../../../fetch/apiService';

// eslint-disable-next-line max-params
export function getMembers(
	searchValue = '',
	permissionProfiles = [],
	teams = [],
	dynamicDashboardId,
	pageNumber = 1,
	pageSize = 50,
	compact = false,
	loginAccess = false,
	excludeSelf = false,
	managed = false,
) {
	return apiGet(
		'members',
		{
			page: pageNumber,
			page_size: pageSize,
			name__icontains: searchValue,
			permission_profile__in: permissionProfiles.join(','),
			team__in: teams.join(','),
			...(loginAccess && { login_access: loginAccess }),
			...(excludeSelf && { exclude_self: excludeSelf }),
			...(dynamicDashboardId && { for_dynamic_dashboard: dynamicDashboardId }),
			...(managed && { managed: true }),
			compact: compact,
		},
		{},
		apiVersionV3,
	);
}
