export const SAVE_SLIDESHOW = 'plecto/slideshow/SAVE_SLIDESHOW';
export const SAVE_SLIDESHOW_SUCCESS = 'plecto/slideshow/SAVE_SLIDESHOW_SUCCESS';
export const SAVE_SLIDESHOW_API_ERROR = 'plecto/slideshow/SAVE_SLIDESHOW_API_ERROR';

export const saveSlideshow = (slideshowId, model) => ({
	type: SAVE_SLIDESHOW,
	slideshowId,
	model,
});

export const saveSlideshowSuccess = (slideshowId, model, payload) => ({
	type: SAVE_SLIDESHOW_SUCCESS,
	slideshowId,
	payload,
	model,
});

export const saveSlideshowFailure = (slideshowId, model, error) => ({
	type: SAVE_SLIDESHOW_API_ERROR,
	slideshowId,
	model,
	error,
});
