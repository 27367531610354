import { apiDelete, apiGet, apiPost, apiPut } from '../../fetch/apiService';
import _pick from 'lodash/pick';
import _isFinite from 'lodash/isFinite';

export function createFormula(formula) {
	return apiPost('report-group-formulas', formula);
}

export function deleteFormula(reportGroupFormulaId) {
	return apiDelete(`report-group-formulas/${reportGroupFormulaId}`);
}

export function getComments(reportGroupFormulaId) {
	return apiGet(`report-group-formulas/${reportGroupFormulaId}/comments`);
}

export function addComment(reportGroupFormulaId, comment) {
	return apiPost(`report-group-formulas/${reportGroupFormulaId}/comments`, comment);
}

export function drilldownBackgroundRenderRequest(reportGroupFormulaId, appliedDimension, pageNumber) {
	return apiGet(`report-group-formulas/${reportGroupFormulaId}/background_render`, {
		...mapDimensionModelToApiModel(appliedDimension),
		...(_isFinite(pageNumber) ? { page_number: pageNumber } : {})
	});
}

export function drilldownBackgroundRenderStatus(reportGroupFormulaId, taskId) {
	return apiGet(`report-group-formulas/${reportGroupFormulaId}/background_render_result/${taskId}`, {
	});
}

export function getSavedDrilldownsRender(reportGroupFormulaId) {
	return apiGet(`report-group-formulas/${reportGroupFormulaId}/render_saved/`);
}

export function getSavedDrilldownsRenderPage(reportGroupFormulaId, pageNumber) {
	return apiGet(`report-group-formulas/${reportGroupFormulaId}/render_saved/`, { page_number: pageNumber });
}

export function mapDimensionModelToApiModel(dimension) {
	return _pick(dimension, ['grouping_field', 'group_by']);
}

export function saveDimension(reportGroupFormulaId, dimension, signal) {
	if (dimension) {
		return apiPost(`report-group-formulas/${reportGroupFormulaId}/dimension`, dimension, { signal });
	} else {
		return apiDelete(`report-group-formulas/${reportGroupFormulaId}/dimension`, {}, null, { signal });
	}
}

export function saveSorting(reportGroupFormulaId, nextOrdering, signal) {
	return apiPut(`report-group-formulas/${reportGroupFormulaId}/drilldown_ordering`, {
		drilldown_ordering: nextOrdering
	}, { signal });
}

