import React, { useCallback, useState, useContext, useMemo, useEffect } from 'react';
import moment from 'moment';
import { IMaskInput } from 'react-imask';
import {
	addDays,
	endOfDay,
} from 'date-fns';
import LocaleContext from '../../../contexts/LocaleContext';
import convertToMomentLocale from '../../utils/convertToMomentLocale';

function getPattern(locale) {
	const momentLocale = convertToMomentLocale(locale);
	const dateFormat = moment().locale(momentLocale).localeData().longDateFormat('L');
	let pattern = dateFormat.replace(/DD/ig, 'd`');
	pattern = pattern.replace(/MM/ig, 'm`');
	pattern = pattern.replace(/YYYY/ig, '`Y');
	return { pattern, dateFormat };
}

export default function DateInput({ className, value, onChange, min, max }) {
	const locale = useContext(LocaleContext);
	const { pattern, dateFormat } = useMemo(() => getPattern(locale),[locale]);
	const [localValue, setValue] = useState(moment(value).format(dateFormat));

	useEffect(() => {
		setValue(moment(value).format(dateFormat));
	}, [value, dateFormat]);

	const onChangeCallback = useCallback((val) => {
		setValue(val.target.value);
	},[]);
	const onAccept = useCallback((val) => {
		const convertedValue = moment(val, dateFormat).toDate();
		onChange(convertedValue);
		setValue(moment(convertedValue).format(dateFormat));
	},[onChange, dateFormat]);

	const format = useCallback((date) => {
		return moment(date).format(dateFormat);
	},[dateFormat]);

	const parse = useCallback((val) => {
		return moment(val, dateFormat);
	},[dateFormat]);

	const minFixed = useMemo(() => {
		if (min) {
			return endOfDay(addDays(min, -1));
		}
		return min;
	},[min]);
	const maxFixed = useMemo(() => {
		if (max) {
			return endOfDay(max);
		}
		return max;
	},[max]);
	return <IMaskInput
		className={className}
		value={localValue}
		autofix={Boolean(min || max)}
		mask={Date}
		pattern={pattern}
		lazy={false}
		onInput={onChangeCallback}
		onComplete={onAccept}
		format={format}
		parse={parse}
		min={minFixed}
		max={maxFixed}
	/>;
}
