import { useCallback, useRef } from 'react';
import { useBoolState } from '../../utils/useBoolState';
import useOutsideClick from '../../../hooks/useOutsideClick';

type UseFilterDropdownProps = {
    onClose?: (selection: string[]) => void;
    onOpen?: () => void;
	selection: string[];
}

export const useFilterDropdown = ({ onClose, onOpen, selection }: UseFilterDropdownProps) => {
	const [isOpen, open, close] = useBoolState();

	const buttonRef = useRef<HTMLDivElement>(null);
	const menuRef = useRef<HTMLDivElement>(null);

	const onDropdownClose = useCallback((selection: string[]) => {
		close();
		onClose?.(selection);
	}, [close, onClose]);

	const handleToggle = useCallback(() => {
		if (isOpen) {
			onDropdownClose(selection);
		} else {
			open();
			onOpen?.();
		}
	}, [isOpen, onDropdownClose, onOpen, open, selection]);

	const onClickOutside = useCallback(() => onDropdownClose(selection), [onDropdownClose, selection]);

	useOutsideClick([menuRef, buttonRef], onClickOutside, isOpen);

	return {
		isOpen,
		onDropdownClose,
		onOpen: open,
		onDropdownToggle: handleToggle,
		buttonRef,
		menuRef,
	};
};
