import React from 'react';
import _compact from 'lodash/compact';
import { RadiosFieldOptionControl } from './RadioFieldOption';

export default function RadiosFieldSet({ children, selectedOption, onSelect }) {
	const buttons = _compact(React.Children.map(children, element => {
		if (!React.isValidElement(element)) return null;
		const { label, option, help } = element.props;
		const selected = selectedOption === option;
		return <RadiosFieldOptionControl
			label={label}
			optionSelected={selected}
			option={option}
			onSelect={onSelect}
			help={help}
		/>;
	}));

	return <div>
		<div>
			{buttons}
		</div>
	</div>;
}
