import { call, put, takeLatest } from '@redux-saga/core/effects';
import * as favoritesActions from './actions';
import * as api from './api';

function* fetchFavorites() {
	try {
		const response = yield call(
			api.fetchFavorites,
		);
		const result = yield response.json();

		if (result.detail) {
			yield put(favoritesActions.fetchFavoritesError(result.detail));

		} else {
			const uuids = [];
			result.forEach(item => {
				uuids.push(item.dashboard_uuid);
			});
			yield put(favoritesActions.fetchFavoritesSuccess(result, uuids));
		}
	} catch (e) {
		yield put(favoritesActions.fetchFavoritesError(e.message));
	}
}

function* addFavorite(action) {
	try {
		const response = yield call(
			api.addFavorite,
			action.body,
			action.memberUuid,
		);
		const result = yield response.json();

		if (result.error) {
			yield put(favoritesActions.addFavoriteError(result.error));
		} else {
			yield put(favoritesActions.addFavoriteSuccess(result));
		}
	} catch (e) {
		yield put(favoritesActions.addFavoriteError(e.message));
	}
}

function* deleteFavorite(action) {
	try {
		const response = yield call(
			api.deleteFavorite,
			action.memberUuid,
			action.uuid,
		);

		if (response.ok) {
			yield put(
				favoritesActions.deleteFavoriteSuccess(
					action.uuid,
					action.dashboardUuid,
					action.undoAction,
				),
			);
		} else {
			yield put(favoritesActions.deleteFavoriteError(response.json()));
		}
	} catch (e) {
		yield put(favoritesActions.deleteFavoriteError(e.message));
	}
}

const favoritesSagas = [
	takeLatest(favoritesActions.FETCH, fetchFavorites),
	takeLatest(favoritesActions.ADD, addFavorite),
	takeLatest(favoritesActions.DELETE, deleteFavorite),
];

export default favoritesSagas;
