import classNames from 'classnames';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { LoaderSizes, cssSizes, loaderSizes } from './loaderSizes';
import { COLORS_BRAND } from '../../styles/colors';

const loaderKeyFrames = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`;

const StyledLoader = styled.div<Props>`
	display: inline-block;
	width: ${props => (cssSizes[props.size] || cssSizes.default).width};
	height: ${props => (cssSizes[props.size] || cssSizes.default).width};
	margin: 5px;
	border: solid ${props => (cssSizes[props.size] || cssSizes.default).border} transparent;
	border-top-color: ${COLORS_BRAND.secondary};
	border-left-color:  ${COLORS_BRAND.secondary};
	border-radius: 100%;
	animation: ${loaderKeyFrames} 800ms linear infinite;

	.btn & {
		width: 18px;
		height: 18px;
		border-top-color: #fff;
		border-left-color: #fff;
		margin: -2px 5px 0 5px;
		vertical-align: middle;
	}
`;

type Props = {
	size: LoaderSizes,
	className?: string,
}

export const Loader = ({ size, className }: Props) =>
	<StyledLoader className={classNames('loader', className)} size={size} />;

Loader.defaultProps = {
	size: loaderSizes.sm,
};
