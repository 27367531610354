import { directoryTypes } from './directoryTypes';

export function getFoldersEndpointByType({ directoryType }) {
	switch (directoryType) {
	case directoryTypes.formula:
		return 'formula';
	case directoryTypes.dataSource:
		return 'datasource';
	case directoryTypes.oneOnOneTemplate:
		return 'oneononetemplate';
	default:
		throw new Error('Unsupported directory type');
	}
}

export function getItemsEndpointByType({ directoryType }) {
	switch (directoryType) {
	case directoryTypes.formula:
		return 'formulas';
	case directoryTypes.dataSource:
		return 'datasources';
	case directoryTypes.oneOnOneTemplate:
		return 'one-on-one-templates';
	case directoryTypes.employee:
		return 'members';
	default:
		throw new Error('Unsupported directory type');
	}
}

export function getSearchEndpointByType(directoryId) {
	const { directoryType } = directoryId;
	switch (directoryType) {
	case directoryTypes.formula:
	case directoryTypes.dataSource:
	case directoryTypes.oneOnOneTemplate:
		return `folders/${getFoldersEndpointByType(directoryId)}/search`;
	case directoryTypes.employee:
		return 'members/search';
	default:
		throw new Error('Unsupported directory type');
	}
}
