import React from 'react';
import Toast from './Toast';
import styled from 'styled-components';
import { smallScreenWidth, toastsZIndex } from './consts';
import { useSelector } from 'react-redux';

const top = 90;

const StyledBlock = styled.div`
	position: fixed;
	top: ${top}px;
	left: 20px;
	margin-top: 40px;
	z-index: ${toastsZIndex};

	@media (min-width: ${smallScreenWidth}px) {
		left: 50%;
		transform: translateX(-50%);
	}
`;

function ToastsBlock() {
	const toasts = useSelector((state) => state.app.toasts.active);
	return toasts.length ? (
		<StyledBlock className={'toast-block'}>
			{toasts.map(t => <Toast
				key={t.uuid}
				{...t} />)}
		</StyledBlock>
	) : null;
}

export default React.memo(ToastsBlock);

