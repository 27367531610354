import { FETCH, FETCH_SUCCESS } from './actions';

const mapPermissionProfile = (permissionProfile) => ({
	uuid: permissionProfile.uuid,
	name: permissionProfile.name
});

const permissionProfiles = (state = { isFetching: false, permissionProfiles: [], isStale: true }, action) => {
	switch (action.type) {
	case FETCH:
		return {
			...state,
			isFetching: true,
			isStale: false,
		};
	case FETCH_SUCCESS: {
		return {
			...state,
			permissionProfiles: action.payload.map(mapPermissionProfile),
			isFetching: false,
		};
	}
	default:
		return state;
	}
};

export { permissionProfiles };
