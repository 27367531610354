import _find from 'lodash/find';
import _isArray from 'lodash/isArray';
import _compact from 'lodash/compact';
import _flatten from 'lodash/flatten';
import { useMemo } from 'react';

const useValueArray = (value, options, creatable) => {
	const valueArray = useMemo(() => {
		const selectedValues = _isArray(value) ? value : [value];
		const allOptions = _flatten(creatable ? options.concat({ value: value, label: value }) : options
			.map(opt => opt.options ? opt.options : [opt]));

		const selectedOptions =
			_compact(selectedValues.map(selectedValue =>
				_find(allOptions, { value: selectedValue }) ||
				_find(allOptions, { value: String(selectedValue) })
			));
		return selectedOptions;
	}, [value, creatable, options]);

	return valueArray;
};

export default useValueArray;
