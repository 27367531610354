import { useRef, useEffect } from 'react';

export default function useDidMountEffect(fn) {
	const didMountRef = useRef(false);
  
	useEffect(() => {
		if (!didMountRef.current) {
			didMountRef.current = true;
			fn();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
