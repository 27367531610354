export const FETCH = 'plecto/v3/writtenUpdateTemplates/FETCH';
export const FETCH_SUCCESS = 'plecto/v3/writtenUpdateTemplates/FETCH_SUCCESS';
export const FETCH_API_ERROR = 'plecto/v3/writtenUpdateTemplates/FETCH_API_ERROR';

export const fetchWrittenUpdateTemplates = () => ({
	type: FETCH
});

export const fetchWrittenUpdateTemplatesSuccess = (payload) => ({
	type: FETCH_SUCCESS,
	payload,
});

export const fetchWrittenUpdateTemplatesFailure = (error) => ({
	type: FETCH_API_ERROR,
	error
});
