import { takeLatest } from 'redux-saga/effects';
import {
	FETCH,
	FETCH_FILTERED,
	FETCH_TEAMS_COMPACT,
	fetchTeamsFailure,
	fetchTeamsFilteredSuccess,
	fetchTeamsSuccess,
	fetchTeamsCompactSuccess,
} from './actions';
import { getTeams } from './api';
import apiSagaGenerator from '../../../saga/apiSagaGenerator';

const teamsV3Sagas = [
	takeLatest(FETCH,
		apiSagaGenerator()
			.forApi(getTeams)
			.withArguments((action) => [
				action.dynamicDashboardId ? (getParentId(action) || 'first_level') : getParentId(action),
				null,
				false,
				false,
				action.dynamicDashboardId,
			])
			.forSuccess(fetchTeamsSuccess)
			.withActionArguments((action) => [action.parentIdsList])
			.forError(fetchTeamsFailure)
			.withMessage('Error getting teams')
			.generate(),
	),
	takeLatest(FETCH_FILTERED,
		apiSagaGenerator()
			.forApi(getTeams)
			.withArguments((action) => [
				'',
				action.searchValue,
				false,
				false,
				action.dynamicDashboardId,
			])
			.forSuccess(fetchTeamsFilteredSuccess)
			.withActionArguments(() => [])
			.forError(fetchTeamsFailure)
			.withMessage('Error getting teams')
			.generate(),
	),
	takeLatest(FETCH_TEAMS_COMPACT,
		apiSagaGenerator()
			.forApi(getTeams)
			.withArguments((action) => [
				'',
				'',
				true,
				action.withMembersOnly,
				action.dynamicDashboardId,
			])
			.forSuccess(fetchTeamsCompactSuccess)
			.withActionArguments(() => [])
			.forError(fetchTeamsFailure)
			.withMessage('Error getting teams')
			.generate(),
	),
];

const getParentId = (action) => action.parentIdsList[action.parentIdsList.length - 1];

export default teamsV3Sagas;
