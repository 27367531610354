import { ACHIEVEMENT_BADGE_COLORS as COLORS } from './achievementBadgeColors';
import { ACHIEVEMENT_BADGE_THEMES as THEMES } from './achievementBadgeThemes';

const DEFAULT = {
	[THEMES.light]: {
		icon: {
			color: '#FFFFFF',
		},
		badge: {
			stroke: '#0F1E38',
			fill: '#2D415A',
		},
		counter: {
			color: '#FFFFFF',
			background: '#126BDD',
		},
	},
	[THEMES.dark]: {
		icon: {
			color: '#FFFFFF',
		},
		badge: {
			stroke: '#B8CCF0',
			fill: '#566583',
		},
		counter: {
			color: '#0F1E38',
			background: '#FFFFFF',
		},
	},
};

const GOLD = {
	[THEMES.light]: {
		icon: {
			color: '#754605',
		},
		badge: {
			stroke: '#FAA201',
			fill: '#FEBD01',
		},
		counter: {
			color: '#FFFFFF',
			background: '#000000',
		},
	},
	[THEMES.dark]: {
		icon: {
			color: '#1A253F',
		},
		badge: {
			stroke: '#FED767',
			fill: '#FEBD01',
		},
		counter: {
			color: '#0F1E38',
			background: '#FFFFFF',
		},
	},
};

const SILVER = {
	[THEMES.light]: {
		icon: {
			color: '#1A253F',
		},
		badge: {
			stroke: '#B5C1D1',
			fill: '#E1E8F1',
		},
		counter: {
			color: '#FFFFFF',
			background: '#000000',
		},
	},
	[THEMES.dark]: {
		icon: {
			color: '#1A253F',
		},
		badge: {
			stroke: '#F0F7FF',
			fill: '#C3CEDD',
		},
		counter: {
			color: '#0F1E38',
			background: '#FFFFFF',
		},
	},
};

const BRONZE = {
	[THEMES.light]: {
		icon: {
			color: '#1A253F',
		},
		badge: {
			stroke: '#D7702E',
			fill: '#EF9C58',
		},
		counter: {
			color: '#FFFFFF',
			background: '#000000',
		},
	},
	[THEMES.dark]: {
		icon: {
			color: '#FFFFFF',
		},
		badge: {
			stroke: '#F49547',
			fill: '#D6783C',
		},
		counter: {
			color: '#0F1E38',
			background: '#FFFFFF',
		},
	},
};

const PURPLE = {
	[THEMES.light]: {
		icon: {
			color: '#FFFFFF',
		},
		badge: {
			stroke: '#6258DD',
			fill: '#7F77FF',
		},
		counter: {
			color: '#FFFFFF',
			background: '#000000',
		},
	},
	[THEMES.dark]: {
		icon: {
			color: '#FFFFFF',
		},
		badge: {
			stroke: '#9992FF',
			fill: '#7269E0',
		},
		counter: {
			color: '#0F1E38',
			background: '#FFFFFF',
		},
	},
};

const MONOCHROME = {
	[THEMES.light]: {
		icon: {
			color: '#FFFFFF',
		},
		badge: {
			stroke: '#0F1E38',
			fill: '#0F1E38',
		},
		counter: {
			color: '#0F1E38',
			background: '#FFFFFF',
		},
	},
	[THEMES.dark]: {
		icon: {
			color: '#0F1E38',
		},
		badge: {
			stroke: '#FFFFFF',
			fill: '#FFFFFF',
		},
		counter: {
			color: '#FFFFFF',
			background: '#0F1E38',
		},
	},
};

export const ACHIEVEMENT_COLOR_THEMES = {
	[COLORS.default]: DEFAULT,
	[COLORS.gold]: GOLD,
	[COLORS.silver]: SILVER,
	[COLORS.bronze]: BRONZE,
	[COLORS.purple]: PURPLE,
	[COLORS.monochrome]: MONOCHROME,
};

export function getAchievementColorTheme({ color, theme }) {
	const colorProps = ACHIEVEMENT_COLOR_THEMES[color] || ACHIEVEMENT_COLOR_THEMES[COLORS.default];
	return colorProps[theme] || colorProps[THEMES.light];
}
