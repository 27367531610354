export default function convertToIntlLocale(organizationLocale) {
	switch (organizationLocale) {
	case 'da_DK':
		return 'da-DK';
	case 'sv_SE':
		return 'sv-SE';
	case 'nb_NO':
		return 'nb-NO';
	case 'en_GB':
		return 'en-gb';
	case 'de_DE':
		return 'de-DE';
	case 'pl_PL':
		return 'pl-PL';
	case 'en_US':
	default:
		return 'en-us';
	}
}
