import { report } from '../models/report/reducers';
import { formulas } from '../models/formula/reducers';
import { members } from '../models/member/v2/reducers';
import { reportGroupsById } from '../models/reportGroup/reducers';
import { reportGroupFormulasById } from '../models/reportGroupFormula/reducers';
import { schedules } from '../models/reportSchedule/reducers';
import { teams } from '../models/team/v2/reducers';
import dashboards from '../models/dashboards/reducers';
import datasources from '../models/datasources/reducers';
import favorites from '../models/favorites/reducers';
import myTeams from '../models/my-teams/reducers';
import registrations from '../models/registrations/reducers';
import widgets from '../models/widgets/reducers';
import registrationPreviews from '../models/registrationPreviews/reducers';
import timePeriod from '../models/timePeriod/reducers';
import dataSourceVariables from '../models/dataSourceVariables/reducers';
import devices from '../models/devices/reducers';
import announcements from '../models/announcements/reducer';
import screens from '../models/screens/reducer';
import { membersV3 } from '../models/member/v3/reducers';
import { teamsV3 } from '../models/team/v3/reducers';
import { permissionProfiles } from '../models/permissionProfiles/reducers';
import timeZones from '../models/timeZones/reducer';
import sonosDevices from '../models/sonosDevices/reducer';
import hueDevices from '../models/hueDevices/reducer';
import { writtenUpdateTemplates } from '../models/writtenUpdateTemplates/reducers';
import { writtenUpdateSchedules } from '../models/writtenUpdateSchedules/reducers';
import { writtenUpdates } from '../models/writtenUpdates/reducers';
import directory from '../models/directory/reducer';
import { achievementTypes } from '../models/achievementTypes/reducers';
import { trainingQuizzes } from '../models/trainingQuizzes/reducers';
import exportJobs from '../models/exportJobs/reducers';
import { slideshows } from '../models/slideshows/reducers';
import oneOnOnes from '../models/oneOnOnes/reducers';

const apiReducers = {
	achievementTypes,
	announcements,
	dashboards,
	dataSourceVariables,
	datasources,
	devices,
	directory,
	exportJobs,
	favorites,
	formulas,
	members,
	membersV3,
	myTeams,
	oneOnOnes,
	permissionProfiles,
	registrationPreviews,
	registrations,
	report,
	reportGroupFormulasById,
	reportGroupsById,
	schedules,
	screens,
	slideshows,
	sonosDevices,
	hueDevices,
	teams,
	teamsV3,
	timePeriod,
	timeZones,
	trainingQuizzes,
	widgets,
	writtenUpdateSchedules,
	writtenUpdateTemplates,
	writtenUpdates,
};

export default apiReducers;

