import {
	GET_COMMENTS, GET_COMMENTS_FAILURE, GET_COMMENTS_SUCCESS
} from './actions';

const oneOnOnes = (state = { isFetching: false, comments: [] }, action) => {
	switch (action.type) {
	case GET_COMMENTS:
		return {
			...state,
			isFetching: true,
		};
	case GET_COMMENTS_SUCCESS: {
		return {
			...state,
			comments: action.payload,
			isFetching: false,
		};
	}
	case GET_COMMENTS_FAILURE:
		return {
			...state,
			isFetching: false,
		};
	default:
		return state;
	}
};

export default oneOnOnes;
