import { apiDelete, apiGet, apiPost, apiVersionV3 } from '../../fetch/apiService';

export function getComments(oneOnOneId) {
	return apiGet(
		`one-on-ones/${oneOnOneId}/comments`,
		{},
		{},
		apiVersionV3
	);
}

export function createComment(oneOnOneId, comment) {
	return apiPost(
		`one-on-ones/${oneOnOneId}/comments/`,
		comment,
		{},
		{},
		apiVersionV3
	);
}

export function deleteComment(oneOnOneId, commentId) {
	return apiDelete(
		`one-on-ones/${oneOnOneId}/comments/${commentId}/`,
		{},
		{},
		{},
		apiVersionV3
	);
}
