import React, { useCallback, useMemo } from 'react';
import { generateMonthNumbers, getMonthOptions, monthsToNumberOfDays } from '../logic/constants';
import Dropdown from '../../common/dropdown/Dropdown';
import styled from 'styled-components';
import _clamp from 'lodash/clamp';
import { useTranslation } from 'react-i18next';

const S = {};

S.Container = styled.div`
	display: flex;
`;

S.Label = styled.span`
	color: #666666;
	flex: 0 1 auto;
`;

S.Month = styled(Dropdown)`
	flex: 0 1 9em;
`;
S.Number = styled(Dropdown)`
	flex: 0 1 5em;
`;

const dayOfMonthOptions = generateMonthNumbers();
const monthsOptions = getMonthOptions();
export default function YearStartPoint({ startingOnMonth = 1, startingOnDay = 1, actions }) {
	const onMonthChange = useCallback((nextMonth) => {
		actions.changeYearStartPoint(nextMonth, _clamp(startingOnDay, 1, monthsToNumberOfDays[nextMonth]));
		
	},[actions, startingOnDay]);

	const onDayChange = useCallback((nextDay) => {
		actions.changeYearStartPoint(startingOnMonth, _clamp(nextDay, 1, monthsToNumberOfDays[startingOnMonth]));
		
	},[actions, startingOnMonth]);

	const dayOptions = useMemo(() => {
		return dayOfMonthOptions.slice(0, monthsToNumberOfDays[startingOnMonth]);
	}, [startingOnMonth]);
	const { t } = useTranslation();
	return (
		<S.Container>
			<S.Label className={'mode-selector-label'}>
				{t('Year starts on')}
			</S.Label>
			<S.Month
				onValueChange={onMonthChange}
				value={startingOnMonth}
				options={monthsOptions}
			/>
			<S.Number
				onValueChange={onDayChange}
				value={startingOnDay}
				options={dayOptions}
			/>
		</S.Container>
	);
}
