import { takeLatest, takeEvery, put, call } from 'redux-saga/effects';
import {
	CHANGE_FORMULA_ORDER,
	changeReportGroupFormulaOrdersSuccess,
	changeReportGroupFormulaOrdersFailure,
	FETCH_RENDER,
	fetchReportGroupRenderSuccess,
	fetchReportGroupRenderFailure,
	ADD,
	createReportGroupSuccess,
	createReportGroupFailure,
	UPDATE,
	saveReportGroupSuccess,
	saveReportGroupFailure,
	DELETE,
	deleteReportGroupSuccess,
	deleteReportGroupFailure,
	COPY,
	copyReportGroupSuccess,
	copyReportGroupFailure,
	fetchReportGroupRenders,
	FETCH_RENDER_PAGE,
	fetchReportGroupRenderPageSuccess,
	fetchReportGroupRenderPageFailure,
	FETCH_RENDER_ALL,
	fetchReportGroupRenderAllSuccess,
	fetchReportGroupRenderAllFailure,
	fetchAllReportGroupRenders,
	UPDATE_SUCCESS,
	PREVIEW,
	previewSuccess,
	previewFailure,
} from './actions';
import {
	createGroup,
	deleteGroup,
	getRender,
	reorderFormulas,
	saveGroup,
	getRenderPage,
	copyGroup,
	getRenderAll,
	getPreviewRender,
} from './api';
import apiSagaGenerator from '../../saga/apiSagaGenerator';
import * as reportActions from '../report/actions';
import { initReportGroupDrilldownsRerender } from '../reportGroupFormula/actions';
import takeLatestBy from 'redux-saga-take-latest-by';

export const saveReportGroup = apiSagaGenerator()
	.forApi(saveGroup)
	.withArguments((action) => [action.reportGroupId, action.group])
	.forSuccess(saveReportGroupSuccess)
	.withActionArguments((action) => [
		action.reportGroupId,
		action.prefetchRenders,
	])
	.forError(saveReportGroupFailure)
	.withMessage('Error saving report group')
	.generate();

const reportGroupSagas = [
	takeLatest(
		CHANGE_FORMULA_ORDER,
		apiSagaGenerator()
			.forApi(reorderFormulas)
			.withArguments((action) => [
				action.reportGroupId,
				action.orderedReportGroupFormulaIds,
			])
			.forSuccess(changeReportGroupFormulaOrdersSuccess)
			.forError(changeReportGroupFormulaOrdersFailure)
			.withMessage('Error changing report group formulas order')
			.generate()
	),
	takeLatestBy(
		PREVIEW,
		apiSagaGenerator()
			.forApi(getPreviewRender)
			.cancellable()
			.withArguments((action) => [
				action.reportGroupId,
				action.previewSettings,
				action.allPages,
			])
			.forSuccess(previewSuccess)
			.withActionArguments((action) => [
				action.reportGroupId,
				action.previewId,
				action.allPages,
			])
			.forError(previewFailure)
			.withMessage('Error getting report group preview data')
			.generate(),
		(action) => action.reportGroupId
	),
	takeEvery(
		FETCH_RENDER_ALL,
		apiSagaGenerator()
			.forApi(getRenderAll)
			.withArguments((action) => [action.reportGroupId])
			.forSuccess(fetchReportGroupRenderAllSuccess)
			.forError(fetchReportGroupRenderAllFailure)
			.includeErrorFromResponse((res) => res.statusText)
			.withMessage('Error fetching report group render')
			.generate()
	),
	takeEvery(
		FETCH_RENDER,
		apiSagaGenerator()
			.forApi(getRender)
			.withArguments((action) => [action.reportGroupId])
			.forSuccess(fetchReportGroupRenderSuccess)
			.forError(fetchReportGroupRenderFailure)
			.includeErrorFromResponse((res) => res.statusText)
			.withMessage('Error fetching report group render')
			.generate()
	),
	takeEvery(
		FETCH_RENDER_PAGE,
		apiSagaGenerator()
			.forApi(getRenderPage)
			.withArguments((action) => [action.reportGroupId, action.page])
			.forSuccess(fetchReportGroupRenderPageSuccess)
			.forError(fetchReportGroupRenderPageFailure)
			.includeErrorFromResponse((res) => res.statusText)
			.withMessage('Error fetching report group render')
			.generate()
	),

	takeEvery(
		ADD,
		apiSagaGenerator()
			.forApi(createGroup)
			.withArguments((action) => [action.group])
			.forSuccess(createReportGroupSuccess)
			.withActionArguments((action) => [action.reportId])
			.forError(createReportGroupFailure)
			.withMessage('Error creating report group')
			.generate()
	),
	takeLatest(UPDATE, saveReportGroup),
	takeLatest(
		DELETE,
		apiSagaGenerator()
			.forApi(deleteGroup)
			.withArguments((action) => [action.reportGroupId])
			.forSuccess(deleteReportGroupSuccess)
			.skipResult()
			.forError(deleteReportGroupFailure)
			.withMessage('Error deleting report group')
			.generate()
	),
	takeEvery(
		COPY,
		apiSagaGenerator()
			.forApi(copyGroup)
			.withArguments((action) => [action.reportGroupId])
			.forSuccess(copyReportGroupSuccess)
			.withActionArguments((action) => [action.reportGroupId])
			.forError(copyReportGroupFailure)
			.withMessage('Error copying report group')
			.generate()
	),
	takeEvery(UPDATE_SUCCESS, fetchSingleReportGroup),
	takeEvery(reportActions.UPDATE_SUCCESS, fetchReportGroupsAfterSave),
];

function* fetchReportGroupsAfterSave(action) {
	const reportGroupIds = action.payload.report_groups;
	for (let i = 0; i < reportGroupIds.length; i++) {
		const reportGroup = reportGroupIds[i];
		yield call(fetchReportGroup, reportGroup);
	}
}

function* fetchSingleReportGroup(action) {
	if (action.prefetchRenders) {
		yield call(fetchReportGroup, action.payload);
	}
}

function* fetchReportGroup(reportGroup) {
	if (!reportGroup.widget_type || reportGroup.widget_type === 'table') {
		yield put(fetchReportGroupRenders(reportGroup.uuid));
	} else {
		yield put(fetchAllReportGroupRenders(reportGroup.uuid));
	}
	const reportGroupFormulas = reportGroup.formulas || [];

	for (const formula of reportGroupFormulas) {
		yield put(initReportGroupDrilldownsRerender(formula.uuid));
	}
}

export default reportGroupSagas;
