import React from 'react';
import styled from 'styled-components';
import { formOpacities } from '../formOpacities';
import { withCssSelector } from '../../shared/withCssSelector';

const StyledInput = styled.input<{
	$disabled?: boolean | undefined;
	$hasError?: boolean | undefined;
}>`
	accent-color: #12A34D;
	opacity: ${props => props.$disabled ? formOpacities.disabled : formOpacities.full};
	pointer-events: ${props => props.$disabled ? 'none' : 'auto'};

	background-image: none;
	border: 1px solid ${props => props.$hasError ? '#f6cad1' : '#d3d8d8'};
	border-radius: 4px;

	-webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

	&[type='radio'],
	&[type='range'],
	&[type='checkbox'] {
		height: 16px;
		width: 16px;
		cursor: pointer;

		:focus {
			border-color: #12a34d;
			outline: 0;
			-webkit-box-shadow: 0 0 8px rgba(18, 163, 77, 0.6);
			box-shadow: 0 0 8px rgba(18, 163, 77, 0.6);
		}
	}

	&[type='range'] {
		display: block;
		width: 100%;
	}

	&[type='date'],
	&[type='text'],
	&[type='datetime'],
	&[type='email'],
	&[type='file'],
	&[type='month'],
	&[type='week'],
	&[type='url'],
	&[type='number'],
	&[type='password'],
	&[type='search'],
	&[type='submit'],
	&[type='tel'] {
		display: block;
		width: 100%;
		height: 38px;
		padding: 8px 10px;
		font-size: 14px;
		line-height: 1.42857143;
		color: #2d415a;
		background-color: #fff;
		background-image: none;

		:focus {
			border-color: #12a34d;
			outline: 0;
			-webkit-box-shadow: 0 0 8px rgba(18, 163, 77, 0.6);
			box-shadow: 0 0 8px rgba(18, 163, 77, 0.6);
		}

		::placeholder {
			color: #C9CACC;
		}
	}
`;

export type InputProps = {
	hasError?: boolean | undefined
} & React.ComponentPropsWithoutRef<'input'>

export const Input = withCssSelector(React.forwardRef<HTMLInputElement, InputProps>(
	function Input(
		{
			hasError,
			disabled,
			type = 'text',
			tabIndex,
			readOnly,
			...props
		},
		ref
	) {
		return <StyledInput
			ref={ref}
			type={type}
			$hasError={hasError}
			$disabled={disabled}
			tabIndex={disabled ? -1 : tabIndex}
			readOnly={disabled ? true : readOnly}
			{...props}
		/>;
	}
), StyledInput.toString());
