import { takeEvery, put, delay, take, select } from 'redux-saga/effects';
import {
	EXPORT,
	exportSuccess,
	exportFailure,
	EXPORT_SUCCESS,
	EXPORT_STATUS,
	exportStatusSuccess,
	exportStatusFailure,
	exportStatusRequest,
	EXPORT_STATUS_SUCCESS,
	EXPORT_STATUS_API_ERROR,
	CHECK_EXPORT_STATUS,
	checkExportStatus
} from './actions';
import apiSagaGenerator from '../../saga/apiSagaGenerator';
import _find from 'lodash/find';
import { isJobOver } from './exportStatus';
import { getExportStatus, runExport } from './api';

const exportSagas = [
	takeEvery(EXPORT,
		apiSagaGenerator()
			.forApi(runExport)
			.withArguments((action) => [action.exportType, action.parameters])
			.forSuccess(exportSuccess)
			.withActionArguments((action) => [action.exportType, action.requestId, action.parameters])
			.forError(exportFailure)
			.withMessage('Error requesting export')
			.generate()
	),
	takeEvery(EXPORT_STATUS,
		apiSagaGenerator()
			.forApi(getExportStatus)
			.withArguments((action) => [action.exportType, action.exportId, action.parameters])
			.forSuccess(exportStatusSuccess)
			.withActionArguments((action) => [action.exportType, action.requestId, action.parameters])
			.forError(exportStatusFailure)
			.withMessage('Error checking export status')
			.generate()
	),
	takeEvery(EXPORT_SUCCESS, checkIfExportDoneAfterInitiating),
	takeEvery(CHECK_EXPORT_STATUS, checkIfExportDone),

];

const timeout = 5000;

function* checkIfExportDoneAfterInitiating(action) {
	const { exportType, requestId, parameters, payload: { export_id: exportId }} = action;
	yield put(checkExportStatus(exportType, requestId, exportId, parameters));
}

function* checkIfExportDone(action) {
	yield delay(timeout);

	const { exportType, requestId, exportId, parameters } = action;

	const exportJob = yield select(state => _find(state.exportJobs, { requestId }));
	if (!exportJob) {
		return;
	}

	yield put(exportStatusRequest(exportType, requestId, exportId, parameters));

	const responseAction = yield take(action => 
		(action.type === EXPORT_STATUS_SUCCESS || action.type === EXPORT_STATUS_API_ERROR) 
		&& action.requestId === requestId);
	if (responseAction.type === EXPORT_STATUS_SUCCESS && !isJobOver(responseAction.payload.status)) {
		yield put(checkExportStatus(exportType, requestId, exportId, parameters));
	}
}

export { checkIfExportDone };

export default exportSagas;
