import { formatDate } from '../../logic/dateUtils';
import { relativeModes, weekDays } from '../../logic/constants';
import { getPeriodName, getPeriodNamePlural, getRelativeModeName } from '../../logic/translations';
import { t } from '../../../../../setupTranslations';

const englishFormatter = {
	formatStart: function(mode, amount, period) {
		return formatPeriod(mode, amount, period);
	},

	formatAllTime: function() {
		return t('All time');
	},

	formatAllTimeToDate: function() {
		return t('All time to date'); 
	},

	formatDayRange: function(mode, fromHours, toHours) {
		switch (mode) {
		case relativeModes.current:
			return `Today ${fromHours}-${toHours}`;
		case relativeModes.previous:
			return `Yesterday ${fromHours}-${toHours}`;
		case relativeModes.next:
			return `Tomorrow ${fromHours}-${toHours}`;
		}
		return '';
	},

	formatWeekRange: function(mode, fromDay, toDay) {
		const period = fromDay !== toDay ?
			`${formatWeekDayAbbr(fromDay)}-${formatWeekDayAbbr(toDay)}` :
			formatWeekDay(fromDay);

		switch (mode) {
		case relativeModes.current:
			return `Current week (${period})`;
		case relativeModes.previous:
			return `Previous week (${period})`;
		case relativeModes.next:
			return `Next week (${period})`;
		}
	},

	formatOffset: function(future, amount, period) {
		// eslint-disable-next-line max-len
		return `Offset by ${future ? '+' : '-'} ${amount === 1 ? '' : `${amount} `} ${amount === 1 ? period : `${period}s`}`;
	},

	formatCustomDates: function(startDate, endDate) {
		return  `${formatDate(startDate)} - ${formatDate(endDate)}`;
	},
};

function formatWeekDay(weekDay) {
	switch (weekDay) {
	case weekDays.monday:
		return 'Monday';
	case weekDays.tuesday:
		return 'Tuesday';
	case weekDays.wednesday:
		return 'Wednesday';
	case weekDays.thursday:
		return 'Thursday';
	case weekDays.friday:
		return 'Friday';
	case weekDays.saturday:
		return 'Saturday';
	case weekDays.sunday:
		return 'Sunday';
	}
	return '';
}

function formatWeekDayAbbr(weekDay) {
	switch (weekDay) {
	case weekDays.monday:
		return 'Mon';
	case weekDays.tuesday:
		return 'Tue';
	case weekDays.wednesday:
		return 'Wed';
	case weekDays.thursday:
		return 'Thu';
	case weekDays.friday:
		return 'Fri';
	case weekDays.saturday:
		return 'Sat';
	case weekDays.sunday:
		return 'Sun';
	}
	return '';
}

export default englishFormatter;

function formatPeriod(mode, amount, period) {
	// eslint-disable-next-line max-len
	return `${getRelativeModeName(mode)} ${amount === 1 ? '' : `${amount} `}${amount > 1 ? getPeriodNamePlural(period).toLowerCase() : getPeriodName(period).toLowerCase()}`;
}
