import { call, put, select, cancelled, getContext } from 'redux-saga/effects';

const apiSagaGenerator = () => {
	let includeResultInSuccess = true;
	let errorMessage = '';
	let exceptionMessage = '';
	let includeResultInError = false;
	let actionArgs = null;
	let apiArgs = null;
	let stateArgsSelector = null;
	let messageFromResult = null;
	let messageFromErrorResponse = null;
	let postSuccessAction = null;
	let postSuccessActionCondition = null;
	let cancellable = false;

	return {
		forApi: (apiFunction) => {
			const apiObject = {
				withArguments: (getApiArgs) => {
					apiArgs = getApiArgs;
					return apiObject;
				},
				cancellable: () => {
					cancellable = true;
					return apiObject;
				},
				withPropsFromState: (selector) => {
					stateArgsSelector = selector;
					return apiObject;
				},
				forSuccess: (successAction) => {
					const successObject = {
						skipResult: () => {
							includeResultInSuccess = false;
							return successObject;
						},
						withActionArguments: (getActionsArgs) => {
							actionArgs = getActionsArgs;
							return successObject;
						},
						andPut: (afterSuccessAction, afterSuccessCondition) => {
							postSuccessAction = afterSuccessAction;
							postSuccessActionCondition = afterSuccessCondition;
							return successObject;
						},
						forError: (errorAction) => {
							const errorObject = {
								withMessage: (message) => {
									errorMessage = message;
									return errorObject;
								},
								withExceptionMessage: (message) => {
									exceptionMessage = message;
									return errorObject;
								},
								includeErrorResult: (messageFromResultGetter) => {
									includeResultInError = true;
									messageFromResult = messageFromResultGetter;
									return errorObject;
								},
								includeErrorFromResponse: (messageFromResponseGetter) => {
									messageFromErrorResponse = messageFromResponseGetter;
									return errorObject;
								},
								generate: () => {
									return genericApiSaga(
										apiFunction,
										successAction,
										errorAction,
										errorMessage,
										exceptionMessage,
										apiArgs,
										actionArgs,
										includeResultInSuccess,
										includeResultInError,
										messageFromResult,
										messageFromErrorResponse,
										postSuccessAction,
										postSuccessActionCondition,
										stateArgsSelector,
										cancellable
									);
								}
							};

							return errorObject;
						}
					};
					return successObject;
				}
			};
			return apiObject;
		}
	};
};

// eslint-disable-next-line max-params
function genericApiSaga(
	apiFunction,
	successAction,
	errorAction,
	errorMessage,
	exceptionMessage,
	getApiArgs,
	getActionsArgs,
	includeResultInSuccess,
	includeResultInError,
	messageFromErrorResult,
	messageFromErrorResponse,
	postSuccessAction,
	postSuccessActionCondition,
	stateArgsSelector,
	cancellable
) {
	return function* (action) {
		const apiArgs = getApiArgs ? getApiArgs(action) : [];
		if (stateArgsSelector) {
			const stateArgs = yield select(stateArgsSelector, action);
			apiArgs.push(...stateArgs);
		}
		const actionArgs = getActionsArgs ? getActionsArgs(action) : apiArgs;
		const abortController = cancellable ? new AbortController() : null;

		try {
			const response = abortController
				? yield call(apiFunction, ...apiArgs, abortController?.signal)
				: yield call(apiFunction, ...apiArgs);

			if (response.ok) {
				if (includeResultInSuccess) {
					const result = yield response.json();
					yield put(successAction(...actionArgs, result));
					if (
						postSuccessAction &&
						(!postSuccessActionCondition || postSuccessActionCondition(action))
					) {
						yield put(postSuccessAction(...actionArgs));
					}
				} else {
					yield put(successAction(...actionArgs));
				}
			} else {
				let messageForError = errorMessage;
				if (messageFromErrorResponse) {
					const messageFromResponse = messageFromErrorResponse(response);
					if (messageFromResponse) {
						messageForError = messageFromResponse;
					}
				}
				if (includeResultInError) {
					const errorResult = yield response.json();
					const messageFromResult = messageFromErrorResult
						? messageFromErrorResult(errorResult)
						: null;
					yield put(
						errorAction(
							...actionArgs,
							messageFromResult || messageForError,
							errorResult
						)
					);
				} else {
					yield put(errorAction(...actionArgs, messageForError));
				}
			}
		} catch (e) {
			yield put(errorAction(...actionArgs, exceptionMessage || e.message));
			const errorHandler = yield getContext('errorHandler');
			if (errorHandler && typeof errorHandler === 'function') {
				errorHandler(e);
			}
		} finally {
			if (yield cancelled()) {
				abortController?.abort();
			}
		}
	};
}

export default apiSagaGenerator;
