import React from 'react';

export default function DropDownMenu({ value, onValueChange, children }) {

	return React.Children.map(children, child => {
		if (!React.isValidElement(child)) return null;

		return React.cloneElement(child, {
			...child.props,
			selected: child.props.value === value,
			onSelect: onValueChange,
		});
	});
}
