export const FETCH = 'plecto/v3/team/FETCH';
export const FETCH_SUCCESS = 'plecto/v3/team/FETCH_SUCCESS';
export const FETCH_TEAMS_API_ERROR = 'plecto/v3/team/FETCH_TEAMS_API_ERROR';
export const FETCH_FILTERED = 'plecto/v3/team/FETCH_FILTERED';
export const FETCH_FILTERED_SUCCESS = 'plecto/v3/team/FETCH_FILTERED_SUCCESS';
export const FETCH_TEAMS_COMPACT = 'plecto/v3/team/FETCH_TEAMS_COMPACT';
export const FETCH_TEAMS_COMPACT_SUCCESS = 'plecto/v3/team/FETCH_TEAMS_COMPACT_SUCCESS';

export const fetchTeams = (parentIdsList, dynamicDashboardId) => ({
	type: FETCH,
	parentIdsList,
	dynamicDashboardId,
});

export const fetchTeamsSuccess = (parentIdsList, payload) => ({
	type: FETCH_SUCCESS,
	payload,
	parentIdsList
});

export const fetchTeamsFailure = (parentIdsList, error) => ({
	type: FETCH_TEAMS_API_ERROR,
	parentIdsList,
	error
});

export const fetchTeamsFiltered = (searchValue, dynamicDashboardId) => ({
	type: FETCH_FILTERED,
	searchValue,
	dynamicDashboardId,
});

export const fetchTeamsFilteredSuccess = (payload) => ({
	type: FETCH_FILTERED_SUCCESS,
	payload
});

export const fetchTeamsCompact = (withMembersOnly, dynamicDashboardId) => ({
	type: FETCH_TEAMS_COMPACT,
	withMembersOnly,
	dynamicDashboardId,
});

export const fetchTeamsCompactSuccess = (payload) => ({
	type: FETCH_TEAMS_COMPACT_SUCCESS,
	payload
});

export function shouldFetchTeams(state) {
	return !state.teamsV3.isFetching && state.teamsV3.isStale;
}

export function fetchTeamsIfNeeded(dynamicDashboardId) {
	return (dispatch, getState) => {
		if (shouldFetchTeams(getState())) {
			return dispatch(fetchTeams(undefined, dynamicDashboardId));
		}
	};
}
