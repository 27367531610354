import * as reportActions from '../report/actions';
import {
	ADD_SUCCESS,
	DELETE_SUCCESS,
	FETCH_RENDER_API_ERROR,
	FETCH_RENDER,
	UPDATE,
	UPDATE_SUCCESS,
	FETCH_RENDER_SUCCESS,
	CHANGE_FORMULA_ORDER,
	CHANGE_FORMULA_ORDER_SUCCESS,
	UPDATE_API_ERROR,
	COPY,
	COPY_SUCCESS,
	FETCH_RENDER_PAGE,
	FETCH_RENDER_PAGE_SUCCESS,
	FETCH_RENDER_PAGE_API_ERROR,
	FETCH_RENDER_ALL,
	FETCH_RENDER_ALL_SUCCESS,
	FETCH_RENDER_ALL_API_ERROR,
	PREVIEW,
	PREVIEW_SUCCESS,
	PREVIEW_API_ERROR,
	DISMISS_PREVIEW,
	PREVIEW_WITHOUT_DATA,
} from './actions';
import * as formulaActions from '../reportGroupFormula/actions';

import _omit from 'lodash/omit';
import _keyBy from 'lodash/keyBy';
import _reject from 'lodash/reject';
import _pick from 'lodash/pick';
import { mapInitialPagination, addRenderedPage } from './pagination';

function reportGroupsById(state = {}, action) {
	switch (action.type) {
	case reportActions.FETCH_SUCCESS:
	case reportActions.UPDATE_SUCCESS: {
		const mappedGroups = action.payload.report_groups.map(g => mapReportGroup(g));
		return _keyBy(mappedGroups, 'uuid');
	}
	case formulaActions.ADD:
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				isAddReportGroupFormulaSaving: true,
				addReportGroupFormulaErrors: null
			} ,
		};
	case CHANGE_FORMULA_ORDER: {
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				reportGroupFormulaIds: action.orderedReportGroupFormulaIds,
			}
		};
	}
	case CHANGE_FORMULA_ORDER_SUCCESS: {
		const formulas = action.payload.formulas.map(groupFormula => groupFormula.uuid);
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				reportGroupFormulaIds: formulas,
			}
		};
	}
	case FETCH_RENDER:
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				isFetching: true,
				isStale: false,
				isError: false,
				errorMessage: null,
			}
		};
	case FETCH_RENDER_SUCCESS:
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				isFetching: false,
				isStale: false,
				isError: false,
				errorMessage: null,
				pagination: mapInitialPagination(action.payload.pagination_data),
			}
		};

	case FETCH_RENDER_PAGE: {
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				isFetching: true,
				pagination: addRenderedPage(state[action.reportGroupId].pagination, action.page)
			}
		};
	}
	case FETCH_RENDER_ALL:
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				isFetching: true,
			}
		};
	case FETCH_RENDER_ALL_SUCCESS:
	case FETCH_RENDER_PAGE_SUCCESS: {
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				isFetching: false,
			}
		};
	}
	case FETCH_RENDER_ALL_API_ERROR:
	case FETCH_RENDER_PAGE_API_ERROR: {
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				isFetching: false,
			}
		};
	}
	case FETCH_RENDER_API_ERROR:
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				isError: true,
				isFetching: false,
				isStale: true,
				errorMessage: action.errorMessage,
			}
		};
	case ADD_SUCCESS:
		return {
			...state,
			[action.payload.uuid]: mapReportGroup(action.payload),
		};
	case COPY_SUCCESS:
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				isFetching: false,
			},
			[action.payload.uuid]: mapReportGroup(action.payload),
		};
	case COPY:
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				isFetching: true,
			}
		};

	case UPDATE:
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				isFetching: true,
				isSaving: true,
				isStale: false,
				isError: false,
				errorMessage: null,
			}
		};

	case UPDATE_SUCCESS:
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				...mapReportGroup(action.payload)
			}
		};
	case UPDATE_API_ERROR:
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				isFetching: false,
				isSaving: false,
			}
		};
	case PREVIEW_WITHOUT_DATA: {
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				preview: {
					...(state[action.reportGroupId].preview || {}),
					data: {
						..._omit(state[action.reportGroupId], 'preview'),
						...(state[action.reportGroupId].preview?.data || {}),
						...mapPreviewFromApiModel(action.previewSettings, true),
					}
				}
			}
		};
	}
	case PREVIEW: {
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				previewId: action.previewId,
				preview: {
					...(state[action.reportGroupId].preview || {}),
					isFetching: true,
					previewSettings: action.previewRawSettings,
					data: {
						..._omit(state[action.reportGroupId], 'preview'),
						...mapPreviewFromApiModel(action.previewSettings, true),
					}
				}
			}
		};
	}
	case PREVIEW_SUCCESS: {
		if (state[action.reportGroupId]?.previewId === action.previewId) {
			return {
				...state,
				[action.reportGroupId]: {
					...state[action.reportGroupId],
					preview: {
						...(state[action.reportGroupId].preview || {}),
						isFetching: false,
						data: {
							...(state[action.reportGroupId].preview.data || {}),
							...mapReportGroup(action.payload, !action.allPages),
							timePeriodOptions: (state[action.reportGroupId].preview.data.timePeriodOptions || {}),
						}
					}
				}
			};
		}
		return state;
	}
	case PREVIEW_API_ERROR: {
		if (state[action.reportGroupId]?.previewId === action.previewId) {
			return {
				...state,
				[action.reportGroupId]: {
					...state[action.reportGroupId],
					preview: {
						...(state[action.reportGroupId].preview || {}),
						isFetching: false,
					}
				}
			};
		}
		return state;
	}
	case DISMISS_PREVIEW: {
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				previewId: null,
				preview: null
			}
		};
	}
	case formulaActions.DELETE_SUCCESS:
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				reportGroupFormulaIds: _reject(
					state[action.reportGroupId].reportGroupFormulaIds,(id) => id === action.reportGroupFormulaId)
			}
		};
	case formulaActions.ADD_SUCCESS: {
		let index = -1;
		if (action.after) {
			index = state[action.reportGroupId].reportGroupFormulaIds.indexOf(action.after);

			if (index < 0) {
				index = state[action.reportGroupId].reportGroupFormulaIds.length - 1;
			}
		}
		
		++index;
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				reportGroupFormulaIds: [
					...state[action.reportGroupId].reportGroupFormulaIds.slice(0,index),
					action.payload.uuid,
					...state[action.reportGroupId].reportGroupFormulaIds.slice(index)
				],
				isAddReportGroupFormulaOpen: false,
				isAddReportGroupFormulaSaving: false,
				addReportGroupFormulaErrors: null,
			}
		};
	}
	case formulaActions.ADD_API_ERROR:
		return {
			...state,
			[action.reportGroupId]: {
				...state[action.reportGroupId],
				isAddReportGroupFormulaOpen: true,
				isAddReportGroupFormulaSaving: false,
				addReportGroupFormulaErrors: action.errors,
			}
		};
	case DELETE_SUCCESS: {
		return _omit(state, [action.reportGroupId]);
	}

	default:
		return state;
	}
}

function mapDateInterval(interval) {
	return { ...interval, isCurrent: new Date(interval.start) <= new Date() && new Date(interval.end) > new Date() };
}

function mapReportGroup(group, onlyCurrentPage) {
	const timePeriodOptions = _pick(group, [
		'start_amount',
		'start_period',
		'start_shift_amount',
		'start_shift_period',
		'end_period',
		'end_amount',
		'end_shift_amount',
		'end_shift_period',
		'concrete_start_date',
		'concrete_end_date',
		'grouping',
		'total_shift_amount',
		'total_shift_period',
		'starting_on_day',
		'starting_on_month'
	]);
	
	return {
		isFetching: false,
		isSaving: false,
		isStale: false,
		isError: false,
		errorMessage: null,
		isAddReportGroupFormulaOpen: false,
		isAddReportGroupFormulaSaving: false,
		addReportGroupFormulaErrors: null,
		uuid: group.uuid,
		report: group.report,
		title: group.title,
		hasDisabledDataSourceSelection: group.has_inherited_teams || group.has_inherited_members,
		teams: group.teams,
		members: group.members,
		reportGroupFormulaIds: group.formulas.map(groupFormula => groupFormula.uuid),
		timePeriodOptions,
		includeSummary: group.include_summary,
		summaryType: group.summary_column_type,
		widgetType: group.widget_type,
		settings: group.settings,
		dateIntervals: group.date_intervals.has_error 
			? [] : 
			(onlyCurrentPage 
				? group.date_intervals.map(mapDateInterval) : group.all_date_intervals.map(mapDateInterval)),
		freezeSummaryColumn: group.freeze_summary_column,
	};
}

export function mapGroupToApiModel(groupState) {
	return {
		report: groupState.report,
		title: groupState.title,
		teams: groupState.teams,
		members: groupState.members,
		formulas: groupState.formulas,
		include_summary: groupState.includeSummary,
		freeze_summary_column: groupState.freezeSummaryColumn,
		summary_column_type: groupState.summaryType,
		settings: groupState.settings,
		widget_type: groupState.widgetType,
		...groupState.timePeriodOptions
	};
}

export function mapPreviewFromApiModel(group) {
	const timePeriodOptions = _pick(group, [
		'start_amount',
		'start_period',
		'start_shift_amount',
		'start_shift_period',
		'end_period',
		'end_amount',
		'end_shift_amount',
		'end_shift_period',
		'concrete_start_date',
		'concrete_end_date',
		'grouping',
		'total_shift_amount',
		'total_shift_period',
		'starting_on_day',
		'starting_on_month'
	]);

	return {
		title: group.title,
		hasDisabledDataSourceSelection: group.has_inherited_teams || group.has_inherited_members,
		teams: group.teams,
		members: group.members,
		timePeriodOptions,
		includeSummary: group.include_summary,
		summaryType: group.summary_column_type,
		widgetType: group.widget_type,
		settings: group.settings,
		freezeSummaryColumn: group.freeze_summary_column,
	};
}

export { reportGroupsById };
