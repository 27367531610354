import _keyBy from 'lodash/keyBy';
import _flatten from 'lodash/flatten';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import * as reportActions from '../report/actions';
import {
	ADD_COMMENT,
	ADD_COMMENT_SUCCESS,
	ADD_SUCCESS, DELETE_SUCCESS,
	FETCH_COMMENTS,
	FETCH_COMMENTS_SUCCESS,
} from './actions';
import {
	FETCH_RENDER_SUCCESS,
	COPY_SUCCESS,
	FETCH_RENDER_PAGE_SUCCESS,
	UPDATE_SUCCESS as REPORT_GROUP_UPDATE_SUCCESS,
	PREVIEW_SUCCESS,
	PREVIEW,
	DISMISS_PREVIEW,
	FETCH_RENDER_ALL_SUCCESS,
} from '../reportGroup/actions';
import _findIndex from 'lodash/findIndex';
import splitRenderAndSummary from './summaryService';
import _reject from 'lodash/reject';
import _filter from 'lodash/filter';
import _difference from 'lodash/difference';
import drilldownReducer from './dimensions/drilldownReducer';
import createDimensionKey from '../formula/createDimensionKey';
import drilldownRenderReducer from './dimensions/drilldownRenderReducer';
import { initDrilldownRender, initRendersTable, insertActualRenders } from './dimensions/renderService';

function reportGroupFormulaComment(payload) {
	return {
		uuid: payload.uuid,
		text: payload.text,
		period: payload.period,
		createdDate: payload.created_date,
		createdBy: payload.created_by ? {
			uuid: payload.created_by.uuid,
			name: payload.created_by.name,
			avatarUrl: payload.created_by.avatar_url,
		} : {},
	};
}

function reportGroupFormulasById(state = {}, action) {
	state = drilldownReducer(state, action);
	state = drilldownRenderReducer(state, action);
	switch (action.type) {
	case reportActions.FETCH_SUCCESS:
	case reportActions.UPDATE_SUCCESS: {
		// update all groupFormulas
		const groupsOfFormulas = action.payload.report_groups.map(group => {
			return group.formulas.map(f => mapReportGroupFormula(f, group.uuid));
		});
		return _keyBy(_flatten(groupsOfFormulas), 'uuid');
	}
	case REPORT_GROUP_UPDATE_SUCCESS: {
		if (action.prefetchRenders) {
			const updatedFormulas = action.payload.formulas.map(f => mapReportGroupFormula(f, action.reportGroupId));
			const nextGroupFormulasState = _keyBy(updatedFormulas, 'uuid');
			const nextFormulasIds = Object.keys(nextGroupFormulasState);
			const previousFormulas = _filter(Object.keys(state), k => state[k].reportGroupId === action.reportGroupId);
	
			const deletedFormulas = _difference(previousFormulas, nextFormulasIds);
	
			return {
				..._omit(state, deletedFormulas),
				...nextGroupFormulasState,
			};
		}
		return state;
	}
	case PREVIEW: {
		return {
			...state,
			preview: {
				...state.preview,
				[action.reportGroupId]: {
					...(state.preview?.[action.reportGroupId] || {}),
					previewId: action.previewId,
				}
			}
		};
	}
	case PREVIEW_SUCCESS: {
		if (state.preview?.[action.reportGroupId]?.previewId === action.previewId) {
			const updatedFormulas = action.payload.formulas.map(f => {
				const groupFormula = mapReportGroupFormula(f, action.reportGroupId);
				let render = f.render, summary = null;
				let dimension = null;
				let drillDownRender = null;
				if (groupFormula.previewDimension) {
					// MR we need preview render to handle table widgets properly.
					render = f.preview_render;
					dimension = groupFormula.previewDimension;
					drillDownRender = initDrilldownRender({ render: f.render });
				} 
				
				if (render && action.payload.include_summary) {
					const summarySplit = splitRenderAndSummary(render);
					render = summarySplit.render;
					summary = summarySplit.summary;
				}

				const nextRender = render;

				return {
					...groupFormula,
					isStale: false,
					render: nextRender,
					drillDownRender,
					summary,
					savedDimension: dimension,
					appliedDimension: dimension,
					renderSummaryType: action.payload.summary_column_type,
					renderedDrilldownPages: [action.payload.pagination_data.current_page]
				};
			});
			const nextGroupFormulasState = _keyBy(updatedFormulas, 'uuid');

			return {
				...state,
				preview: {
					...state.preview,
					[action.reportGroupId]: {
						...state.preview[action.reportGroupId],
						formulas: nextGroupFormulasState,
					}
				}
			};
		}
		return state;
	}
	case DISMISS_PREVIEW: {
		return {
			...state,
			preview: {
				..._omit(state.preview, [action.reportGroupId])
			}
		};
	}
	case COPY_SUCCESS: {
		const newFormulas = action.payload.formulas.map((f) => mapReportGroupFormula(f, action.payload.uuid));
		return {
			...state,
			..._keyBy(newFormulas, 'uuid')
		};
	}
	case FETCH_RENDER_SUCCESS: {
		const updatedFormulas = action.payload.formulas.map(groupFormula => {
			let render = groupFormula.render, summary = null;
			if (action.payload.include_summary) {
				const summarySplit = splitRenderAndSummary(groupFormula.render);
				render = summarySplit.render;
				summary = summarySplit.summary;
			}

			const nextRender = render.has_error
				? render
				: initRendersTable(
					action.payload.pagination_data.items_count,
					render,
					action.payload.pagination_data.index
				);

			return {
				...state[groupFormula.uuid],
				render: nextRender,
				isStale: false,
				summary,
				renderSummaryType: action.payload.summary_column_type,
				renderedDrilldownPages: [action.payload.pagination_data.current_page]
			};
		});

		const updatedFormulasState = _keyBy(updatedFormulas, 'uuid');

		return Object.assign({}, state, updatedFormulasState);
	}

	case FETCH_RENDER_ALL_SUCCESS: {
		const updatedFormulas = action.payload.formulas.map(groupFormula => {
			const formulaState = state[groupFormula.uuid];
			let render = groupFormula.render, summary = null, drillDownRender = null;
			
			if (formulaState.appliedDimension) {
				render = [];
				drillDownRender = initDrilldownRender({ render: groupFormula.render });
			} else {
				if (action.payload.include_summary) {
					const summarySplit = splitRenderAndSummary(groupFormula.render);
					render = summarySplit.render;
					summary = summarySplit.summary;
				}
			}

			return {
				...state[groupFormula.uuid],
				isStale: false,
				render,
				drillDownRender,
				summary,
				renderSummaryType: action.payload.summary_column_type,
			};
		});

		const updatedFormulasState = _keyBy(updatedFormulas, 'uuid');

		return Object.assign({}, state, updatedFormulasState);
	}

	case FETCH_RENDER_PAGE_SUCCESS: {
		const updatedFormulas = action.payload.formulas.map(groupFormula => {
			const currentRenderTable = state[groupFormula.uuid].render;

			const nextRender = groupFormula.render.has_error
				? groupFormula.render
				: currentRenderTable.has_error
					? currentRenderTable
					: insertActualRenders(
						currentRenderTable,
						_reject(groupFormula.render, 'is_summary'),
						action.payload.pagination_data.index
					);
			return {
				...state[groupFormula.uuid],
				render: nextRender,
				isStale: false,
			};
		});

		const updatedFormulasState = _keyBy(updatedFormulas, 'uuid');

		return Object.assign({}, state, updatedFormulasState);
	}
	case ADD_SUCCESS:
		return {
			...state,
			[action.payload.uuid]: mapReportGroupFormula(action.payload, action.reportGroupId)
		};
	case FETCH_COMMENTS:
		return {
			...state,
			[action.reportGroupFormulaId]: {
				...state[action.reportGroupFormulaId],
				isFetchingComments: true,
			}
		};
	case ADD_COMMENT:
		return {
			...state,
			[action.reportGroupFormulaId]: {
				...state[action.reportGroupFormulaId],
				isCreatingComment: true,
			}
		};

	case FETCH_COMMENTS_SUCCESS:
		return {
			...state,
			[action.reportGroupFormulaId]: {
				...state[action.reportGroupFormulaId],
				comments: action.payload.map(reportGroupFormulaComment),
				isFetchingComments: false,
			}
		};
	case ADD_COMMENT_SUCCESS: {
		let renders = state[action.reportGroupFormulaId].render;
		if (renders && renders.length) {
			const commentPeriod = action.payload.period;
			const affectedPeriodIndex = _findIndex(
				renders,
				(r) =>
					!r.placeholder &&
					r.actual?.start_date <= commentPeriod &&
					commentPeriod <= r.actual?.end_date
			);
			if (affectedPeriodIndex >= null) {
				renders = [
					...renders.slice(0,affectedPeriodIndex),
					{
						...renders[affectedPeriodIndex],
						comments: [...renders[affectedPeriodIndex].comments, action.payload],
						comments_count: renders[affectedPeriodIndex].comments_count + 1,
					},
					...renders.slice(affectedPeriodIndex + 1)
				];
			}
		}
		return {
			...state,
			[action.reportGroupFormulaId]: {
				...state[action.reportGroupFormulaId],
				comments: state[action.reportGroupFormulaId].comments.concat([
					reportGroupFormulaComment(action.payload),
				]),
				isCreatingComment: false,
				render: renders,
			},
		};
	}
	case DELETE_SUCCESS: {
		return _omit(state, [action.reportGroupFormulaId]);
	}
	default:
		return state;
	}
}

function mapReportGroupFormula(formula, reportGroupId) {
	const savedDimension = mapDimension(formula.saved_dimension);
	const previewDimension = mapDimension(formula.preview_dimension);
	return {
		uuid: formula.uuid,
		display_title: formula.display_title,
		title: formula.title,
		formula: formula.formula,
		target: formula.target,
		is_highlighted: formula.is_highlighted,
		has_percentage_change: formula.has_percentage_change,
		has_slide_in_export: formula.has_slide_in_export,
		percentage_change_period: formula.percentage_change_period,
		isSaving: false,
		isFetching: false,
		isStale: true,
		comments: [],
		isFetchingComments: false,
		isCreatingComment: false,
		reportGroupId: reportGroupId,
		savedDimension,
		appliedDimension: savedDimension,
		isAppliedDimensionSaved: !!savedDimension,
		settings: formula.settings,
		order: formula.order,
		previewDimension,
		format: formula.format,
		drilldown_ordering: formula.drilldown_ordering
	};
}

function mapDimension(dimension) {
	if (!dimension || !dimension.group_by) {
		return null;
	}
	const mapped = _pick(dimension, ['group_by', 'grouping_field']);
	const key = createDimensionKey(mapped);
	return {
		key,
		...mapped
	};
}

export { reportGroupFormulasById };
