import { usePopperTooltip } from 'react-popper-tooltip';
import ReactDOM from 'react-dom';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { zIndexAboveAll } from '../../styles/zIndex';
import { FONT_FAMILY, FONT_WEIGHT } from '../../styles/typography';
import { Placement } from '@popperjs/core';

const StyledTooltipArrow = styled.div``;

const StyledTooltipContainer = styled.div`
	--tooltipBackground: #010d17;

	z-index: ${zIndexAboveAll};
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	max-width: 350px;
	padding: 4px 10px;
	color: white;
	background-color: var(--tooltipBackground);
	text-align: center;
	text-decoration: none;
	white-space: pre-wrap;
	font-family: ${FONT_FAMILY};
	font-size: 12px;
	font-weight: ${FONT_WEIGHT.normal};
	line-height: 1.4;

	${StyledTooltipArrow} {
		height: 6px;
		width: 12px;
		position: absolute;
		pointer-events: none;

		:before {
			border-style: solid;
			content: '';
			display: block;
			height: 0;
			margin: auto;
			width: 0;
		}

		:after {
			border-style: solid;
			content: '';
			display: block;
			height: 0;
			margin: auto;
			position: absolute;
			width: 0;
		}
	}

	&[data-popper-interactive='false'] {
		pointer-events: none;
	}

	&[data-popper-placement^='top'] {
		${StyledTooltipArrow} {
			bottom: 0;
			left: 0;
			margin-bottom: -6px;

			:before {
				border-color: var(--tooltipBackground) transparent transparent transparent;
				border-width: 6px 6px 0;
				position: absolute;
			}

			:after {
				border-color: var(--tooltipBackground) transparent transparent transparent;
				border-width: 6px 6px 0;
			}
		}
	}

	&[data-popper-placement^='bottom'] {
		${StyledTooltipArrow} {
			left: 0;
			margin-top: -6px;
			top: 0;

			:before {
				border-color: transparent transparent var(--tooltipBackground) transparent;
				border-width: 0 6px 6px 6px;
				position: absolute;
			}

			:after {
				border-color: transparent transparent var(--tooltipBackground) transparent;
				border-width: 0 6px 6px 6px;
			}
		}
	}

	&[data-popper-placement^='left'] {
		${StyledTooltipArrow} {
			margin-right: -8px;
			margin-top: -4px;
			right: 0;

			:before {
				border-color: transparent transparent transparent var(--tooltipBackground);
				border-width: 8px 0 8px 8px;
			}

			:after {
				border-color: transparent transparent transparent var(--tooltipBackground);
				border-width: 8px 0 8px 8px;
				left: 0;
				top: 0;
			}
		}
	}

	&[data-popper-placement^='right'] {
		${StyledTooltipArrow} {
			left: 0;
			margin-top: -4px;
			margin-left: -8px;

			:before {
				border-color: transparent var(--tooltipBackground) transparent transparent;
				border-width: 8px 8px 8px 0;
			}

			:after {
				border-color: transparent var(--tooltipBackground) transparent transparent;
				border-width: 8px 8px 8px 0;
				left: 8px;
				top: 0;
			}
		}
	}

`;

type Props = {
	className?: string,
	message: ReactNode,
	interactive?: boolean,
	delayHide?: number,
	delayShow?: number,
	placement?: Placement,
	visible?: boolean,
	children: (props: { triggerRef: React.Dispatch<React.SetStateAction<HTMLElement | null>> }) => JSX.Element,
};

export const InteractiveTooltip = ({
	children,
	message,
	interactive = false,
	delayHide = 100,
	delayShow = 100,
	placement = 'top',
	className,
	visible: isVisible
}: Props) => {
	const {
		getArrowProps,
		getTooltipProps,
		setTooltipRef,
		setTriggerRef,
		visible,
	} = usePopperTooltip({
		interactive: interactive,
		delayHide: delayHide,
		delayShow: delayShow,
		placement: placement,
		visible: isVisible
	},
	{
		modifiers: [
			{
				name: 'arrow',
				options: {
					padding: 4,
				},
			},
			{
				name: 'offset',
				options: {
					// eslint-disable-next-line no-magic-numbers
					offset: [0, 6],
				},
			},
		],
		placement: placement || 'auto',
	});

	const trigger = children({ triggerRef: setTriggerRef });

	return <>
		{trigger}
		{visible &&
			ReactDOM.createPortal(
				<StyledTooltipContainer ref={setTooltipRef} {...getTooltipProps({ className: className })}>
					{message}
					<StyledTooltipArrow {...getArrowProps()} />
				</StyledTooltipContainer>, document.body)
		}
	</>;
};
