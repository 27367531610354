import { useState } from 'react';
import { useCallback } from 'react';

export default function useEditMode() {
	const [editMode, setEditMode] = useState(false);
	const startEdit = useCallback(() => setEditMode(true), []);
	const endEdit = useCallback(() => setEditMode(false), []);

	return {
		editMode, startEdit, endEdit
	};
}
