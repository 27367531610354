import _partition from 'lodash/partition';

export default function splitRenderAndSummary(groupRender) {
	let summary = null;
	let render = groupRender;
	if (!groupRender.has_error) {
		const [summaryCells, renderCells] = _partition(groupRender, 'is_summary');
		if (summaryCells.length) {
			summary = summaryCells[0];
		}
		render = renderCells;
	}

	return { render, summary };
}
