import React, { memo } from 'react';
import Calendar from 'react-date-range/dist/components/Calendar';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { defaultWeekStartsOn, defaultWeekdayDisplayFormat } from './constants';
import { defaultMaxDate, defaultMinDate } from './defaultDate';

const S = {};
S.Calendar = styled(Calendar)`
	.rdrSelected {
		background-color: #2bb673;
		position: absolute;
		top: 0;
		left: 0px;
		right: 0px;
		bottom: 0px;
		pointer-events: none;
		border: 0px solid currentColor;
		z-index: 1;
		border-radius: 50%;
	}
	.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
		color: #ffffff;
		z-index: 2;
	}
	.rdrDay:not(.rdrSelected):hover {
		background-color: #fff;
		box-sizing: border-box;
		z-index: 1;
		border: 2px solid #2bb673;
		border-radius: 50%;
	}
	.rdrDayToday .rdrDayNumber:not(:only-child) span:after {
		content: none;
	}
	.rdrMonthPicker select {
		background-position: right 12px top calc(50% + 1px);
	}	
	.rdrYearPicker select {
		background-position: right 4px top calc(50% + 1px);
	}
`;

function DatePicker({
	date,
	onDateChange,
	showPreview = false,
	weekStartsOn = defaultWeekStartsOn,
	minDate = defaultMinDate,
	maxDate = defaultMaxDate,
	weekdayDisplayFormat = defaultWeekdayDisplayFormat,
}) {
	return <S.Calendar
		date={date}
		onChange={onDateChange}
		showPreview={showPreview}
		weekStartsOn={weekStartsOn}
		minDate={minDate}
		maxDate={maxDate}
		weekdayDisplayFormat={weekdayDisplayFormat}
	/>;
}

DatePicker.propTypes = {
	date: PropTypes.instanceOf(Date).isRequired,
	onDateChange: PropTypes.func.isRequired,
	minDate: PropTypes.instanceOf(Date),
	maxDate: PropTypes.instanceOf(Date),
	weekdayDisplayFormat: PropTypes.string,
	weekStartsOn: PropTypes.number,
};

export default memo(DatePicker);
