import { takeLatest } from 'redux-saga/effects';
import {
	FETCH_WRITTEN_UPDATE,
	fetchWrittenUpdateFailure,
	fetchWrittenUpdateSuccess,
	SUBMIT_WRITTEN_UPDATE,
	submitWrittenUpdateFailure,
	submitWrittenUpdateSuccess
} from './actions';
import apiSagaGenerator from '../../saga/apiSagaGenerator';
import { fetchWrittenUpdate, submitWrittenUpdate } from './api';

const writtenUpdatesSagas = [
	takeLatest(FETCH_WRITTEN_UPDATE,
		apiSagaGenerator()
			.forApi(fetchWrittenUpdate)
			.withArguments((action) => [action.writtenUpdateId])
			.forSuccess(fetchWrittenUpdateSuccess)
			.forError(fetchWrittenUpdateFailure)
			.withMessage('Error fetching written update')
			.generate()
	),
	takeLatest(SUBMIT_WRITTEN_UPDATE,
		apiSagaGenerator()
			.forApi(submitWrittenUpdate)
			.withArguments((action) => [action.writtenUpdateId, action.writtenUpdate])
			.forSuccess(submitWrittenUpdateSuccess)
			.forError(submitWrittenUpdateFailure)
			.withMessage('Error submitting written update')
			.generate()
	)
];

export default writtenUpdatesSagas;
