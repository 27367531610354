import { apiDelete, apiGet, apiPost, apiPut, apiVersionV3 } from '../../fetch/apiService';

export function createWrittenUpdateSchedule(writtenUpdateSchedule) {
	return apiPost(
		'written-update-schedules',
		writtenUpdateSchedule,
		{},
		{},
		apiVersionV3
	);
}

export function fetchWrittenUpdateSchedule(writtenUpdateScheduleId) {
	return apiGet(
		`written-update-schedules/${writtenUpdateScheduleId}`,
		{},
		{},
		apiVersionV3
	);
}

export function modifyWrittenUpdateSchedule(writtenUpdateScheduleId, writtenUpdateSchedule) {
	return apiPut(
		`written-update-schedules/${writtenUpdateScheduleId}`,
		writtenUpdateSchedule,
		{},
		{},
		apiVersionV3
	);
}

export function deleteWrittenUpdateSchedule(writtenUpdateScheduleId) {
	return apiDelete(
		`written-update-schedules/${writtenUpdateScheduleId}`,
		{},
		{},
		{},
		apiVersionV3
	);
}
