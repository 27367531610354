import { apiGet } from '../../fetch/apiService';

export function fetchDatasources() {
	return apiGet('datasources', { registrable: true });
}

export function fetchAllDatasources() {
	return apiGet('datasources');
}

export function fetchDatasource(dataSourceId) {
	return apiGet(`datasources/${dataSourceId}`);
}
