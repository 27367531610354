import React, { useMemo } from 'react';
import useTimePeriodWithGranularity from './useTimePeriodWithGranularity';

const TimePeriodSelectorContext = React.createContext(null);

export default TimePeriodSelectorContext;

export function TimePeriodSelectorContextWrapper({
	timePeriodOptions,
	granularity,
	onChange,
	disallowSinglePeriod,
	children }) {

	const {
		onChangePeriod,
		onSavePeriod,
		onCancelPeriod,
		onChangeGranularity,
		granularityOptions,
	} = useTimePeriodWithGranularity(timePeriodOptions, granularity, onChange, disallowSinglePeriod);

	const contextValue = useMemo(() => ({
		timePeriodOptions,
		granularity,
		onChangePeriod,
		onSavePeriod,
		onCancelPeriod,
		onChangeGranularity,
		granularityOptions,
	}), [
		timePeriodOptions,
		granularity,
		onChangePeriod,
		onSavePeriod,
		onCancelPeriod,
		onChangeGranularity,
		granularityOptions]);

	return (
		<TimePeriodSelectorContext.Provider value={contextValue}>
			{children}
		</TimePeriodSelectorContext.Provider>
	);
}
