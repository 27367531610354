export const FETCH = 'plecto/favorites/FETCH';
export const FETCH_SUCCESS = 'plecto/favorites/FETCH_SUCCESS';
export const FETCH_API_ERROR = 'plecto/favorites/FETCH_API_ERROR';

export const ADD = 'plecto/favorites/ADD';
export const ADD_SUCCESS = 'plecto/favorites/ADD_SUCCESS';
export const ADD_API_ERROR = 'plecto/favorites/ADD_API_ERROR';

export const DELETE = 'plecto/favorites/DELETE';
export const DELETE_SUCCESS = 'plecto/favorites/DELETE_SUCCESS';
export const DELETE_API_ERROR = 'plecto/favorites/DELETE_API_ERROR';

export const fetchFavorites = () => ({
	type: FETCH,
});

export const fetchFavoritesSuccess = (result, uuids) => ({
	type: FETCH_SUCCESS,
	result,
	uuids,
});

export const fetchFavoritesError = error => ({
	type: FETCH_API_ERROR,
	error,
});

export const addFavorite = (memberUuid, body) => ({
	type: ADD,
	memberUuid,
	body,
});

export const addFavoriteSuccess = result => ({
	type: ADD_SUCCESS,
	result,
});

export const addFavoriteError = error => ({
	type: ADD_API_ERROR,
	error,
});

export const deleteFavorite = (
	memberUuid,
	uuid,
	dashboardUuid,
	undoAction,
) => ({
	type: DELETE,
	memberUuid,
	uuid,
	dashboardUuid,
	undoAction,
});

export const deleteFavoriteSuccess = (uuid, dashboardUuid, undoAction) => ({
	type: DELETE_SUCCESS,
	uuid,
	dashboardUuid,
	undoAction
});

export const deleteFavoriteError = error => ({
	type: DELETE_API_ERROR,
	error,
});
