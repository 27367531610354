export const CHANGE_MODE = 'CHANGE_MODE';
export const CHANGE_START_MODE = 'CHANGE_START_MODE';
export const CHANGE_START_PERIOD = 'CHANGE_START_PERIOD';
export const CHANGE_START_AMOUNT = 'CHANGE_START_AMOUNT';
export const TOGGLE_OFFSET = 'TOGGLE_OFFSET';
export const CHANGE_OFFSET_MODE = 'CHANGE_OFFSET_MODE';
export const CHANGE_OFFSET_PERIOD = 'CHANGE_OFFSET_PERIOD';
export const CHANGE_OFFSET_AMOUNT = 'CHANGE_OFFSET_AMOUNT';
export const TOGGLE_END_DATE = 'TOGGLE_END_DATE';
export const CHANGE_END_MODE = 'CHANGE_END_MODE';
export const CHANGE_END_PERIOD = 'CHANGE_END_PERIOD';
export const CHANGE_END_AMOUNT = 'CHANGE_END_AMOUNT';
export const TOGGLE_TIME_RANGE = 'TOGGLE_TIME_RANGE';
export const CHANGE_FROM_HOURS = 'CHANGE_FROM_HOURS';
export const CHANGE_TO_HOURS = 'CHANGE_TO_HOURS';
export const CHANGE_FROM_WEEK_DAY = 'CHANGE_FROM_WEEK_DAY';
export const CHANGE_TO_WEEK_DAY = 'CHANGE_TO_WEEK_DAY';
export const CHANGE_CUSTOM_DATE = 'CHANGE_CUSTOM_DATE';
export const TIME_PERIOD_REINIT = 'TIME_PERIOD_REINIT';
export const CHANGE_TO_PRESET_MODE = 'CHANGE_TO_PRESET_MODE';
export const CHANGE_PRESET = 'CHANGE_PRESET';
export const CLEAR_PRESET = 'CLEAR_PRESET';
export const TOGGLE_PRESET_CUSTOM_INCLUDE = 'TOGGLE_PRESET_CUSTOM_INCLUDE';

export const CHANGE_MONTH_START_POINT = 'CHANGE_MONTH_START_POINT';
export const CHANGE_WEEK_START_POINT = 'CHANGE_WEEK_START_POINT';
export const CHANGE_YEAR_START_POINT = 'CHANGE_YEAR_START_POINT';

const actions = {
	changeMode: mode => ({ type: CHANGE_MODE, mode }),
	changeStartMode: mode => ({ type: CHANGE_START_MODE, mode }),
	changeStartPeriod: period => ({ type: CHANGE_START_PERIOD, period }),
	changeStartAmount: amount => ({ type: CHANGE_START_AMOUNT, amount }),
	toggleOffset: () => ({ type: TOGGLE_OFFSET }),
	changeOffsetMode: mode => ({ type: CHANGE_OFFSET_MODE, mode }),
	changeOffsetPeriod: period => ({ type: CHANGE_OFFSET_PERIOD, period }),
	changeOffsetAmount: amount => ({ type: CHANGE_OFFSET_AMOUNT, amount }),
	toggleEndDate: () => ({ type: TOGGLE_END_DATE }),
	changeEndMode: mode => ({ type: CHANGE_END_MODE, mode }),
	changeEndPeriod: period => ({ type: CHANGE_END_PERIOD, period }),
	changeEndAmount: amount => ({ type: CHANGE_END_AMOUNT, amount }),
	toggleTimeRange: () => ({ type: TOGGLE_TIME_RANGE }),

	changeFromHours: fromHours => ({ type: CHANGE_FROM_HOURS, fromHours }),
	changeToHours: toHours => ({ type: CHANGE_TO_HOURS, toHours }),
	changeFromWeekDay: fromDay => ({ type: CHANGE_FROM_WEEK_DAY, fromDay }),
	changeToWeekDay: toDay => ({ type: CHANGE_TO_WEEK_DAY, toDay }),
	changeCustomDateRange: (startDate, endDate) => ({ type: CHANGE_CUSTOM_DATE, startDate, endDate }),
	reInit: (timePeriodOptions, skipPresetReducer) =>
		({ type: TIME_PERIOD_REINIT, timePeriodOptions, skipPresetReducer }),
	changeToPresetMode: (presetMode) => ({ type: CHANGE_TO_PRESET_MODE, presetMode }),
	changePreset: (preset, config) => ({ type: CHANGE_PRESET, preset, config }),
	clearPreset: () => ({ type: CLEAR_PRESET }),
	togglePresetIncludeToday: () => ({ type: TOGGLE_PRESET_CUSTOM_INCLUDE }),
	changeMonthStartPoint: (start) => ({ type: CHANGE_MONTH_START_POINT, start }),
	changeWeekStartPoint: (start) => ({ type: CHANGE_WEEK_START_POINT, start }),
	changeYearStartPoint: (startMonth, startDay) => ({ type: CHANGE_YEAR_START_POINT, startMonth, startDay }),
};

export default actions;
