export const FETCH_WRITTEN_UPDATE = 'plecto/v3/writtenUpdates/FETCH_WRITTEN_UPDATE';
export const FETCH_WRITTEN_UPDATE_SUCCESS = 'plecto/v3/writtenUpdates/FETCH_WRITTEN_UPDATE_SUCCESS';
export const FETCH_WRITTEN_UPDATE_FAILURE = 'plecto/v3/writtenUpdates/FETCH_WRITTEN_UPDATE_FAILURE';

export const SUBMIT_WRITTEN_UPDATE = 'plecto/v3/writtenUpdates/SUBMIT_WRITTEN_UPDATE';
export const SUBMIT_WRITTEN_UPDATE_SUCCESS = 'plecto/v3/writtenUpdates/SUBMIT_WRITTEN_UPDATE_SUCCESS';
export const SUBMIT_WRITTEN_UPDATE_FAILURE = 'plecto/v3/writtenUpdates/SUBMIT_WRITTEN_UPDATE_FAILURE';

export const fetchWrittenUpdate = (writtenUpdateId) => ({
	type: FETCH_WRITTEN_UPDATE,
	writtenUpdateId
});

export const fetchWrittenUpdateSuccess = (id, payload) => ({
	type: FETCH_WRITTEN_UPDATE_SUCCESS,
	payload
});

export const fetchWrittenUpdateFailure = () => ({
	type: FETCH_WRITTEN_UPDATE_FAILURE
});

export const submitWrittenUpdate = (writtenUpdateId, writtenUpdate) => ({
	type: SUBMIT_WRITTEN_UPDATE,
	writtenUpdateId,
	writtenUpdate
});

export const submitWrittenUpdateSuccess = () => ({
	type: SUBMIT_WRITTEN_UPDATE_SUCCESS
});

export const submitWrittenUpdateFailure = () => ({
	type: SUBMIT_WRITTEN_UPDATE_FAILURE
});
