import styled from 'styled-components';
import { SPACING } from '../../styles/spacing';
import { FONT_SIZES } from '../../styles/typography';
import { COLORS_BORDER, COLORS, COLORS_GRAY } from '../../styles/colors';
import { INPUT_HEIGHT } from '../../styles/typography';
import { opacify } from 'polished';

const OPACITY = 0.6;

export const Input = styled.input.attrs(props => ({
	error: props.error ? COLORS['error'] : COLORS_GRAY[400],
}))`
	display: block;
	width: 100%;
	height: ${INPUT_HEIGHT};
	padding: ${SPACING.smaller} ${SPACING.small};
	font-size: ${FONT_SIZES.big};
	color: ${COLORS_GRAY['700']};
	background-color: ${COLORS.light};
	border: 1px solid ${props => props.error};
	border-radius: 4px;
	outline: none;

	&:focus {
		border-color: ${COLORS_BORDER.brand};
		outline: 0;
		box-shadow: 0 0 8px ${opacify(OPACITY, COLORS_BORDER.brand)};
	}
`;
