import { FETCH, FETCH_SUCCESS } from './actions';
import _keyBy from 'lodash/keyBy';
import _pick from 'lodash/pick';

const screens = (state = { isFetching: false, screensById: {}, isStale: true, }, action) => {
	switch (action.type) {
	case FETCH:
		if (action.organizationId) {
			return {
				...state,
				[action.organizationId]: {
					...state[action.organizationId],
					isFetching: true,
					isStale: false,
				}
			};
		}
		return {
			...state,
			isFetching: true,
			isStale: false,
		};
	case FETCH_SUCCESS: {
		if (action.organizationId) {
			return {
				...state,
				[action.organizationId]: {
					...state[action.organizationId],
					screensById: _keyBy(action.payload.map(t => _pick(t, ['uuid', 'name'])), 'uuid'),
					isFetching: false,
				}
			};
		}
		return {
			...state,
			screensById: _keyBy(action.payload.map(t => _pick(t, ['uuid', 'name'])), 'uuid'),
			isFetching: false,
		};
	}
	default:
		return state;
	}
};

export default screens;
