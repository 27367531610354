import { actionPrefix } from '../constants';

export const CREATE_ANSWER = `${actionPrefix}CREATE_ANSWER`;
export const CREATE_ANSWER_SUCCESS = `${actionPrefix}CREATE_ANSWER_SUCCESS`;
export const CREATE_ANSWER_FAILURE = `${actionPrefix}CREATE_ANSWER_FAILURE`;

export const createAnswer = (quizResultId, question) => ({
	type: CREATE_ANSWER,
	quizResultId,
	question
});

export const createAnswerSuccess = (quizResultId, question, payload) => ({
	type: CREATE_ANSWER_SUCCESS,
	quizResultId,
	question,
	payload
});

export const createAnswerFailure = (quizResultId, question, payload, error, errors) => ({
	type: CREATE_ANSWER_FAILURE,
	error,
	errors
});
