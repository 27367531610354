export const ACHIEVEMENT_BADGE_SIZES = {
	small: 'small',
	medium: 'medium',
	large: 'large',
};

export const ACHIEVEMENT_BADGE_SIZES_PX = {
	[ACHIEVEMENT_BADGE_SIZES.small]: '26px',
	[ACHIEVEMENT_BADGE_SIZES.medium]: '48px',
	[ACHIEVEMENT_BADGE_SIZES.large]: '60px',
};
