import { takeLatest } from 'redux-saga/effects';
import {
	CREATE_COMMENT,
	createCommentFailure,
	createCommentSuccess,
	DELETE_COMMENT,
	deleteCommentFailure,
	deleteCommentSuccess,
	GET_COMMENTS,
	getCommentsFailure,
	getCommentsSuccess
} from './actions';
import apiSagaGenerator from '../../saga/apiSagaGenerator';
import {
	createComment, deleteComment, getComments
} from './api';

const oneOnOnesSagas = [
	takeLatest(GET_COMMENTS,
		apiSagaGenerator()
			.forApi(getComments)
			.withArguments((action) => [action.oneOnOneId])
			.forSuccess(getCommentsSuccess)
			.forError(getCommentsFailure)
			.includeErrorResult()
			.withMessage('Error getting comments')
			.generate()
	),
	takeLatest(CREATE_COMMENT,
		apiSagaGenerator()
			.forApi(createComment)
			.withArguments((action) => [action.oneOnOneId, action.comment])
			.forSuccess(createCommentSuccess)
			.withActionArguments((action) => [action.commentId])
			.forError(createCommentFailure)
			.includeErrorResult()
			.withMessage('Error creating comment')
			.generate()
	),
	takeLatest(DELETE_COMMENT,
		apiSagaGenerator()
			.forApi(deleteComment)
			.withArguments((action) => [action.oneOnOneId, action.commentId])
			.forSuccess(deleteCommentSuccess)
			.withActionArguments((action) => [action.commentId])
			.skipResult()
			.forError(deleteCommentFailure)
			.includeErrorResult()
			.withMessage('Error deleting comment')
			.generate()
	),
];

export default oneOnOnesSagas;
