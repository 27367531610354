import { ACHIEVEMENT_BADGE_SHAPES as SHAPES } from '../achievementBadgeShapes';
import React from 'react';
import PropTypes from 'prop-types';
import AchievementBadge from './AchievementBadge';

/* eslint-disable max-len */
const hexagon = ({ fill, stroke }) =>
	<svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M31 2.88675L52.9808 15.5774C53.5996 15.9346 53.9808 16.5949 53.9808 17.3094V42.6906C53.9808 43.4051 53.5996 44.0654 52.9808 44.4226L31 57.1133C30.3812 57.4705 29.6188 57.4705 29 57.1132L7.01924 44.4226C6.40043 44.0654 6.01924 43.4051 6.01924 42.6906V17.3094C6.01924 16.5949 6.40043 15.9346 7.01924 15.5774L29 2.88675C29.6188 2.52949 30.3812 2.52949 31 2.88675Z"
			fill={fill} stroke={stroke} strokeWidth="4"
		/>
	</svg>;

const square = ({ fill, stroke }) =>
	<svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M52 6H8C6.89543 6 6 6.89543 6 8V52C6 53.1046 6.89543 54 8 54H52C53.1046 54 54 53.1046 54 52V8C54 6.89543 53.1046 6 52 6Z"
			fill={fill} stroke={stroke} strokeWidth="4"
		/>
	</svg>;
/* eslint-enable max-len */

const circle = ({ fill, stroke }) =>
	<svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="6" y="6" width="48" height="48" rx="24" fill={fill} stroke={stroke} strokeWidth="4"/>
	</svg>;

function badgeRenderer({ shape }) {
	switch (shape) {
	case SHAPES.square:
		return square;
	case SHAPES.circle:
		return circle;
	case SHAPES.hexagon:
	default:
		return hexagon;
	}
}

export default function AchievementBadgeBackground({ shape, fill, stroke }) {
	return badgeRenderer({ shape })({ fill, stroke });
}

AchievementBadge.propTypes = {
	shape: PropTypes.oneOf(Object.values(SHAPES)),
	fill: PropTypes.string.isRequired,
	stroke: PropTypes.string.isRequired,
};
