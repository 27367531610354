import React from 'react';
import { FilterItem } from './types';
import buildForest from './treeBuilder/buildForest';
import { FilterTree } from './FilterTree';
import { TreeDataContext } from './TreeDataContext';
import { FilterDropdownBase } from './FilterDropdownBase';

type FilterDropdownProps = {
    label: string,
    items: FilterItem[];
    selection?: string[];
    isFetching?: boolean;
    onSelectionChange?: (selection: string[]) => unknown;
    onClose?: (selection: string[]) => void;
    onOpen?: () => void;
    fitContent?: boolean;
    canSelectedAll?: boolean;
    maxWidth?: number;
    compactLabel?: boolean;
    wrapUnavailableParents?: boolean;
};

export function FilterTreeDropdown({
	label,
	items,
	selection = [],
	isFetching,
	onSelectionChange,
	onClose,
	onOpen,
	canSelectedAll,
	fitContent,
	maxWidth,
	compactLabel,
	wrapUnavailableParents,
}: FilterDropdownProps) {

	return (
		<FilterDropdownBase
			fitContent={fitContent}
			isFetching={isFetching}
			items={items}
			label={label}
			canSelectAll={canSelectedAll}
			maxWidth={maxWidth}
			compactLabel={compactLabel}
			onClose={onClose}
			onOpen={onOpen}
			onSelectionChange={onSelectionChange}
			selection={selection}
		>
			{
				({ items, selection, filteredItems, onToggle, searchMode }) => {
					const { forest, initiallyExpanded } = buildForest(items, selection, wrapUnavailableParents);
					return <div>
						<TreeDataContext.Provider value={{ initiallyExpanded, selection }}>
							<FilterTree
								onToggle={onToggle}
								items={searchMode ? filteredItems : forest}
							/>
						</TreeDataContext.Provider>
					</div>;
				}
			}
		</FilterDropdownBase>
	);
}
