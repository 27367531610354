import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Loader } from '../loaders/Loader';

export default function SaveButton({ className, extraClassName, text, isLoading, onClick, disabled }) {
	const classes = classNames(className || 'btn btn-lg btn-success', extraClassName);
	const { t } = useTranslation();
	return <button
		type={onClick ? 'button' : 'submit'}
		onClick={onClick}
		className={classes}
		disabled={isLoading || disabled}
	>
		{isLoading ? <Loader /> : (text || t('Save'))}
	</button>;
}
