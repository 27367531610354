import classNames from 'classnames';
import React from 'react';
import { Icon } from '../icon/Icon';
import { IconButton } from '../buttons/IconButton';
import Badge from '../badge/Badge';

type Props = {
	title?: JSX.Element | string,
	badge?: string,
	onClose?: () => void,
	className?: string,
	icon?: string,
	blankMode?: boolean,
};

export function PaneTitle({ title, badge, onClose, className, icon, blankMode }: Props) {
	const showTitle = title && !blankMode;
	return <div className={classNames('pane-title', className)}>
		<div className="pane-title-right">
			<IconButton className='modal-close-btn' iconName='x' onClick={onClose} />
		</div>
		{showTitle && <div className="pane-title-h1">
			{icon && <Icon name={icon} />} {title}{badge && <Badge label={badge} />}
		</div>}
	</div>;
}
