import React, { MouseEventHandler, useCallback } from 'react';
import classNames from 'classnames';
import { Tooltip } from '../tooltip/Tooltip';
import { Icon } from '../icon/Icon';
import styled from 'styled-components';

type Props = {
	className?: string,
	label: string,
	onClick?: (eventParameter?: string) => void,
	url?: string,
	title?: string,
	icon?: string,
	eventParameter?: string,
	disabled?: boolean,
}

const StyledLink = styled.a`
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
  }
`;

export const DropdownMenuAction = ({
	className,
	title,
	label,
	onClick,
	url,
	icon,
	eventParameter,
	disabled,
	...otherProps
}: Props) => {
	const onClickCallback = useCallback((e: Parameters<MouseEventHandler<HTMLAnchorElement>>[0]) => {
		if (disabled) {
			e.preventDefault();
		} else {
			if (!url) {
				e.preventDefault();
				onClick?.(eventParameter);
			}
		}

	},[url, eventParameter, onClick, disabled]);

	const menuAction = (
		<li className={classNames(className, { disabled })}>
			<StyledLink
				onClick={onClickCallback}
				{ ...(url && { href: url }) }
				{...otherProps}>
				{icon ? <Icon name={icon} /> : null}
				{label}
			</StyledLink>
		</li>
	);

	return title ? <Tooltip message={title} placement="bottom">{menuAction}</Tooltip> : menuAction;
};

export function DropdownMenuSeparator() {
	return <li role="separator" className="divider"></li>;
}
