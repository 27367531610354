import React from 'react';
import styled from 'styled-components';
import { formOpacities } from '../formOpacities';
import { withCssSelector } from '../../shared/withCssSelector';

const StyledTextArea = styled.textarea<{
	$disabled?: boolean | undefined;
	$hasError?: boolean | undefined;
}>`
	accent-color: #12A34D;
	opacity: ${props => props.$disabled ? formOpacities.disabled : formOpacities.full};
	pointer-events: ${props => props.$disabled ? 'none' : 'auto'};

	background-image: none;
	border: 1px solid ${props => props.$hasError ? '#f6cad1' : '#d3d8d8'};
	border-radius: 4px;

	-webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

	display: block;
	width: 100%;
	padding: 8px 10px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #2d415a;
	background-color: #fff;

	:focus {
		border-color: #12a34d;
		outline: 0;
		-webkit-box-shadow: 0 0 8px rgba(18, 163, 77, 0.6);
		box-shadow: 0 0 8px rgba(18, 163, 77, 0.6);
	}

	::placeholder {
		color: #C9CACC;
	}
`;

export type TextAreaProps = {
	hasError?: boolean | undefined
} & React.ComponentPropsWithoutRef<'textarea'>

export const TextArea = withCssSelector(React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
	function TextArea(
		{
			hasError,
			disabled,
			tabIndex,
			readOnly,
			...props
		},
		ref
	) {
		return <StyledTextArea
			ref={ref}
			$hasError={hasError}
			$disabled={disabled}
			tabIndex={disabled ? -1 : tabIndex}
			readOnly={disabled ? true : readOnly}
			{...props}
		/>;
	}
), StyledTextArea.toString());
