export const FETCH = 'plecto/datasources/FETCH';
export const FETCH_SUCCESS = 'plecto/datasources/FETCH_SUCCESS';
export const FETCH_API_ERROR = 'plecto/datasources/FETCH_API_ERROR';
export const FETCH_ALL = 'plecto/datasources/FETCH_ALL';
export const FETCH_ALL_SUCCESS = 'plecto/datasources/FETCH_ALL_SUCCESS';
export const FETCH_ALL_API_ERROR = 'plecto/datasources/FETCH_ALL_API_ERROR';
export const FETCH_DATASOURCE = 'plecto/datasources/FETCH_DATASOURCE';
export const FETCH_DATASOURCE_SUCCESS = 'plecto/datasources/FETCH_DATASOURCE_SUCCESS';
export const FETCH_DATASOURCE_API_ERROR = 'plecto/datasources/FETCH_DATASOURCE_API_ERROR';

export const fetchDatasources = () => ({
	type: FETCH,
});

export const fetchDatasourcesSuccess = result => ({
	type: FETCH_SUCCESS,
	result,
});

export const fetchDatasourcesError = error => ({
	type: FETCH_API_ERROR,
	error,
});

export const fetchAllDatasources = () => ({
	type: FETCH_ALL,
});

export const fetchAllDatasourcesSuccess = result => ({
	type: FETCH_ALL_SUCCESS,
	result,
});

export const fetchAllDatasourcesError = error => ({
	type: FETCH_ALL_API_ERROR,
	error,
});

export const fetchDataSource = (dataSourceId) => ({
	type: FETCH_DATASOURCE,
	dataSourceId
});

export const fetchDataSourceSuccess = (dataSourceId, payload) => ({
	type: FETCH_DATASOURCE_SUCCESS,
	payload
});

export const fetchDataSourceError = () => ({
	type: FETCH_DATASOURCE_API_ERROR
});
