import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { itemFontSize, itemHorizontalPadding, singleItemSize } from './sizing';
import { IconButton } from '../buttons/IconButton';
import { ItemInput } from './ItemInput';
import useEditMode from './useEditMode';
import { Tooltip } from '../tooltip/Tooltip';

const StyledItemLabel = styled.span`
	border-radius: 4px;
	display: flex;
	align-items: center;
	background-color: #F1F2F3;
	padding: 2px ${props => itemHorizontalPadding[props.$size]}px;
	height: ${props => singleItemSize[props.$size]}px;
	font-size: ${props => itemFontSize[props.$size]}px;
	text-overflow: ellipsis;
	overflow: hidden;
	column-gap: 8px;
	${props => props.$hasError ? css`
		border: 1px solid #f6cad1;
	` : ''}
`;

const StyledIconButton = styled(IconButton)`
	padding: 0;
	border: 0;
	background-color: transparent;
	color: #9CA3AF;
	cursor: pointer;

	&:hover {
		color: #374151;
	}
`;

const StyledName = styled.span`
	text-overflow: ellipsis;
	overflow: hidden;
	color: #374151;
`;

export default function SelectedItem({ item, size, onRemove, editable, onEdit, onChange }) {
	const onItemRemoved = useCallback(() => {
		onRemove(item.id);
	}, [item.id, onRemove]);

	const onCrossClick = useCallback((e) => {
		e.stopPropagation();
		onItemRemoved();
	}, [onItemRemoved]);

	const { editMode, startEdit, endEdit } = useEditMode();

	const enableEdit = (e) => {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		onEdit();
		startEdit();
	};

	const onChanged = (nextVal) => {
		onChange(item.id, nextVal);
		endEdit();
	};

	const onItemInputClick = useCallback((e) => {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
	}, []);

	const hasError = Boolean(item.error);

	const label = <StyledItemLabel $size={size} $hasError={hasError} >
		<StyledName
			$size={size}
			onClick={editable ? enableEdit : null}
		>{item.name}</StyledName>
		<StyledIconButton iconName={'x'} onClick={onCrossClick} $size={size}/>
	</StyledItemLabel>;

	if (editMode) {
		return <ItemInput
			value={item.name}
			onEntered={onChanged}
			onBlur={endEdit}
			onRemove={onItemRemoved}
			size={size}
			onClick={onItemInputClick} />;
	}

	if (hasError) {
		return <Tooltip message={item.error}>
			{label}
		</Tooltip>;
	}

	return label;
}
