import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fillWithDefaults } from './blocks/defaultOptions';
import { Provider } from 'react-redux';
import TimePeriodSelectorControl from './TimePeriodSelectorControl';
import TimePeriodSelectorContext from './TimePeriodSelectorContext';

function TimePeriodSelectorWrapper({ store, ...props }) {
	const { onSavePeriod, onChangePeriod, onCancelPeriod, timePeriodOptions } = useContext(TimePeriodSelectorContext);

	const [editMode, setEditMode] = useState(!!props.openOnStart);
	const [editorOptions, setEditorOptions] = useState(() => fillWithDefaults(timePeriodOptions));

	useEffect(() => {
		if (!editMode) {
			setEditorOptions(fillWithDefaults(timePeriodOptions));
		}
	}, [timePeriodOptions, editMode]);

	const onSelectorOpen = (open) => {
		setEditMode(open);
	};

	const onSave = (state) => {
		setEditMode(false);
		onSavePeriod(state);
	};

	const onCancel = () => {
		setEditMode(false);
		onCancelPeriod?.();
	};

	const onChange = (state) => {
		if (editMode) {
			onChangePeriod?.(state);
		}
	};

	return <Provider store={store}>
		<TimePeriodSelectorControl
			{...props}
			timePeriodOptions={editorOptions}
			onSelectorOpen={onSelectorOpen}
			onSave={onSave}
			onCancel={onCancel}
			onChange={onChange}
		/>
	</Provider>;
}

TimePeriodSelectorWrapper.propTypes = {
	openOnStart: PropTypes.bool,
};

export default memo(TimePeriodSelectorWrapper);

