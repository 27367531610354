import updateStateById from '../../../redux/updateStateById';
import {
	FETCH_DRILLDOWNS_RENDER_SUCCESS,
	FETCH_DRILLDOWNS_RENDER,
	FETCH_DRILLDOWNS_RENDER_API_ERROR,
	CLEAR_DRILLDOWNS_RENDER,
	REQUEST_BACKGROUND_DRILLDOWN_RENDER_SUCCESS,
	REQUEST_BACKGROUND_DRILLDOWN_RENDER,
} from '../actions';
import { addPageToDrilldownRender, initDrilldownRender } from './renderService';
import _isFinite from 'lodash/isFinite';

export default function drilldownRenderReducer(state = {}, action) {
	switch (action.type) {
	case FETCH_DRILLDOWNS_RENDER: {
		return updateStateById(
			state,
			action.reportGroupFormulaId,
			(currentState) => ({
				isFetchingDrilldowns: true,
				drillDownRender: action.isPaginated
					? currentState.drillDownRender
					: null,
				renderedDrilldownPages: action.isPaginated
					? (currentState.renderedDrilldownPages || []).concat([action.page])
					: [],
			})
		);
	}

	case FETCH_DRILLDOWNS_RENDER_SUCCESS: {
		return updateStateById(state, action.reportGroupFormulaId, (currentState) => ({
			isFetchingDrilldowns: false,
			renderedDrilldownPages: action.isPaginated 
				? currentState.renderedDrilldownPages 
				: [action.payload.pagination_data.current_page],
			drillDownRender: action.isPaginated
				? addPageToDrilldownRender(action.payload, currentState.drillDownRender)
				: initDrilldownRender(action.payload)
		}));
	}	
	case FETCH_DRILLDOWNS_RENDER_API_ERROR: {
		return updateStateById(state, action.reportGroupFormulaId, {
			isFetchingDrilldowns: false,
		});
	}
	case CLEAR_DRILLDOWNS_RENDER: {
		return updateStateById(state, action.reportGroupFormulaId, (currentState) => ({
			renderedDrilldownPages: [],
			drillDownRender: {}
		}));
	}

	case REQUEST_BACKGROUND_DRILLDOWN_RENDER: {
		return updateStateById(state, action.reportGroupFormulaId, (currentState) => {
			const { operationId, pageNumber } = action;

			const requestKey = getRenderJobId(pageNumber);

			return {
				renderTasks: {
					...(currentState.renderTasks || {}),
					[requestKey]: {
						operationId: operationId
					}
				}
			};
		});
	}
	
	case REQUEST_BACKGROUND_DRILLDOWN_RENDER_SUCCESS: {
		return updateStateById(state, action.reportGroupFormulaId, (currentState) => {
			const { operationId, pageNumber, payload: { task_id: taskId }} = action;
			const requestKey = getRenderJobId(pageNumber);

			const currentOperationId = currentState.renderTasks?.[requestKey]?.operationId;
			if (currentOperationId === operationId) {
				return {
					renderTasks: {
						...(currentState.renderTasks || {}),
						[requestKey]: {
							...currentState.renderTasks[requestKey],
							taskId: taskId
						}
					}
				};
			}
		});
	}

	default:
		return state;
	}
}

export function getRenderJobId(pageNumber) {
	return _isFinite(pageNumber) ? pageNumber : 'default';
}
