import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconWithTooltip from '../iconWithTooltip/IconWithTooltip';
import classNames from 'classnames';

const S = {};

S.HelpInline = styled.span`
	display: inline-block;
	margin-top: 0;
	margin-bottom: 4px;
	min-height: 22px;
	vertical-align: middle;
`;

function HelpInline({ text, iconName = 'info-icon', className }) {
	if (text) {
		return <S.HelpInline className={classNames(className, 'help-inline')}>
			<IconWithTooltip iconName={iconName} message={text} />
		</S.HelpInline>;
	}
	return null;
}

HelpInline.propTypes = {
	text: PropTypes.string,
	iconName: PropTypes.string,
};

export default HelpInline;
