/* eslint-disable max-len */
import React, { useCallback, memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateRange } from 'react-date-range';
import RangeInputs from './RangeInputs';
import styled from 'styled-components';

const S = {};
S.Wrapper = styled.div`
  box-sizing: border-box;
  background: #ffffff;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

.rdrMonthAndYearWrapper {
  box-sizing: inherit;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.rdrMonthAndYearPickers {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.rdrMonths {
  display: -webkit-box;
  display: flex;
  margin: auto;
}

.rdrMonthsVertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.rdrWeekDays {
  display: -webkit-box;
  display: flex;
}

.rdrWeekDay {
  width: 30px;
  margin-left: 34px;
  margin-right: 34px;

  box-sizing: inherit;
  text-align: center;
}

.rdrDays {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  width: 30px * 7 + 34px * 2 * 7;
}

.rdrDateRangeWrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.rdrDay {
  box-sizing: inherit;
  width: 30px;
  position: relative;
  font: inherit;
  cursor: pointer;
  margin-bottom: 34px;
  margin-right: 34px;
  margin-left: 34px;

}

.rdrDayNumber {
  display: block;
  position: relative;

  span {
    color: #1d2429;
  }
}

.rdrDayDisabled {
  cursor: not-allowed;
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  pointer-events: none;
}


.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  pointer-events: none;
}

.rdrStaticRanges {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.rdrInputRange {
  display: -webkit-box;
  display: flex;
}

.rdrCalendarWrapper {
  color: #000000;
}

.rdrMonthAndYearWrapper {
  -webkit-box-align: center;
  align-items: center;
}

.rdrMonthAndYearPickers {
  font-weight: 500;

  select {
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;

    &::-ms-expand {
      display: none;
    }

    border: 0;
    background: transparent;
    padding: 5px 25px 5px 10px;
    border-radius: 4px;
    outline: 0;
    color: #3e484f;
    background: url("data:image/svg+xml;utf8,<svg width='3px' height='2px' viewBox='0 0 3 2' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
    background-position: right 4px top calc(50% + 2px);
    background-size: 18px;
    cursor: pointer;
    text-align-last: center;

    &:hover {
      background-color: #F9F9F9;
    }
  }
}

.rdrMonthPicker, .rdrYearPicker {
  margin: 0 5px;
}

.rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.4em;
  padding: 0;
  border: 0;
  outline: none;
  background: 0;
}

.rdrNextPrevButton {
  i {
    font-family: 'plectoicons';
    display: inline-block;
    speak: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    color: #888;
  }

  &:hover i {
    &:before {
      color: #181818;
    }
  }
}

.rdrMonth .rdrWeekDays {
  padding: 0;
}

.rdrWeekDay {
  font-weight: 400;
  line-height: 2.667em;
  color: rgb(132, 144, 149);
}

.rdrDay {
  background: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  line-height: 30px;
  height: 30px;
  text-align: center;
  color: #1d2429;

  &:focus {
    outline: 0;
  }
}

.rdrDayNumber {
  outline: 0;
  font-weight: 400;
  position: absolute;
  left: 0;
  right: 0;
  top: 5px;
  bottom: 5px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 500;

  &:after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: 18px;
    height: 1px;
    background: #181818;
  }
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  background: currentColor;
  position: absolute;
  left: 0;
  right: 0;
}

.rdrInRange {
  top: 0;
  bottom: 0;
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
  background: rgba(255, 255, 255, 0.09);
  position: absolute;
  top: 0;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: none;
  border: 0px solid currentColor;
  z-index: 1;
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview, .rdrStartEdge, .rdrEndEdge {
  background: rgba(255, 255, 255, 0.09);
  position: absolute;
  top: 0;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: none;
  border: 0px solid currentColor;
  z-index: 1;
}

.rdrDefinedRangesWrapper {
  background: #fff;
  margin-right: 20px;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected .rdrStaticRangeLabel {
  color: #181818;
  font-weight: 500;
}

.rdrStaticRange {
  border: 0;
  cursor: pointer;
  display: block;
  outline: 0;
  padding: 0;
  background: #fff;
  color: #666666;
  border-bottom: none;
  height: 30px;
  width: 100px;
  margin-bottom: 34px;

  &:hover .rdrStaticRangeLabel {
    color: #181818;
  }
}

.rdrStaticRangeLabel {
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 0px 0px 0px 6px;
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
}

.rdrDayHovered,
.rdrDay:hover {
  .rdrDayStartPreview, .rdrDayEndPreview {
    background-color: #fff;
    box-sizing: border-box;
    z-index: 2;

    &:not(.rdrStartEdge):not(.rdrEndEdge):before {
      background-color: #fff;
      z-index: 1;
      border: 2px solid #2bb673;
    }

    & + .rdrDayNumber,
    & + span:not(.rdrDayInPreview):not(.rdrDayEndPreview):not(.rdrDayStartPreview) + .rdrDayNumber {
      span {
        color: #1d2429 !important;

        &:after {
          background: #1d2429;
        }
      }
    }
  }
}


.rdrDayPassive .rdrDayNumber span {
  color: #d5dce0;
}

.rdrDayDisabled {
  background-color: rgb(248, 248, 248);

  .rdrDayNumber span {
    color: #aeb9bf;
  }
}

.rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview {
  -webkit-filter: grayscale(100%) opacity(60%);
  filter: grayscale(100%) opacity(60%);
}

.rdrInRange,
.rdrDayInPreview {
  opacity: 0.1;
  background-color: #2bb673;
  border-radius: 0 !important;
  margin-right: -34px;
  margin-left: -34px;
  margin-left: -34px;
}

.rdrStartEdge,
.rdrEndEdge,
.rdrDayEndPreview,
.rdrDayStartPreview {
  background-color: #2bb673;
  border-radius: 50%;

  & + .rdrDayNumber,
  & + span:not(.rdrDayInPreview):not(.rdrDayEndPreview):not(.rdrDayStartPreview) + .rdrDayNumber {
    span {
      color: #ffffff !important;
      z-index: 2;

      &:after {
        background: transparent;
      }
    }
  }

  &:before {
    content: '';
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: transparent;
    display: inline-block;
  }

  &:after {
    content: '';
    opacity: 0.1;
    background-color: #2bb673;
    width: 100%;
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    width: calc(100% + 4px);
    z-index: -1;
  }

  &:not(.rdrDayEndPreview):not(.rdrDayStartPreview):after {
    height: 100%;
    top: 0;
    bottom: 0;
  }
}

.rdrStartEdge,
.rdrDayStartPreview:not(.rdrEndEdge):not(.rdrStartEdge):not(.rdrInRange) {
  &:after {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
}

.rdrEndEdge,
.rdrDayEndPreview:not(.rdrEndEdge):not(.rdrStartEdge):not(.rdrInRange) {
  &:after {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
}

.rdrEndEdge:not(.rdrStartEdge),
.rdrDayEndPreview:not(.rdrDayStartPreview) {
  &:after {
    left: -34px;
  }
}

.rdrStartEdge.rdrEndEdge,
.rdrDayStartPreview.rdrDayEndPreview {
  &:after {
    width: 100%;
  }
}

.rdrCalendarInputsWrapper {
  width: 100%;
}

.rdrRangeInputs {
	display: flex;
	flex-basis: 100%;
	margin: 0 2px 16px 2px;

	.rdrRangeInput {
		&-container {
			flex: 2 1 auto;
			position: relative;
		}

		&-error {
			color: #b62b2b;
			font-size: 12px;
			width: 100%;
    		top: calc(100% + 2px);
			position: absolute;
			line-height: 15px;
		}

		font-size: 14px;
		line-height: 1.2;
		border: 2px solid #cdd3d3;
		width: 100%;

		&--focused {
			border: 2px solid #2bb673;
		}

		&--has-error {
			border: 2px solid #b62b2b;
			box-shadow: none;
		}

		&-separator {
			flex: 0 1 auto;
			margin: 0 10px;
			font-size: 12px;

			span {
				vertical-align: middle;
				line-height: 28px;
			}
		}
	}

}
`;

function DateRangeSelector({ timeFrom, timeTo, onChange }) {
	const [ranges, setRanges] = useState([{
		startDate: timeFrom,
		endDate: timeTo,
		key: 'selection',
	}]);

	const [currentPreviewRange, setCurrentPreviewRange] = useState({
		startDate: timeFrom,
		endDate: timeTo,
	});

	const changeDate = useCallback((value, rangeClosed) => {
		onChange(value.selection.startDate, value.selection.endDate);
		setRanges([value.selection]);
	}, [onChange]);

	const changeDatesManually = useCallback((startDate, endDate) => {
		onChange(startDate, endDate);
	},[onChange]);

	useEffect(() => {
		setRanges([{
			startDate: timeFrom,
			endDate: timeTo,
			key: 'selection',
		}]);
		setCurrentPreviewRange({
			startDate: timeFrom,
			endDate: timeTo,
		});
	}, [timeFrom, timeTo]);

	const [focusedRange, setFocusedRange] = useState([0,0]);

	return  <div className="rdrDateRangePickerWrapper">
		<div className="rdrCalendarInputsWrapper">
			<div className='rdrRangeInputs'>
				<RangeInputs 
					{...currentPreviewRange}
					onChange={changeDatesManually}
				/>
			</div>
			<DateRange
				onRangeFocusChange={setFocusedRange}
				focusedRange={focusedRange}
				ranges={ranges}
				onChange={changeDate}
				weekStartsOn={1}
				showDateDisplay={false}
				rangeColors={['#fff','#fff','#fff']}
				weekdayDisplayFormat="E"
				initialFocusedRange={focusedRange}
				dragSelectionEnabled={false}
				fixedHeight
			/>
		</div>
	</div>;
}

DateRangeSelector.propTypes = {
	timeFrom: PropTypes.instanceOf(Date).isRequired,
	timeTo: PropTypes.instanceOf(Date).isRequired,
	onChange: PropTypes.func.isRequired,
};

export default memo(DateRangeSelector);
