import { takeLatest } from 'redux-saga/effects';
import {
	SAVE_SLIDESHOW,
	saveSlideshowSuccess,
	saveSlideshowFailure,
} from './actions';
import { saveSlideshow } from './api';
import apiSagaGenerator from '../../saga/apiSagaGenerator';

const slideshowsSagas = [
	takeLatest(SAVE_SLIDESHOW,
		apiSagaGenerator()
			.forApi(saveSlideshow)
			.cancellable()
			.withArguments(action => [action.slideshowId, action.model])
			.forSuccess(saveSlideshowSuccess)
			.forError(saveSlideshowFailure)
			.withMessage('Error saving slideshow')
			.generate()
	),
];

export default slideshowsSagas;
