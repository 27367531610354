import React, { useContext } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import LocaleContext from '../../../contexts/LocaleContext';
import getThousandsGroupStyle from './getThousandsGroupStyle';

export default function NumberFormatter({
	value,
	placeholder,
	className,
	type,
	displayType,
	decimalScale,
	allowNegative,
	prefix,
	suffix,
	onValueChange,
	onKeyDown,
	renderText,
	customInput,
	isNumericString
}) {
	const { thousandsSeparator, thousandsGroup, decimalSeparator } = useContext(LocaleContext);
	const thousandsGroupStyle = getThousandsGroupStyle(thousandsGroup);
	return <NumberFormat
		placeholder={placeholder}
		onValueChange={onValueChange}
		autoFocus
		isNumericString={isNumericString}
		onKeyDown={onKeyDown}
		thousandsGroupStyle={thousandsGroupStyle}
		thousandSeparator={thousandsSeparator}
		value={value}
		prefix={prefix}
		suffix={suffix}
		decimalSeparator={decimalSeparator}
		displayType={displayType}
		type={type}
		renderText={renderText}
		customInput={customInput}
		allowNegative={allowNegative}
		decimalScale={decimalScale}
		className={className}
	/>;
}

NumberFormatter.propTypes = {
	type: PropTypes.string,
	displayType: PropTypes.string,
	decimalSeparator: PropTypes.string,
	decimalScale: PropTypes.number,
	thousandSeparator: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	thousandsGroupStyle: PropTypes.number,
	allowNegative: PropTypes.bool,
	prefix: PropTypes.string,
	suffix: PropTypes.string,
};

NumberFormatter.defaultProps = {
	type: 'text',
	displayType: 'text',
	isNumericString: true,
};

