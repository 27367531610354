import { useEffect, useMemo, useState } from 'react';
import usePassiveEventListener from '../../components/utils/usePassiveEventListener';
import _debounce from 'lodash/debounce';

export default function useInfiniteScroll(callback, scrollableRef = window, isFetching, hasMore) {
	const [isLoading, setIsLoading] = useState(isFetching || false);
	const callbackDebounced = useMemo(() => _debounce(() => callback()), [callback]);

	const getScrollPosition = ({ scrollHeight, scrollTop, clientHeight }) =>
		Math.floor(scrollHeight - scrollTop) === clientHeight;

	const handleScroll = () => {
		const scrollableContainer = scrollableRef.current;
		if (scrollableContainer) {
			const isBottom = getScrollPosition(scrollableContainer);
			if (isFetching || !hasMore) return;
			if (isBottom && !isLoading) {
				callbackDebounced();
				setIsLoading(true);
			}
		}
	};
	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	usePassiveEventListener(scrollableRef, 'scroll', handleScroll);

	return { isLoading: isFetching, setIsLoading };
}
