import { call, put, takeEvery, takeLatest } from '@redux-saga/core/effects';
import * as registrationsActions from './actions';
import * as api from './api';

function* fetchRegistrations(action) {
	try {
		const response = yield call(
			api.fetchRegistrations,
			action.dataSource,
		);
		const result = yield response.json();

		if (result.detail) {
			yield put(registrationsActions.fetchRegistrationsError(result.detail));
		} else {
			yield put(registrationsActions.fetchRegistrationsSuccess(result));
		}
	} catch (e) {
		yield put(registrationsActions.fetchRegistrationsError(e.message));
	}
}

function* addRegistration(action) {
	try {
		const response = yield call(
			api.addRegistration,
			action.body,
		);
		const result = yield response.json();

		if (!result.id) {
			const addedRegistration = {
				...action.body,
				unsaved: true,
				uuid: '',
			};
			if (!action.retry) {
				yield put(
					registrationsActions.addRegistrationError(
						result.message,
						addedRegistration,
					),
				);
			}
		} else {
			const addedRegistration = {
				...action.body,
				isNew: true,
				unsaved: false,
				uuid: result.id,
			};
			if (!action.retry) {
				yield put(
					registrationsActions.addRegistrationSuccess(addedRegistration),
				);
			} else {
				yield put(
					registrationsActions.addRegistrationSuccess(
						addedRegistration,
						true,
						action.index,
					),
				);
			}
		}
	} catch (e) {
		const addedRegistration = {
			...action.body,
			unsaved: true,
			uuid: '',
		};
		if (!action.retry) {
			yield put(
				registrationsActions.addRegistrationError(e.message, addedRegistration),
			);
		}
	}
}

function* updateRegistration(action) {
	try {
		const response = yield call(
			api.updateRegistration,
			action.dataSource + '/' + action.itemUuid,
			action.body,
		);
		const result = yield response.json();

		if (!result.id) {
			yield put(registrationsActions.updateRegistrationError(result.message));
		} else {
			const editedRegistration = {
				...action.body,
				uuid: result.id,
			};
			yield put(
				registrationsActions.updateRegistrationSuccess(editedRegistration),
			);
		}
	} catch (e) {
		yield put(registrationsActions.updateRegistrationError(e.message));
	}
}

function* deleteRegistration(action) {
	try {
		const response = yield call(
			api.deleteRegistration,
			action.datasourceUuid + '/' + action.uuid,
		);

		if (response.ok) {
			yield put(registrationsActions.deleteRegistrationSuccess());
		} else {
			yield put(registrationsActions.deleteRegistrationError(response.json()));
		}
	} catch (e) {
		yield put(registrationsActions.deleteRegistrationError(e.message()));
	}
}

const registrationsSagas = [
	takeLatest(registrationsActions.FETCH, fetchRegistrations),
	takeEvery(registrationsActions.DELETE, deleteRegistration),
	takeLatest(registrationsActions.ADD, addRegistration),
	takeLatest(registrationsActions.UPDATE, updateRegistration),
];

export default registrationsSagas;
