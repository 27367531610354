import React, { useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { CheckboxLabel } from '../checkbox/Checkbox';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import ensureInView from '../../utils/ensureInView';
import { COLORS } from '../../styles/colors';

const textColor = '#121212';
const hoverColor = '#EAF8F1';

const S = {};
const animationDuration = 200;

S.Item = styled.div`
	cursor: pointer;
	color: ${textColor};
	background-color: white;
	padding: 8px 12px;
	font-size: 14px;
	transition: background-color ${animationDuration}ms, color ${animationDuration}ms;
	margin: 0;

	&:hover {
		background-color: ${hoverColor};
	}

	& ${CheckboxLabel} {
		color: ${textColor};
	}

	&.dropdown-menu-item-selected {
		color: white;
		background-color: ${COLORS.primary};

		${props => props.hasChildren ? css`
		padding-top: 4px;
		padding-bottom: 4px;
		` : ''}

		${CheckboxLabel} {
			color: white;
		}
	}

	& .react-select__menu {
		color: initial;

		.react-select__option {
			cursor: pointer;
		}
	}
`;

S.ItemOverlay = styled.div`
	${props => props.inline ? css`
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		& > * {
			margin-right: 0.5em;
		}

		& .react-select-container {
			.react-select__control {
				min-height: 28px;
				max-height: 28px;
				height: 28px;
				font-size: 14px;

				.react-select__value-container {
					padding-top: 0;
					padding-bottom: 0;
				}

				.react-select__indicator {
					padding: 4px;
				}
			}
		}
	` : ''}
`;

export default function DropDownMenuItem({ label, value, selectValue, selected, onSelect, children, inline }) {
	const onClick = useCallback(() => {
		if (!selected) {
			onSelect(value, selectValue);
		}
	},[onSelect, selectValue, selected, value]);

	const itemRef = useRef();

	useDidMountEffect(() => {
		if (selected) {
			// do not use scrollIntoView here, as it causes page scrolling on reports
			ensureInView(itemRef.current.parentNode, itemRef.current);
		}
	});

	return (
		<S.Item
			ref={itemRef}
			className={classNames({ 'dropdown-menu-item-selected': selected })}
			onClick={onClick}
			hasChildren={Boolean(children)}
		>
			<S.ItemOverlay selected={selected} inline={inline}>
				<span>
					{label}
				</span>
				{selected ? children : null}
			</S.ItemOverlay>
		</S.Item>
	);
}
