import React from 'react';
import { Icon } from '../../icon/Icon';
import Breadcrumb from './Breadcrumb';
import styled from 'styled-components';

const S = {};

S.BreadCrumbs = styled.div`
	border: 1px solid #e2e6e6;
	display: flex;
`;

S.BreadcrumbBackButton = styled.div`
	flex: 0 1;
	cursor: pointer;
	padding: 5px 5px;
	display: flex;
	align-items: center;
	width: auto;

	&:hover {
		color: #262626;
		background-color: #f5f5f5;
	}
`;

S.Path = styled.div`
	flex: 1 1;
	padding: 5px 5px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	direction: rtl;
	text-align: left;
`;

S.BreadcrumbBackButtonLabel = styled.div`
	padding-left: 5px;
`;

export default function Breadcrumbs({ pathComponents, onBack, onBackTo, onBackLabel }) {
	return pathComponents.length ? <S.BreadCrumbs className="multi-level-dropdown-breadcrumbs">
		<S.BreadcrumbBackButton className="multi-level-dropdown-breadcrumbs-back" onClick={onBack}>
			<Icon name="arrow-left" />
			{onBackLabel && <S.BreadcrumbBackButtonLabel>{onBackLabel}</S.BreadcrumbBackButtonLabel>}
		</S.BreadcrumbBackButton>
		<S.Path className="multi-level-dropdown-breadcrumbs-path">
			{!onBackLabel && pathComponents.map((pc, i) =>
				<Breadcrumb
					key={`${pc.value}-${i}`}
					goToLevel={onBackTo}
					{...pc}
					isLast={i === pathComponents.length - 1}
				/>
			)}
		</S.Path>
	</S.BreadCrumbs> : null;
}
