import React, { useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import PopupFormPortal from './PopupFormPortal';
import PropTypes from 'prop-types';
import useOutsideClick from '../../../hooks/useOutsideClick';

export default function PopupInitiator({
	className,
	children,
	popup,
	offset,
	padding,
	initiallyOpen,
	placement,
	closeOnOutsideClick,
	zIndex,
}) {
	const menuRef = useRef();
	const controlRef = useRef();
	const [visible, setVisibility] = useState(initiallyOpen);
	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);

	const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
		placement,
		modifiers: [
			{
				name: 'offset',
				enabled: true,
				options: {
					offset: [0, offset]
				}
			},
			{
				name: 'flip',
				options: {
					padding: padding
				},
			}
		]
	});

	function togglePopup() {
		setVisibility(!visible);
		if (!visible) {
			update();
		}
	}

	function closePopup() {
		if (menuRef.current) {
			setVisibility(false);
		}
	}

	useOutsideClick([menuRef, controlRef], () => {
		if (closeOnOutsideClick) {
			closePopup();
		}
	}, visible);

	return (
		<div className={className} ref={controlRef}>
			{children?.({
				initiatorRef: setReferenceElement,
				togglePopup,
				isOpen: visible,
				updatePopper: update
			})}

			<PopupFormPortal
				ref={setPopperElement}
				zIndex={zIndex}
				style={{ ...styles.popper }}
				{...attributes.popper}
			>
				{visible && popup?.({
					popupRef: menuRef,
					closePopup,
					isOpen: visible,
					updatePopper: update
				})}
			</PopupFormPortal>
		</div>
	);
}

PopupInitiator.defaultProps = {
	offset: 0,
	placement: 'bottom-start',
	padding: 0,
};

PopupInitiator.propTypes = {
	children: PropTypes.func.isRequired,
	popup: PropTypes.func.isRequired,
};
