export const FETCH = 'plecto/widgets/FETCH';
export const FETCH_SUCCESS = 'plecto/widgets/FETCH_SUCCESS';
export const FETCH_API_ERROR = 'plecto/widgets/FETCH_API_ERROR';

export const fetchWidgets = dashboardUuid => ({
	type: FETCH,
	dashboardUuid,
});

export const fetchWidgetsSuccess = (dashboardUuid, data) => ({
	type: FETCH_SUCCESS,
	dashboardUuid,
	data,
});

export const fetchWidgetsError = (dashboardUuid, error) => ({
	type: FETCH_API_ERROR,
	dashboardUuid,
	error,
});
