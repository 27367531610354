import englishFormatter from './englishFormatter';

export default function getFormatterForLanguage(language) {
	switch (language) {
	case 'en':
		return englishFormatter;
	default:
		return englishFormatter;
	}
}
