const BORDER_RADIUS = {
	base: '15px',
	xxs: '4px',
	xs: '5px',
	small: '10px',
	large: '25px',
};

export {
	BORDER_RADIUS,
};
