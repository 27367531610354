import React from 'react';
import styled from 'styled-components';
import { Loader } from '../common/loaders/Loader';

const SlideInFooter = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 10px 20px;
	gap: 10px;
	border-top: 1px solid #E5E7EB;
	background-color: #FFFFFF;
	& > .btn {
		flex: 1 auto;
	}
`;

const FooterBtn = styled.div`
	padding: 8px 36px;
	font-size: 14px;
	line-height: 1.42857143;
	border-radius: 4px;
	text-transform: uppercase;

	& > .loader {
		width: 18px;
		height: 18px;
		border-top-color: #fff;
		border-left-color: #fff;
		margin: -2px 5px 0 5px;
		vertical-align: middle;
	}
`;

const SaveButton = styled(FooterBtn)`
	background-color: #12A34D;
	color: #FFFFFF;
`;

export default function Footer({ onSave, onClose, isSaving, cancelText, saveText }) {
	return <SlideInFooter className={'slide-in-footer'}>
		<FooterBtn type="button" className="btn btn-lg btn-default" onClick={onClose}>{cancelText}</FooterBtn>
		<SaveButton type="button" className="btn btn-lg btn-success" onClick={onSave} disabled={isSaving}>
			{isSaving ? <Loader/> : saveText}
		</SaveButton>
	</SlideInFooter>;
}
