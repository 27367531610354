import { useState, useEffect, useMemo, useCallback } from 'react';
import getWindowSize from './windowSize';
import _throttle from 'lodash/throttle';
import screenSizes from './screenSizes';

const THROTTLE_PERIOD_IN_MILLIS = 500;

export function isVerySmall(screenSize) {
	return screenSize <= screenSizes.vs;
}

export function useWindowSizeBreakpoint() {
	const [windowSizeBreakpoint, setWindowSizeBreakpoint] =
		useState(() => getWindowSize(window).sizeBreakpoint);

	useEffect(() => {
		function handleResize() {
			setWindowSizeBreakpoint(getWindowSize(window).sizeBreakpoint);
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowSizeBreakpoint;
}

// should return window size immediately after resize, but only below breakpoint provided.
// it won't rerender component when size is bigger than breakpoint
export function useWindowSizeBelowBreakpoint(breakpoint) {
	const [windowSize, setWindowSize] = useState(() => getSizeBelow(breakpoint));

	useEffect(() => {
		function handleResize() {
			setWindowSize(getSizeBelow(breakpoint));
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [breakpoint]);

	return windowSize;
}

export function useWindowSizeAboveBreakpoint(breakpoint) {
	const [windowSize, setWindowSize] = useState(() => getSizeAbove(breakpoint));

	useEffect(() => {
		function handleResize() {
			setWindowSize(getSizeAbove(breakpoint));
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [breakpoint]);

	return windowSize;
}

function getSizeBelow(breakpoint) {
	const { width, sizeBreakpoint } = getWindowSize(window);
	return sizeBreakpoint <= breakpoint ? width : null;
}

function getSizeAbove(breakpoint) {
	const { width, sizeBreakpoint } = getWindowSize(window);
	return sizeBreakpoint >= breakpoint ? width : null;
}

export function useElementWidth(ref, breakpoint = 0) {
	useWindowSizeAboveBreakpoint(breakpoint);
	const { width } = useResizeObserver(ref);
	const elementWidth =  width >= 0 ?
		width :
		(ref && ref.current ? ref.current.getBoundingClientRect().width - 1 /* account for floating point errors.*/: 0);
	return elementWidth;
}

export function useRetainLeftScroll(ref, condition) {
	const callback = useCallback((prevWidth, prevHeight, nextWidth) => {

		if (condition && ref.current.scrollLeft && prevWidth) {
			const scrollLeftRatio = ref.current.scrollLeft / prevWidth;
			ref.current.scrollLeft = scrollLeftRatio * nextWidth;
		}

	}, [ref, condition]);
	useResizeObserver(ref, callback, true);
}

export function useResizeObserver(ref, onResize = null, immediate = false, box = 'content-box') {
	const [observerWidth, setObserverWidth] = useState(-1);
	const [observerHeight, setObserverHeight] = useState(-1);

	useEffect(() => {
		let resizeObserver = null;
		let domNode = null;
		let throttled = null;
		let animationFrame = null;
		if (ref && ref.current && typeof ResizeObserver !== 'undefined') {
			domNode = ref.current;
			const callback = entries => {
				animationFrame = window.requestAnimationFrame(() => {
					if (!Array.isArray(entries) || !entries.length) {
						return;
					}

					const entry = entries[0];
					const boxSize = box === 'content-box' ? entry.contentBoxSize?.[0] : entry.borderBoxSize?.[0];
					const width = boxSize?.inlineSize ||
						entry.contentRect.width;
					const height = boxSize?.blockSize ||
						entry.contentRect.height;
					if (onResize) {
						const prevWidth = observerWidth;
						const prevHeight = observerHeight;
						onResize(prevWidth, prevHeight, width, height);
					}

					setObserverWidth(width);
					setObserverHeight(height);
				});

			};
			resizeObserver =
				new ResizeObserver(immediate ? callback : (throttled = _throttle(callback, THROTTLE_PERIOD_IN_MILLIS)));
			resizeObserver.observe(domNode, { box });
		}
		return () => {
			if (resizeObserver && domNode) {
				resizeObserver.unobserve(domNode);
			}
			throttled?.cancel?.();
			window.cancelAnimationFrame(animationFrame);
		};
	}, [ref, observerWidth, onResize, observerHeight, immediate, box]);

	const observerSize =
		useMemo(() => ({ width: observerWidth, height: observerHeight }), [observerHeight, observerWidth]);

	return observerSize;
}
