import {
	DRILL_DOWN_BY,
	CLEAR_DRILL_DOWN,
	SAVE_DRILLDOWN,
	UPDATE_SORTING,
} from '../actions';

export default function drilldownReducer(state = {}, action) {
	switch (action.type) {
	case DRILL_DOWN_BY: {
		return {
			...state,
			[action.reportGroupFormulaId]: {
				...state[action.reportGroupFormulaId],
				appliedDimension: action.dimension,
				isAppliedDimensionSaved:
					state[action.reportGroupFormulaId].savedDimension &&
					action.dimension.key ===
						state[action.reportGroupFormulaId].savedDimension.key,
			},
		};
	}
	case CLEAR_DRILL_DOWN: {
		return {
			...state,
			[action.reportGroupFormulaId]: {
				...state[action.reportGroupFormulaId],
				appliedDimension: null,
				isDimensionChanged: false
			}
		};
	}
	case SAVE_DRILLDOWN: {
		return {
			...state,
			[action.reportGroupFormulaId]: {
				...state[action.reportGroupFormulaId],
				savedDimension: action.dimension,
				isAppliedDimensionSaved:
					action.dimension &&
					state[action.reportGroupFormulaId].appliedDimension?.key ===
						action.dimension.key,
			},
		};
	}
	case UPDATE_SORTING: {
		return {
			...state,
			[action.reportGroupFormulaId]: {
				...state[action.reportGroupFormulaId],
				drilldown_ordering: action.sorting
			}
		};
	}
	default:
		return state;
	}
}
