export function logResponseErrors(response, errorHandler) {
	if (errorHandler && response && !response.ok) {
		const status = response.status;
		if (whiteListStatusesToLog.indexOf(status) >= 0) {
			errorHandler(new Error(`API call error. Message: ${response.statusText}. Url: ${response.url}`), {
				status: response.status,
				statusText: response.statusText,
				url: response.url
			});
		}
	}
}

const whiteListStatusesToLog = [
	// eslint-disable-next-line no-magic-numbers
	500, 405, 406, 412, 415
];
