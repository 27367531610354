import {
	modes,
	relativeModes,
	periods,
	timeOptions,
	futureModes,
	weekDays,
	monthsToNumberOfDays,
	getWeekDaysOrdered
} from './constants';
import PropTypes from 'prop-types';
import actions, { 
	CHANGE_MODE,
	CHANGE_START_MODE, 
	CHANGE_START_PERIOD, 
	CHANGE_START_AMOUNT, 
	TOGGLE_TIME_RANGE, 
	CHANGE_FROM_HOURS, 
	CHANGE_TO_HOURS, 
	TOGGLE_OFFSET,
	CHANGE_CUSTOM_DATE,
	TIME_PERIOD_REINIT,
	CHANGE_OFFSET_AMOUNT,
	CHANGE_OFFSET_PERIOD,
	CHANGE_OFFSET_MODE,
	CHANGE_FROM_WEEK_DAY,
	CHANGE_TO_WEEK_DAY,
	CHANGE_MONTH_START_POINT,
	CHANGE_WEEK_START_POINT,
	CHANGE_YEAR_START_POINT
} from './actions';
import {
	calculateOptionsToShow,
	changeIfInvalid,
	changeWeekDayIfInvalid,
	calculateWeekdaysOptionsToShow
} from './endDateValidator';
import visibilityReducer from './visibilityReducer';
import endDateReducer from './endDateReducer';
import presetReducer from './presetReducer';
import _clamp from 'lodash/clamp';
import { DAYS_IN_A_MONTH, DAYS_IN_A_WEEK } from '../constants';

export const timePeriodOptionsShape = PropTypes.shape({
	mode: PropTypes.oneOf(Object.values(modes)).isRequired,
	offset: PropTypes.shape({
		enabled: PropTypes.bool,
		future: PropTypes.bool,
		period: PropTypes.oneOf(Object.values(periods)),
		amount: PropTypes.number
	}),
	relativeStartDate: PropTypes.shape({
		mode: PropTypes.oneOf(Object.values(relativeModes)), 
		period: PropTypes.oneOf(Object.values(periods)),
		amount: PropTypes.number, 
	}),
	relativeEndDate: PropTypes.shape({
		enabled: PropTypes.bool,
		mode: PropTypes.oneOf(Object.values(relativeModes)),
		amount: PropTypes.number,
		period: PropTypes.oneOf(Object.values(periods)),
	}),
	allTimeEndDate: PropTypes.shape({
		enabled: PropTypes.bool,

	}),
	timeRange: PropTypes.shape({
		enabled: PropTypes.bool, 
		fromHours: PropTypes.string, 
		toHours: PropTypes.string,
		fromDay: PropTypes.string,
		toDay: PropTypes.string
	}), 
	customDate: PropTypes.shape({
		startDate: PropTypes.instanceOf(Date),
		endDate: PropTypes.instanceOf(Date)
	})
});

export function createInitialState({
	mode = modes.relative,
	offset = {
		enabled: false,
		future: true,
		period: periods.year,
		amount: 1
	},
	relativeStartDate = {
		mode: relativeModes.current, 
		period: periods.day, 
		amount: 1, 
		startingOnDay: 1,
		startingOnMonth: 1,
	},
	relativeEndDate = {
		enabled: false,
		mode: relativeModes.current,
		amount: 1,
		period: periods.month,
	},
	allTimeEndDate = {
		enabled: false,
	},
	timeRange = {
		enabled: false, 
		fromHours: '09:00', 
		toHours: '17:00',
		fromDay: weekDays.monday,
		toDay: weekDays.friday
	}, 
	customDate = {
		startDate: new Date(),
		endDate: new Date()
	}
}, skipPresetReducer) {
	let initialState = {
		mode: mode,
		offset: {
			enabled: offset.enabled,
			future: offset.future,
			period: offset.period,
			amount: offset.amount,
		},
		relativeStartDate: {
			mode: relativeStartDate.mode,
			amount: relativeStartDate.amount,
			period: relativeStartDate.period,
			fixedAmount: relativeStartDate.mode === relativeModes.current,
			startingOnDay: relativeStartDate.startingOnDay || 1,
			startingOnMonth: relativeStartDate.startingOnMonth || 1,
		},
		relativeEndDate: {
			enabled: relativeEndDate.enabled &&
				(relativeStartDate.mode !== relativeModes.next || relativeEndDate.mode === relativeModes.current),
			mode: relativeEndDate.mode,
			amount: relativeEndDate.amount,
			period: relativeEndDate.period,
			fixedAmount: relativeEndDate.mode === relativeModes.current,
		},
		allTimeEndDate: {
			enabled: allTimeEndDate.enabled,
		},
		timeRange: {
			enabled: timeRange.enabled,
			fromHours: timeRange.fromHours,
			toHours: timeRange.toHours,
			fromDay: timeRange.fromDay,
			toDay: timeRange.toDay,
			toHoursOptions: calculateOptionsToShow(timeRange.fromHours, timeOptions),
			toDayOptions: calculateWeekdaysOptionsToShow(timeRange.fromDay, relativeStartDate.startingOnDay)
		},
		customDate: {
			startDate: customDate.startDate,
			endDate: customDate.endDate
		},
	};

	const initAction = actions.reInit({}, skipPresetReducer);

	initialState = endDateReducer(initialState, initAction);
	initialState = visibilityReducer(initialState, initAction);
	if (!skipPresetReducer) {
		initialState = presetReducer(initialState, initAction);
	}
	return initialState;
}

export function timePeriodReducer(state, action) {
	state = logicReducer(state, action);
	state = endDateReducer(state, action);
	state = visibilityReducer(state, action);
	if (!action.skipPresetReducer) {
		state = presetReducer(state, action);
	}

	return state;
}

function logicReducer(state, action) {
	switch (action.type) {
	case CHANGE_MODE: {
		let customDate = state.customDate;
		if (action.mode === modes.custom) {
			customDate = {
				startDate: customDate.startDate || new Date(),
				endDate: customDate.endDate || new Date(),
			};
		}
		return {
			...state,
			mode: action.mode,
			customDate: customDate,
		};
	}
	case CHANGE_START_MODE:
		return {
			...state,
			relativeStartDate: {
				...state.relativeStartDate,
				mode: action.mode,
				fixedAmount: action.mode === relativeModes.current,
				amount: action.mode !== relativeModes.current ? state.relativeStartDate.amount : 1,
			},
		};
	case CHANGE_START_PERIOD:
		return {
			...state,
			relativeStartDate: {
				...state.relativeStartDate,
				period: action.period,
				startingOnDay: 1,
				startingOnMonth: 1,
			},
			timeRange: {
				...state.timeRange,
				fromHours: '09:00', 
				toHours: '17:00',
				fromDay: weekDays.monday,
				toDay: weekDays.sunday
			}
		};
	case CHANGE_START_AMOUNT:
		return {
			...state,
			relativeStartDate: {
				...state.relativeStartDate,
				amount: action.amount,
			}
		};
	case TOGGLE_OFFSET: 
		return {
			...state,
			offset: {
				...state.offset,
				enabled: !state.offset.enabled
			}
		};
	case CHANGE_OFFSET_MODE:
		return {
			...state,
			offset: {
				...state.offset,
				future: action.mode === futureModes.inTheFuture
			}
		};
	case CHANGE_OFFSET_PERIOD:
		return {
			...state,
			offset: {
				...state.offset,
				period: action.period,
			}
		};
	case CHANGE_OFFSET_AMOUNT:
		return {
			...state,
			offset: {
				...state.offset,
				amount: action.amount,
			}
		};

	case TOGGLE_TIME_RANGE:
		return {
			...state,
			timeRange: {
				...state.timeRange,
				enabled: !state.timeRange.enabled
			}
		};
	case CHANGE_FROM_HOURS: 
		return {
			...state,
			timeRange: {
				...state.timeRange,
				fromHours: action.fromHours,
				toHours: changeIfInvalid(action.fromHours, state.timeRange.toHours),
				toHoursOptions: calculateOptionsToShow(action.fromHours, timeOptions)
			}
		};
	case CHANGE_TO_HOURS: 
		return {
			...state,
			timeRange: {
				...state.timeRange,
				toHours: changeIfInvalid(state.timeRange.fromHours, action.toHours),
			}
		};
	case CHANGE_FROM_WEEK_DAY: 
		return {
			...state,
			timeRange: {
				...state.timeRange,
				fromDay: action.fromDay,
				toDay: changeWeekDayIfInvalid(
					action.fromDay,
					state.timeRange.toDay,
					state.relativeStartDate.startingOnDay
				),
				toDayOptions: calculateWeekdaysOptionsToShow(action.fromDay, state.relativeStartDate.startingOnDay)
			}
		};
	case CHANGE_TO_WEEK_DAY: 
		return {
			...state,
			timeRange: {
				...state.timeRange,
				toDay: changeWeekDayIfInvalid(state.timeRange.fromDay, action.toDay),
			}
		};
	case CHANGE_CUSTOM_DATE: {
		return {
			...state, 
			customDate: {
				startDate: action.startDate,
				endDate: action.endDate
			}
		};
	}
	case CHANGE_MONTH_START_POINT: {
		return {
			...state,
			relativeStartDate: {
				...state.relativeStartDate,
				startingOnDay: _clamp(action.start, 1, DAYS_IN_A_MONTH),
				startingOnMonth: 1
			}
		};
	}
	case CHANGE_WEEK_START_POINT: {
		const startDay = _clamp(action.start, 1, DAYS_IN_A_WEEK);
		const weekDaysOrdered = getWeekDaysOrdered(startDay);
		const fromDay = weekDaysOrdered[0];
		const toDay = weekDaysOrdered[weekDaysOrdered.length - 1];
		return {
			...state,
			relativeStartDate: {
				...state.relativeStartDate,
				startingOnDay: _clamp(action.start, 1, DAYS_IN_A_WEEK),
				startingOnMonth: 1
			},
			timeRange: {
				...state.timeRange,
				fromDay,
				toDay: toDay,
				toDayOptions: calculateWeekdaysOptionsToShow(fromDay, startDay)
			}
		};
	}
	case CHANGE_YEAR_START_POINT: {
		return {
			...state,
			relativeStartDate: {
				...state.relativeStartDate,
				startingOnDay: _clamp(action.startDay, 1, monthsToNumberOfDays[action.startMonth]),
				startingOnMonth: action.startMonth,
			}
		};
	}
	case TIME_PERIOD_REINIT: {
		return createInitialState(action.timePeriodOptions, action.skipPresetReducer);
	}
	}
	return state;
}
