import React, {
	useState, useEffect, useLayoutEffect, useRef, useCallback, PropsWithChildren, ReactNode, ReactElement
} from 'react';

import { TabButton } from './TabButton';
import styled from 'styled-components';
import { useUniqueId } from './useUniqueId';

import { scrollElementIntoView } from './scrollUtilities';
import { useDraggable } from 'react-use-draggable-scroll';
import { PropsWithClassName } from './PropsWithClassName';

const StyledTabsContainer = styled.div`
	border-bottom: 1px solid #d6e0e0;
`;

const StyledTabButtons = styled.div`
	display: flex;
`;

const StyledTabButton = styled(TabButton)`
	min-height: 52px;
	user-select: none;
	max-width: 100%;

	span {
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
	}
`;

const tabButtonClassName = (pref: string, id: string) => `${pref}-tab-button-${id}`;

type TabSelectorProps = PropsWithChildren<PropsWithClassName<{
	selectedTab?: string,
	autoScroll?: boolean,
	onTabChange?: (id: string) => void,
}>>

export function TabSelector({ className, selectedTab, autoScroll = true, onTabChange, children }: TabSelectorProps) {
	const selectorRef = useRef() as React.MutableRefObject<HTMLInputElement>;

	const elements = React.Children.toArray(children).filter(child => React.isValidElement(child)) as ReactElement[];

	const selectorId = useUniqueId('tab-selector-');
	const tabIds: Array<string> = elements.map(({ props }) => props.id);

	const [tab, setTab] = useState(selectedTab || tabIds[0]);
	const normalizedTab = tabIds.indexOf(tab) >= 0 ? tab : tabIds[0];
	const { events } = useDraggable(selectorRef);

	const canScroll: boolean = Boolean(tab) && autoScroll;

	useEffect(() => {
		if (selectedTab) {
			setTab(selectedTab);
		}
	}, [selectedTab]);

	useLayoutEffect(() => {
		if (canScroll) {
			scrollElementIntoView(`.${tabButtonClassName(selectorId, tab)}`);
		}
	}, [selectorId, canScroll, tab]);

	const onSelect = useCallback((id: string) => {
		setTab(id);
		if (onTabChange)
			onTabChange(id);
	}, [onTabChange]);

	let currentTab: ReactNode | null = null;
	const buttons = elements.map((element) => {
		const { id, title, children } = element.props;
		let selected = id === normalizedTab;

		if (!normalizedTab && !currentTab) {
			selected = true;
		}
		if (selected) {
			currentTab = children;
		}
		return <StyledTabButton
			key={id}
			id={id}
			className={tabButtonClassName(selectorId, id)}
			label={title}
			selected={selected}
			onSelect={onSelect} />;
	});

	return <>
		<StyledTabsContainer className={className}>
			<StyledTabButtons ref={selectorRef} className={'tab-buttons-container'} {...events}>
				{buttons}
			</StyledTabButtons>
		</StyledTabsContainer>
		<div>
			{currentTab}
		</div>
	</>;
}
