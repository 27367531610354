import {
	FETCH_ALL,
	fetchAllRegistrationsPreviewsSuccess,
	fetchAllRegistrationsPreviewsError,
	FETCH_NEXT,
	fetchNextRegistrationsPreviewsSuccess,
	fetchNextRegistrationsPreviewsError,
} from './actions';
import { fetchRegistrationPreviews } from './api';
import apiSagaGenerator from '../../saga/apiSagaGenerator';
import takeLatestBy from 'redux-saga-take-latest-by';

const registrationPreviewsSagas = [
	takeLatestBy(
		FETCH_ALL,
		apiSagaGenerator()
			.forApi(fetchRegistrationPreviews)
			.cancellable()
			.withArguments((action) => [
				action.dataSource,
				action.filters,
				action.sorting,
				null,
				action.pageSize,
			])
			.forSuccess(fetchAllRegistrationsPreviewsSuccess)
			.withActionArguments((action) => [action.dataSource, action.uuid])
			.forError(fetchAllRegistrationsPreviewsError)
			.withMessage('Error getting registrations previews')
			.generate(),
		(action) => `${action.uuid}_${action.dataSource}`
	),
	takeLatestBy(
		FETCH_NEXT,
		apiSagaGenerator()
			.forApi(fetchRegistrationPreviews)
			.cancellable()
			.withArguments((action) => [
				action.dataSource,
				action.filters,
				action.sorting,
				action.searchAfter,
				action.pageSize,
			])
			.forSuccess(fetchNextRegistrationsPreviewsSuccess)
			.withActionArguments((action) => [
				action.dataSource,
				action.uuid,
				action.pageSize,
			])
			.forError(fetchNextRegistrationsPreviewsError)
			.withMessage('Error getting registrations previews')
			.generate(),
		(action) => action.uuid
	),
];

export default registrationPreviewsSagas;
