export const FETCH = 'plecto/member/FETCH';
export const FETCH_SUCCESS = 'plecto/member/FETCH_SUCCESS';
export const FETCH_API_ERROR = 'plecto/member/FETCH_API_ERROR';

export const fetchMembers = () => ({
	type: FETCH,
});

export const fetchMembersSuccess = (payload) => ({
	type: FETCH_SUCCESS,
	payload,
});

export const fetchMembersFailure = (error) => ({
	type: FETCH_API_ERROR,
	error
});

export function shouldFetchMembers(state) {
	return !state.members.isFetching && state.members.isStale; 
}

export function fetchMembersIfNeeded() {
	return (dispatch, getState) => {
		if (shouldFetchMembers(getState())) {
			return dispatch(fetchMembers());
		}
	};
}
