export const multiSelectInputSizes = {
	default: 'default',
	compact: 'compact',
} as const;

export type InputSizes = keyof typeof multiSelectInputSizes;

export const singleItemSize = {
	[multiSelectInputSizes.default]: 28,
	[multiSelectInputSizes.compact]: 20,
};

export const placeholderFontSize = {
	[multiSelectInputSizes.default]: 14,
	[multiSelectInputSizes.compact]: 12,
};

export const placeholderPadding = {
	[multiSelectInputSizes.default]: 4,
	[multiSelectInputSizes.compact]: 3,
};

export const itemFontSize = {
	[multiSelectInputSizes.default]: 12,
	[multiSelectInputSizes.compact]: 11,
};

export const itemHorizontalPadding = {
	[multiSelectInputSizes.default]: 10,
	[multiSelectInputSizes.compact]: 8,
};

export const itemGap = 4;

export const selectorBorder = 1;

const maxVisibleRows = 6;

// eslint-disable-next-line no-magic-numbers
export const getMinHeight = (size: InputSizes) => getSelectorHeight(size, 1);

export const getMaxHeight = (size: InputSizes) => getSelectorHeight(size, maxVisibleRows);

const getSelectorHeight = (size: InputSizes, rows: number): number =>
	// eslint-disable-next-line no-magic-numbers
	rows * singleItemSize[size] + (rows + 1) * itemGap + selectorBorder * 2;
