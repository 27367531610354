import React, { memo, useCallback, useRef, useLayoutEffect, useMemo } from 'react';
import { Manager, Reference, Popper } from 'react-popper';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PopoverHost from './PopoverHost';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { Icon } from '../icon/Icon';
import { isVerySmall, useWindowSizeBreakpoint } from '../../utils/size/sizeHooks';
import styled from 'styled-components';
import { zIndexAboveAll } from '../../styles/zIndex';
import _startsWith from 'lodash/startsWith';
import { Loader } from '../loaders/Loader';

const S = {};

S.TabButton = styled.button`
	border-width: 1px;
	.loader {
		border-top-color: #416871;
		border-left-color: #416871;
	}
	&.popover-tab-button-open {
		border: 1px solid #2bb673;
		&:focus,
		&:active,
		&:hover {
			border: 1px solid #2bb673;
		}
	}
`;

S.TabContentWrapper = styled.div`
	visibility: hidden;
	z-index: ${zIndexAboveAll};
	&.popover-tab-content-wrapper-open {
		visibility: visible;
	}
	@media (max-width: 767px) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

function PopoverTabButton({
	name,
	className,
	buttonClassName,
	children,
	icon,
	open,
	onOpen,
	buttonControl,
	placement,
	sameWidth,
	minWidth,
	fixedHeight,
	loading,
	offsetX = 0,
	offsetY = 0,
	padding = 0
}) {

	const windowSizeBreakpoint = useWindowSizeBreakpoint();
	const smallScreen = isVerySmall(windowSizeBreakpoint);
	const updateFunction = useRef();
	const onToggle = useCallback(() => {
		onOpen(!open);

	}, [open, onOpen]);

	const onClose = useCallback(() => {
		if (open)
			onOpen(false);
	}, [open, onOpen]);

	const tabNode = useRef();
	const buttonNode = useRef();
	const hostRef = useRef();
	useOutsideClick([buttonNode, tabNode, hostRef], onClose);

	useLayoutEffect(() => {
		if (updateFunction.current) {
			updateFunction.current();
		}
	}, [name, children, open]);

	const childrenWithProps = (children, placement) => {
		return React.Children.map(children, child => {
			if (!React.isValidElement(children)) return null;

			const placementClassName = `popover-tab-${placement}`;

			return React.cloneElement(child, {
				...child.props,
				className: child.props.className ?
					`${child.props.className} ${placementClassName}` :
					placementClassName,
			});
		});
	};

	const placementToSet = placement || 'bottom';

	const button = <S.TabButton
		ref={buttonNode}
		type="button"
		className={classNames('btn btn-sm btn-default popover-tab-button', {
			'popover-tab-button-open': open,
			[`popover-tab-${placement}`]: placement
		}, buttonClassName)}
		onClick={onToggle}
		title={name}>
		{buttonControl ? buttonControl : (
			loading ? <Loader size={'default'} /> : <>
				{icon && <Icon name={icon} />}
				<span>{name}</span>
			</>)}
	</S.TabButton>;

	const popperModifiers = useMemo(() => {
		const modifiers = [{
			name: 'offset',
			enabled: true,
			options: {
				offset: [offsetX, offsetY],
			},
		},
		{
			name: 'flip',
			options: {
				padding: padding,
			},
		}
		];

		if (sameWidth) {
			modifiers.push({
				name: 'sameWidth',
				enabled: true,
				fn: ({ state }) => {
					state.styles.popper.minWidth = minWidth ?
						`${Math.max(minWidth, state.rects.reference.width)}px` :
						`${state.rects.reference.width}px`;
				},
				phase: 'beforeWrite',
				requires: ['computeStyles'],
			});
		}

		if (fixedHeight && _startsWith(placementToSet, 'bottom')) {
			modifiers.push({
				name: 'flip',
				enabled: true,
			});

			// the goal is to anticipate the fixed height of control, even if control is not
			// that big initially. This prevents jumping when control grows bigger.
			modifiers.push({
				name: 'flip-fixed-height',
				enabled: true,
				phase: 'main',
				requires: ['flip'],
				fn: ({ state }) => {
					const { y, height } = state.elements.reference.getBoundingClientRect();
					// state.rects.reference.y was buggy in report slide in
					const positionY = _startsWith(state.placement, 'bottom') ?
						y + height + (offsetY || 0)
						: y - (offsetY || 0);
					const docHeight = window.innerHeight;

					const totalHeight = fixedHeight;
					// we want to support variation placements ("bottom-start")
					const hyphenIndex = placementToSet.indexOf('-');
					const suffix = hyphenIndex >= 0 ? placementToSet.substring(hyphenIndex) : '';
					if (_startsWith(state.placement, 'bottom') && totalHeight > (docHeight - positionY)) {
						state.placement = `top${suffix}`;
						state.reset = true;
					} else if (_startsWith(state.placement, 'top') && totalHeight > positionY) {
						state.placement = 'auto';
						state.reset = true;
					}
				},
			});
		}

		return modifiers;
	}, [fixedHeight, minWidth, offsetX, offsetY, padding, placementToSet, sameWidth]);

	// we don't need popper here
	if (smallScreen) {
		return <React.Fragment>
			<div className={classNames('popover-tab', className)}>
				{button}
			</div>
			<PopoverHost ref={hostRef}>
				<S.TabContentWrapper
					className={classNames('popover-tab-content-wrapper', { 'popover-tab-content-wrapper-open': open })}
				>
					{open && children}
				</S.TabContentWrapper>
			</PopoverHost>
		</React.Fragment>;
	}

	return <Manager>
		<Reference>
			{({ ref }) => (
				<div ref={ref} className={classNames('popover-tab', className)} >
					{button}
				</div>
			)}
		</Reference>
		<PopoverHost ref={hostRef}>
			<Popper
				innerRef={tabNode}
				placement={placementToSet}
				modifiers={popperModifiers}>
				{({ ref, style, placement, forceUpdate }) => {
					updateFunction.current = forceUpdate;

					return <S.TabContentWrapper
						ref={ref}
						style={style}
						data-placement={placement}
						className={classNames('popover-tab-content-wrapper', {
							'popover-tab-content-wrapper-open': open,
						})}>
						{open && childrenWithProps(children, placement)}
					</S.TabContentWrapper>;
				}
				}
			</Popper>
		</PopoverHost>
	</Manager>;
}

export const tabContentPropTypes = {
	name: PropTypes.string.isRequired,
	icon: PropTypes.string,
	className: PropTypes.string,
};

PopoverTabButton.propTypes = {
	...tabContentPropTypes,
	open: PropTypes.bool,
	onOpen: PropTypes.func.isRequired
};

export default memo(PopoverTabButton);
