import {
	FETCH_ALL,
	FETCH_ALL_SUCCESS,
	FETCH_ALL_API_ERROR,
	PRELOAD,
	FETCH_NEXT,
	FETCH_NEXT_SUCCESS,
	CLEAR
} from './actions';

const initialState = {
};

const registrationPreviews = (state = initialState, action) => {
	switch (action.type) {
	case FETCH_ALL: {
		return updateStateForDataSource(state, action.uuid, action.dataSource, () => ({
			nextSearchAfter: null,
			isFetching: true,
			dataSource: action.dataSource,
			isThereMore: true
		}));
	}
	case PRELOAD: 
	case FETCH_ALL_SUCCESS: {
		const isThereMore = action.result.registrations.length === 0 
			|| action.result.registrations.length < action.result.total;

		return updateStateForDataSource(state, action.uuid, action.dataSource, () => ({
			isStale: false, 
			isFetching: false,
			total: action.result.total,
			nextSearchAfter: action.result.next_search_after,
			registrations: action.result.registrations.map(mapRegistration),
			dataSource: action.dataSource,
			isThereMore
		}));
	}
	case FETCH_ALL_API_ERROR:
		return updateStateForDataSource(state, action.uuid, action.dataSource, () => ({
			isFetching: false,
		}));

	case FETCH_NEXT: {
		return updateStateForDataSource(state, action.uuid, action.dataSource, () => ({
			isFetchingPage: true,
		}));
	}
	case FETCH_NEXT_SUCCESS: {
		return updateStateForDataSource(state, action.uuid, action.dataSource, (current) => {
			const registrationDeduplicated = action.result.registrations.filter(registration => 
				!(current.registrations.map(curr => curr.uuid)).includes(registration.uuid));
			const allRegistrations = current.registrations.concat(registrationDeduplicated.map(mapRegistration));
			const isThereMore = allRegistrations.length < action.result.total;
			return {
				isFetchingPage: false,
				total: action.result.total,
				nextSearchAfter: action.result.next_search_after,
				registrations: current.registrations.concat(registrationDeduplicated.map(mapRegistration)),
				isThereMore
			};
		});
	}
	case CLEAR: {
		return initialState;
	}
	default:
		return state;
	}
};

function updateStateForDataSource(state, uuid, dataSource, change) {
	const currentState = state[uuid]?.[dataSource] || {
		isStale: true,
		isFetching: false,
		isFetchingPage: false,
		total: null,
		nextSearchAfter: null,
		registrations: [],
		isThereMore: true
	};

	return {
		...state,
		[uuid]: {
			...state[uuid],
			[dataSource]: {
				...currentState,
				...change(currentState)
			}
		}
	};
}

function mapRegistration(hit) {
	return {
		dataSourceId: hit.data_source_uuid,
		uuid: hit.uuid,
		externalId: hit.external_id,
		label: hit.label,
		url: hit.url,
		permissions: hit.permissions,
		dateCreated: hit.date,
		dateCreatedLocalized: hit.localized_date,
		memberId: hit.member_uuid,
		dateModified: hit.date_modified,
		dateImported: hit.date_imported,
		teamId: hit.team_uuid,
		data: mapRegistrationData(hit.data),
		member: {
			id: hit.member.id,
			name: hit.member.name,
			isActive: hit.member.is_active,
			isAdmin: hit.member.is_admin,
			url: hit.member.url,
			avatar: hit.member.avatar,
		},
		team: hit.team
	};
}

function mapRegistrationData(data) {
	const mapped = {};
	for (const f of Object.keys(data)) {
		const hit = data[f];
		mapped[f] = {
			id: f,
			type: hit.type,
			value: hit.value,
			localizedValue: hit.localized_value,
			error: hit.error,
			url: hit.url
		};
	}

	return mapped;
}

export default registrationPreviews;
