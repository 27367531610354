import styled, { css } from 'styled-components';
import { Icon } from '../../icon/Icon';
import { COLORS, COLORS_BRAND, COLORS_GRAY } from '../../../styles/colors';

export const StyledDropdownItem = styled.li`
	cursor: pointer;
	display: flex;
	align-items: center;
	background-color: ${COLORS.light};
	${props => props.selected ? css`
		color: hsl(0,0%,100%);
		background-color: ${COLORS_BRAND.primary};
	` : ''}
	${props => props.selectable ? css`
		&:hover {
			background-color: ${COLORS_GRAY['200']};
		}
	` : ''}
`;

export const StyledDropdownInfo = styled(StyledDropdownItem)`
	text-align: center;
	color: hsl(0, 0%, 60%);
	padding: 0 6px;
	cursor: auto;
`;

export const StyledDropdownItemTitle = styled.div`
	flex: 1 0;
`;

export const StyledDropdownIcon = styled(Icon)`
	flex: 0 1;
	padding: 6px 10px;
	line-height: 1.4;
`;

export const StyledDropdownArrow = styled(Icon)`
	flex: 0 1;
	padding: 6px 10px;
	align-items: center;
	display: flex;
	cursor: pointer;
	&:hover {
		background-color: rgb(217, 217, 217);
	}
`;
