import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import _isNil from 'lodash/isNil';
import { PopupFormParentContext } from './PopupFormParentContext';
const zIndexPopover = 70;

const Wrapper = styled.div.attrs(props => ({
	zIndex: !_isNil(props.zIndex) ? props.zIndex : zIndexPopover,
}))`
	z-index: ${props => props.zIndex};
	font-size: 14px;
	color: #333;
	.btn {
		font-size: 14px;
	}
`;

class PopupFormPortal extends React.Component {
	static contextType = PopupFormParentContext;

	constructor(props) {
		super(props);
		
		this.el = document.createElement('div');
		this.parent = null;
	}

	componentDidMount() {
		const parentSelector = this.context;
		this.parent = document.body;
		if (parentSelector) {
			const parent = document.querySelector(parentSelector);
			if (parent) {
				this.parent = parent;
			}
		}

		this.parent.appendChild(this.el);
	}

	componentWillUnmount() {
		this.parent.removeChild(this.el);
	}

	render() {
		const { forwardedRef, children, ...other } = this.props;
		return ReactDOM.createPortal(
			<Wrapper {...other} ref={forwardedRef}>
				{children}
			</Wrapper>,
			this.el,
		);
	}
}

export default React.forwardRef((props, ref) => (
	<PopupFormPortal {...props} forwardedRef={ref}/>
));
