import { takeLatest } from 'redux-saga/effects';
import {
	CREATE_WRITTEN_UPDATE_SCHEDULE,
	createWrittenUpdateScheduleFailure,
	createWrittenUpdateScheduleSuccess,
	DELETE_WRITTEN_UPDATE_SCHEDULE,
	deleteWrittenUpdateScheduleFailure,
	deleteWrittenUpdateScheduleSuccess,
	FETCH_WRITTEN_UPDATE_SCHEDULE,
	fetchWrittenUpdateScheduleFailure,
	fetchWrittenUpdateScheduleSuccess,
	MODIFY_WRITTEN_UPDATE_SCHEDULE,
	modifyWrittenUpdateScheduleFailure,
	modifyWrittenUpdateScheduleSuccess
} from './actions';
import apiSagaGenerator from '../../saga/apiSagaGenerator';
import {
	createWrittenUpdateSchedule,
	deleteWrittenUpdateSchedule,
	fetchWrittenUpdateSchedule,
	modifyWrittenUpdateSchedule
} from './api';

const writtenUpdateSchedulesSagas = [
	takeLatest(CREATE_WRITTEN_UPDATE_SCHEDULE,
		apiSagaGenerator()
			.forApi(createWrittenUpdateSchedule)
			.withArguments((action) => [action.writtenUpdateSchedule])
			.forSuccess(createWrittenUpdateScheduleSuccess)
			.forError(createWrittenUpdateScheduleFailure)
			.withMessage('Error creating written update schedule')
			.generate() 
	),
	takeLatest(FETCH_WRITTEN_UPDATE_SCHEDULE,
		apiSagaGenerator()
			.forApi(fetchWrittenUpdateSchedule)
			.withArguments((action) => [action.writtenUpdateScheduleId])
			.forSuccess(fetchWrittenUpdateScheduleSuccess)
			.forError(fetchWrittenUpdateScheduleFailure)
			.withMessage('Error fetching written update schedule')
			.generate()
	),
	takeLatest(MODIFY_WRITTEN_UPDATE_SCHEDULE,
		apiSagaGenerator()
			.forApi(modifyWrittenUpdateSchedule)
			.withArguments((action) => [action. writtenUpdateScheduleUuid, action.writtenUpdateSchedule])
			.forSuccess(modifyWrittenUpdateScheduleSuccess)
			.forError(modifyWrittenUpdateScheduleFailure)
			.withMessage('Error modifying written update schedule')
			.generate()
	),
	takeLatest(DELETE_WRITTEN_UPDATE_SCHEDULE,
		apiSagaGenerator()
			.forApi(deleteWrittenUpdateSchedule)
			.withArguments((action) => [action.writtenUpdateScheduleId])
			.forSuccess(deleteWrittenUpdateScheduleSuccess)
			.skipResult()
			.withActionArguments((action) => [action.writtenUpdateScheduleId])
			.forError(deleteWrittenUpdateScheduleFailure)
			.withMessage('Error deleting written update schedule')
			.generate()
	),
];

export default writtenUpdateSchedulesSagas;
