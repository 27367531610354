import { createContext, useContext } from 'react';

export type TreeDataContextType = {
	selection: string[],
	initiallyExpanded: string[],
}

export const TreeDataContext = createContext<TreeDataContextType>({} as TreeDataContextType);

export const useTreeDataContext = () => useContext(TreeDataContext);
