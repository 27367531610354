export const FETCH = 'plecto/myTeams/FETCH';
export const FETCH_SUCCESS = 'plecto/myTeams/FETCH_SUCCESS';
export const FETCH_API_ERROR = 'plecto/myTeams/FETCH_API_ERROR';

export const fetchTeams = datasource => ({
	type: FETCH,
	datasource,
});

export const fetchTeamsSuccess = (datasource, result) => ({
	type: FETCH_SUCCESS,
	result,
});

export const fetchTeamsError = error => ({
	type: FETCH_API_ERROR,
	error,
});
