const FONT_FAMILY = '"Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif';

const FONT_WEIGHT = {
	lighter: 200,
	light: 300,
	normal: 400,
	bold: 500,
	bolder: 600,
};

const FONT_SIZES = {
	base: '12px',
	small: '10px',
	medium: '13px',
	big: '14px',
	bigger: '16px',
	large: '18px',
	xl: '20px',
	xxl: '30px',
};

const LINE_HEIGHT = {
	base: 1.2
};

const INPUT_HEIGHT = '38px';

export {
	FONT_FAMILY,
	FONT_WEIGHT,
	FONT_SIZES,
	LINE_HEIGHT,
	INPUT_HEIGHT
};
