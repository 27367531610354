import parseValidInteger from './parseValidInteger';
import minMaxValue from './minMaxValue';

export default function normalizeValue(value, min, max, allowDecimals) {
	if (!value && value !== 0) {
		return null;
	}

	if (allowDecimals) {
		const floatNumber = parseFloat(value);
		return minMaxValue(floatNumber, min, max);
	} else {
		const validInteger = parseValidInteger(value);
		if (validInteger !== null) {
			return minMaxValue(validInteger, min, max);
		}
		return null;
	}
}
