import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

const S = {};
S.TabButton = styled.div`
	display: inline-block;
	cursor: pointer;
	width: auto;
	text-align: center;
	font-weight: 400;
	white-space: nowrap;
	flex: 1;
	max-width: 50%;
	color: #9CA3AF;
	> span {
		display: inline-block;
		padding: 0 5px 10px;
	}

	&:after {
		content: '';
		display: block;
		width: 0;
		height: 4px;
		background: #12A34D;
		transition: width .2s;
		animation-timing-function: ease;
		margin: auto;
	}

	&:hover {
		color: #374151;
	}

	&.tab-button--selected {
		color: #374151;

		&:after {
			width: 100%;
		}
	}
`;
export default function TabButton({ className, label, selected, onSelect }) {
	return <S.TabButton   
		className={classNames('tab-button', className, { 'tab-button--selected': selected })}
		onClick={onSelect}>
		<span>
			{label}
		</span>
	</S.TabButton>;
}

TabButton.propTypes = {
	label: PropTypes.string.isRequired,
	selected: PropTypes.bool,
	onSelect: PropTypes.func.isRequired
};
