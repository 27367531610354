import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getWeekDaysOptions, periods, timeOptionsStart } from '../logic/constants';
import TimeRange from './TimeRange';
import timeExpressionValidator from '../logic/timeExpressionValidator';
import HelpInline from '../../common/helpInline/HelpInline';
import Checkbox from '../../common/checkbox/Checkbox';
import OptionEnabler from './OptionEnabler';
import { useTranslation } from 'react-i18next';

function TimeRangeSelector({ startingOnDay, timeRange, period, actions, visibility, disabled }) {
	const { t } = useTranslation();
	const weekDaysOptions = useMemo(() => getWeekDaysOptions(startingOnDay), [startingOnDay]);

	return <React.Fragment>
		<OptionEnabler className={'time-period-option-enabler'}>
			<Checkbox
				checked={timeRange.enabled}
				onToggle={actions.toggleTimeRange}
				label={period === periods.day ? t('Select time range') : t('Select date range')}
				disabled={disabled}
			/>
			{disabled &&
			<HelpInline
				text={t('Limited time range can’t be set when more than one day or week is chosen' +
					' or custom end date is enabled')}
			/>}
		</OptionEnabler>
		{timeRange.enabled && visibility.timeRangeDay && !disabled && <TimeRange
			disabled={!timeRange.enabled} 
			fromValue={timeRange.fromHours} 
			toValue={timeRange.toHours}
			fromOptions={timeOptionsStart}
			toOptions={timeRange.toHoursOptions}
			changeFromValue={actions.changeFromHours}
			changeToValue={actions.changeToHours}
			validator={timeExpressionValidator}
			label={t('Time:')} /> }
		{timeRange.enabled && visibility.timeRangeWeek && !disabled && <TimeRange
			disabled={!timeRange.enabled} 
			fromValue={timeRange.fromDay} 
			toValue={timeRange.toDay}
			fromOptions={weekDaysOptions}
			toOptions={timeRange.toDayOptions}
			changeFromValue={actions.changeFromWeekDay}
			changeToValue={actions.changeToWeekDay}
			label={t('Days:')}/> 
		}
	</React.Fragment>;
}

TimeRangeSelector.propTypes = {
	actions: PropTypes.shape({
		toggleTimeRange: PropTypes.func.isRequired,
		changeFromHours: PropTypes.func.isRequired,
		changeToHours: PropTypes.func.isRequired,
		changeFromWeekDay: PropTypes.func.isRequired,
		changeToWeekDay: PropTypes.func.isRequired,
	}).isRequired,
	timeRange: PropTypes.shape({
		enabled: PropTypes.bool.isRequired,
		fromHours: PropTypes.string,
		toHours: PropTypes.string,
		fromDay: PropTypes.string,
		toDay: PropTypes.string,
	}).isRequired,
	visibility: PropTypes.shape({
		timeRangeDay: PropTypes.bool.isRequired,
		timeRangeWeek: PropTypes.bool.isRequired,
	}).isRequired
};

export default memo(TimeRangeSelector);
