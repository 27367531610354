import { keyframes } from 'styled-components';

export const slideIn = keyframes`
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0%);
	}
`;

export const slideOut = keyframes`
	from {
		transform: translateX(0%);
	}
	to {
		transform: translateX(-100%);
	}
`;

export const slideInReverse = keyframes`
	from {
		transform: translateX(-100%);
	}
	to {
		transform: translateX(0%);
	}
`;

export const slideOutReverse = keyframes`
	from {
		transform: translateX(0%);
	}
	to {
		transform: translateX(100%);
	}
`;
