import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

export default function IconWithTooltip({ iconName, className, message }) {
	const icon = useRef();
	useEffect(() => {
		const el = $(icon.current);
		if (el.tooltip) {
			el.tooltip({
				container: 'body'
			});
		}
	
		return () => {	el.tooltip ? el.tooltip('destroy') : null; };
	}, []);

	return <span 
		ref={icon}
		className={classNames(`icon icon-${iconName}`, className)}
		data-toggle="tooltip"
		data-placement="right"
		data-original-title={message}
		title={message} />;
}
