import { modes, periods, relativeModes } from './constants';
import { t } from '../../../../setupTranslations';

const modeOptionsGenerator = (relativeMode) => (period) => ({
	mode: modes.relative,
	relativeStartDate: {
		mode: relativeMode,
		amount: 1,
		period: period,
		startingOnMonth: 1,
		startingOnDay: 1
	},
	relativeEndDate: {
		enabled: false,
	},
	offset: {
		enabled: false,
	},
	timeRange: {
		enabled: false,
	}
});

const currentModeOptionsGenerator = modeOptionsGenerator(relativeModes.current);
const previousModeOptionsGenerator = modeOptionsGenerator(relativeModes.previous);
const nextModeOptionsGenerator = modeOptionsGenerator(relativeModes.next);

const currentModeToDateOptionsGenerator = (period) => ({
	mode: modes.relative,
	relativeStartDate: {
		mode: relativeModes.current,
		amount: 1,
		period: period,
		startingOnMonth: 1,
		startingOnDay: 1
	},
	relativeEndDate: {
		enabled: true,
		mode: relativeModes.current,
		amount: 1,
		period: periods.day,
	},
	offset: {
		enabled: false,
	},
	timeRange: {
		enabled: false,
	}
});

const customOptionsGenerator = (relativeMode) => ({
	mode: modes.relative,
	relativeStartDate: {
		mode: relativeMode,
		startingOnMonth: 1,
		startingOnDay: 1
	},
	relativeEndDate: {
		mode: relativeModes.current,
		amount: 1,
	},
	offset: {
		enabled: false,
	},
	timeRange: {
		enabled: false,
	}
});

export const presetTypes = {
	today: 'preset_today',
	week: 'preset_week',
	month: 'preset_month',
	quarter: 'preset_quarter',
	year: 'preset_year',
	weekToDate: 'preset_week_td',
	monthToDate: 'preset_month_td',
	quarterToDate: 'preset_quarter_td',
	yearToDate: 'preset_year_td',

	yesterday: 'preset_yesterday',
	last_week: 'preset_last_week',
	last_month: 'preset_last_month',
	last_quarter: 'preset_last_quarter',
	last_year: 'preset_last_year',
	last_custom: 'preset_last_custom',

	tomorrow: 'preset_tomorrow',
	next_week: 'preset_next_week',
	next_month: 'preset_next_month',
	next_quarter: 'preset_next_quarter',
	next_year: 'preset_next_year',
	next_custom: 'preset_next_custom',
};

export const matchingPresets = [
	[
		{ mode: relativeModes.current, preset: presetTypes.today },
		{ mode: relativeModes.previous, preset: presetTypes.yesterday },
		{ mode: relativeModes.next, preset: presetTypes.tomorrow }
	],
	[
		{ mode: relativeModes.current, preset: presetTypes.week },
		{ mode: relativeModes.previous, preset: presetTypes.last_week },
		{ mode: relativeModes.next, preset: presetTypes.next_week }
	],
	[
		{ mode: relativeModes.current, preset: presetTypes.month },
		{ mode: relativeModes.previous, preset: presetTypes.last_month },
		{ mode: relativeModes.next, preset: presetTypes.next_month }
	],
	[
		{ mode: relativeModes.current, preset: presetTypes.quarter },
		{ mode: relativeModes.previous, preset: presetTypes.last_quarter },
		{ mode: relativeModes.next, preset: presetTypes.next_quarter }
	],
	[
		{ mode: relativeModes.current, preset: presetTypes.year },
		{ mode: relativeModes.previous, preset: presetTypes.last_year },
		{ mode: relativeModes.next, preset: presetTypes.next_year }
	],
	[
		{ mode: relativeModes.previous, preset: presetTypes.last_custom },
		{ mode: relativeModes.next, preset: presetTypes.next_custom }
	],
];

export const currentPresetsOptions = () => [
	{
		label: t('Today'),
		value: presetTypes.today,
		config: currentModeOptionsGenerator(periods.day),
	},
	{
		label: t('Current Week'),
		value: presetTypes.week,
		config: currentModeOptionsGenerator(periods.week),
	},
	{
		label: t('Current Month'),
		value: presetTypes.month,
		config: currentModeOptionsGenerator(periods.month),
	},
	{
		label: t('Current Quarter'),
		value: presetTypes.quarter,
		config: currentModeOptionsGenerator(periods.quarter),
	},
	{
		label: t('Current Year'),
		value: presetTypes.year,
		config: currentModeOptionsGenerator(periods.year),
	},
	{
		label: t('Week to date'),
		value: presetTypes.weekToDate,
		config: currentModeToDateOptionsGenerator(periods.week),
	},
	{
		label: t('Month to date'),
		value: presetTypes.monthToDate,
		config: currentModeToDateOptionsGenerator(periods.month),
	},
	{
		label: t('Quarter to date'),
		value: presetTypes.quarterToDate,
		config: currentModeToDateOptionsGenerator(periods.quarter),
	},
	{
		label: t('Year to date'),
		value: presetTypes.yearToDate,
		config: currentModeToDateOptionsGenerator(periods.year),
	},
];

export const previousPresetsOptions = () => [
	{
		label: t('Yesterday'),
		value: presetTypes.yesterday,
		config: previousModeOptionsGenerator(periods.day),
	},
	{
		label: t('Previous Week'),
		value: presetTypes.last_week,
		config: previousModeOptionsGenerator(periods.week),
	},
	{
		label: t('Previous Month'),
		value: presetTypes.last_month,
		config: previousModeOptionsGenerator(periods.month),
	},
	{
		label: t('Previous Quarter'),
		value: presetTypes.last_quarter,
		config: previousModeOptionsGenerator(periods.quarter),
	},
	{
		label: t('Previous Year'),
		value: presetTypes.last_year,
		config: previousModeOptionsGenerator(periods.year),
	},
	{
		label: t('Previous...'),
		value: presetTypes.last_custom,
		isCustom: true,
		config: customOptionsGenerator(relativeModes.previous),
	},
];

export const nextPresetsOptions = () => [
	{
		label: t('Tomorrow'),
		value: presetTypes.tomorrow,
		config: nextModeOptionsGenerator(periods.day),
	},
	{
		label: t('Next Week'),
		value: presetTypes.next_week,
		config: nextModeOptionsGenerator(periods.week),
	},
	{
		label: t('Next Month'),
		value: presetTypes.next_month,
		config: nextModeOptionsGenerator(periods.month),
	},
	{
		label: t('Next Quarter'),
		value: presetTypes.next_quarter,
		config: nextModeOptionsGenerator(periods.quarter),
	},
	{
		label: t('Next Year'),
		value: presetTypes.next_year,
		config: nextModeOptionsGenerator(periods.year),
	},
	{
		label: t('Next...'),
		value: presetTypes.next_custom,
		isCustom: true,
		config: customOptionsGenerator(relativeModes.next),
	},
];

export function getModeOptions(relativeMode) {
	switch (relativeMode) {
	case relativeModes.current:
		return currentPresetsOptions();
	case relativeModes.previous:
		return previousPresetsOptions();
	case relativeModes.next:
		return nextPresetsOptions();
	default:
		return currentPresetsOptions();
	}
}

