export const FETCH = 'plecto/reportSchedules/FETCH';
export const FETCH_SUCCESS = 'plecto/reportSchedules/FETCH_SUCCESS';
export const FETCH_API_ERROR = 'plecto/reportSchedules/FETCH_API_ERROR';
export const ADD = 'plecto/reportSchedules/ADD';
export const ADD_SUCCESS = 'plecto/reportSchedules/ADD_SUCCESS';
export const ADD_API_ERROR = 'plecto/reportSchedules/ADD_API_ERROR';
export const SAVE = 'plecto/reportSchedules/SAVE';
export const UPDATE = 'plecto/reportSchedules/UPDATE';
export const UPDATE_SUCCESS = 'plecto/reportSchedules/UPDATE_SUCCESS';
export const UPDATE_API_ERROR = 'plecto/reportSchedules/UPDATE_API_ERROR';
export const DELETE = 'plecto/reportSchedules/DELETE';
export const DELETE_SUCCESS = 'plecto/reportSchedules/DELETE_SUCCESS';
export const DELETE_API_ERROR = 'plecto/reportSchedules/DELETE_API_ERROR';

export const fetchReportSchedules = (reportId) => ({
	type: FETCH,
	reportId
});

export const fetchReportSchedulesSuccess = (reportId, payload) => ({
	type: FETCH_SUCCESS,
	reportId,
	payload
});

export const fetchReportSchedulesFailure = (reportId, error) => ({
	type: FETCH_API_ERROR,
	reportId,
	error
});

export function shouldFetchReportSchedules(state, reportId) {
	const schedules = state.schedules;
	return !schedules || !schedules[reportId] || !schedules[reportId].isFetching;
}

export function fetchReportSchedulesIfNeeded(reportId) {
	return (dispatch, getState) => {
		if (shouldFetchReportSchedules(getState(), reportId)) {
			return dispatch(fetchReportSchedules(reportId));
		}
	};
}

export const createReportScheduleRequest = (reportId, schedule) => ({
	type: ADD,
	reportId,
	schedule
});

export const createReportScheduleSuccess = (reportId, payload) => ({
	type: ADD_SUCCESS,
	reportId,
	payload
});

export const createReportScheduleFailure = (reportId, error, errors) => ({
	type: ADD_API_ERROR,
	reportId,
	error,
	errors
});

export const saveReportSchedule = (reportId, schedule) => ({
	type: SAVE,
	reportId,
	schedule
});

export const updateReportScheduleRequest = (reportId, scheduleId, schedule) => ({
	type: UPDATE,
	reportId,
	scheduleId,
	schedule
});

export const saveReportScheduleSuccess = (reportId, scheduleId, payload) => ({
	type: UPDATE_SUCCESS,
	reportId,
	scheduleId,
	payload,
});

export const saveReportScheduleFailure = (reportId, scheduleId, error, errors) => ({
	type: UPDATE_API_ERROR,
	reportId,
	scheduleId,
	error,
	errors
});

export const deleteReportScheduleRequest = (reportId, scheduleId) => ({
	type: DELETE,
	reportId,
	scheduleId
});

export const deleteReportScheduleSuccess = (reportId, scheduleId) => ({
	type: DELETE_SUCCESS,
	reportId,
	scheduleId
});

export const deleteReportScheduleFailure = (reportId, scheduleId, error) => ({
	type: DELETE_API_ERROR,
	reportId,
	scheduleId,
	error
});
