import { apiDelete, apiGet, apiPost, apiPut, apiVersionV3 } from '../../fetch/apiService';

export function createQuiz(quiz) {
	return apiPost(
		'quizzes',
		quiz,
		{},
		{},
		apiVersionV3
	);
}

export function fetchQuiz(quizId) {
	return apiGet(
		`quizzes/${quizId}`,
		{},
		{},
		apiVersionV3
	);
}

export function updateQuiz(quiz) {
	return apiPut(
		`quizzes/${quiz.id}`,
		quiz,
		{},
		{},
		apiVersionV3
	);
}

export function createQuestion(quizId, question) {
	return apiPost(
		`quizzes/${quizId}/questions`,
		question,
		{},
		{},
		apiVersionV3
	);
}

export function getQuestions(quizId) {
	return apiGet(`quizzes/${quizId}/questions`,
		{},
		{},
		apiVersionV3
	);
}

export function updateQuestionsOrder(quizId, questions) {
	return apiPost(
		`quizzes/${quizId}/questions/set_questions_ordering/`,
		questions,
		{},
		{},
		apiVersionV3
	);
}

export function getQuestion(quizId, questionId, showCorrectSolution) {
	return apiGet(`quizzes/${quizId}/questions/${questionId}`,
		{
			...(showCorrectSolution && { show_correct_solution: showCorrectSolution }),
		},
		{},
		apiVersionV3
	);
}

export function updateQuestion(quizId, question) {
	return apiPut(
		`quizzes/${quizId}/questions/${question.uuid}`,
		question,
		{},
		{},
		apiVersionV3
	);
}

export function uploadQuestionImage(quizId, image) {
	return apiPut(
		`quizzes/${quizId}/upload_option_image/`,
		{ image },
		{},
		{},
		apiVersionV3
	);
}

export function deleteQuestion(quizId, questionId) {
	return apiDelete(
		`quizzes/${quizId}/questions/${questionId}`,
		{},
		{},
		{},
		apiVersionV3
	);
}

export function createAnswer(quizResultId, question) {
	return apiPost(
		`quiz-results/${quizResultId}/answers`,
		question,
		{},
		{},
		apiVersionV3
	);
}

export function getResultAnswers(sessionResultId) {
	return apiGet(`quiz-results/${sessionResultId}/answers/`,
		{},
		{},
		apiVersionV3
	);
}

export function createResults(quizResultId, timeLeft, timedOut) {
	return apiPost(
		`quiz-results/${quizResultId}/complete/`,
		{
			...(timeLeft && { time_left: timeLeft }),
			...(timedOut && { timed_out: timedOut })
		},
		{},
		{},
		apiVersionV3
	);
}

export function createReview(id, score, reviewComment) {
	return apiPut(
		`quizzes-reviews/${id}/`,
		{
			score,
			...(reviewComment && { reviewers_comment: reviewComment })
		},
		{},
		{},
		apiVersionV3
	);
}

export function createSession(quizId, session) {
	return apiPost(
		`quizzes/${quizId}/sessions`,
		session,
		{},
		{},
		apiVersionV3
	);
}

export function getSessionResults(quizId, sessionId) {
	return apiGet(
		`quizzes/${quizId}/sessions/${sessionId}/results/`,
		{},
		{},
		apiVersionV3
	);
}

export function getSessionResultsQuestionDetails(quizId, sessionId, questionId) {
	return apiGet(
		`quizzes/${quizId}/sessions/${sessionId}/results/questions/${questionId}`,
		{},
		{},
		apiVersionV3
	);
}
