import { takeLatest } from 'redux-saga/effects';
import {
	CREATE_ANSWER,
	CREATE_QUESTION,
	CREATE_QUIZ,
	CREATE_SESSION,
	createAnswerFailure,
	createAnswerSuccess,
	createQuestionFailure,
	createQuestionSuccess,
	createQuizFailure,
	createQuizSuccess,
	createSessionFailure,
	createSessionSuccess,
	GET_QUESTION,
	GET_QUESTIONS,
	getQuestionFailure,
	getQuestionsFailure,
	getQuestionsSuccess,
	getQuestionSuccess,
	FETCH_QUIZ,
	fetchQuizFailure,
	fetchQuizSuccess,
	UPDATE_QUIZ,
	updateQuizFailure,
	updateQuizSuccess,
	UPDATE_QUESTION,
	updateQuestionSuccess,
	updateQuestionFailure,
	DELETE_QUESTION,
	deleteQuestionSuccess,
	deleteQuestionFailure,
	GET_SESSION_RESULTS_QUESTION_DETAILS,
	getSessionResultsQuestionDetailsSuccess,
	getSessionResultsQuestionDetailsFailure,
	UPDATE_QUESTIONS_ORDER,
	updateQuestionsOrderSuccess,
	updateQuestionsOrderFailure,
	CREATE_REVIEW,
	createReviewSuccess,
	createReviewFailure,
	UPLOAD_QUESTION_IMAGE,
	uploadQuestionImageFailure,
	uploadQuestionImageSuccess,
} from './actions';
import apiSagaGenerator from '../../saga/apiSagaGenerator';
import {
	createQuestion,
	createQuiz,
	createSession,
	getQuestions,
	getQuestion,
	createAnswer,
	createResults,
	getResultAnswers,
	fetchQuiz,
	updateQuiz,
	updateQuestion,
	deleteQuestion,
	getSessionResults,
	getSessionResultsQuestionDetails,
	updateQuestionsOrder,
	createReview,
	uploadQuestionImage
} from './api';
import {
	CREATE_RESULTS,
	createResultsFailure,
	createResultsSuccess,
	GET_RESULTS,
	getResultsFailure,
	getResultsSuccess,
	GET_SESSION_RESULTS,
	getSessionResultsSuccess,
	getSessionResultsFailure
} from './results/actions';

const trainingQuizSagas = [
	takeLatest(CREATE_QUIZ,
		apiSagaGenerator()
			.forApi(createQuiz)
			.withArguments((action) => [action.quiz])
			.forSuccess(createQuizSuccess)
			.forError(createQuizFailure)
			.includeErrorResult()
			.withMessage('Error creating quiz')
			.generate()
	),
	takeLatest(FETCH_QUIZ,
		apiSagaGenerator()
			.forApi(fetchQuiz)
			.withArguments((action) => [action.quizId])
			.forSuccess(fetchQuizSuccess)
			.forError(fetchQuizFailure)
			.includeErrorResult()
			.withMessage('Error fetching quiz')
			.generate()
	),
	takeLatest(UPDATE_QUIZ,
		apiSagaGenerator()
			.forApi(updateQuiz)
			.withArguments((action) => [action.quiz])
			.forSuccess(updateQuizSuccess)
			.forError(updateQuizFailure)
			.includeErrorResult()
			.withMessage('Error updating quiz')
			.generate()
	),
	takeLatest(CREATE_QUESTION,
		apiSagaGenerator()
			.forApi(createQuestion)
			.withArguments((action) => [action.quizId, action.question])
			.forSuccess(createQuestionSuccess)
			.withActionArguments((action) => [action.questionUuid])
			.forError(createQuestionFailure)
			.includeErrorResult()
			.withMessage('Error creating question')
			.generate()
	),
	takeLatest(GET_QUESTION,
		apiSagaGenerator()
			.forApi(getQuestion)
			.withArguments((action) => [action.quizId, action.questionId, action.showCorrectSolution])
			.forSuccess(getQuestionSuccess)
			.withActionArguments((action) => [action.quizId, action.questionId, action.showCorrectSolution])
			.forError(getQuestionFailure)
			.includeErrorResult()
			.withMessage('Error getting a question')
			.generate()
	),
	takeLatest(GET_QUESTIONS,
		apiSagaGenerator()
			.forApi(getQuestions)
			.withArguments((action) => [action.quizId])
			.forSuccess(getQuestionsSuccess)
			.forError(getQuestionsFailure)
			.includeErrorResult()
			.withMessage('Error getting questions')
			.generate()
	),
	takeLatest(UPDATE_QUESTIONS_ORDER,
		apiSagaGenerator()
			.forApi(updateQuestionsOrder)
			.withArguments((action) => [action.quizId, action.questions])
			.forSuccess(updateQuestionsOrderSuccess)
			.forError(updateQuestionsOrderFailure)
			.includeErrorResult()
			.withMessage('Error updating questions order')
			.generate()
	),
	takeLatest(UPDATE_QUESTION,
		apiSagaGenerator()
			.forApi(updateQuestion)
			.withArguments((action) => [action.quizId, action.question])
			.forSuccess(updateQuestionSuccess)
			.forError(updateQuestionFailure)
			.includeErrorResult()
			.withMessage('Error updating question')
			.generate()
	),
	takeLatest(DELETE_QUESTION,
		apiSagaGenerator()
			.forApi(deleteQuestion)
			.withArguments((action) => [action.quizId, action.questionId])
			.forSuccess(deleteQuestionSuccess)
			.withActionArguments((action) => [action.questionId])
			.skipResult()
			.forError(deleteQuestionFailure)
			.includeErrorResult()
			.withMessage('Error deleting question')
			.generate()
	),
	takeLatest(UPLOAD_QUESTION_IMAGE,
		apiSagaGenerator()
			.forApi(uploadQuestionImage)
			.withArguments((action) => [action.quizId, action.image])
			.forSuccess(uploadQuestionImageSuccess)
			.withActionArguments((action) => [action.quizId, action.image])
			.forError(uploadQuestionImageFailure)
			.includeErrorResult()
			.withMessage('Error uploading an image')
			.generate()
	),
	takeLatest(CREATE_ANSWER,
		apiSagaGenerator()
			.forApi(createAnswer)
			.withArguments((action) => [action.quizResultId, action.question])
			.forSuccess(createAnswerSuccess)
			.forError(createAnswerFailure)
			.includeErrorResult()
			.withMessage('Error answering question')
			.generate()
	),
	takeLatest(CREATE_REVIEW,
		apiSagaGenerator()
			.forApi(createReview)
			.withArguments((action) => [
				action.id, action.score, action.reviewComment, action.sessionId, action.questionId
			])
			.forSuccess(createReviewSuccess)
			.skipResult()
			.forError(createReviewFailure)
			.includeErrorResult()
			.withMessage('Error submitting a review')
			.generate()
	),
	takeLatest(CREATE_RESULTS,
		apiSagaGenerator()
			.forApi(createResults)
			.withArguments((action) => [action.quizResultId, action.timeLeft, action.timedOut])
			.forSuccess(createResultsSuccess)
			.forError(createResultsFailure)
			.includeErrorResult()
			.withMessage('Error creating results')
			.generate()
	),
	takeLatest(GET_RESULTS,
		apiSagaGenerator()
			.forApi(getResultAnswers)
			.withArguments((action) => [action.sessionResultId])
			.forSuccess(getResultsSuccess)
			.forError(getResultsFailure)
			.includeErrorResult()
			.withMessage('Error getting answers')
			.generate()
	),
	takeLatest(CREATE_SESSION,
		apiSagaGenerator()
			.forApi(createSession)
			.withArguments((action) => [action.quizId, action.session])
			.forSuccess(createSessionSuccess)
			.forError(createSessionFailure)
			.includeErrorResult()
			.withMessage('Error creating session')
			.generate()
	),
	takeLatest(GET_SESSION_RESULTS,
		apiSagaGenerator()
			.forApi(getSessionResults)
			.withArguments(({ quizId, sessionId }) => [quizId, sessionId])
			.forSuccess(getSessionResultsSuccess)
			.forError(getSessionResultsFailure)
			.includeErrorResult()
			.withMessage('Error getting session results')
			.generate()
	),
	takeLatest(GET_SESSION_RESULTS_QUESTION_DETAILS,
		apiSagaGenerator()
			.forApi(getSessionResultsQuestionDetails)
			.withArguments(({ quizId, sessionId, questionId }) => [quizId, sessionId, questionId])
			.forSuccess(getSessionResultsQuestionDetailsSuccess)
			.withActionArguments(({ sessionId, questionId }) => [sessionId, questionId])
			.forError(getSessionResultsQuestionDetailsFailure)
			.includeErrorResult()
			.withMessage('Error getting session results question details')
			.generate()
	)
];

export default trainingQuizSagas;
