import React from 'react';
import PropTypes from 'prop-types';

export default function TableHeader({ columns }) {
	return <thead>
		<tr>{columns.map(col => <th key={col} scope="col">{col}</th>)}</tr>
	</thead>;
}

TableHeader.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.string).isRequired,
};
