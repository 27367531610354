import React, { useRef } from 'react';
import { Icon } from '../../icon/Icon';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { COLORS, COLORS_GRAY } from '../../../styles/colors';
import { SPACING } from '../../../styles/spacing';

export const toggleComponents = {};

toggleComponents.Input = styled.input.attrs(props => ({
	color: props.hasLabel ? COLORS_GRAY['700'] : COLORS_GRAY['600'],
}))`
	color: #555;
	overflow: hidden;
	width: calc(100% - 34px);
	text-overflow: ellipsis;
	display: inline-block;
	white-space: nowrap;
	vertical-align: middle;
	border: none;
	outline: none;
	padding: 0;
	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: ${COLORS_GRAY['600']};
		opacity: 1; /* Firefox */
	}
	:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: ${COLORS_GRAY['600']};
	}
	::-ms-input-placeholder { /* Microsoft Edge */
		color: ${COLORS_GRAY['600']};
	}
`;

toggleComponents.Title = styled.div.attrs(props => ({
	color: props.hasLabel ? COLORS_GRAY['700'] : COLORS_GRAY['600'],
}))`
	color: ${props => props.color};
	overflow: hidden;
	width: calc(100% - 34px);
	text-overflow: ellipsis;
	display: inline-block;
	white-space: nowrap;
	vertical-align: middle;
`;

toggleComponents.Icon = styled.div`
	margin-right: ${SPACING.small};
	color: ${COLORS.dark};
	display: inline-block;
	vertical-align: middle;
`;

export default function Toggle({ open, label, iconName = 'chevron-down', placeholder, onSearch }) {
	const onChange = e => onSearch(e.target.value);
	const onBlur = () => inputRef.current?.focus();
	const inputRef = useRef();
	const { t } = useTranslation();
	const visibleLabel = label ? label : placeholder || t('Search...');

	// toggle is inside button. Clicking space when button is focused clicks it.
	const onKeyUp = (e) => e.preventDefault();
	return <div className={'multi-level-dropdown-toggle-container'}>
		<toggleComponents.Icon className="multi-level-dropdown-toggle-icon">
			<Icon name={iconName} />
		</toggleComponents.Icon>
		{open ?
			<toggleComponents.Input
				ref={inputRef}
				hasLabel={label}
				className="multi-level-dropdown-toggle-input"
				placeholder={visibleLabel}
				onChange={onChange}
				autoFocus
				onBlur={onBlur}
				onKeyUp={onKeyUp}
			/> :
			<toggleComponents.Title hasLabel={label} className="multi-level-dropdown-toggle-title">
				<span>{visibleLabel}</span>
			</toggleComponents.Title>
		}
	</div>;
}
