import React, { useState, useCallback, useMemo } from 'react';
import Footer from './Footer';
import SlideInContext from './SlideInContext';
import Title from './Title';
import styled from 'styled-components';
import SlideInHost from './SlideInHost';
import classNames from 'classnames';
import useKeyPressed from '../../hooks/useKeyPressed';
import useBlockBodyScroll from '../../hooks/useBlockBodyScroll';

const SlideInContent = styled.div`
	padding: 20px;
	flex: 1 1 auto;
	overflow-y: auto;
`;

export default React.forwardRef(function EditSlideIn({
	open,
	title,
	onSave,
	onClose,
	children,
	onClick,
	isSaving,
	hideHeader,
	hideFooter,
	cancelText,
	className,
	saveText,
	transparentOverlay,
	onEnterDone,
	icon,
	url,
	onOutsideClick,
	onOverlayClick
}, ref) {

	const [openByChild, setOpen] = useState(null);

	const openChild = useCallback(() => {
		setOpen(null);
	}, []);

	const closeByChild = useCallback(() => {
		setOpen(false);
	}, []);

	const isOpen = open && (openByChild === null || openByChild);

	const context = useMemo(() => ({
		open: openChild,
		close: closeByChild
	}), [openChild, closeByChild]);

	const onEscape = useCallback(() => {
		if (isOpen) {
			onClose();
		}
	},[isOpen, onClose]);

	useKeyPressed(['Esc', 'Escape'], onEscape);

	useBlockBodyScroll(open);

	return <SlideInContext.Provider value={context}>
		<SlideInHost
			open={isOpen}
			transparentOverlay={transparentOverlay}
			onEnterDone={onEnterDone}
			onClick={onClick}
			onOutsideClick={onOutsideClick}
			onOverlayClick={onOverlayClick ? onOverlayClick : onClose}
			ref={ref}
		>
			{!hideHeader && <Title onClose={onClose} icon={icon} title={title} url={url} />}
			<SlideInContent className={classNames('slide-in-content', className)}>
				{open && children}
			</SlideInContent>
			{!hideFooter &&
			<Footer
				onClose={onClose}
				onSave={onSave}
				isSaving={isSaving}
				cancelText={cancelText}
				saveText={saveText}
			/>}
		</SlideInHost>
	</SlideInContext.Provider>;
});
