import React from 'react';
import { useTranslation } from 'react-i18next';

export default function DeleteButton({ text, onClick, disabled }) {
	const { t } = useTranslation();
	return <button
		type={onClick ? 'button' : 'submit'}
		onClick={onClick}
		className={'btn btn-lg btn-danger'}
		disabled={disabled}
	>
		{(text || t('Delete'))}
	</button>;
}
