
import React, { useState } from 'react';
import { keyCodes } from '../../utils/keyCodes';
import _trim from 'lodash/trim';
import AutosizeInput from 'react-input-autosize';
import styled from 'styled-components';
import { InputSizes, placeholderFontSize } from './sizing';
import useEventValueChange from '../../../hooks/useEventValueChange';

const StyledContainer = styled.div<{$size: InputSizes}>`
	display: flex;
	align-items: center;
	input {
		border: none;
		outline: none;
		font-size: ${props => placeholderFontSize[props.$size]}px;
	}
`;

const enterTriggerCodes = [
	keyCodes.enter,
	keyCodes.comma,
	keyCodes.space
];

type Props = {
	onEntered: (value: string) => void,
	onBlur?: () => void,
	onClick?: () => void,
	onRemove?: () => void,
	size: InputSizes,
	value?: string,
};

export function ItemInput({ onEntered, onBlur, onClick, size, value = '', onRemove }: Props) {
	const [inputValue, setInputValue] = useState(value);

	const onConfirmed = (newItem: string) => {
		const val = _trim(newItem);
		if (val) {
			onEntered(val);
			setInputValue('');
		} else {
			onRemove?.();
			onBlur?.();
		}
	};

	const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (enterTriggerCodes.indexOf(e.keyCode) >= 0) {
			e.preventDefault();
			onConfirmed((e.target as HTMLInputElement).value);
		} else if (e.keyCode === keyCodes.escape) {
			setInputValue('');
			onBlur?.();
		}
	};

	const onInputBlur = () => {
		onConfirmed(inputValue);
	};

	const onInputChange = useEventValueChange(setInputValue);

	return (<StyledContainer $size={size} onClick={onClick}>
		<AutosizeInput
			value={inputValue}
			autoFocus
			onKeyDown={onInputKeyDown}
			onChange={onInputChange}
			onBlur={onInputBlur} />
	</StyledContainer>

	);
}
