const screenSizes = {
	xs: 480,
	vs: 620,
	sm: 768,
	md: 992,
	lg: 1200,
	xl: 1400,
};

export default screenSizes;
