import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
	FETCH_MEMBERS_V3,
	fetchMembersFailure,
	fetchMembersSuccess,
	FETCH_MEMBERS_V3_BY_PERMISSION,
	fetchMembersByPermissionProfileSuccess,
	FETCH_MEMBERS_V3_COMPACT,
	fetchMembersCompactSuccess,
} from './actions';
import { getMembers } from './api';
import apiSagaGenerator from '../../../saga/apiSagaGenerator';

const PAGE_NUMBER = 1;
const PAGE_SIZE = 9999999;
const EMPTY_TEAMS = [];
const EMPTY_SEARCH_VALUE = null;
const NO_DYNAMIC_DASHBOARD = null;
const COMPACT = true;
const NOT_COMPACT = false;
const LOGIN_ACCESS_ONLY = true;
const EXCLUDE_SELF = true;
const ONLY_MANAGED = true;

const membersV3Sagas = [
	takeLatest(FETCH_MEMBERS_V3,
		apiSagaGenerator()
			.forApi(getMembers)
			.withArguments((action) => [
				action.searchValue,
				action.permissionProfiles,
				action.teams,
				action.dynamicDashboardId,
				action.pageNumber,
				action.pageSize,
				NOT_COMPACT,
				action.loginAccess,
				action.excludeSelf,
				action.managed,
			])
			.forSuccess(fetchMembersSuccess)
			.withActionArguments((action) => [action.pageNumber])
			.forError(fetchMembersFailure)
			.withMessage('Error getting members')
			.generate(),
	),
	takeLatest(FETCH_MEMBERS_V3_BY_PERMISSION,
		apiSagaGenerator()
			.forApi(getMembers)
			.withArguments((action) => [
				EMPTY_SEARCH_VALUE,
				[action.permissionProfile],
				EMPTY_TEAMS,
				NO_DYNAMIC_DASHBOARD,
				PAGE_NUMBER,
				PAGE_SIZE,
				COMPACT,
				LOGIN_ACCESS_ONLY,
				EXCLUDE_SELF,
				!ONLY_MANAGED,
			])
			.forSuccess(fetchMembersByPermissionProfileSuccess)
			.withActionArguments(() => [])
			.forError(fetchMembersFailure)
			.withMessage('Error getting members')
			.generate(),
	),
	takeEvery(FETCH_MEMBERS_V3_COMPACT,
		apiSagaGenerator()
			.forApi(getMembers)
			.withArguments((action) => [
				action.searchValue,
				action.permissionProfiles,
				action.teams,
				action.dynamicDashboardId,
				PAGE_NUMBER,
				PAGE_SIZE,
				COMPACT,
				action.loginAccess,
				action.excludeSelf,
				action.managed,
			])
			.forSuccess(fetchMembersCompactSuccess)
			.withActionArguments((action) => [action.loadAll])
			.forError(fetchMembersFailure)
			.withMessage('Error getting members')
			.generate(),
	),
];

export default membersV3Sagas;
