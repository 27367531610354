import _filter from 'lodash/filter';
import _slice from 'lodash/slice';

export const pageSize = 100;

export function filterOptions(options, inputValue, filterOption) {
	if (!inputValue) {
		return options;
	}

	return _filter(options, op => filterOption(op, inputValue));
}

export function getOptionsPage(options, page = 0) {
	return _slice(options, page * pageSize, (page + 1) * pageSize);
}
