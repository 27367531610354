import { FETCH, FETCH_API_ERROR, FETCH_SUCCESS } from './actions';

const initialState = {
};

const dataSourceVariables = (state = initialState, action) => {
	switch (action.type) {
	case FETCH: {
		return {
			...state,
			[action.dataSourceId]: {
				isFetching: true,
				data: [],
			}
		};
	}
	case FETCH_SUCCESS: {
		return {
			...state,
			[action.dataSourceId]: {
				isFetching: false,
				data: action.result
			}
		};
	}
	case FETCH_API_ERROR: {
		return {
			...state,
			[action.dataSourceId]: {
				isFetching: false,
				error: action.error,
				data: [],
			}
		};
	}
	default:
		return state;
	}
};

export default dataSourceVariables;
