import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import { default as standardFetch } from 'cross-fetch';
import { fetchBaseUrl } from './fetchOptions';
import ApiError from './ApiError';
import { stringify } from 'qs';
import _trimEnd from 'lodash/trimEnd';
import { logResponseErrors } from './responseErrorHandler';

const basicOptions = {
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
	cache: 'no-cache',
};

let moduleApi = null;
let baseUrl = fetchBaseUrl;

const defaultApiVersion = 'v2';
export const apiVersionV3 = 'v3';
const internalApiUrl = 'internal';
const adminApiUrl = 'admin';

export function createApi({ credentials, headers, mode, queryParameters, moduleFetch, errorHandler }) {

	const fetchOptions = {
		...basicOptions,
		mode,
		credentials,
		headers: Object.assign({}, basicOptions.headers, headers)
	};

	const fetch = moduleFetch || standardFetch;

	return {
		apiGet: function(endpoint, parameters, options = {}, version) {
			return fetchWrapper(fetch(createUrl(endpoint, queryParameters, parameters, options, version), {
				...fetchOptions,
				method: 'GET',
				signal: options.signal,
			}),errorHandler);
		},

		apiPost: function(endpoint, body, parameters, options = {}, version) {
			return fetchWrapper(fetch(createUrl(endpoint, queryParameters, parameters, options, version), {
				...fetchOptions,
				method: 'POST',
				body: JSON.stringify(body),
				signal: options.signal,
			}),errorHandler);
		},

		apiPut: function(endpoint, body, parameters, options = {}, version) {
			return fetchWrapper(fetch(createUrl(endpoint, queryParameters, parameters, options, version), {
				...fetchOptions,
				method: 'PUT',
				body: JSON.stringify(body),
				signal: options.signal,
			}),errorHandler);
		},

		apiDelete: function(endpoint, body, parameters, options = {}, version) {
			return fetchWrapper(fetch(createUrl(endpoint, queryParameters, parameters, options, version), {
				...fetchOptions,
				method: 'DELETE',
				body: JSON.stringify(body),
				signal: options.signal,
			}),errorHandler);
		},
	};
}

export function setUpApi({ credentials, headers, mode, queryParameters, moduleFetch, errorHandler }) {
	moduleApi = createApi({ credentials, headers, mode, queryParameters, moduleFetch, errorHandler });
}

export function clearApi() {
	moduleApi = null;
}

function checkApiCreated() {
	if (!moduleApi) {
		console.error('Api not initialized.');
		throw new ApiError('Api not initialized.');
	}
}

function fetchWrapper(fetchPromise, errorHandler) {
	return fetchPromise.then((response) => {
		logResponseErrors(response, errorHandler);
		return response;
	});
}

function createUrl(endpoint, apiQueryParameters, queryParameters = {}, options, version) {
	let urlParameters = '';
	if ((queryParameters && Object.keys(queryParameters).length > 0) || apiQueryParameters) {
		const mergedParameters = {
			...(apiQueryParameters || {}),
			...queryParameters,
		};
		urlParameters = `?${stringify(mergedParameters)}`;
	}

	endpoint = _trimEnd(endpoint, '/');
	
	if (options.adminApi) {
		return `${baseUrl}/${adminApiUrl}/${endpoint}/${urlParameters}`;
	} else {
		const apiToUse = options && options.internalApi ? internalApiUrl : version || defaultApiVersion;
		return `${baseUrl}/api/${apiToUse}/${endpoint}/${urlParameters}`;
	}
}

export function setBaseUrl(url) {
	baseUrl = url;
}

export function apiGet(endpoint, parameters, options = {}, version) {
	checkApiCreated();
	return moduleApi.apiGet(endpoint, parameters, options, version);
}

export function apiPost(endpoint, body, parameters, options = {}, version) {
	checkApiCreated();
	return moduleApi.apiPost(endpoint, body, parameters, options, version);
}

export function apiPut(endpoint, body, parameters, options = {}, version) {
	checkApiCreated();
	return moduleApi.apiPut(endpoint, body, parameters, options, version);
}

export function apiDelete(endpoint, body, parameters, options = {}, version) {
	checkApiCreated();
	return moduleApi.apiDelete(endpoint, body, parameters, options, version);
}
