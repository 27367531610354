import { takeEvery, put, select } from 'redux-saga/effects';
import {
	ADD_COMMENT,
	ADD, createReportGroupFormulaCommentFailure, createReportGroupFormulaCommentSuccess,
	createReportGroupFormulaFailure,
	createReportGroupFormulaSuccess,
	DELETE,
	deleteReportGroupFormulaFailure,
	deleteReportGroupFormulaSuccess,
	FETCH_COMMENTS, fetchReportGroupFormulaCommentsFailure,
	fetchReportGroupFormulaCommentsSuccess,
	SAVE_DRILLDOWN,
	saveDrilldownSuccess,
	saveDrilldownFailure,
	PIN_DRILL_DOWN,
	CLEAR_DRILL_DOWN,
	saveDrillDown,
	UNPIN_DRILL_DOWN,
	DRILL_DOWN_BY,
	initReportGroupDrilldownsRerender,
	UPDATE_SORTING,
	updateDrilldownSortingSuccess,
	updateDrilldownSortingFailure,
} from './actions';
import { addComment, createFormula, deleteFormula, getComments, saveDimension, saveSorting } from './api';
import { fetchReportGroupRenders } from '../reportGroup/actions';
import apiSagaGenerator from '../../saga/apiSagaGenerator';
import takeLatestBy from 'redux-saga-take-latest-by';
import renderLoopSagas from './dimensions/renderLoopSaga';
import { getAppliedDimension } from './selectors';

export const createReportGroupFormula = apiSagaGenerator()
	.forApi(createFormula)
	.withArguments((action) => [action.formula])
	.forSuccess(createReportGroupFormulaSuccess)
	.andPut(fetchReportGroupRenders)
	.withActionArguments((action) => [action.reportGroupId, action.after])
	.forError(createReportGroupFormulaFailure)
	.includeErrorResult()
	.withMessage('Error creating report group formula')
	.generate();

const reportGroupFormulaSagas = [
	takeEvery(ADD, createReportGroupFormula),
	takeEvery(DELETE, 
		apiSagaGenerator()
			.forApi(deleteFormula)
			.withArguments((action) => [action.reportGroupFormulaId])
			.forSuccess(deleteReportGroupFormulaSuccess)
			.skipResult()
			.withActionArguments((action) => [action.reportGroupId, action.reportGroupFormulaId])
			.forError(deleteReportGroupFormulaFailure)
			.withMessage('Error deleting report group formula')
			.generate()
	),
	takeEvery(FETCH_COMMENTS, 
		apiSagaGenerator()
			.forApi(getComments)
			.withArguments((action) => [action.reportGroupFormulaId])
			.forSuccess(fetchReportGroupFormulaCommentsSuccess)
			.forError(fetchReportGroupFormulaCommentsFailure)
			.withMessage('Error getting formula comments')
			.generate()

	),
	takeEvery(ADD_COMMENT, 
		apiSagaGenerator()
			.forApi(addComment)
			.withArguments((action) => [action.reportGroupFormulaId, action.data])
			.forSuccess(createReportGroupFormulaCommentSuccess)
			.withActionArguments((action) => [action.reportGroupFormulaId])
			.forError(createReportGroupFormulaCommentFailure)
			.withMessage('Error adding comment to formula')
			.generate()
	),
	takeEvery(SAVE_DRILLDOWN, 
		apiSagaGenerator()
			.forApi(saveDimension)
			.withArguments((action) => [action.reportGroupFormulaId, action.dimension])
			.forSuccess(saveDrilldownSuccess)
			.skipResult()
			.forError(saveDrilldownFailure)
			.withMessage('Error updating report group formula dimension')
			.generate()	
	),
	takeEvery(UPDATE_SORTING, 
		apiSagaGenerator()
			.forApi(saveSorting)
			.withArguments((action) => [action.reportGroupFormulaId, action.sorting])
			.forSuccess(updateDrilldownSortingSuccess)
			.skipResult()
			.forError(updateDrilldownSortingFailure)
			.withMessage('Error updating report group formula drilldown sorting')
			.generate()	
	),
	takeEvery(UNPIN_DRILL_DOWN, unpinDimension),
	takeLatestBy([PIN_DRILL_DOWN, CLEAR_DRILL_DOWN], initSaveOfAppliedDimension, action => action.reportGroupFormulaId),
	takeEvery([DRILL_DOWN_BY, CLEAR_DRILL_DOWN], function*(action) {
		yield put(initReportGroupDrilldownsRerender(action.reportGroupFormulaId));
	}),
	...renderLoopSagas
];

function* unpinDimension(action) {
	yield put(saveDrillDown(action.reportGroupFormulaId, null));
}

function* initSaveOfAppliedDimension(action) {
	const dimension = yield select(getAppliedDimension, action.reportGroupFormulaId);
	yield put(saveDrillDown(action.reportGroupFormulaId, dimension));
}

export default reportGroupFormulaSagas;
