import { apiDelete, apiGet, apiPost, apiPut } from '../../fetch/apiService';
export function reorderFormulas(reportGroupId, orderedReportGroupFormulaIds) {
	return apiPut(`report-groups/${reportGroupId}/reorder/`, orderedReportGroupFormulaIds);
}

export function getRender(reportGroupId) {
	return apiGet(`report-groups/${reportGroupId}/render/`);
}

export function getRenderAll(reportGroupId) {
	return apiGet(`report-groups/${reportGroupId}/render/`, { all_pages: true });
}

export function getPreviewRender(reportGroupId, settings, allPages, signal) {
	return apiPost(`report-groups/${reportGroupId}/preview/`, 
		settings, { all_pages: allPages ? true : undefined }, { signal });
}

export function createGroup(reportGroup) {
	return apiPost('report-groups', reportGroup);
}

export function saveGroup(reportGroupId, group) {
	return apiPut(`report-groups/${reportGroupId}`, group);
}

export function copyGroup(reportGroupId) {
	return apiGet(`report-groups/${reportGroupId}/copy/`);
}

export function deleteGroup(reportGroupId) {
	return apiDelete(`report-groups/${reportGroupId}`);
}

export function getRenderPage(reportGroupId, pageNumber) {
	return apiGet(`report-groups/${reportGroupId}/render/`, { page_number: pageNumber });
}
