export const FETCH = 'plecto/team/FETCH';
export const FETCH_SUCCESS = 'plecto/team/FETCH_SUCCESS';
export const FETCH_TEAMS_API_ERROR = 'plecto/team/FETCH_TEAMS_API_ERROR';

export const fetchTeams = () => ({
	type: FETCH,
});

export const fetchTeamsSuccess = (payload) => ({
	type: FETCH_SUCCESS,
	payload,
});

export const fetchTeamsFailure = (error) => ({
	type: FETCH_TEAMS_API_ERROR,
	error
});

export function shouldFetchTeams(state) {
	return !state.teams.isFetching && state.teams.isStale;
}

export function fetchTeamsIfNeeded() {
	return (dispatch, getState) => {
		if (shouldFetchTeams(getState())) {
			return dispatch(fetchTeams());
		}
	};
}
