import _keyBy from 'lodash/keyBy';
import _mapValues from 'lodash/mapValues';

function toMapById(treeNodes) {
	return _keyBy(treeNodes, 'id');
}

function buildChildIdToParentIdMap(allNodes) {
	return _mapValues(toMapById(allNodes), 'parent');
}

function toId(node) {
	return node.id;
}

function wrapParents(childIdToParentIdMap, availableNodesIds) {
	const childToWrappedParentIdMap = Object.assign({}, childIdToParentIdMap);
	Array.from(Object.entries(childToWrappedParentIdMap)).forEach(([child, parent]) => {
		let nextParent = parent;
		while (!!nextParent && !availableNodesIds.has(nextParent)) {
			nextParent = childToWrappedParentIdMap[nextParent];
		}
		childToWrappedParentIdMap[child] = nextParent;
	});
	return childToWrappedParentIdMap;
}

function getAvailableNodes(allNodes, availableNodesIds) {
	return allNodes.filter(node => availableNodesIds.has(node.id));
}

export default function buildForest(allNodes, selectedNodes = [], wrapUnavailableParents) {
	const filteredNodes = allNodes.filter(node => !node.disabled);
	const isFiltered = allNodes.length !== filteredNodes.length;
	const availableNodesIds = new Set(isFiltered ? filteredNodes.map(toId) : allNodes.map(toId));

	const shouldWrapParents = wrapUnavailableParents && isFiltered;

	let childIdToParentIdMap = buildChildIdToParentIdMap(allNodes);
	if (shouldWrapParents) {
		childIdToParentIdMap = wrapParents(childIdToParentIdMap, availableNodesIds);
	}

	let treeNodes = shouldWrapParents ? getAvailableNodes(allNodes, availableNodesIds) : allNodes;
	treeNodes = updateParentAndAvailability(treeNodes, childIdToParentIdMap);

	const treeNodesMap = toMapById(treeNodes);
	const roots = buildTrees(treeNodesMap);
	const initiallyExpanded = expandSelected(treeNodesMap, selectedNodes);

	return { forest: roots, initiallyExpanded };
}

function buildTrees(nodesMap) {
	const forest = [];
	for (const node of Object.values(nodesMap)) {
		if (node.parent) {
			nodesMap[node.parent].children.push(node);
		} else {
			forest.push(node);
		}
	}
	return forest;
}

function updateParentAndAvailability(treeNodes, childIdToParentIdMap) {
	return treeNodes.map(node => (
		{
			...node,
			children: [],
			parent: childIdToParentIdMap[node.id],
		}
	));
}

function expandSelected(treeNodes, selectedNodes) {
	const initiallyExpanded = [];

	for (const selected of selectedNodes) {
		const node = treeNodes[selected];
		let nextParent = node ? node.parent : null;
		while (nextParent && treeNodes[nextParent] && initiallyExpanded.indexOf(nextParent) === -1) {
			initiallyExpanded.push(nextParent);
			nextParent = treeNodes[nextParent].parent;
		}
	}

	return initiallyExpanded;
}
