import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropdown from '../../common/dropdown/Dropdown';

import styled from 'styled-components';
const S = {};

S.Container = styled.div`
	display: flex;
	margin-left: 20px;
`;

S.Label = styled.span`
	color: #666666;
	flex: 0 1 auto;
`;

S.TimeOfDaySelector = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	flex-grow: 1;
	margin-right: 0;

	> * {
		margin-right: 0.5em;
	}

	.react-select-container {
		flex: 2 1 8em;
		max-width: 8em;

		.react-select__control {
			min-width: initial;
		}
	}
`;

function TimeRange({ disabled, label, fromValue, toValue, changeFromValue, changeToValue, fromOptions, toOptions }) {
	return <S.Container  className="time-of-day">
		<S.Label className={classNames('mode-selector-label', { 'text-muted': disabled })}>
			{label}
		</S.Label>
		<S.TimeOfDaySelector className="time-of-day-selector">
			<Dropdown
				disabled={disabled}
				onValueChange={changeFromValue}
				value={fromValue}
				options={fromOptions}
			/>
			<span>-</span>
			<Dropdown
				disabled={disabled}
				onValueChange={changeToValue}
				value={toValue}
				options={toOptions}
			/>
		</S.TimeOfDaySelector>
	</S.Container >;
}

TimeRange.propTypes = {
	allowManualValues: PropTypes.bool,
	changeFromValue: PropTypes.func.isRequired,
	changeToValue: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	fromOptions:
		PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired })),
	fromValue: PropTypes.string,
	label: PropTypes.string.isRequired,
	toOptions:
		PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired })),
	toValue: PropTypes.string,
	validator: PropTypes.func
};

export default memo(TimeRange);
