import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LoaderOverlay } from '../../common/loaders/LoaderOverlay';
import styled from 'styled-components';
const S = {};

S.Preview = styled.div`
	flex: 0 1 auto;
	display: flex;
	align-items: center;
	position: relative;
	min-width: 175px;
	max-width: 175px;
	color: #888;
	font-size: 11px;
`;
function Preview({ isFetching, preview }) {
	return <S.Preview className='time-period-tab-footer-preview'>
		{isFetching && <LoaderOverlay />}
		<div>{preview}</div>
	</S.Preview>;
}

Preview.propTypes = {
	isFetching: PropTypes.bool,
	operationId: PropTypes.string.isRequired,
	state: PropTypes.any
};

const defaultPreviewObject = { };

function mapStateToProps(state, ownProps) {
	const { isFetching } = state.timePeriod[ownProps.operationId] || defaultPreviewObject;

	return {
		isFetching,
	};
}

export default memo(connect(mapStateToProps)(Preview));
