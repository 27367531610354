import { FETCH, FETCH_SUCCESS } from './actions';
import _keyBy from 'lodash/keyBy';

function mapMember(member) {
	return {
		uuid: member.uuid,
		name: member.name,
		email: member.email,
		avatar_url: member.avatar_url,
		url: `/employee/${member.uuid}/`
	};
}

const members = (state = { isFetching: false, membersById: {}, isStale: true, }, action) => {
	switch (action.type) {
	case FETCH:
		return {
			...state,
			isFetching: true,
			isStale: false
		};
	case FETCH_SUCCESS: {
		const members = _keyBy(action.payload.map(mapMember), 'uuid');

		return {
			...state,
			membersById: members,
			isFetching: false,
		};
	}
	default:
		return state;
	}
};

export { members };
