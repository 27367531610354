import { takeLatest } from 'redux-saga/effects';
import {
	ASSIGN_MEMBERS_TO_PERMISSION_PROFILE,
	assignMembersToPermissionProfileFailure,
	assignMembersToPermissionProfileSuccess,
	FETCH,
	fetchPermissionProfilesFailure,
	fetchPermissionProfilesSuccess
} from './actions';
import { getPermissionProfiles, postMembersForPermissionProfiles } from './api';
import apiSagaGenerator from '../../saga/apiSagaGenerator';

const permissionProfilesSagas = [
	takeLatest(FETCH, 
		apiSagaGenerator()
			.forApi(getPermissionProfiles)
			.forSuccess(fetchPermissionProfilesSuccess)
			.forError(fetchPermissionProfilesFailure)
			.withMessage('Error getting permission profiles')
			.generate() 
	),
	takeLatest(ASSIGN_MEMBERS_TO_PERMISSION_PROFILE,
		apiSagaGenerator()
			.forApi(postMembersForPermissionProfiles)
			.withArguments((action) => [action.permissionProfileUuid, action.memberIds])
			.forSuccess(assignMembersToPermissionProfileSuccess)
			.forError(assignMembersToPermissionProfileFailure)
			.withMessage('Error assigning members to permission profile')
			.generate()
	)
];

export default permissionProfilesSagas;
