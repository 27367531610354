import {
	EXPORT,
	EXPORT_SUCCESS,
	EXPORT_API_ERROR,
	EXPORT_STATUS_SUCCESS,
	EXPORT_STATUS_API_ERROR,
	DISMISS_EXPORT,
} from './actions';

import _findIndex from 'lodash/findIndex';
import { exportStatus, isJobOver } from './exportStatus';

const exportJobs = (state = [], action) => {

	switch (action.type) {
	case EXPORT: {
		const { requestId, exportType } = action;
		return [
			...state,
			{
				requesting: true,
				exportType,
				requestId
			}
		];
	}
	case EXPORT_SUCCESS: {
		const { requestId, payload } = action;
		return updateExportJob(state, requestId, {
			requesting: false,
			pending: true,
			exportId: payload.export_id
		});
	}
	case EXPORT_API_ERROR: {
		const { requestId, error } = action;
		return updateExportJob(state, requestId, {
			requesting: false,
			pending: false,
			error
		});
	}

	case EXPORT_STATUS_SUCCESS: {
		const { requestId, payload } = action;
		return updateExportJob(state, requestId, {
			pending: !isJobOver(payload.status),
			fileUrl: payload.status === exportStatus.completed ? payload.file_url : null,
			error: payload.status === exportStatus.failed ? payload.error : null,
		});
	}
	case EXPORT_STATUS_API_ERROR: {
		const { requestId, error } = action;
		return updateExportJob(state, requestId, {
			pending: false,
			error
		});
	}
	case DISMISS_EXPORT: {
		const { requestId } = action;

		const jobIndex = _findIndex(state, { requestId });
		if (jobIndex >= 0) {
			return [
				...state.slice(0,jobIndex),
				...state.slice(jobIndex + 1)
			];
		}
		return state;
	}
	default:
		return state;
	}
};

function updateExportJob(state, requestId, change) {
	const jobIndex = _findIndex(state, { requestId });
	if (jobIndex >= 0) {
		const job = state[jobIndex];
		return [
			...state.slice(0,jobIndex),
			{
				...job,
				...change
			},
			...state.slice(jobIndex + 1)
		];
	}

	return state;
}

export default exportJobs;
