import { takeLatest, takeEvery } from 'redux-saga/effects';
import {
	FETCH,
	fetchFormulasFailure,
	fetchFormulasSuccess,
	RESOLVE,
	resolveFormulaSuccess,
	resolveFormulaFailure,
	FETCH_DIMENSIONS,
	fetchFormulaDimensionsSuccess,
	fetchFormulaDimensionsFailure,
	SAVE_FORMULA,
	saveFormulaSuccess,
	saveFormulaFailure,
	PARSE_FORMULA,
	parseFormulaSuccess,
	parseFormulaFailure,
	previewFormulaSuccess, previewFormulaFailure,
	PREVIEW_FORMULA,
	GET_FORMULA,
	getFormulaSuccess,
	getFormulaFailure,
	LOAD_EDITOR_PARAMETERS,
	loadEditorParametersSuccess,
	loadEditorParametersFailure,
} from './actions';
import {
	getAvailableDimensions,
	getEditorParameters,
	getFormula,
	getFormulas,
	parseFormula,
	previewFormula,
	resolveFormula,
	saveFormula,
} from './api';
import apiSagaGenerator from '../../saga/apiSagaGenerator';
import takeLatestBy from 'redux-saga-take-latest-by';
import errorResultParser from './errorParser/errorResultParser';
import {
	defaultExceptionMessage,
	fetchDimensionsErrorMessage,
	fetchFormulasErrorMessage,
	getFormulaErrorMessage,
	parseErrorMessage,
	previewErrorMessage,
	resolveErrorMessage,
	saveErrorMessage,
} from './messages/errros';

const formulasSagas = [
	takeLatest(
		FETCH,
		apiSagaGenerator()
			.forApi(getFormulas)
			.forSuccess(fetchFormulasSuccess)
			.forError(fetchFormulasFailure)
			.withMessage(fetchFormulasErrorMessage)
			.generate()
	),
	takeEvery(
		RESOLVE,
		apiSagaGenerator()
			.forApi(resolveFormula)
			.withArguments((action) => [action.formulaString])
			.forSuccess(resolveFormulaSuccess)
			.withActionArguments((action) => [action.formulaString, action.formulaId])
			.forError(resolveFormulaFailure)
			.withMessage(resolveErrorMessage)
			.generate()
	),
	takeLatestBy(
		FETCH_DIMENSIONS,
		apiSagaGenerator()
			.forApi(getAvailableDimensions)
			.withArguments((action) => [action.formulaId])
			.forSuccess(fetchFormulaDimensionsSuccess)
			.withActionArguments((action) => [action.formulaId])
			.forError(fetchFormulaDimensionsFailure)
			.withMessage(fetchDimensionsErrorMessage)
			.generate(),
		(a) => a.formulaId
	),
	takeLatestBy(
		SAVE_FORMULA,
		apiSagaGenerator()
			.forApi(saveFormula)
			.cancellable()
			.withArguments((action) => [action.formulaId, action.model])
			.forSuccess(saveFormulaSuccess)
			.withActionArguments((action) => [
				action.formulaId,
				action.model,
				action.onSaveCallback,
			])
			.forError(saveFormulaFailure)
			.includeErrorResult((result) => errorResultParser(result))
			.withMessage(saveErrorMessage)
			.withExceptionMessage(defaultExceptionMessage)
			.generate(),
		(a) => a.formulaId
	),
	takeLatestBy(
		PARSE_FORMULA,
		apiSagaGenerator()
			.forApi(parseFormula)
			.cancellable()
			.withArguments((action) => [action.formulaString])
			.forSuccess(parseFormulaSuccess)
			.withActionArguments((action) => [
				action.formulaString,
				action.formulaId,
				action.doNotShowToast,
			])
			.forError(parseFormulaFailure)
			.includeErrorResult((result) => result.format)
			.withMessage(parseErrorMessage)
			.withExceptionMessage(defaultExceptionMessage)
			.generate(),
		(a) => a.formulaId
	),
	takeLatestBy(
		PREVIEW_FORMULA,
		apiSagaGenerator()
			.forApi(previewFormula)
			.cancellable()
			.withArguments((action) => [
				action.formulaString,
				action.period,
				action.period_amount,
				action.output,
				action.decimals,
			])
			.forSuccess(previewFormulaSuccess)
			.withActionArguments((action) => [
				action.formulaId,
				action.formulaString,
				action.period,
				action.period_amount,
				action.output,
				action.decimals,
			])
			.forError(previewFormulaFailure)
			.withMessage(previewErrorMessage)
			.generate(),
		(a) => a.formulaId
	),
	takeLatest(
		GET_FORMULA,
		apiSagaGenerator()
			.forApi(getFormula)
			.cancellable()
			.withArguments((action) => [action.formulaId])
			.forSuccess(getFormulaSuccess)
			.withActionArguments((action) => [action.formulaId])
			.forError(getFormulaFailure)
			.withMessage(getFormulaErrorMessage)
			.generate()
	),
	takeLatest(
		LOAD_EDITOR_PARAMETERS,
		apiSagaGenerator()
			.forApi(getEditorParameters)
			.forSuccess(loadEditorParametersSuccess)
			.forError(loadEditorParametersFailure)
			.generate()
	),
];

export default formulasSagas;
