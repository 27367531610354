import React, { useState, useCallback, useReducer, useMemo, useRef } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import multiDropdownReducer, {
	createInitialState,
	goNextLevel,
	goBack,
	goBackTo,
	reset,
} from './multiDropdownReducer';
import _compact from 'lodash/compact';
import Menu from './components/Menu';
import { stringifyPath, getOptionPathValue } from './optionsService';
import Breadcrumbs from './components/Breadcrumbs';
import classNames from 'classnames';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import componentMapper from './componentMapper';
import styled from 'styled-components';
import { slideIn, slideInReverse, slideOut, slideOutReverse } from './animations';
import { COLORS_GRAY } from '../../styles/colors';
import { BORDER_RADIUS } from '../../styles/shape';

const ANIMATION_TIMEOUT_IN_MILLIS = 300;

const StyledDropdownContent = styled.div.attrs(props => ({
	boxShadow: props.popupDisabled ? undefined : '0 0 5px rgba(0,0,0,0.2)',
	borderRadius: props.popupDisabled ? undefined : BORDER_RADIUS.xs,
	border: props.popupDisabled ? undefined : `1px solid ${COLORS_GRAY['300']}`,
	fixedWidth: props.fixedWidth
}))`
	border: ${props => props.border};
	border-radius: ${props => props.borderRadius};
	box-shadow: ${props => props.boxShadow};
	background-color: #fff;
	overflow-x: hidden;
	font-size: 12px;
	width: ${props => props.fixedWidth ? '268px' : '100%'};

	.multi-level-dropdown-transition {
		position: relative;
	}

	.slide-enter {
		transform: translateX(100%);
	}

	.slide-enter-active {
		animation: ${slideIn} ${ANIMATION_TIMEOUT_IN_MILLIS} ease-out forwards;
	}

	.slide-exit {
		transform: translateX(0%);
		position: absolute;
		top: 0;
		width: 100%;
	}

	.slide-exit-active {
		animation: ${slideOut} ${ANIMATION_TIMEOUT_IN_MILLIS} ease-out forwards;
	}

	.slide-reverse-enter {
		transform: translateX(-100%);
	}

	.slide-reverse-enter-active {
		animation: ${slideInReverse} ${ANIMATION_TIMEOUT_IN_MILLIS} ease-out forwards;
	}

	.slide-reverse-exit {
		transform: translateX(0);
		position: absolute;
		top: 0;
		width: 100%;
	}

	.slide-reverse-exit-active {
		animation: ${slideOutReverse} ${ANIMATION_TIMEOUT_IN_MILLIS} ease-out forwards;
	}
`;

function MultiLevelDropdownMenu({
	className,
	onClose,
	value,
	options,
	onValueChange,
	onTransition,
	componentOnChange,
	componentValue,
	onBackLabel,
	componentProps,
	popupDisabled,
	fixedWidth,
	fieldRelatioSelectionAllowed
}, ref) {
	const [state, dispatch] =
		useReducer(multiDropdownReducer, { value, options, componentValue }, createInitialState);
	const [animationDirection, setDirection] = useState('slide');

	const nodeRef = useRef(null);

	const onExpand = useCallback(async (value) => {
		await setDirection('slide');
		onTransition?.();
		dispatch(goNextLevel(value));
	}, [onTransition]);

	const onBack = useCallback(async () => {
		await setDirection('slide-reverse');
		onTransition?.();
		dispatch(goBack());
	}, [onTransition]);

	const onBackTo = useCallback(async (level) => {
		await setDirection('slide-reverse');
		onTransition?.();
		dispatch(goBackTo(level));
	}, [onTransition]);

	const pathComponents =
		useMemo(() => _compact(state.path.map(pi => pi.level).concat([state.level])), [state.level, state.path]);
	const currentPath = useMemo(() => stringifyPath(pathComponents), [pathComponents]);

	const onComponentValueChange = useCallback((value) => {
		onClose();
		onValueChange(state.level?.value);
		componentOnChange(value);
	}, [onClose, componentOnChange, onValueChange, state.level]);

	const onSelect = useCallback((value, onSelected) => {
		const fullPathValue = getOptionPathValue(currentPath, value);
		onClose();
		onSelected ? onSelected() : onValueChange(fullPathValue, value);
	},[currentPath, onClose, onValueChange]);

	const onSelectIcon = useCallback((value, onSelectedIcon) => {
		onClose();
		onSelectedIcon();
	},[onClose]);

	useDidUpdateEffect(() => {
		dispatch(reset(value, options, componentValue));
	},[value, options, componentValue]);

	const optionsArray = state.filteredOptions || state.currentOptions;

	return <StyledDropdownContent
		popupDisabled={popupDisabled}
		fixedWidth={fixedWidth}
		ref={ref}
		className={classNames(className, 'multi-level-dropdown-content')}
	>
		<Breadcrumbs
			pathComponents={pathComponents}
			onBackTo={onBackTo}
			onBack={onBack}
			onBackLabel={onBackLabel}
		/>
		<TransitionGroup className="multi-level-dropdown-transition">
			<CSSTransition
				nodeRef={nodeRef}
				key={currentPath}
				classNames={animationDirection}
				timeout={ANIMATION_TIMEOUT_IN_MILLIS}
				onEntered={onTransition}
				onExited={onTransition}>
				{state.level?.component ?
					componentMapper(state.level?.component, onComponentValueChange,
						componentValue, componentProps) :
					<Menu
						nodeRef={nodeRef}
						value={value}
						options={optionsArray}
						currentPath={currentPath}
						onExpand={onExpand}
						onSelect={onSelect}
						onSelectIcon={onSelectIcon}
						fieldRelatioSelectionAllowed={fieldRelatioSelectionAllowed}
					/>}
			</CSSTransition>
		</TransitionGroup>
	</StyledDropdownContent>;
}
export default React.forwardRef(MultiLevelDropdownMenu);
