import { takeEvery, take, put, select, delay } from 'redux-saga/effects';
import {
	FETCH_DRILLDOWNS_RENDER,
	fetchFormulaDrilldownRenderSuccess,
	fetchFormulaDrilldownRenderFailure,
	REQUEST_BACKGROUND_DRILLDOWN_RENDER,
	drilldownBackgroundRenderSuccess,
	drilldownBackgroundRenderFailure,
	requestDrilldownBackgroundRender,
	BACKGROUND_RENDER_STATUS,
	backgroundRenderStatusSuccess,
	backgroundRenderStatusFailure,
	REQUEST_BACKGROUND_DRILLDOWN_RENDER_SUCCESS,
	CHECK_RENDER_STATUS,
	checkRenderStatus,
	backgroundRenderStatus,
	BACKGROUND_RENDER_STATUS_SUCCESS,
	BACKGROUND_RENDER_STATUS_API_ERROR
} from '../actions';
import { drilldownBackgroundRenderRequest, drilldownBackgroundRenderStatus } from '../api';
import apiSagaGenerator from '../../../saga/apiSagaGenerator';
import _get from 'lodash/get';
import takeLatestBy from 'redux-saga-take-latest-by';
import generateUuid from '../../../utils/generateUuid';
import { getRenderJobId } from './drilldownRenderReducer';
import { getAppliedDimension } from '../selectors';
import { renderStatus } from './renderStatus';

const renderLoopSagas = [
	takeLatestBy(
		FETCH_DRILLDOWNS_RENDER,
		initializeBackgroundRender,
		(action) => `${action.reportGroupFormulaId}_page_${action.page}`
	),
	takeEvery(
		REQUEST_BACKGROUND_DRILLDOWN_RENDER,
		apiSagaGenerator()
			.forApi(drilldownBackgroundRenderRequest)
			.withArguments((action) => [
				action.reportGroupFormulaId,
				action.appliedDimension,
				action.pageNumber,
			])
			.forSuccess(drilldownBackgroundRenderSuccess)
			.withActionArguments((action) => [
				action.reportGroupFormulaId,
				action.appliedDimension,
				action.operationId,
				action.pageNumber,
			])
			.forError(drilldownBackgroundRenderFailure)
			.withMessage('Drilldown render request failed')
			.generate()
	),
	takeEvery(
		BACKGROUND_RENDER_STATUS,
		apiSagaGenerator()
			.forApi(drilldownBackgroundRenderStatus)
			.withArguments((action) => [action.reportGroupFormulaId, action.taskId])
			.forSuccess(backgroundRenderStatusSuccess)
			.withActionArguments((action) => [
				action.reportGroupFormulaId,
				action.operationId,
				action.taskId,
			])
			.forError(backgroundRenderStatusFailure)
			.withMessage('Drilldown render request failed')
			.generate()
	),
	takeEvery(REQUEST_BACKGROUND_DRILLDOWN_RENDER_SUCCESS, startRenderCheckLoop),
	takeEvery(CHECK_RENDER_STATUS, checkIfRenderDone),
];

function* initializeBackgroundRender(action) {
	const appliedDimension = yield select(getAppliedDimension, action.reportGroupFormulaId);
	const operationId = generateUuid();
	yield put(
		requestDrilldownBackgroundRender(action.reportGroupFormulaId, appliedDimension, operationId, action.page));
}

function* startRenderCheckLoop(action) {
	const { reportGroupFormulaId, operationId, pageNumber, payload: { task_id: taskId }} = action;
	yield put(checkRenderStatus(reportGroupFormulaId, operationId, pageNumber, taskId));
}

// eslint-disable-next-line no-magic-numbers
const pollingIntervals = [500, 1000, 1000, 1000, 3000, 3000, 5000];

export function* checkIfRenderDone(action) {
	const { reportGroupFormulaId, operationId, taskId, pageNumber, attemptNo } = action;

	const nextTimeout = pollingIntervals[Math.min(attemptNo, pollingIntervals.length - 1)];
	yield delay(nextTimeout);

	const currentOperationId = yield select(state => 
		// eslint-disable-next-line max-len
		_get(state, `reportGroupFormulasById.${reportGroupFormulaId}.renderTasks.${getRenderJobId(pageNumber)}.operationId`));

	if (currentOperationId === operationId) {
		const appliedDimension = yield select(getAppliedDimension, action.reportGroupFormulaId);
		yield put(backgroundRenderStatus(reportGroupFormulaId, operationId, taskId));
		const responseAction = yield take(
			action => (action.type === BACKGROUND_RENDER_STATUS_SUCCESS 
				|| action.type === BACKGROUND_RENDER_STATUS_API_ERROR) && action.taskId === taskId);
		if (responseAction.type === BACKGROUND_RENDER_STATUS_SUCCESS) {

			const { payload: { state, results }} = responseAction;
			if (state === renderStatus.success) {

				yield put(
					fetchFormulaDrilldownRenderSuccess(reportGroupFormulaId, pageNumber, appliedDimension,results));

			} else if (state === renderStatus.failure || state === renderStatus.revoked) {
				yield put(
					fetchFormulaDrilldownRenderFailure(
						reportGroupFormulaId, 
						pageNumber, appliedDimension,'Error fetching report group drilldown render'));
			} else {
				yield put(checkRenderStatus(reportGroupFormulaId, operationId, pageNumber, taskId, attemptNo + 1));
			}
		} else {
			const { errorMessage } = responseAction;

			yield put(fetchFormulaDrilldownRenderFailure(
				reportGroupFormulaId, 
				pageNumber, appliedDimension,errorMessage || 'Error fetching report group drilldown render'));
		}
	}
}

export default renderLoopSagas;
