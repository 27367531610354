import React from 'react';
import classNames from 'classnames';
import { DropdownMenu } from '../dropdownMenuAction/DropdownMenu';

export default function SplitButton({ onClick, className, label, children, size, direction = 'left' }) {
	if (!children) {
		return <button type="button" onClick={onClick} className="btn btn-white">{label}</button>;
	}
	return <div className={classNames('btn-group split-button', className, size ? `btn-group-${size}` : null)}>
		<button type="button" onClick={onClick} className="btn btn-white">{label}</button>
		<DropdownMenu direction={direction}>
			{children}
		</DropdownMenu>

	</div>;
}
