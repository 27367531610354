import {
	FETCH,
	FETCH_SUCCESS,
	FETCH_API_ERROR,
	ADD_API_ERROR,
	ADD_SUCCESS,
	DELETE_API_ERROR,
	DELETE_SUCCESS,
} from './actions';

const initialState = {
	data: [],
	uuids: [],
	isFetching: false,
	error: null,
};

const favorites = (state = initialState, action) => {
	switch (action.type) {
	case FETCH:
		return {
			...state,
			isFetching: true,
		};
	case FETCH_SUCCESS:
		return {
			...state,
			data: action.result,
			uuids: action.uuids,
			isFetching: false,
			error: null,
		};
	case FETCH_API_ERROR:
		return {
			...state,
			isFetching: false,
			error: action.error,
		};

	case ADD_SUCCESS:
		return {
			...state,
			data: [...state.data, action.result],
			uuids: [...state.uuids, action.result.dashboard_uuid],
			error: null,
		};
	case ADD_API_ERROR:
		return {
			...state,
			error: action.error,
		};

	case DELETE_SUCCESS:
		return {
			...state,
			data: state.data.filter(item => item.uuid !== action.uuid),
			uuids: state.uuids.filter(item => item !== action.dashboardUuid),
			error: null,
		};
	case DELETE_API_ERROR:
		return {
			...state,
			error: action.error,
		};
	default:
		return state;
	}
};

export default favorites;
