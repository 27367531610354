import { actionPrefix } from '../constants';

export const CREATE_QUIZ = `${actionPrefix}CREATE_QUIZ`;
export const CREATE_QUIZ_SUCCESS = `${actionPrefix}CREATE_QUIZ_SUCCESS`;
export const CREATE_QUIZ_FAILURE = `${actionPrefix}CREATE_QUIZ_FAILURE`;

export const FETCH_QUIZ = `${actionPrefix}FETCH_QUIZ`;
export const FETCH_QUIZ_SUCCESS = `${actionPrefix}FETCH_QUIZ_SUCCESS`;
export const FETCH_QUIZ_FAILURE = `${actionPrefix}FETCH_QUIZ_FAILURE`;

export const UPDATE_QUIZ = `${actionPrefix}UPDATE_QUIZ`;
export const UPDATE_QUIZ_SUCCESS = `${actionPrefix}UPDATE_QUIZ_SUCCESS`;
export const UPDATE_QUIZ_FAILURE = `${actionPrefix}UPDATE_QUIZ_FAILURE`;

export const createQuiz = (quiz) => ({
	type: CREATE_QUIZ,
	quiz
});

export const createQuizSuccess = (quiz, payload) => ({
	type: CREATE_QUIZ_SUCCESS,
	payload
});

export const createQuizFailure = (payload, error, errors) => ({
	type: CREATE_QUIZ_FAILURE,
	error,
	errors
});

export const fetchQuiz = (quizId) => ({
	type: FETCH_QUIZ,
	quizId
});

export const fetchQuizSuccess = (id, payload) => ({
	type: FETCH_QUIZ_SUCCESS,
	payload
});

export const fetchQuizFailure = () => ({
	type: FETCH_QUIZ_FAILURE
});

export const updateQuiz = (quiz) => ({
	type: UPDATE_QUIZ,
	quiz
});

export const updateQuizSuccess = (quiz, payload) => ({
	type: UPDATE_QUIZ_SUCCESS,
	payload
});

export const updateQuizFailure = (payload, error, errors) => ({
	type: UPDATE_QUIZ_FAILURE,
	error,
	errors
});
