import React, { useMemo } from 'react';
import { getWeekDaysOptions } from '../logic/constants';
import _clamp from 'lodash/clamp';
import _findIndex from 'lodash/findIndex';
import Dropdown from '../../common/dropdown/Dropdown';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DAYS_IN_A_WEEK } from '../constants';

const S = {};

S.Container = styled.div`
	display: flex;
`;

S.Label = styled.span`
	color: #666666;
	flex: 0 1 auto;
`;

S.Dropdown = styled(Dropdown)`
	flex: 0 1 8em;
`;

export default function WeekStartPoint({ startingOnDay = 1, actions }) {
	const weekDaysOptions = useMemo(() => getWeekDaysOptions(), []);

	const onWeekDayChange = (value) => {
		const index = _findIndex(weekDaysOptions, { value });
		actions.changeWeekStartPoint(_clamp(index + 1, 1, DAYS_IN_A_WEEK));
	};
	const weekDayIndex = _clamp(startingOnDay - 1, 0, DAYS_IN_A_WEEK - 1);
	const { t } = useTranslation();
	return (
		<S.Container>
			<S.Label className={'mode-selector-label'}>
				{t('Week starts on')}
			</S.Label>
			<S.Dropdown
				onValueChange={onWeekDayChange}
				value={weekDaysOptions[weekDayIndex].value}
				options={weekDaysOptions}
			/>
		</S.Container>
	);
}
