import { takeLatest } from 'redux-saga/effects';
import {
	FETCH, fetchHueDevicesFailure, fetchHueDevicesSuccess
} from './actions';
import { getLights } from './api';
import apiSagaGenerator from '../../saga/apiSagaGenerator';

const hueDevicesSagas = [
	takeLatest(FETCH,
		apiSagaGenerator()
			.forApi(getLights)
			.withArguments(a => [a.credentialsId])
			.forSuccess(fetchHueDevicesSuccess)
			.forError(fetchHueDevicesFailure)
			.withMessage('Error getting Hue lights')
			.generate()
	),
];

export default hueDevicesSagas;
