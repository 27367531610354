export const FETCH_MEMBERS_V3 = 'plecto/v3/member/FETCH_MEMBERS';
export const FETCH_MEMBERS_V3_SUCCESS = 'plecto/v3/member/FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_V3_ERROR = 'plecto/v3/member/FETCH_MEMBERS_ERROR';
export const FETCH_MEMBERS_V3_BY_PERMISSION = 'plecto/v3/member/FETCH_BY_PERMISSION';
export const FETCH_MEMBERS_V3_BY_PERMISSION_SUCCESS = 'plecto/v3/member/FETCH_BY_PERMISSION_SUCCESS';
export const CLEAR_MEMBERS = 'plecto/v3/member/CLEAR_MEMBERS';
export const FETCH_MEMBERS_V3_COMPACT = 'plecto/v3/member/FETCH_MEMBERS_V3_COMPACT';
export const FETCH_MEMBERS_V3_COMPACT_SUCCESS = 'plecto/v3/member/FETCH_MEMBERS_V3_COMPACT_SUCCESS';

export const fetchMembers = (
	loginAccess,
	excludeSelf,
	searchValue,
	permissionProfiles,
	teams,
	dynamicDashboardId,
	pageNumber,
	pageSize,
	managed,
// eslint-disable-next-line max-params
) => ({
	type: FETCH_MEMBERS_V3,
	loginAccess,
	excludeSelf,
	searchValue,
	permissionProfiles,
	teams,
	pageNumber,
	pageSize,
	dynamicDashboardId,
	managed,
});

export const fetchMembersSuccess = (pageNumber, payload) => ({
	type: FETCH_MEMBERS_V3_SUCCESS,
	pageNumber,
	payload,
});

export const fetchMembersFailure = (pageNumber, error) => ({
	type: FETCH_MEMBERS_V3_ERROR,
	pageNumber,
	error
});

export const fetchMembersByPermissionProfile = (permissionProfile) => ({
	type: FETCH_MEMBERS_V3_BY_PERMISSION,
	permissionProfile
});

export const fetchMembersByPermissionProfileSuccess = (payload) => ({
	type: FETCH_MEMBERS_V3_BY_PERMISSION_SUCCESS,
	payload,
});

export const clearMembers = () => ({
	type: CLEAR_MEMBERS
});

export const loadAllCompactMembers = () => ({
	type: FETCH_MEMBERS_V3_COMPACT,
	loadAll: true,
});

export const fetchMembersCompact = (
	loginAccess,
	excludeSelf,
	searchValue,
	permissionProfiles,
	teams,
	dynamicDashboardId,
	managed,
// eslint-disable-next-line max-params
) => ({
	type: FETCH_MEMBERS_V3_COMPACT,
	loginAccess,
	excludeSelf,
	searchValue,
	permissionProfiles,
	teams,
	dynamicDashboardId,
	managed,
});

export const fetchMembersCompactSuccess = (loadAll, payload) => ({
	type: FETCH_MEMBERS_V3_COMPACT_SUCCESS,
	payload,
	loadAll
});

export function shouldFetchMembers(state) {
	return !state.membersV3.isFetching && state.membersV3.isStale;
}

export function shouldFetchMembersOnScroll(state, action) {
	const { next, pageNumber, isFetching } = state.membersV3;
	return !isFetching && !!next && (action.pageNumber !== pageNumber);
}

const initParams = {
	searchValue: '',
	teams: [],
	permissionProfiles: [],
	loginAccess: false,
	excludeSelf: false,
	managed: false,
};

export function fetchMembersIfNeeded(parameters) {
	const {
		searchValue,
		permissionProfiles,
		teams,
		pageNumber,
		pageSize,
		loginAccess,
		excludeSelf,
		dynamicDashboardId,
		managed,
	} = parameters || initParams;

	return (dispatch, getState) => {
		if (shouldFetchMembers(getState())) {
			return dispatch(fetchMembers(
				loginAccess,
				excludeSelf,
				searchValue,
				permissionProfiles,
				teams,
				dynamicDashboardId,
				pageNumber,
				pageSize,
				managed,
			));
		}
	};
}

export function fetchMembersOnScrollIfNeeded(payload) {
	const {
		pageNumber,
		pageSize,
		teams,
		searchValue,
		permissionProfiles,
		loginAccess,
		excludeSelf,
		dynamicDashboardId,
		managed,
	} = payload || initParams;

	return (dispatch, getState) => {
		if (shouldFetchMembersOnScroll(getState(), payload)) {
			return dispatch(fetchMembers(
				loginAccess,
				excludeSelf,
				searchValue,
				permissionProfiles,
				teams,
				dynamicDashboardId,
				pageNumber,
				pageSize,
				managed,
			));
		}
	};
}

