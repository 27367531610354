import { apiGet, apiPut, apiDelete } from '../../fetch/apiService';

export function getReport(reportId) {
	return apiGet(`reports/${reportId}`);
}

export function getAllReports() {
	return apiGet('reports');
}

export function updateReport(reportId, reportSettings) {
	return apiPut(`reports/${reportId}`, reportSettings);
}

export function reorderGroups(reportId, orderedReportGroupIds) {
	return apiPut(`reports/${reportId}/reorder`, orderedReportGroupIds);
}

export function archive(reportId) {
	return apiPut(`reports/${reportId}/archive`);
}

export function restore(reportId) {
	return apiPut(`reports/${reportId}/restore`);
}

export function deleteReport(reportId) {
	return apiDelete(`reports/${reportId}`);
}
