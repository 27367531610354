import {
	CANCEL_SEARCH,
	LOAD_FOLDERS,
	LOAD_FOLDERS_SUCCESS,
	LOAD_ITEMS,
	LOAD_ITEMS_SUCCESS,
	SEARCH,
	SEARCH_SUCCESS
} from './actions';

export default function chooseReducerToRunAction(actionType) {
	switch (actionType) {

	case SEARCH:
	case LOAD_FOLDERS:
	case LOAD_ITEMS:
	case LOAD_FOLDERS_SUCCESS:
	case LOAD_ITEMS_SUCCESS:
	case SEARCH_SUCCESS:
	case CANCEL_SEARCH:
		return { runOnCurrent: true };

	default:
		return { runOnPrimary: true };
	}
}
