export default function mergeRefs(...refs) {
	const filteredRefs = refs.filter(Boolean);
	if (!filteredRefs.length) return null;
	const mergedRef = inst => {
		for (const ref of filteredRefs) {
			if (typeof ref === 'function') {
				ref(inst);
			} else if (ref) {
				ref.current = inst;
			}
		}
		mergedRef.current = inst;
	};
	return mergedRef;
}
