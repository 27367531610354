import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { FilterItem } from './types';
import { StyledFilterItem, StyledInput } from './StyledFilterItem';
import { InteractiveTooltip } from '../tooltip/InteractiveTooltip';
import { useTranslation } from 'react-i18next';

const StyledFilterItemContainer = styled.div`
  padding: 8px 12px;
  flex: 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

const StyledFilterMenuItemLabel = styled.div`
  flex: 1;
`;

const StyledAvatar = styled.div<{
    image: string;
}>`
  background-size: cover;
  background-position: center;
  background-image: ${props => `url(${props.image})`};
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;

type FilterDropdownItemProps = {
    selected: boolean;
    filterItem: FilterItem;
    onToggle: (id: string) => unknown;
};

export function FilterDropdownItem({
	selected,
	filterItem,
	onToggle,
	children,
}: PropsWithChildren<FilterDropdownItemProps>) {
	const { t } = useTranslation();
	const onChange = () => {
		if (!filterItem.disabled)
			onToggle(filterItem.id);
	};

	return <InteractiveTooltip message={t('This option is disabled')}>
		{({ triggerRef }) => (
			<StyledFilterItem
				$disabled={filterItem.disabled}
				onClick={onChange}
				ref={filterItem.disabled ? triggerRef : null}
			>
				<StyledFilterItemContainer>
					<StyledInput type="checkbox"
						onChange={onChange}
						checked={selected}
					/>
					{filterItem.imageUrl ? <StyledAvatar image={filterItem.imageUrl} /> : null}
					<StyledFilterMenuItemLabel>{filterItem.label}</StyledFilterMenuItemLabel>
				</StyledFilterItemContainer>
				{children}
			</StyledFilterItem>
		)}
	</InteractiveTooltip>;
}
