import {
	CHANGE_START_MODE,
	CHANGE_START_PERIOD,
	CHANGE_START_AMOUNT,
	TOGGLE_END_DATE,
	TIME_PERIOD_REINIT
} from './actions';
import { periods, relativeModes } from './constants';

export default function visibilityReducer(state, action) {
	switch (action.type) {
	case CHANGE_START_MODE:
	case CHANGE_START_PERIOD:
	case CHANGE_START_AMOUNT:
	case TOGGLE_END_DATE: 
	case TIME_PERIOD_REINIT:
		return {
			...state,
			visibility: getVisibilityOptions(state)
		};
	}

	return state;
}

function getVisibilityOptions(state) {
	const customEndDateVisible = state.relativeStartDate.mode !== relativeModes.next;
	const timeRangeVisible = (!customEndDateVisible || !state.relativeEndDate.enabled) &&
		state.relativeStartDate.amount === 1 &&
		(state.relativeStartDate.period === periods.day || state.relativeStartDate.period === periods.week);
	return {
		timeRangeVisible,
		timeRangeDay: timeRangeVisible && state.relativeStartDate.period === periods.day,
		timeRangeWeek: timeRangeVisible && state.relativeStartDate.period === periods.week,
		customEndDateVisible
	};
}
