import { put, takeLatest, select } from 'redux-saga/effects';
import { createReportSchedule, deleteReportSchedule, getReportSchedule, updateReportSchedule } from './api';
import {
	ADD,
	createReportScheduleFailure,
	createReportScheduleSuccess,
	DELETE,
	deleteReportScheduleFailure,
	deleteReportScheduleSuccess,
	FETCH,
	fetchReportSchedulesFailure,
	fetchReportSchedulesSuccess,
	UPDATE,
	saveReportScheduleFailure,
	saveReportScheduleSuccess,
	SAVE,
	deleteReportScheduleRequest,
	updateReportScheduleRequest,
	createReportScheduleRequest
} from './actions';
import { scheduleIdSelector } from './selectors';
import apiSagaGenerator from '../../saga/apiSagaGenerator';

const reportSchedulesSagas = [
	takeLatest(FETCH, apiSagaGenerator()
		.forApi(getReportSchedule)
		.withArguments((action) => [action.reportId])
		.forSuccess(fetchReportSchedulesSuccess)
		.forError(fetchReportSchedulesFailure)
		.withMessage('Error getting report schedule')
		.generate() 
	),
	takeLatest(ADD, 
		apiSagaGenerator()
			.forApi(createReportSchedule)
			.withArguments((action) => [action.reportId, action.schedule])
			.forSuccess(createReportScheduleSuccess)
			.withActionArguments((action) =>  [action.reportId])
			.forError(createReportScheduleFailure)
			.includeErrorResult()
			.withMessage('Error creating report schedule')
			.generate() 
	),
	takeLatest(UPDATE, 
		apiSagaGenerator()
			.forApi(updateReportSchedule)
			.withArguments((action) => [action.reportId, action.scheduleId, action.schedule])
			.forSuccess(saveReportScheduleSuccess)
			.withActionArguments((action) =>  [action.reportId, action.scheduleId])
			.forError(saveReportScheduleFailure)
			.includeErrorResult()
			.withMessage('Error saving report schedule')
			.generate() 
	),
	takeLatest(DELETE, 
		apiSagaGenerator()
			.forApi(deleteReportSchedule)
			.withArguments((action) => [action.reportId, action.scheduleId])
			.forSuccess(deleteReportScheduleSuccess)
			.skipResult()
			.forError(deleteReportScheduleFailure)
			.withMessage('Error deleting report schedule')
			.generate() 
	),
	takeLatest(SAVE, saveSchedule),
];

function* saveSchedule(action) {
	const schedule = action.schedule;
	const scheduleId = yield select(scheduleIdSelector, action.reportId);

	if (scheduleId) {
		if (schedule.interval === '') {
			yield put(deleteReportScheduleRequest(action.reportId, scheduleId));
		} else {
			yield put(updateReportScheduleRequest(action.reportId, scheduleId, schedule));
		}
	} else {
		if (schedule.interval !== '') {
			yield put(createReportScheduleRequest(action.reportId, schedule));
		}
	}
}

export default reportSchedulesSagas;
