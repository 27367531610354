export const FETCH = 'plecto/permissionProfiles/FETCH';
export const FETCH_SUCCESS = 'plecto/permissionProfiles/FETCH_SUCCESS';
export const FETCH_PERMISSION_PROFILES_API_ERROR = 'plecto/permissionProfiles/FETCH_PERMISSION_PROFILES_API_ERROR';
export const ASSIGN_MEMBERS_TO_PERMISSION_PROFILE = 'plecto/permissionProfiles/ASSIGN_MEMBERS_TO_PERMISSION_PROFILE';
export const ASSIGN_MEMBERS_TO_PERMISSION_PROFILE_SUCCESS =
	'plecto/permissionProfiles/ASSIGN_MEMBERS_TO_PERMISSION_PROFILE_SUCCESS';
export const ASSIGN_MEMBERS_TO_PERMISSION_PROFILE_API_ERROR =
	'plecto/permissionProfiles/ASSIGN_MEMBERS_TO_PERMISSION_PROFILE_API_ERROR';

export const fetchPermissionProfiles = () => ({
	type: FETCH,
});

export const fetchPermissionProfilesSuccess = (payload) => ({
	type: FETCH_SUCCESS,
	payload,
});

export const fetchPermissionProfilesFailure = (error) => ({
	type: FETCH_PERMISSION_PROFILES_API_ERROR,
	error
});

export const assignMembersToPermissionProfile = (permissionProfileUuid, memberIds) => ({
	type: ASSIGN_MEMBERS_TO_PERMISSION_PROFILE,
	permissionProfileUuid,
	memberIds
});

export const assignMembersToPermissionProfileSuccess = () => ({
	type: ASSIGN_MEMBERS_TO_PERMISSION_PROFILE_SUCCESS
});

export const assignMembersToPermissionProfileFailure = (error) => ({
	type: ASSIGN_MEMBERS_TO_PERMISSION_PROFILE_API_ERROR,
	error
});

export function shouldFetchPermissionProfiles(state) {
	return !state.permissionProfiles.isFetching && state.permissionProfiles.isStale;
}

export function fetchPermissionProfilesIfNeeded() {
	return (dispatch, getState) => {
		if (shouldFetchPermissionProfiles(getState())) {
			return dispatch(fetchPermissionProfiles());
		}
	};
}
