/*
 * The standard page header containing main-breadcrumbs and main-header.
 * Breadcrumbs are passed in as raw HTML. Actions can be passed in as children or as HTML.
 *
 * Example:
 *
 * <PageHeader title="My object">
 *     <button onClick={this.handleMyAction}>My action</button>
 * </PageHeader>
 */

import React, { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledLabel = styled.span`
	margin-left: 5px;
`;

type Props = {
	title: string,
	breadcrumbsHtml: string,
	actionsLeftHtml?: string,
	actionsRightHtml?: string,
	forTour?: boolean,
	alertMessage?: string,
	resetError?: () => void,
	archived?: boolean,
	componentBeforeActions: React.ReactNode,
};

export function PageHeader({
	forTour,
	title,
	alertMessage,
	resetError,
	archived,
	actionsLeftHtml,
	actionsRightHtml,
	breadcrumbsHtml,
	componentBeforeActions,
	children
}: PropsWithChildren<Props>) {

	const [showActions, setShowActions] = useState(false);
	const { t } = useTranslation();

	const handleActionsToggle = () => {
		setShowActions(!showActions);
	};

	return <>
		<div key="breadcrumbs" className="main-breadcrumbs" dangerouslySetInnerHTML={{ __html: breadcrumbsHtml }}></div>
		<div key="header" className="main-header">
			<div className="main-header-content">
				<h1 className="main-header-title">
					{title}{archived && <StyledLabel className="label label-warning">{t('Archived')}</StyledLabel>}
					{forTour && <StyledLabel className="label info">{t('Sample')}</StyledLabel>}
				</h1>
				<div className="main-header-actions main-header-actions-enable-toggle">
					<a className="main-header-actions-toggle btn btn-white btn-sm" onClick={handleActionsToggle}>
						{t('Actions')}<span className="caret"></span>
					</a>
					<div className="main-header-actions-container" style={{ display: showActions ? 'block' : 'none' }}>
						<div className="main-header-actions-group">
							{componentBeforeActions}
							{ actionsLeftHtml ?
								<span dangerouslySetInnerHTML={{ __html: actionsLeftHtml }}></span>
								: null
							}
							{children}
							{ actionsRightHtml ?
								<span dangerouslySetInnerHTML={{ __html: actionsRightHtml }}></span>
								: null
							}
						</div>
					</div>
				</div>
			</div>
		</div>
		{alertMessage ? <div className="main-messages">
			<div className="alert alert-danger" onClick={resetError}>
				<span className="alert-icon icon icon-warning"></span>
				<div className="alert-text">{alertMessage}</div>
			</div>
		</div> : null}
	</>;
}
