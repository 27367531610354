import {
	BULK_DELETE_ITEMS_SUCCESS,
	CANCEL_SEARCH,
	PREPARE_MOVE_SIDE_STATE,
	CREATE_FOLDER,
	CREATE_FOLDER_API_ERROR,
	CREATE_FOLDER_SUCCESS,
	DELETE_FOLDER_SUCCESS,
	FETCH_ITEMS_BY_FOLDERS,
	FETCH_ITEMS_BY_FOLDERS_SUCCESS,
	LOAD,
	LOAD_FOLDERS,
	LOAD_FOLDERS_SUCCESS,
	LOAD_ITEMS,
	LOAD_ITEMS_SUCCESS,
	MOVE_ITEMS_COMPLETED,
	RENAME_FOLDER,
	SEARCH,
	SEARCH_SUCCESS,
} from './actions';
import _isFunction from 'lodash/isFunction';
import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';
import _reject from 'lodash/reject';
import _difference from 'lodash/difference';
import { itemTypes } from './itemTypes';
import sortedUniqItems from './sortedUniqItems';
import _compact from 'lodash/compact';
import chooseReducerToRunAction from './actionDispatcher';
import _cloneDeep from 'lodash/cloneDeep';
import { moveSideState } from './sideState';
import { ROOT_ID } from './constants';
import sortWithCollation from './sortWithColation';
import _isArray from 'lodash/isArray';

const defaultState = () => ({
	foldersById: {
		[ROOT_ID]: {
			itemsCount: -1,
			subfoldersLoaded: false,
			loadFoldersOnInit: true,
			itemsLoaded: false,
			subfolders: [],
			items: [],
		},
	},
	itemsById: {},
});

const directory = (state = {}, action) => {
	if (action.directoryId) {
		const { stateId, directoryType } = action.directoryId;
		const { runOnPrimary, runOnCurrent } = chooseReducerToRunAction(
			action.type
		);

		if (stateId !== directoryType) {
			let currentState = state;
			if (runOnPrimary) {
				currentState = directoryTypeReducer(
					currentState,
					action,
					directoryType
				);
			}

			if (runOnCurrent) {
				currentState = directoryTypeReducer(currentState, action, stateId);
			}

			return currentState;
		} else {
			return directoryTypeReducer(state, action, directoryType);
		}
	}

	return state;
};

function directoryTypeReducer(state, action, stateId) {
	switch (action.type) {
	case LOAD: {
		const { folderUuid, shouldReloadItemsOnly } = action;

		if (shouldReloadItemsOnly) {
			const uuid = folderUuid || ROOT_ID;
			return {
				...state,
				[stateId]: {
					...state[stateId],
					foldersById: {
						...state[stateId].foldersById,
						[uuid]: {
							...state[stateId].foldersById[uuid],
							shouldReloadItemsOnly: true,
						},
					},
				},
			};
		}

		if (!folderUuid) {
			return {
				...state,
				[stateId]: defaultState(),
			};
		}

		return state;
	}
	case LOAD_FOLDERS: {
		const { folderUuid } = action;

		return updateFolderInDirectory(state, stateId, folderUuid, {
			loadingSubfolders: true,
		});
	}
	case LOAD_ITEMS: {
		const { folderUuid, page } = action;

		return updateFolderInDirectory(
			state,
			stateId,
			folderUuid,
			page > 1
				? {
					loadingNextPage: true,
				}
				: {
					loadingItems: true,
				}
		);
	}
	case FETCH_ITEMS_BY_FOLDERS:
		return updateDirectoryTypeState(state, stateId, (currentState) => {
			return {
				itemsByFolders: {
					...currentState.itemsByFolders,
					isFetching: true,
				},
			};
		});
	case LOAD_FOLDERS_SUCCESS: {
		const { folderUuid = ROOT_ID, payload } = action;

		const folderList = folderUuid === ROOT_ID ? payload : payload.subfolders;

		const subfolders = folderList.map((folder) =>
			mapFolder(folder, folderUuid)
		);

		const change = {
			loadingSubfolders: false,
			subfoldersLoaded: true,
			subfolders: sortWithCollation(subfolders, [(item) => item.name]).map(
				(s) => s.uuid
			),
			descendantsDepth: undefined,
			shouldReloadItemsOnly: false,
		};

		let updatedState = state;

		const parent = state[stateId].foldersById[folderUuid];
		if (parent) {
			updatedState = updateFolderInDirectory(
				state,
				stateId,
				folderUuid,
				change
			);
		} else {
			updatedState = updateDirectoryTypeState(
				state,
				stateId,
				(currentState) => {
					return {
						foldersById: {
							...currentState.foldersById,
							[folderUuid]: {
								...mapFolder(payload, payload.parent),
								...change,
							},
						},
					};
				}
			);
		}

		const subfoldersById = _keyBy(subfolders, 'uuid');

		return updateDirectoryTypeState(updatedState, stateId, (currentState) => {
			return {
				foldersById: {
					...currentState.foldersById,
					...subfoldersById,
				},
			};
		});
	}
	case LOAD_ITEMS_SUCCESS: {
		const { folderUuid = ROOT_ID, page, pageSize } = action;

		const items = action.payload.results.map((item) =>
			mapItem(item, folderUuid)
		);

		const itemsById = _keyBy(items, 'uuid');

		const changeFunc = (folder, directoryState) => {
			const currentItems = (folder.items || []).map(
				(itemId) => directoryState.itemsById[itemId]
			);
			return {
				loadingItems: false,
				loadingNextPage: false,
				itemsLoaded: true,
				items: sortedUniqItems([...currentItems, ...items], pageSize > 1),
				page,
				pageSize,
				hasNextPage: Boolean(action.payload.next),
			};
		};

		const parentUpdated = updateFolderInDirectory(
			state,
			stateId,
			folderUuid,
			changeFunc
		);

		return updateDirectoryTypeState(
			parentUpdated,
			stateId,
			(currentState) => {
				return {
					itemsById: {
						...currentState.itemsById,
						...itemsById,
					},
				};
			}
		);
	}
	case FETCH_ITEMS_BY_FOLDERS_SUCCESS: {
		return updateDirectoryTypeState(state, stateId, (currentState) => {
			return {
				itemsByFolders: {
					...currentState.itemsByFolders,
					isFetching: false,
					items: action.payload,
				},
			};
		});
	}
	case CREATE_FOLDER: {
		const { folder } = action;
		const { items } = folder;

		// TODO should we block parent?

		return blockItems(state, stateId, items);
	}
	case DELETE_FOLDER_SUCCESS: {
		const { folderUuid } = action;

		// TODO delete contents

		const folder = state[stateId].foldersById[folderUuid];
		if (folder) {
			const parentUpdated = updateFolderInDirectory(
				state,
				stateId,
				folder.parent,
				(parentState) => ({
					subfolders: _reject(
						parentState.subfolders,
						(id) => id === folderUuid
					),
				})
			);

			return updateDirectoryTypeState(
				parentUpdated,
				stateId,
				(currentState) => {
					return {
						foldersById: _omit(currentState.foldersById || {}, [folderUuid]),
					};
				}
			);
		}

		return state;
	}

	case BULK_DELETE_ITEMS_SUCCESS: {
		const { items, folders } = action;

		const itemsIds = items.map((i) => i.uuid);
		const foldersIds = folders.map((i) => i.uuid);

		let parentUpdated = state;

		foldersIds.forEach((folderUuid) => {
			const folder = parentUpdated[stateId].foldersById[folderUuid];
			if (folder) {
				parentUpdated = updateFolderInDirectory(
					parentUpdated,
					stateId,
					folder.parent,
					(parentState) => ({
						subfoldersCount: parentState.subfoldersCount - 1,
						subfolders: _reject(
							parentState.subfolders,
							(id) => id === folder.uuid
						),
					})
				);
			}
		});

		itemsIds.forEach((itemUuid) => {
			const item = parentUpdated[stateId].itemsById[itemUuid];
			if (item) {
				parentUpdated = updateFolderInDirectory(
					parentUpdated,
					stateId,
					item.parent,
					(parentState) => ({
						itemsCount: parentState.itemsCount - 1,
						items: _reject(parentState.items, (id) => id === item.uuid),
					})
				);
			}
		});

		return updateDirectoryTypeState(
			parentUpdated,
			stateId,
			(currentState) => {
				return {
					foldersById: _omit(currentState.foldersById || {}, foldersIds),
					itemsById: _omit(currentState.itemsById || {}, itemsIds),
				};
			}
		);
	}
	case CREATE_FOLDER_SUCCESS: {
		const { payload, folder } = action;
		const { uuid, name } = payload;
		const { parent = ROOT_ID, items, subfolders } = folder;

		const itemsIds = items.map((i) => i.uuid);
		const subfoldersIds = subfolders.map((i) => i.uuid);

		const folderContents = {
			uuid: uuid,
			name: name,
			itemType: itemTypes.folder,
			subfoldersLoaded: true,
			subfolders: subfoldersIds,
			itemsLoaded: true,
			parent: parent,
			items: itemsIds,
			itemsCount: itemsIds.length,
		};

		const parentUpdated = updateFolderInDirectory(
			state,
			stateId,
			parent,
			(parentState, directoryState) => {
				const currentItems = parentState.subfolders.map(
					(itemId) => directoryState.foldersById[itemId]
				);

				return {
					subfolders: sortWithCollation(
						[...currentItems, folderContents],
						[(item) => item.name]
					).map((s) => s.uuid),
				};
			}
		);

		const subfolderAdded = updateDirectoryTypeState(
			parentUpdated,
			stateId,
			(currentState) => {
				return {
					foldersById: {
						...currentState.foldersById,
						[uuid]: folderContents,
					},
				};
			}
		);

		return updateParents(
			subfolderAdded,
			stateId,
			itemsIds,
			subfoldersIds,
			uuid
		);
	}
	case MOVE_ITEMS_COMPLETED: {
		const { folder, items = [], folders = [] } = action;

		const folderUuid = folder ? folder.uuid : ROOT_ID;

		const itemsIds = items.map((i) => i.uuid);
		const foldersIds = folders.map((i) => i.uuid);

		const parentUpdated = updateFolderInDirectory(
			state,
			stateId,
			folderUuid,
			(parentState, directoryState) => {
				const nextItems = _compact(
					[...parentState.items, ...itemsIds].map(
						(itemId) => directoryState.itemsById[itemId]
					)
				);

				const nextFolders = _compact(
					[...parentState.subfolders, ...foldersIds].map(
						(itemId) => directoryState.foldersById[itemId]
					)
				);

				return {
					items: parentState.itemsLoaded
						? sortWithCollation(nextItems, [(item) => item.name]).map(
							(it) => it.uuid
						)
						: parentState.items,
					itemsCount: parentState.itemsLoaded
						? nextItems.length
						: parentState.itemsCount + itemsIds.length,
					subfolders: parentState.subfoldersLoaded
						? sortWithCollation(nextFolders, [(item) => item.name]).map(
							(it) => it.uuid
						)
						: [...parentState.subfolders, ...foldersIds],
				};
			}
		);

		return updateParents(
			parentUpdated,
			stateId,
			itemsIds,
			foldersIds,
			folderUuid
		);
	}
	case CREATE_FOLDER_API_ERROR: {
		const { folder } = action;
		const { items } = folder;

		return unblockItems(state, stateId, items);
	}
	case RENAME_FOLDER: {
		const { folderUuid, name } = action;
		const folderUpdated = updateFolderInDirectory(
			state,
			stateId,
			folderUuid,
			{
				name,
			}
		);

		const folder = state[stateId].foldersById[folderUuid];

		if (folder) {
			return updateFolderInDirectory(
				folderUpdated,
				stateId,
				folder.parent,
				(parentState, directoryState) => {
					const nextFolders = parentState.subfolders.map(
						(itemId) => directoryState.foldersById[itemId]
					);

					return {
						subfolders: sortWithCollation(nextFolders, [
							(item) => item.name,
						]).map((it) => it.uuid),
					};
				}
			);
		}

		return folderUpdated;
	}
	case SEARCH: {
		const { query } = action;
		return updateDirectoryTypeState(state, stateId, (currentState) =>
			query === currentState.search?.query
				? null
				: {
					...defaultState(),
					search: {
						query: query,
						loading: true,
					},
				}
		);
	}
	case SEARCH_SUCCESS: {
		const { payload, query } = action;

		return updateDirectoryTypeState(state, stateId, (currentState) => {
			if (currentState.search?.query === query) {
				return unwrapSearchResults(currentState, payload);
			}
			return {};
		});
	}
	case CANCEL_SEARCH: {
		if (state[stateId].search) {
			return _omit(state, stateId);
		}
		return state;
	}
	case PREPARE_MOVE_SIDE_STATE: {
		return {
			...state,
			[moveSideState(stateId)]: _cloneDeep(state[stateId]),
		};
	}
	default:
		return state;
	}
}

function updateDirectoryTypeState(state, stateId, change) {
	const currentState = state[stateId] || defaultState();
	return {
		...state,
		[stateId]: {
			...currentState,
			...((_isFunction(change) ? change(currentState) : change) || {}),
		},
	};
}

function updateItemInDirectory(state, stateId, itemId, change) {
	return updateDirectoryTypeState(state, stateId, (currentState) => {
		const item = currentState.itemsById[itemId];
		if (item) {
			return {
				...currentState,
				itemsById: {
					...currentState.itemsById,
					[itemId]: {
						...item,
						...((_isFunction(change) ? change(item) : change) || {}),
					},
				},
			};
		}
		return {};
	});
}

function updateFolderInDirectory(state, stateId, folderUuid = ROOT_ID, change) {
	return updateDirectoryTypeState(state, stateId, (currentState) => {
		const folder = currentState.foldersById[folderUuid || ROOT_ID];
		if (folder) {
			return {
				...currentState,
				foldersById: {
					...currentState.foldersById,
					[folderUuid || ROOT_ID]: {
						...folder,
						...((_isFunction(change) ? change(folder, currentState) : change) ||
							{}),
					},
				},
			};
		}
		return {};
	});
}

function blockItems(state, stateId, affectedItems) {
	return updateDirectoryTypeState(state, stateId, (currentState) => ({
		blockedItems: {
			...(currentState.blockedItems || {}),
			..._keyBy(affectedItems, (item) => item),
		},
	}));
}

function unblockItems(state, stateId, affectedItems) {
	return updateDirectoryTypeState(state, stateId, (currentState) => ({
		blockedItems: _omit(currentState.blockedItems || {}, affectedItems),
	}));
}

function updateParents(state, stateId, itemsIds, foldersIds, newParentId) {
	let updatedState = state;
	let itemsParents = [];

	for (const itemId of itemsIds) {
		updatedState = updateItemInDirectory(
			updatedState,
			stateId,
			itemId,
			(item) => {
				if (newParentId !== item.parent) {
					itemsParents.push(item.parent);
					return {
						parent: newParentId,
					};
				}
			}
		);
	}

	let foldersParents = [];

	for (const folderId of foldersIds) {
		updatedState = updateFolderInDirectory(
			updatedState,
			stateId,
			folderId,
			(folder) => {
				if (newParentId !== folder.parent) {
					foldersParents.push(folder.parent);
					return {
						parent: newParentId,
					};
				}
			}
		);
	}

	itemsParents = _uniq(itemsParents);

	for (const parentId of itemsParents) {
		updatedState = updateFolderInDirectory(
			updatedState,
			stateId,
			parentId,
			(parent) => {
				const newItems = _difference(parent.items, itemsIds);
				return {
					items: newItems,
					itemsCount: newItems.length,
				};
			}
		);
	}

	foldersParents = _uniq(foldersParents);

	for (const parentId of foldersParents) {
		updatedState = updateFolderInDirectory(
			updatedState,
			stateId,
			parentId,
			(parent) => {
				const newFolders = _difference(parent.subfolders, foldersIds);

				return {
					subfolders: newFolders,
				};
			}
		);
	}

	return updatedState;
}

function mapFolder(folder, parent) {
	return {
		uuid: folder.uuid,
		name: folder.name,
		itemType: itemTypes.folder,
		itemsCount: folder.items_count,
		subfoldersLoaded: folder.subfolders.length === 0,
		subfolders: folder.subfolders,
		itemsLoaded: folder.items_count === 0,
		parent: parent,
		items: [],
		descendantsDepth: folder.descendants_depth,
		depth: folder.depth,
	};
}

function mapItem(item, parent) {
	return {
		...item,
		name: item.title || item.name,
		itemType: itemTypes.item,
		parent: parent,
	};
}

function mapSearchFolder(folder, parent) {
	const isLoaded = folder.folders.length || folder.items.length;

	return {
		uuid: folder.uuid,
		name: folder.name,
		itemType: itemTypes.folder,
		parent: parent,
		descendantsDepth: folder.descendants_depth,
		...(isLoaded
			? {
				subfoldersLoaded: true,
				itemsLoaded: true,
				itemsCount: folder.items,
				subfolders: folder.folders.map((f) => f.uuid),
				items: folder.items.map((item) => item.uuid),
			}
			: {
				subfoldersLoaded: false,
				itemsLoaded: false,
				subfolders: [],
				items: [],
			}),
	};
}

function unwrapSearchResults(currentState, payload) {
	const itemsToProcess = [
		{
			folderId: ROOT_ID,
			folders: payload.folders || [],
			items: _isArray(payload) ? payload : payload.items,
		},
	];

	const allItems = [];
	const allFolders = [];
	let leafsNumber = 0;

	while (itemsToProcess.length) {
		const { folderId, folders, items } = itemsToProcess.pop();

		const { items: mappedItems, subfolders: mappedSubfolders } =
			unwrapSearchFolder(folderId, folders, items);

		allItems.push(...mappedItems);
		allFolders.push(...mappedSubfolders);

		leafsNumber += mappedItems.length;
		if (
			mappedSubfolders.length === 0 &&
			mappedItems.length === 0 &&
			folderId !== ROOT_ID
		) {
			++leafsNumber;
		}

		itemsToProcess.push(
			...folders.map((folder) => ({
				folderId: folder.uuid,
				folders: folder.folders,
				items: folder.items,
			}))
		);
	}

	return {
		search: {
			...currentState.search,
			loading: false,
			loaded: true,
			count: leafsNumber,
		},
		itemsById: _keyBy(allItems, 'uuid'),
		foldersById: {
			[ROOT_ID]: {
				subfoldersLoaded: true,
				itemsLoaded: true,
				subfolders: (payload.folders || []).map((f) => f.uuid),
				items: (_isArray(payload) ? payload : payload.items).map((f) => f.uuid),
			},
			..._keyBy(allFolders, 'uuid'),
		},
	};
}

function unwrapSearchFolder(folderId, folders, items) {
	const subfolders = folders.map((folder) => mapSearchFolder(folder, folderId));
	const mappedItems = items.map((item) => mapItem(item, folderId));

	return { items: mappedItems, subfolders };
}

export default directory;
